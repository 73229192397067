<template>
  <div>
    <el-dialog title="设置下次联系时间" :visible.sync="show" width="40%" @close="exit()">
      <div style="text-align:left;">
        <p style="width:180px;float:left;text-align:right;">
          请选择日期：
        </p>
        <el-date-picker
          v-model="datetime"
          type="date"
          placeholder="选择日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div style="margin-top:10px;text-align:left;">
        <p style="width:180px;float:left;text-align:right;">
          下次联系内容：
        </p>
        <el-input
          type="textarea"
          style="width:220px;vertical-align: top;"
          :rows="2"
          placeholder="请输入内容"
          v-model="contactmsg"
        >
        </el-input>
      </div>
      <div style="margin-top:10px;text-align:left;">
        <p style="width:180px;float:left;text-align:right;">
          其他备注：
        </p>
        <el-input
          type="textarea"
          style="width:220px;vertical-align: top;"
          :rows="2"
          placeholder="请输入内容"
          v-model="otherbz"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exit()">取 消</el-button>
        <el-button type="primary" @click="subTime">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["TalkSetNextContactTime", "ContactId", "serve"],
  watch: {
    TalkSetNextContactTime(o, n) {
      this.show = o;
      this.datetime = "";
      this.contactmsg = "";
      this.otherbz = "";
      this.axios
        .get(this.serve + "/api/UserExtend/get_user_extend?userId=" + this.ContactId)
        .then((res) => {
          if (res.data.code == 1) {
            this.datetime = res.data.result.nextConcatTime;
            this.contactmsg = res.data.result.nextConcatContent;
            this.otherbz = res.data.result.notes;
          }
        });
    },
    ContactId(o, n) {
      console.log(o);
    },
  },
  data() {
    return {
      show: false,
      datetime: "",
      contactmsg: "",
      otherbz: "",
    };
  },
  mounted() {},
  methods: {
    exit() {
      this.show = false;
      this.$emit("update:TalkSetNextContactTime", false);
    },
    subTime() {
      if (this.datetime == "") {
        this.$message({
          type: "error",
          message: "请选择时间",
        });
        return;
      }
      // console.log({ userId: this.ContactId, dateTime: this.datetime });
      this.axios
        .post(
          this.serve + "/api/Concat/set_next_concat_time",
          this.qs.stringify({ userId: this.ContactId, dateTime: this.datetime })
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.axios
              .post(
                this.serve + "/api/UserExtend/add_edit",
                this.qs.stringify({
                  userId: this.ContactId,
                  nextConcatContent: this.contactmsg,
                  notes: this.otherbz,
                })
              )
              .then((res) => {
                if (res.data.code == 1) {
                  this.$message({
                    type: "success",
                    message: "设置成功",
                  });
                  this.exit();
                  this.$emit("changenextcontactTime", this.datetime, this.contactmsg, this.otherbz);
                } else {
                  this.$message({
                    type: "error",
                    message: res.data.msg,
                  });
                }
              });
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        });
    },
  },
};
</script>
