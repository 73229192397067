<template>
  <div>
    <el-tabs
      id="lowerleveltab"
      v-model="activeName"
      @tab-click="handleClick"
      tab-position="top"
      v-loading="loadhuanzhelist"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.8)"
    >
      <el-tab-pane label="查看" name="first">
        <div style="margin-bottom:10px;width:100%;float:left;">
          <el-select
            v-model="kefuchoice"
            filterable
            placeholder="请选择客服"
            @change="change"
            style="float:left;"
            size="small"
          >
            <el-option
              v-for="(item, index) in kefulist"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div style="float:left;" v-if="get_own_roles == 3">
            <el-input
              v-model="searchID"
              placeholder="请输入用户编号或电话号"
              style="width:220px;margin:0 20px;"
              clearable
              @clear="clearsearch"
              size="small"
            ></el-input>
            <el-input
              v-model="searchName"
              placeholder="请输入用户名"
              style="width:220px;margin-right:20px;"
              clearable
              @clear="clearsearch"
              size="small"
            ></el-input>
            <el-button type="primary" @click="search" size="small">查询</el-button>
          </div>
        </div>
        <div class="LLmain">
          <div class="LLlist">
            <van-list class="infinite-list" finished-text="没有更多了">
              <!-- @load="friendload" v-model="loadingfriend"
              :finished="finishedfriend"@load="gethuanzhelist"-->
              <div class="zdymain" v-if="list.length > 0">
                <div
                  v-for="item in list"
                  class="zdyline van-clearfix"
                  :key="item.id"
                  :class="{ act: toID == item.id }"
                  @contextmenu.prevent="show(item, item.id)"
                >
                  <div
                    @click="lookup(item)"
                    style="
                    cursor: pointer;
                    height: 48px;
                    float: left;
                    width: 290px;
                  "
                  >
                    <div class="zdyuserimg">
                      <img :src="filterheadImg(item.headImg)" alt="" />
                    </div>
                    <div class="zdycustname" style="width: 230px">
                      <span
                        style="
                        width: 150px;
                        display: inline-block;
                        line-height: 14px;
                        vertical-align: middle;
                      "
                        v-if="item.desc != '' && item.desc != null && item.desc != undefined"
                        >{{ item.desc }}</span
                      >
                      <span
                        style="
                        width: 150px;
                        display: inline-block;
                        line-height: 14px;
                        vertical-align: middle;
                      "
                        v-else-if="item.name != '' && item.name != null && item.name != undefined"
                        >{{ item.name | filtername(item.id) }}</span
                      >
                      <span
                        style="
                        width: 150px;
                        display: inline-block;
                        line-height: 14px;
                        vertical-align: middle;
                      "
                        v-else
                        >待完善用户{{ item.id.substr(item.id.length - 4) }}</span
                      >
                      <span
                        style="
                        width: 65px;
                        float: right;
                        font-size: 12px;
                        color: #808080;
                        transform: scale(0.8);
                        line-height: 15px;
                        margin-top: 10px;
                      "
                      >
                        <i style="display: inline-block">{{ item.lastContactTime | capitalize }}</i>
                        <i style="display: inline-block">{{
                          item.lastContactTime | capitalize2
                        }}</i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
          </div>
          <div class="talkmain" style="height:80vh;" v-if="dialogFormVisible">
            <div class="talkname">{{ talkname }}</div>
            <p class="lookhistory" v-if="lookmoreshow" @click="loadmore">
              查看历史记录
            </p>
            <div>
              <div class="talk-main" style="height:65vh;" id="scrolldIV1">
                <div class="talkline" v-for="(item, index) in talklist" :key="index">
                  <div class="timeline">{{ item.sendTime }}</div>
                  <div class="leftimg" v-if="item.from == toID">
                    <img :src="userheadimg" alt="" />
                  </div>
                  <div class="talkleft" v-if="item.from == toID">
                    <p style="margin: 0" v-if="item.type == '0'">
                      <span>
                        {{ item.content }}
                      </span>
                    </p>
                    <p style="margin: 0" v-if="item.type == '1'">
                      <span>
                        <img :src="item.content" alt="" @click="big(item.content)" />
                      </span>
                    </p>
                  </div>
                  <div class="rightimg" v-if="item.from != toID">
                    <img :src="headimg" alt="" />
                  </div>
                  <div class="talkright" v-if="item.from != toID">
                    <span>
                      <i v-if="item.type == '0'"
                        ><b>{{ item.content }}</b></i
                      >
                      <i v-if="item.type == '1'">
                        <img :src="item.content" alt="" @click="big(item.content)" />
                      </i>
                      <i v-if="item.type == 3">
                        <div style="width: 220px">
                          <p>寻医报名表</p>
                          <p>
                            您好，为了便于更好的帮您寻找擅长治疗您疾病的好医生，请点击对话框，详细填写报名表。
                          </p>
                          <p style="display: none"></p>
                        </div>
                      </i>
                      <i v-if="item.type == '2'">
                        <div style="width: 100px">
                          <p>问诊单:{{ JSON.parse(item.content).name }}</p>
                          <p>诊单编号:{{ JSON.parse(item.content).id }}</p>
                          <p
                            @click="
                              EditZD = true;
                              EditMsg.kid = JSON.parse(item.content).id;
                            "
                          >
                            查看详情>
                          </p>
                        </div>
                      </i>
                      <i v-if="item.type == 4">
                        <div style="width: 300px">
                          <p>精准匹配</p>
                          <div>
                            <div
                              class="talkdocline"
                              v-for="(it, index) in JSON.parse(item.content)"
                              :key="index"
                            >
                              <img :src="it.headimg" alt="" />
                              <div class="wenzi">
                                <p class="wenzidocname">
                                  {{ it.name }}<span>{{ it.rank }}</span>
                                </p>
                                <p class="wenzihospatil">{{ it.hospital }}</p>
                                <p class="wenzigoodat">
                                  擅长：
                                  <span>{{ it.goodat }}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <p style="display: none"></p>
                        </div>
                      </i>
                      <i v-if="item.type == '5'">
                        <div style="width: 220px">
                          <p>寻医问诊表</p>
                          <p>
                            您好，为了便于帮您精准匹配好医生，请点击此对话框，详细填写寻医问诊表。
                          </p>
                          <p
                            @click="
                              EditXY = true;
                              EditXYMsg.kid = JSON.parse(item.content).id;
                              EditXYMsg.noedit = true;
                            "
                          >
                            查看详情>
                          </p>
                        </div>
                      </i>
                      <i v-if="item.type == '6'">
                        <div style="width: 220px">
                          <p>{{ JSON.parse(item.content).doc_name }}&nbsp;医生&nbsp;问诊单</p>
                          <p>
                            您好，为了让匹配的好医生详细了解您的病情信息，给出准确治疗方案，请点击此对话框，详细填写医生问诊单。
                          </p>
                          <p
                            @click="
                              EditZD = true;
                              EditMsg.kid = JSON.parse(item.content).id;
                              EditMsg.noedit = true;
                            "
                          >
                            查看详情>
                          </p>
                        </div>
                      </i>

                      <i v-if="item.type == '7'">
                        <div style="width: 220px">
                          <p>频道名:{{ JSON.parse(item.content).channel }}</p>
                          <p>ID:{{ JSON.parse(item.content).id }}</p>
                          <p @click="lookchanneldetail(JSON.parse(item.content))">
                            查看详情>
                          </p>
                        </div>
                      </i>
                      <i v-if="item.type == '8'">
                        <div style="width: 220px">
                          <p style="background: #ff8040">支付问诊费用</p>
                          <p>
                            您好，在线医助给您发来了支付问诊费用请求，点击开始支付。
                          </p>
                          <p @click="lookzfdetail(JSON.parse(item.content).id, 'zf')">
                            查看详情>
                          </p>
                        </div>
                      </i>
                      <i v-if="item.type == '9'">
                        <div style="width: 220px">
                          <p style="background: #ff8040">支付订单费用</p>
                          <p>订单ID:{{ JSON.parse(item.content).yforderno }}</p>
                          <p>ID:{{ JSON.parse(item.content).id }}</p>
                          <p @click="lookzfdetail(JSON.parse(item.content).id, 'dd')">
                            查看详情>
                          </p>
                        </div>
                      </i>
                      <i v-if="item.type == '10'">
                        <div style="width: 300px">
                          <p>好转病例</p>
                          <div class="talkdocline" style="border-bottom: none">
                            <img :src="JSON.parse(item.content).headimg" alt="" />
                            <div class="wenzi">
                              <p class="wenzidocname">
                                {{ JSON.parse(item.content).name
                                }}<span>{{ JSON.parse(item.content).ill_name }}</span>
                              </p>
                              <p class="wenzigoodat">
                                <span>{{ JSON.parse(item.content).des }}</span>
                              </p>
                            </div>
                          </div>
                          <p style="display: none"></p>
                        </div>
                      </i>
                      <i v-if="item.type == '11'">
                        <div style="width: 220px">
                          <p></p>
                          <p>
                            {{ JSON.parse(item.content).name }}给患者:{{
                              changetel(JSON.parse(item.content).id)
                            }}发送了留言短信。
                          </p>
                          <p></p>
                        </div>
                      </i>
                      <i v-if="item.type == '13'">
                        <div style="width: 220px">
                          <p>反馈表</p>
                          <p>
                            您好，为了便于您以后的治疗请认真填写反馈信息表。
                          </p>
                          <p
                            @click="
                              lookfeedback = true;
                              feedbacknoedit = false;
                              feedbackid = JSON.parse(item.content).id;
                            "
                          >
                            查看详情>
                          </p>
                        </div>
                      </i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="统计" name="second">
        <talkcensuspatient :serve="serve"></talkcensuspatient>
      </el-tab-pane>
    </el-tabs>
    <!-- 编辑寻医弹窗开始 -->
    <el-dialog title="编辑寻医单" :modal="false" :visible.sync="EditXY" width="566px">
      <xypreview
        :kid="EditXYMsg.kid"
        :custid="EditXYMsg.custid"
        v-if="EditXY"
        :EditZD="EditXY"
        :noedit="false"
      ></xypreview>
    </el-dialog>
    <!-- 编辑寻医弹窗结束 -->
    <!-- 编辑诊单开始 -->
    <el-dialog title="编辑诊单" :modal="false" :visible.sync="EditZD" width="566px">
      <preview
        :kid="EditMsg.kid"
        :custid="EditMsg.custid"
        v-if="EditZD"
        :EditZD="EditZD"
        :noedit="false"
      ></preview>
    </el-dialog>
    <!-- 编辑诊单结束 -->
    <!-- 查看反馈表详情开始 -->
    <feedback
      :lookfeedback.sync="lookfeedback"
      :feedbackid="feedbackid"
      :feedbacknoedit="feedbacknoedit"
    ></feedback>
    <!-- 查看反馈表详情结束 -->
    <!-- 查看订单详情 -->
    <talklookorder
      :lookorder.sync="lookorder"
      :talkorderid="talkorderid"
      :talkordertype="talkordertype"
    ></talklookorder>
    <!-- 查看订单详情 -->
    <!-- 右键菜单开始 -->
    <div id="rightmenuL" v-show="visible">
      <div @click="lookuser1('', 'right')">用户信息</div>
    </div>
    <!-- 右键菜单结束 -->
    <!-- 查看大图开始 -->
    <el-dialog title="查看图片" :visible.sync="bigpic">
      <img :src="bigpicurl" alt="" width="90%" />
    </el-dialog>
    <!-- 查看大图结束 -->
    <!-- 查看订单详情 -->
    <talklookorder
      :lookorder.sync="lookorder"
      :talkorderid="talkorderid"
      :talkordertype="talkordertype"
    ></talklookorder>
    <!-- 查看订单详情 -->
  </div>
</template>
<script>
import "../assets/talkStyle.css";
import preview from "../components/preview.vue";
import xypreview from "../components/xypreview.vue";
import feedback from "../components/feedback.vue";
import talkcensuspatient from "../components/talkcensuspatient.vue";
import talklookorder from "../components/talklookorder.vue";

export default {
  props: ["gettalkLowerLevel", "get_own_roles", "serve", "lookuser"],
  components: {
    preview,
    xypreview,
    feedback,
    talkcensuspatient,
    talklookorder
  },
  data() {
    return {
      serveurl: "",
      uid: sessionStorage.getItem("servicer_id"),
      kefuchoice: "",
      kefulist: [],
      dialogFormVisible: false,
      talkname: "",
      list: [],
      friendpage: 1,
      indexedDBindex: 1,
      talklist: [],
      toID: "",
      loadingfriend: false,
      finishedfriend: false,
      female: require("../assets/female.png"),
      male: require("../assets/male.png"),
      lookmoreshow: true,
      headimg: "",
      userheadimg: "",
      noedit: "",
      EditZD: false, //编辑诊单
      EditMsg: {
        kid: "",
        custid: "",
        noedit: false,
      },
      EditXY: false, //编辑寻医
      EditXYMsg: {
        kid: "",
        custid: "",
        noedit: false,
      },
      lookfeedback: false, //显示反馈表弹窗
      feedbackmsg: {}, //选择的反馈模板信息
      feedbackid: "", //反馈信息id
      feedbacknoedit: true, //反馈表是否可以编辑
      indexedDBindex: 1,
      custall: "",
      visible: false,
      activeName: "first",
      bigpic: false,
      bigpicurl: "",
      searchID: "", //搜索的用户ID
      searchName: "", //搜索的用户姓名
      loadhuanzhelist: false,
      lookorder:false,
      talkorderid:"",
      talkordertype:"",
    };
  },

  watch: {
    gettalkLowerLevel(o, n) {
      if (o) {
        this.kefuchoice = "";
        this.list = [];
        this.friendpage = 1;
        this.dialogFormVisible = false;
        this.getkefulist();
      }
    },
    visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    },
  },
  filters: {
    capitalize: function(value) {
      if (value == "0001-01-01 00:00:00") return "";
      if (!value) return "";
      value = value.substr(0, 10);
      return value;
    },
    capitalize2: function(value) {
      if (value == "0001-01-01 00:00:00") return "";
      if (!value) return "";
      value = value.substr(10, value.length);
      return value;
    },
    filtername: function(value, id) {
      if (value.indexOf("dxhs_") == 0) {
        return "待完善用户" + id.substr(id.length - 4);
      } else {
        return value;
      }
    },
  },
  mounted() {
    /*获取客服头像*/
    if (sessionStorage.getItem("headimg")) {
      this.headimg = sessionStorage.getItem("headimg");
    } else {
      this.headimg = this.female;
    }
  },
  methods: {
    //查看诊费详情
    lookzfdetail(id, type) {
      this.lookorder = true;
      this.talkorderid = id;
      this.talkordertype = type;
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    lookuser1(item) {
      this.visible = false;
      this.$emit("lookuser", this.custall, "lowerright");
    },
    //关闭右键菜单
    closeMenu() {
      this.visible = false;
    },
    //显示右键菜单
    show(item, id) {
      this.visible = true;
      var ele = "";
      var e = window.event;
      ele = document.getElementById("rightmenuL");
      ele.style.top = e.clientY + "px";
      ele.style.left = e.clientX + "px"; //获取当前位置显示
      this.custall = item;
    },
    getkefulist() {
      this.axios.get(this.serve + "/api/Employee/get_employee?Id=" + this.uid).then((res) => {
        if (res.data.code == 1) {
          this.kefulist = res.data.result;
        }
      });
    },
    change() {
      this.list = [];
      this.friendpage = 1;
      this.finishedfriend = false;
      this.gethuanzhelist();
    },
    gethuanzhelist() {
      // this.loadingfriend = true;
      this.loadhuanzhelist = true;
      this.axios
        .get(
          this.serve +
            "/api/User/get_user_with_relation_ex?userID=" +
            this.kefuchoice +
            "&index=" +
            this.friendpage
        )
        .then((res) => {
          if (res.data.code == 1) {
            if (res.data.result.length > 0) {
              this.list = this.list.concat(res.data.result);
              this.loadhuanzhelist = false;
              this.friendpage = this.friendpage + 1;
            } else {
              this.loadhuanzhelist = false;
              this.$message({
                message: "没有数据",
                type: "error",
              });
              this.finishedfriend = true;
            }
          } else {
            this.finishedfriend = true;
            this.loadingfriend = false;
          }
          this.loadingfriend = false;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    lookup(item) {
      this.dialogFormVisible = true;
      this.getonepagehistory(item);
      this.toID = item.id;
      if (item.desc != "" && item.desc != null && item.desc != undefined) {
        this.talkname = item.desc;
      } else if (item.name != "" && item.name != null && item.name != undefined) {
        //展示聊天姓名
        if (item.name.indexOf("dxhs_") == 0) {
          this.talkname = "待完善用户" + item.id.substr(item.id.length - 4);
        } else {
          this.talkname = item.name;
        }
      } else {
        this.talkname = "资料待完善用户" + item.id.substr(item.id.length - 4);
      }
      if (
        item.headImg == "" ||
        item.headImg == null ||
        item.headImg == undefined ||
        item.headImg == "null" ||
        item.headImg == "undefined"
      ) {
        this.userheadimg = this.male;
      } else {
        this.userheadimg = item.headImg;
      }
      this.lookmoreshow = true;
    },
    getonepagehistory(item) {
      this.axios
        .get(this.serve + "/api/Message/get_user_history_message?id=" + item.id + "&index=1")
        .then((res) => {
          if (res.data.code == 1) {
            this.talklist = res.data.result;
            this.indexedDBindex = 2;
            this.$nextTick(() => {
              var div = document.getElementById("scrolldIV1");
              div.scrollTop = div.scrollHeight;
            });
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    loadmore() {
      this.axios
        .get(
          this.serve +
            "/api/Message/get_user_history_message?id=" +
            this.toID +
            "&index=" +
            this.indexedDBindex
        )
        .then((res) => {
          if (res.data.code == 1) {
            if (res.data.result.length > 0) {
              var newtalklist = res.data.result;
              var oldtalklist = this.talklist;
              this.talklist = [];
              this.talklist = newtalklist.concat(oldtalklist);
              if (this.indexedDBindex == 1) {
                this.talklist = [];
              }
              this.indexedDBindex++;
            } else {
              this.$message({
                message: "没有更多了",
                type: "warning",
              });
              this.lookmoreshow = false;
            }
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    //过滤头像
    filterheadImg(val) {
      var url = "";
      if (val == null || val == "" || val == undefined || val == "null") {
        url = this.male;
      } else {
        url = val;
      }
      return url;
    },
    changetel(val) {
      var reg = /^(\d{3})\d*(\d{4})$/;
      var aaa = val.replace(reg, "$1****$2");
      return aaa;
    },
    big(url) {
      this.bigpic = true;
      this.bigpicurl = url;
    },
    //根据用户姓名、手机号、用户编号搜索
    search() {
      this.list = [];
      this.dialogFormVisible = false;
      if (this.searchID == "" && this.searchName == "") {
        this.$message({
          message: "请输入任意一个搜索条件",
          type: "warning",
        });
        return;
      }
      //根据姓名搜索
      if (this.searchName != "") {
        this.searchbyname(this.searchName);
      }
      //根据用户编号或电话号搜索
      if (this.searchID != "") {
        this.searchbyid(this.searchID);
      }
    },
    searchbyname(name) {
      this.axios.get(this.serve + "/api/User/get_user_by_name?name=" + name).then((res) => {
        if (res.data.code == 1) {
          console.log(res.data.result);
          this.list = res.data.result;
        }
      });
    },
    searchbyid(id) {
      //先查询接口换手机号
      this.axios.get("/gu/get_cust_info_by_custid?cust_id=" + id).then((res) => {
        if (res.data.code == 0) {
          // console.log(res);
          var tel = res.data.result.Tel1;
          this.searchbyname(tel);
        } else {
          this.searchbyname(id);
        }
      });
    },
    clearsearch() {
      this.list = [];
      this.dialogFormVisible = false;
    },
  },
};
</script>
<style>
.LLlist {
  height: 80vh;
  width: 320px;
  float: left;
}
.LLmain {
  height: 75vh;
}
.LLmain .talkmain {
  width: 75%;
  float: left;
}
.LLmain .timeline {
  text-align: center;
}
.LLmain .lookhistory {
  text-align: center;
}
.LLlist .infinite-list {
  height: 80vh;
  width: 320px;
}
.LLlist .zdymain {
  height: 70vh;
  float: inherit;
  border: none;
}
.LLlist .zdyline {
  float: inherit;
}
#talktabs #lowerleveltab .el-tabs__header {
  width: auto;
  height: auto;
}
#talktabs #lowerleveltab .el-tabs__nav-scroll {
  background: none;
}
#talktabs #lowerleveltab .el-tabs__active-bar {
  width: 28px !important;
}
</style>
