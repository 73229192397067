<template>
  <div class="drugtc">
    <el-dialog title="查看订单" :visible.sync="show" width="80%" @close="exit()">
      <el-divider content-position="left">基本信息</el-divider>
      <el-form ref="form" :model="form" label-width="80px" class="usermsg" size="mini">
        <el-row>
          <!-- <el-col :span="6">
            <el-form-item label="订单编号">
              <el-input v-model="form.orderid"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item label="会员编号">
              <el-input v-model="form.custid" readonly=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="收件姓名">
              <el-input v-model="form.custname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="会员电话">
              <el-input v-model="tel" readonly=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <!-- <el-col :span="6">
            <el-form-item label="付款方式">
              <el-select v-model="form.payway" placeholder="请选择">
                <el-option
                  v-for="item in paywayoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item label="制单人">
              <el-input v-model="form.servicerid" readonly=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地址">
              <el-cascader
                size="mini"
                :options="options"
                v-model="form.selectedOptions"
                @change="handleChange"
                style="width:400px;"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="详细地址">
              <el-input v-model="form.addres"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider content-position="left">订单详情</el-divider>
      <div class="orderdetailbox">
        <div class="firstline">
          <el-row>
            <el-col :span="4">编号</el-col>
            <el-col :span="4">名称</el-col>
            <el-col :span="4">单价</el-col>
            <el-col :span="4">规格</el-col>
            <el-col :span="4">数量</el-col>
            <el-col :span="4">小计</el-col>
          </el-row>
        </div>
        <div class="detailmain">
          <div class="csrolldiv">
            <div v-for="(item, index) in drugdetail" :key="index">
              <el-row>
                <el-col :span="24" class="fontblod"> 处方{{ index + 1 }} </el-col>
              </el-row>
              <el-row v-for="(item1, index) in cartsfilter(item.carts)" :key="index">
                <el-col :span="4">{{ item1.code }}</el-col>
                <el-col :span="4">{{ item1.title }}</el-col>
                <el-col :span="4">￥{{ item1.price }}</el-col>
                <el-col :span="4">{{ item1.grams }}</el-col>
                <el-col :span="4">{{ item1.num }}</el-col>
                <el-col :span="4">￥{{ littlenum(item1) }}</el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="zongji">
          <el-row>
            <el-col :span="4">合计</el-col>
            <el-col :span="18" class="textleft">￥{{ hejiprice }}</el-col>
          </el-row>
        </div>
      </div>
      <el-button type="primary" v-if="nodrug" @click="sub">生成订单</el-button>
    </el-dialog>
  </div>
</template>
<script>
import { insert, del, push, read, search } from "../assets/sql2.js";
import { addu, readu, delu, updateu, putu } from "../assets/msglist.js";
import { regionData, CodeToText } from "element-china-area-data";
export default {
  props: ["lookdrugtc", "drugkid", "drugcustid", "drugcusttel", "talkconnection", "toID", "db"],
  watch: {
    lookdrugtc(o, n) {
      this.show = o;
      this.form.custname = "";
      this.form.selectedOptions = [];
      this.form.addres = "";
    },
    drugkid(o, n) {
      this.getdrug(o);
      // this.getorderid(o);
    },
    drugcustid(o, n) {
      this.form.custid = o;
    },
    drugcusttel(o, n) {
      this.form.tel = o;
      this.tel = o.substring(0, 3) + "****" + o.substring(7);
    },
  },
  data() {
    return {
      show: false,
      drugdetail: [],
      hejiprice: "",
      options: regionData,
      paywayoptions: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      form: {
        orderid: "", //订单编号
        custid: "", //会员编号
        custname: "", //会员姓名
        tel: "", //电话号1
        payway: "", //付款方式
        servicerid: sessionStorage.getItem("servicer_id"), //制单人
        selectedOptions: [],
        addres: "", //详细地址
      },
      tel: "",
      servicerid: sessionStorage.getItem("servicer_id"),
      sid: sessionStorage.getItem("id"),
      KID: "",
      solutionList: [],
      nodrug: true,
    };
  },
  mounted() {},
  methods: {
    exit() {
      this.show = false;
      this.$emit("update:lookdrugtc", false);
    },
    getdrug(kid) {
      this.axios.get("/docpres/get_gu_docpres_order_by_kid?kid=" + kid).then((res) => {
        if (res.data.code == 0) {
          this.drugdetail = res.data.result;
          this.hejiprice = res.data.amount;
          this.KID = kid;
          this.nodrug = true;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
          this.drugdetail = [];
          this.hejiprice = "";
          this.KID = "";
          this.nodrug = false;
        }
      });
    },
    getsolutionList(val) {},
    getorderid() {
      this.axios
        .post("/order/create_order_id", this.qs.stringify({ servicer_id: servicerid }))
        .then((res) => {
          if (res.data.code == 0) {
            this.drugdetail = res.data.result;
            this.hejiprice = res.data.amount;
          } else {
            this.drugdetail = [];
            this.hejiprice = "";
          }
        });
    },
    handleChange() {
      var loc = "";
      for (let i = 0; i < this.form.selectedOptions.length; i++) {
        loc += CodeToText[this.form.selectedOptions[i]];
      }
    },
    cartsfilter(val) {
      if (val != "" && val != null && val != undefined) {
        val = JSON.parse(val);
        return val;
      }
    },
    littlenum(item) {
      var price = item.price;
      var num = item.num;
      return price * num;
    },
    slicedrug(val) {
      var str = "",
        arr = [];
      val.forEach((element) => {
        arr = arr.concat(this.cartsfilter(element.carts));
      });
      arr = arr.reduce((obj, item) => {
        let find = obj.find((i) => i.id === item.id);
        let _d = {
          ...item,
          frequency: 1,
        };
        find ? ((find.num += item.num), find.frequency++) : obj.push(_d);
        return obj;
      }, []);
      this.solutionList = arr;
      console.log(arr);
      arr.forEach((element) => {
        str += element.title + "*" + element.num + ";";
      });
      return str;
    },
    sub() {
      //提交新的订单是否需要设置初始状态
      var drugstr = this.slicedrug(this.drugdetail);
      var form = {
        kid: this.KID,
        cust_id: this.form.custid,
        servicer_id: this.servicerid,
        receiver: this.form.custname,
        province: CodeToText[this.form.selectedOptions[0]],
        city: CodeToText[this.form.selectedOptions[1]],
        counties: CodeToText[this.form.selectedOptions[2]],
        adress: this.form.addres,
        tel: this.form.tel,
        bak_tel: "",
        amount: this.hejiprice,
        amount_discounts: 0,
        suggestions: "",
        solution: drugstr,
        solution_list: JSON.stringify(this.solutionList),
      };
      console.log(form);
      if (form.receiver == "") {
        this.$message({
          message: "请填写用户姓名",
          type: "error",
        });
        return;
      }
      if (this.form.selectedOptions.length <= 0) {
        this.$message({
          message: "请填写用户的收货地址",
          type: "error",
        });
        return;
      }
      if (this.form.addres == "") {
        this.$message({
          message: "请填写用户的收货地址",
          type: "error",
        });
        return;
      }
      this.axios
        .post("/order/create_order_id", this.qs.stringify({ servicer_id: this.servicerid }))
        .then((res) => {
          if (res.data.code == 0) {
            var order_id = res.data.order_id;
            form.order_id = order_id;
            this.axios.post("/order/insert_order", this.qs.stringify(form)).then((res) => {
              if (res.data.code == 0) {
                //创建完成后，改变订单状态为提交，等待审核
                this.axios
                  .post(
                    "/order/update_order_state",
                    this.qs.stringify({ state: 1, order_id: order_id })
                  )
                  .then((res) => {
                    if (res.data.code == 0) {
                      this.$message({
                        message: "订单创建成功,请等待审核",
                        type: "success",
                      });
                    } else {
                      this.$message({
                        message: res.data.msg,
                        type: "error",
                      });
                    }
                  });
                //发给患者
                // var toID = this.toID;
                // var fromID = "s" + this.sid;
                // var orderid = res.data.result.Order_ID;
                // var id = res.data.result.ID;
                // var _kid = this.KID;
                // this.talkconnection
                //   .invoke(
                //     "send",
                //     toID,
                //     fromID,
                //     JSON.stringify({ yforderno: orderid, id: id, KID: _kid }),
                //     9
                //   )
                //   .then((res) => {
                //     console.log(res);
                //     if (res.err == 1) {
                //       //发送成功
                //       this.$message({
                //         message: "发送成功",
                //         type: "success",
                //       });
                //       push(this.db, toID, res.data);
                //       updateu(this.db, this.sid, toID, "sendTime", res.data, function(res) {});
                //       this.$emit("sendMoneyChangeAlllist");
                //       this.$emit("sendMoneyChangeTalklist");
                //       this.$emit("drugtcfalse");
                //       this.exit();
                //     } else {
                //       this.$message({
                //         message: "发送失败，请重新发送",
                //         type: "error",
                //       });
                //     }
                //   });
                // this.$message({
                //   message: res.data.msg,
                //   type: "success",
                // });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
  },
};
</script>
<style>
.drugtc {
  color: #606266;
}
.drugtc .el-dialog__body {
  padding: 0 20px 20px;
}
.usermsg {
  width: 100%;
}
.usermsg .el-select,
.usermsg .el-cascader {
  float: left;
}
.orderdetailbox {
  width: 100%;
  border: 1px solid #ebeef5;
  border-bottom: none;
  margin-bottom: 20px;
}
.firstline {
  width: 100%;
  background-color: #f5f7fa;
}
.detailmain {
  border-bottom: 1px solid #ebeef5;
  height: 300px;
  overflow-y: auto;
}
.detailmain .csrolldiv {
  height: auto;
}
.orderdetailbox .el-row {
  height: 38px;
  line-height: 38px;
  border-bottom: 1px solid #ebeef5;
}
.orderdetailbox .el-col {
  border-right: 1px solid #ebeef5;
}
.orderdetailbox .el-row .el-col:last-child {
  border-right: none;
}
.zongji {
  background-color: #f5f7fa;
}
.fontblod {
  font-weight: bold;
}
.textleft {
  text-align: left;
  padding-left: 70px;
}
</style>
