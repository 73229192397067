<template>
  <div>
    <el-dialog title="查看药方" :visible.sync="show" width="50%" class="drugmain" @close="exit()">
      <div>
        <p class="drugtitle">患者信息</p>
        <div class="drugp">
          <p>
            患者编号:<span>{{ drugdetail.patient_id }}</span>
          </p>
          <p>
            患者姓名:<span>{{ drugdetail.patient_name }}</span>
          </p>
          <p>
            患者性别:<span>{{ drugdetail.patient_sex }}</span>
          </p>
          <p>
            患者年龄:<span>{{ drugdetail.patient_age }}</span>
          </p>
        </div>
      </div>
      <el-divider></el-divider>
      <div>
        <p class="drugtitle">医生信息</p>
        <div class="drugp">
          <p>
            医生编号:<span>{{ drugdetail.doc_id }}</span>
          </p>
          <p>
            医生姓名:<span>{{ drugdetail.doc_name }}</span>
          </p>
        </div>
      </div>
      <el-divider></el-divider>
      <div>
        <p class="drugtitle">药方信息</p>
        <div>
          <p>
            辨病信息：<span>{{ drugdetail.doctor_disease }}</span>
          </p>
          <p>
            辨证信息：<span>{{ drugdetail.doctor_dialectic }}</span>
          </p>
          <p>
            中药药态：<span>{{ doctor_shape }}</span>
          </p>
          <p>
            药单信息：<span>{{ carts }}</span>
          </p>
          <p>
            总剂数：<span>{{ drugdetail.doctor_dosage }}</span>
          </p>
          <p>
            用法用量：<span>{{ doctor_pharmacy }}</span>
          </p>
        </div>
      </div>
      <el-divider></el-divider>
      <div>
        <p>备注：</p>
        <span>{{ drugdetail.doctor_remark }}</span>
      </div>
      <div>
        <p>总价：</p>
        <span>{{ drugdetail.amount_str }}</span>
      </div>
      <div style="margin-top:20px;text-align:center;" v-if="drugdetail.order_state == 1">
        <el-button type="primary" @click="shdrug">下单</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { insert, del, push, read, search } from "../assets/sql2.js";
import { addu, readu, delu, updateu, putu } from "../assets/msglist.js";
export default {
  props: ["drug", "drugdetail", "talkconnection", "toID", "db"],
  data() {
    return {
      show: false,
      carts: "", //药方信息
      doctor_shape: "",
      doctor_pharmacy: "",
      showsh: false,
      sid: sessionStorage.getItem("id"),
      serverid: sessionStorage.getItem("servicer_id"),
    };
  },
  watch: {
    drug(o, n) {
      this.show = o;
      this.carts = "";
      this.doctor_shape = "";
      this.cartsfilter(this.drugdetail.carts);
      this.shapefilter(this.drugdetail.doctor_shape);
      this.pharmacyfilter(this.drugdetail.doctor_pharmacy);
    },
  },
  methods: {
    exit() {
      this.show = false;
      this.$emit("update:drug", false);
    },
    cartsfilter(val) {
      if (val != "" && val != null && val != undefined) {
        val = JSON.parse(val);
        var str = "";
        for (let a = 0; a < val.length; a++) {
          const element = val[a];
          str += element.title + "：" + element.num + element.grams + "；";
        }
        this.carts = str;
      }
    },
    shapefilter(val) {
      var dic = {
        1:'内服',
        2:"外用"
      }
      return dic[val];
    },
    pharmacyfilter(val) {
      var str = "";
      if (val != "" && val != null && val != undefined) {
        val = JSON.parse(val);
      }
      var dic={
        meiRiJiShu: "每日剂数",
        meiJiCiShu: "每剂次数",
        zuiXiaoZhongLiang: "最小重量",
        zuiDaZhongLiang: "最大重量",
        meiRiCiShu: "每日次数",
        meiRiKeShu: "每日克数",
        yueYongTianShu: "约用天数",
        meiRiWanShu: "每日丸数",
        meiWanKeShu: "每丸克数",
        fuYongCiShu: "服用次数",
        jitianhuan: "每多少天换一次",
      };
      for (var key in dic) {
           for (var key1 in val) {
           if(key == key1){
             str += dic[key] + "：" + val[key1] + "；";
           }
        }
      }
      this.doctor_pharmacy = str;
      return str;
    },
    //审核药方
    shdrug() {
      this.$confirm("是否确认无误？确认后将发送订单给用户！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.sendorder();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    sendorder() {
      var talkconnection = this.talkconnection;
      var form = {
        doctor_id: "2c5cb7a658bd4a09a171c38e7a3c896d", //毅飞的医生ID this.drugdetail.doctor_id
        doctor_shape: this.drugdetail.doctor_shape, //中药药态
        doctor_disease: this.drugdetail.doctor_disease, //辨病信息
        doctor_dialectic: this.drugdetail.doctor_dialectic, //辩证信息
        doctor_dosage: this.drugdetail.doctor_dosage, //总共剂数
        doctor_pharmacy: this.drugdetail.doctor_pharmacy, //用法用量
        doctor_remark: this.drugdetail.doctor_remark, //医生
        patient_id: this.drugdetail.patient_id, //患者编号
        patient_name: this.drugdetail.patient_name, //患者姓名
        patient_age: this.drugdetail.patient_age, //患者年龄
      };
      var str = "";
      var arr = JSON.parse(this.drugdetail.carts);
      for (let m = 0; m < arr.length; m++) {
        const element = arr[m];
        str += element.code + "=" + element.num + ",";
      }
      form.carts = str;
      var yforderno = "";
      form.patient_sex = this.drugdetail.patient_sex == "男" ? 1 : 0; //患者性别
      this.axios.post("/yf/lucky_recipel_submit", this.qs.stringify(form)).then((res) => {
        console.log(res);
        if (res.data.res == 1) {
          yforderno = res.data.yf_order_no;
          this.axios
            .post(
              "/docpres/update_get_gu_docpres_order_yf_no",
              this.qs.stringify({
                yf_order_no: res.data.yf_order_no,
                id: this.drugdetail.id,
                input_id: this.serverid,
              })
            )
            .then((res) => {
              console.log(res);
              if (res.data.code == 0) {
                var toID = this.toID;
                var fromID = "s" + this.sid;
                // var alllist = JSON.parse(localStorage.getItem("usertalklist"+this.sid));
                var id = this.drugdetail.id;
                talkconnection
                  .invoke("send", toID, fromID, JSON.stringify({ yforderno: yforderno, id: id }), 9)
                  .then((res) => {
                    console.log(res);
                    if (res.err == 1) {
                      //发送成功
                      let measge = {
                        content: res.data.content,
                        from: fromID,
                        type: "9",
                        sendTime: res.data.sendTime,
                      };
                      push(this.db, toID, res.data);
                      updateu(this.db, this.sid, toID, "sendTime", measge, function(res) {});
                      this.$emit("uploadlist");
                      this.exit();
                    } else {
                      this.$message({
                        message: "发送失败，请重新发送",
                        type: "error",
                      });
                    }
                  });
              }
            });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    //按照日期排序
    comparetime(property) {
      return function(a, b) {
        var value1 = new Date(a[property]).getTime();
        var value2 = new Date(b[property]).getTime();
        return value2 - value1;
      };
    },
    /** 获取当前时间 格式：yyyy-MM-dd HH:MM:SS*/
    getCurrentTime() {
      var date = new Date(); //当前时间
      var month = this.zeroFill(date.getMonth() + 1); //月
      var day = this.zeroFill(date.getDate()); //日
      var hour = this.zeroFill(date.getHours()); //时
      var minute = this.zeroFill(date.getMinutes()); //分
      var second = this.zeroFill(date.getSeconds()); //秒
      //当前时间
      var curTime =
        date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
      return curTime;
    },
    /**补零*/
    zeroFill(i) {
      if (i >= 0 && i <= 9) {
        return "0" + i;
      } else {
        return i;
      }
    },
  },
};
</script>
<style>
.drugmain {
  text-align: left;
}
.drugmain div {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
.drugmain .drugtitle {
  font-size: 18px;
  color: #606266;
}
.drugmain p {
  color: #409eff;
}
.drugmain p span {
  color: #606266;
}
.drugp p {
  width: 25%;
  float: left;
  margin: 0;
}
.drugmain .el-divider--horizontal {
  margin: 10px 0;
}
</style>
