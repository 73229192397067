<template>
  <div style="overflow-y: auto;overflow-x:hidden;height: 100vh;padding:10px">
    <div class="add" >
      <!-- <p style="float: left;margin: 0;">选择状态：</p> -->
      <el-select
        v-if="getownroles != 0"
        v-model="kefuchoice"
        multiple
        collapse-tags
        value-key="serviceId"
        placeholder="请选择客服"
        style="margin-right:10px;width:200px;"
        size="small"
      >
        <el-option label="全选" value="all"></el-option>
        <el-option v-for="item in kefulist" :key="item.name" :label="item.name" :value="item">
        </el-option>
      </el-select>

      <el-select
        v-model="select2"
        placeholder="请选择支付状态"
        style="float: left;width: 150px;margin-right:10px;"
        size="small"
      >
        <el-option v-for="item in options2" :key="item.id" :label="item.value" :value="item.id">
        </el-option>
      </el-select>
      <!-- <p style="float: left;margin: 0;margin-left: 20px;">选择日期：</p> -->
      <el-date-picker
        v-model="date"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        end-placeholder="结束日期"
        style="width:280px;"
        size="small"
      >
      </el-date-picker>
      <!-- <el-date-picker
        v-model="date"
        type="date"
        placeholder="选择日期"
        format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd"
        clearable
      >
      </el-date-picker> -->
      <el-input
        placeholder="请输入电话号或用户编号"
        style="width: 190px;margin-left:10px;"
        v-model="custid"
        clearable
        size="small"
      ></el-input>
      <el-button type="primary" size="small" style="margin-left:10px" @click="getdata(1)">查询</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" label="ID" v-if="false"></el-table-column>
      <el-table-column prop="Receiver" label="患者姓名"></el-table-column>
      <el-table-column prop="Tel" label="电话号">
        <template slot-scope="scope">
          <span>{{ scope.row.Tel.substring(0, 3) + "****" + scope.row.Tel.substring(7) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="Cust_ID" label="用户编号"></el-table-column>
      <el-table-column prop="Order_ID" label="订单编号"></el-table-column>
      <el-table-column prop="Order_Date" label="创建时间"></el-table-column>
      <el-table-column prop="amount_str" label="金额"></el-table-column>
      <el-table-column prop="Solution" label="明细"></el-table-column>
      <el-table-column prop="" label="地址">
        <template slot-scope="scope">
          <span>
            {{ scope.row.Province }}{{ scope.row.City }}{{ scope.row.Counties
            }}{{ scope.row.Adress }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="Pay_State" label="支付状态">
        <template slot-scope="scope">
          <span>
            {{ dingdanzhuangtai(scope.row.Pay_State) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="Shipping_State" label="物流状态">
        <template slot-scope="scope">
          <span>
            {{ wuliuzhuangtai(scope.row.Shipping_State) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div class="opt doc">
            <!-- <a @click="lookzd(scope.row)">查看诊单</a> -->
            <!-- <a @click="lookdrug(scope.row)">查看药方</a> -->
            <!-- <a @click="sendordertouser(scope.row)">发起支付</a> -->
            <!-- <a @click="changestate(scope.row)">修改状态</a> -->
            <talkchangeorderstate :ordermsg="scope.row" @changeorderlist="changeorderlist"></talkchangeorderstate>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      ></el-pagination>
    </p>
    <el-dialog title="查看诊单" :modal="false" :visible.sync="EditZD" width="566px">
      <preview
        :kid="EditMsg.kid"
        :custid="EditMsg.custid"
        v-if="EditZD"
        :EditZD="EditZD"
        :noedit="EditMsg.noedit"
      ></preview>
    </el-dialog>
    <lookdrug :drug.sync="drug" :drugdetail="drugdetail"></lookdrug>
  </div>
</template>
<script>
import preview from "../components/preview.vue";
import lookdrug from "../components/lookdrug.vue";
import talkchangeorderstate from "../components/talkchangeorderstate.vue"
export default {
  props: ["showOrder", "get_own_roles", "serve"],
  components: {
    preview,
    lookdrug,
    talkchangeorderstate
  },
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [10], // 个数选择器（可修改）
      PageSize: 10, // 默认每页显示的条数（可修改）
      value: false,
      EditMsg: {},
      EditZD: false,
      drug: false,
      drugdetail: "",
      sid: sessionStorage.getItem("id"),
      select2: "",
      options2: [
        { id: 0, value: "未付款" },
        { id: 1, value: "已付款" },
        { id: 2, value: "已确认" },
        { id: -1, value: "付款失败" },
      ],
      date: "",
      serverid: sessionStorage.getItem("servicer_id"),
      uid: sessionStorage.getItem("id"),
      kefulist: [],
      kefuchoice: "", //选择的客服
      getownroles: "", //是否具有某些权限
      custid: "",
      ordermsg:"",
    };
  },
  watch: {
    showOrder(o, n) {
      if (o) {
        // this.getdata(1);
      }
    },
    get_own_roles(o, n) {
      this.getownroles = o;
      if (o != 0) {
        this.getkefulist();
      }
    },
    kefuchoice(val, oldval) {
      let newindex = val.indexOf("all"),
        oldindex = oldval.indexOf("all"); //获取val和oldval里all的索引,如果没有则返回-1
      if (newindex != -1 && oldindex == -1 && val.length > 1)
        //如果新的选择里有勾选了选择所有选择所有 则 只直线勾选所有整个选项
        this.kefuchoice = ["all"];
      else if (newindex != -1 && oldindex != -1 && val.length > 1)
        //如果操作前有勾选了选择所有且当前也选中了勾选所有且勾选数量大于1  则移除掉勾选所有
        this.kefuchoice.splice(val.indexOf("all"), 1);
    },
    date(newVal, oldVal){
      if (!newVal) {
        this.date = [];
      }
    }
  },
  mounted() {},
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage);
    },
    getkefulist() {
      this.axios.get(this.serve + "/api/Employee/get_employee?Id=" + this.uid).then((res) => {
        if (res.data.code == 1) {
          this.kefulist = res.data.result;
        }
      });
    },
    // getNewDay(dateTemp, days) {
    //   var dateTemp = dateTemp.split("-");
    //   var nDate = new Date(dateTemp[1] + "-" + dateTemp[2] + "-" + dateTemp[0]); //转换为MM-DD-YYYY格式
    //   var millSeconds = Math.abs(nDate) + days * 24 * 60 * 60 * 1000;
    //   var rDate = new Date(millSeconds);
    //   var year = rDate.getFullYear();
    //   var month = rDate.getMonth() + 1;
    //   if (month < 10) month = "0" + month;
    //   var date = rDate.getDate();
    //   if (date < 10) date = "0" + date;
    //   return year + "-" + month + "-" + date;
    // },
    getdata(page) {
      this.list = [];
      var kflist = [];
      if (this.kefuchoice == "all") {
        kflist = this.kefulist;
      } else {
        kflist = this.kefuchoice;
      }
      var str = "";
      if (kflist.length > 0) {
        kflist.forEach((item) => {
          str += item.serviceId + ",";
        });
        str = str.substr(0, str.length - 1);
      } else {
        str = this.serverid;
      }
      var date1 = "",
        date2 = "";
      var datestr2 = "",
        datestr1 = "";
      if (this.date != "") {
        date1 = this.date[0];
        date2 = this.date[1];
      }else if(this.custid == ''){
        this.$message({
          message: "请选择日期",
          type: "error",
        });
        return;
      }
      if (this.select2 === "" && this.custid == '') {
        this.$message({
          message: "请选择状态",
          type: "error",
        });
        return;
      } else {
        datestr1 = "&create_datetime1=" + date1;
        datestr2 = "&create_datetime2=" + date2;
      }
      // if (this.select2 == 0)  else {
      //   datestr1 = "&datetime1=" + date1;
      //   datestr2 = "&datetime2=" + date2;
      // }
      var custstr = "";
      if (this.custid != "") {
        custstr = "&cust_id=" + this.custid;
      }
      this.axios
        .get(
          "/order/get_orders?page=" +
            page +
            "&size=" +
            this.PageSize +
            "&pay_state=" +
            this.select2 +
            custstr +
            "&input_ids=" +
            str +
            datestr1 +
            datestr2
        )
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      //   this.axios
      //     .get(
      //       "/docpres/get_gu_docpres_order?z_input_id=" +
      //         this.serverid +
      //         "&page=" +
      //         page +
      //         "&size=" +
      //         this.PageSize +
      //         "&state=" +
      //         this.select2 +
      //         "&datetime1=" +
      //         date1 +
      //         "&datetime2=" +
      //         date2
      //     )
      //     .then((res) => {
      //       console.log(res);
      //       if (res.data.code == 0) {
      //         this.list = res.data.result;
      //         this.totalCount = res.data.count;
      //       }
      //     });
    },
    lookzd(row) {
      this.EditMsg.kid = row.kid;
      this.EditMsg.custid = row.cust_id;
      this.EditMsg.noedit = "no";
      this.EditZD = true;
    },
    lookdrug(row) {
      this.drug = true;
      this.drugdetail = row;
    },
    //发起支付
    sendordertouser(row) {
      var form = {
        doctor_id: row.doctor_id, //毅飞的医生ID
        doctor_shape: row.doctor_shape, //中药药态
        doctor_disease: row.doctor_disease, //辨病信息
        doctor_dialectic: row.doctor_dialectic, //辩证信息
        doctor_dosage: row.doctor_dosage, //总共剂数
        doctor_pharmacy: row.doctor_pharmacy, //用法用量
        doctor_remark: row.doctor_remark, //医生
        patient_id: row.patient_id, //患者编号
        patient_name: row.patient_name, //患者姓名
        patient_sex: row.patient_sex, //患者性别
        patient_age: row.patient_age, //患者年龄
      };
      var str = "";
      var arr = JSON.parse(row.carts);
      for (let m = 0; m < arr.length; m++) {
        const element = arr[m];
        str += element.id + "=" + element.numa;
      }
      form.carts = str;
      this.axios.post("/yf/lucky_recipel_submit", this.qs.stringify(form)).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            message: "发送成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    //物流状态
    wuliuzhuangtai(val) {
      var str = "";
      var dic = {
        0: "未发货",
        1: "已发货",
        2: "签收",
        3: "配货中",
        4: "已发货部分商品",
        5: "发货中",
        9: "退回",
      };
      for (var key in dic) {
        if (key == val) {
          str = dic[key];
        }
      }
      return str;
    },
    //订单状态
    dingdanzhuangtai(val) {
      var str = "";
      var dic = {
        "-1": "付款失败",
        0: "未付款",
        1: "已付款",
        2: "已确认",
        6: "已退款",
      };
      for (var key in dic) {
        if (key == val) {
          str = dic[key];
        }
      }
      return str;
    },
    changeorderlist(){
      this.getdata(1);
    }
  },
};
</script>
<style>
.paging {
  text-align: center;
}
</style>
