<template>
  <div style="height:100vh;">
    <div class="add">
      <el-select
        v-model="dismainstate"
        placeholder="请选择状态"
        style="width:120px;margin-right:10px;"
        clearable
        @clear="dismainstate = ''"
        size="small"
      >
        <el-option v-for="item in options" :key="item.id" :label="item.value" :value="item.id">
        </el-option>
      </el-select>
      <el-input
        placeholder="请输入用户编号"
        clearable
        @clear="searchcustid = ''"
        prefix-icon="el-icon-search"
        v-model="searchcustid"
        style="width: 160px;margin-right: 10px;"
        size="small"
      >
      </el-input>
      <el-input
        placeholder="请输入医生ID"
        clearable
        @clear="searchdocid = ''"
        prefix-icon="el-icon-search"
        v-model="searchdocid"
        style="width:140px;margin-right:10px"
        size="small"
      >
      </el-input>
      <el-date-picker
        v-model="date"
        type="daterange"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        clearable
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="margin-right:10px;width:260px;"
        size="small"
      >
      </el-date-picker>
      <el-button type="primary" size="small" @click="query(1)">查询</el-button>
    </div>
    <el-table :data="list" height="80vh" border style="width: 100%;">
      <el-table-column prop="KID" label="问诊单编号"></el-table-column>
      <el-table-column prop="Dis_Name" label="问诊单名"></el-table-column>
      <el-table-column prop="Cust_ID" label="用户编号"></el-table-column>
      <el-table-column prop="Cust_Name" label="用户姓名"></el-table-column>
      <el-table-column prop="Input_ID" label="服务人ID"></el-table-column>
      <el-table-column prop="doc_name" label="医生姓名"></el-table-column>
      <el-table-column prop="r_time" label="标记时间"></el-table-column>
      <el-table-column prop="user_status" label="当前状态">
        <template slot-scope="scope">
          <span>{{ transformstate(scope.row.user_status) }}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div class="opt">
            <el-button size="mini" type="warning" icon="el-icon-view" @click="lookup(scope.row)"
              >查看并修改</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      ></el-pagination>
    </p>
    <el-dialog
      title="问诊表"
      :modal="true"
      :visible.sync="EditZD"
      width="566px"
      style="text-align:center;"
    >
      <preview
        :kid="EditMsg.kid"
        :custid="EditMsg.custid"
        v-if="EditZD"
        :EditZD="EditZD"
        :noedit="EditMsg.noedit"
        :shenhe.sync="shenhe"
        ref="uppdf"
      ></preview>
      <el-button
        type="primary"
        round
        @click="
          dialogFormVisible = true;
          changeStatusVal = '';
        "
        >修改状态</el-button
      >
    </el-dialog>
    <!-- 修改状态弹窗开始 -->
    <el-dialog
      title="修改状态"
      :visible.sync="dialogFormVisible"
      width="30%"
      style="text-align:center;"
    >
      <el-select v-model="changeStatusVal" clearable placeholder="请选择">
        <el-option
          v-for="item in statusoptions"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="subchangeStatus">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改状态弹窗结束 -->
  </div>
</template>
<script>
import preview from "../components/preview.vue";
export default {
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [10], // 个数选择器（可修改）
      PageSize: 10, // 默认每页显示的条数（可修改）
      value: false,
      dialogFormVisible: false,
      row: "",
      options: [
        { id: "0", value: "未处理" },
        { id: "1", value: "已处理" },
      ],
      dismainstate: "",
      searchcustid: "",
      searchdocid: "",
      serviceid: sessionStorage.getItem("servicer_id"),
      date: [],
      EditZD: false,
      EditMsg: {
        kid: "",
        custid: "",
        noedit: "",
      },
      shenhe: false,
      statusoptions: [
        { id: 1, label: "待完善寻医信息" },
        { id: 2, label: "等待精准匹配好医生" },
        { id: 3, label: "已被医生选取" },
        { id: 4, label: "等待完善疾病信息" },
        { id: 5, label: "执行医生方案" },
        { id: 6, label: "治疗无效等待匹配其他医生治疗" },
        { id: 8, label: "确认有效继续治疗" },
        { id: 7, label: "已治愈" },
      ],
      changeStatusVal: "",
      uid: sessionStorage.getItem("servicer_id"),
    };
  },
  watch: {
    date(newDate, oldDate) {
      if (newDate == null) {
        this.date = [];
      }
    },
  },
  components: {
    preview,
  },
  mounted() {
    this.query(1);
  },
  methods: {
    transformstate(val) {
      var str = "";
      //   var dic = {
      //     0: "未填写",
      //     1: "提交",
      //     2: "审核",
      //     3: "打回",
      //     6: "已下发",
      //     9: "删除",
      //   };
      //   for (var key in dic) {
      //     if (key == val) {
      //       str = dic[key];
      //     }
      //   }
      if (val) {
        for (let a = 0; a < this.statusoptions.length; a++) {
          if (val == this.statusoptions[a].id) {
            str = this.statusoptions[a].label;
          }
        }
      } else {
        str = "暂未标记";
      }
      return str;
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.query(currentPage);
    },
    query(page) {
      var str = "";
      console.log(this.dismainstate);
      if (this.dismainstate == "") {
        str += "&dismain_state=all";
      } else {
        str += "&dismain_state=" + this.dismainstate;
      }
      if (this.searchcustid != "") {
        str += "&cust_id=" + this.searchcustid;
      }
      if (this.searchdocid != "") {
        str += "&doc_id=" + this.searchdocid;
      }
      if (this.serviceid != "") {
        str += "&servicer_id=" + this.serviceid;
      }
      if (this.date.length > 0) {
        str += "&datetime1=" + this.date[0] + "&datetime2=" + this.date[1];
      }
      this.axios
        .get(
          "field/get_z_field_dismains_by_userstate?page=" + page + "&size=" + this.PageSize + str
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        });
    },
    lookup(row) {
      this.row = row;
      this.EditZD = true;
      this.EditMsg.kid = row.KID;
      this.EditMsg.custid = row.Cust_ID;
      this.EditMsg.noedit = false;
    },
    subchangeStatus() {
      var form = {
        kid: this.row.KID,
        doc_id: this.row.Doc_ID,
        cust_id: this.row.Cust_ID,
        ill_lib_id: this.row.ill_lib_id,
        user_status: this.changeStatusVal,
      };
      console.log(form);
      this.axios.post("field/update_gu_user_state_record", this.qs.stringify(form)).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: "修改成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
      this.dialogFormVisible = false;
      this.EditZD = false;
    },
  },
};
</script>
