<template>
  <div>
    <el-dialog title="自动回复" :visible.sync="show" width="60%" @close="exit()">
      <div class="add">
        <el-button size="mini" type="warning" @click="addtemplate('new','')">添加</el-button>
        <!-- <el-button size="mini" type="warning" @click="del1">删除</el-button> -->
      </div>
      <el-table :data="tableData" border max-height="320">
        <el-table-column label="内容" prop="content"></el-table-column>
        <el-table-column width="220">
          <template slot-scope="scope">
            <div>
              <el-button size="mini" type="primary" @click="addtemplate('edit',scope.row)">编辑</el-button>
              <!-- <el-button size="mini" type="success" @click="send(scope.row)">发送</el-button> -->
              <el-button size="mini" type="danger" @click="del(scope.row)">删除</el-button>
              <el-button size="mini" type="success" @click="choice(scope.row)">选择</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="add" width="50%">
      <el-form label-width="100px">
        <el-form-item label="编辑内容">
          <el-input type="textarea" :autosize="{ minRows: 3}" placeholder="请输入内容" v-model="content">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="Sub">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { addt, readt, updatet, delt, deltt } from "../assets/replaytemplate.js";
import { insert, del, push, read, search } from "../assets/sql2.js";
import { addu, readu, delu, updateu, putu } from "../assets/msglist.js";
var _this = "";
export default {
  props: ["setreply","message", "toID", "db", "talkconnection"],
  data() {
    return {
      show: false,
      add: false,
      tableData: [],
      content: "",
      sid: sessionStorage.getItem("id"),
      type: "",
      row: "",
      title:'',
    };
  },
  watch: {
    setreply(o, n) {
      this.show = o;
      this.getdatalist();
    },
  },
  mounted() {
    _this = this;
  },
  methods: {
    getdatalist() {
      readt(this.db, this.sid, function (res) {
        _this.tableData = res;
      });
    },
    exit() {
      this.show = false;
      this.$emit("update:setreply", false);
    },
    addtemplate(type, row) {
      this.type = type;
      this.add = true;
      if (type == "") {
        this.content = "";
        this.title = '添加回复';
      } else {
        this.content = row.content;
        this.row = row;
        this.title = '编辑回复';
      }
    },
    send(row) {
      var fromID = "s" + this.sid;
      this.talkconnection
        .invoke("send", this.toID, fromID, row.content, 0)
        .then((res) => {
          if (res.err == 1) {
            //发送成功
            let measge = {
              content: res.data.content,
              from: fromID,
              type: "0",
              sendTime: res.data.sendTime,
            };
            push(this.db, this.toID, res.data);
            updateu(
              this.db,
              this.sid,
              this.toID,
              "sendTime",
              measge,
              function (res) {}
            );
            this.$emit("sendMoneyChangeAlllist");
            this.$emit("sendMoneyChangeTalklist");
            this.exit();
          } else {
            this.$message({
              message: "发送失败，请重新发送",
              type: "error",
            });
          }
        });
    },
    Sub() {
      if (this.type == "new") {
        addt(this.db, this.sid, this.content);
      } else {
        updatet(this.db, this.sid, this.content, "content", this.row.content);
      }
      this.add = false;
      this.getdatalist();
    },
    del(row) {
      this.$confirm("是否删除该条自动回复?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delt(this.db, this.sid, row.content, function (res) {
            console.log(res);
            if (res == "ok") {
              _this.$message({
                message: "删除成功",
                type: "success",
              });
            }
            _this.getdatalist();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    choice(row){
      this.$emit("suu", row.content);
      this.exit();
    }
  },
};
</script>
