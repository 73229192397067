<template>
  <div>
    <el-row>
      <el-col :span="8" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" style="height: 100vh; overflow-y: auto; width: 320px">
        <div class="zdymain" v-if="doclist.length > 0">
          <div v-for="item in doclist" class="zdyline" :key="item.id" :class="{ act: toID == item.id }" @contextmenu.prevent="show(item, item.id)">
            <div style="width: 25px; float: left">
              <span v-if="item.isOnline == 'true'" class="inline"></span>
              <span v-else class="outline"></span>
            </div>
            <div @click="lookup1(item, item.id, item.type, item.name, item.desc)" style="cursor: pointer;height: 48px;float: left;width: 250px;">
              <div class="zdyuserimg">
                <img :src="filterheadImg1(item.headImg)" alt="" />
              </div>
              <div class="zdycustname" style="width: 190px">
                <span style="width: 90px;display: inline-block;line-height: 14px;vertical-align: middle;" v-if="item.desc != '' && item.desc != null && item.desc != undefined">{{ item.desc }}</span>
                <span style="width: 90px;display: inline-block;line-height: 14px;vertical-align: middle;" v-else-if="item.name != '' && item.name != null && item.name != undefined">{{ item.name | filtername(item.id) }}</span>
                <span class="itemmsg" v-show="item.hasmsg == 'true'"></span>
                <span class="item" v-if="item.noReadCount > 0">{{ item.noReadCount }}</span>
                <span style="width: 65px;float: right;font-size: 12px;color: #808080;transform: scale(0.8);line-height: 15px;margin-top: 10px;">
                  <i style="display: inline-block">{{ item.sendTime | capitalize }}</i>
                  <i style="display: inline-block">{{ item.sendTime | capitalize2 }}</i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="zdymain zdynolist">暂无数据</div>
      </el-col>
      <el-col :span="16">
        <div class="talkmain" v-if="dialogFormVisible">
          <div class="talkname">{{ talkname }}</div>
          <p class="lookhistory" v-if="lookmoreshow" @click="loadmore">
            查看历史记录
          </p>
          <div v-loading="talklistloading">
            <div class="talk-main" id="scrolldIV">
              <div class="talkline" v-for="(item, index) in talklist" :key="index">
                <div class="timeline">{{ item.sendTime }}</div>
                <div class="leftimg" v-if="item.from == toID">
                  <img :src="userheadimg" alt="" />
                </div>
                <div class="talkleft" v-if="item.from == toID">
                  <p style="margin: 0" v-if="item.type == '0'">
                    <span>
                      {{ item.content }}
                    </span>
                  </p>
                  <p style="margin: 0" v-if="item.type == '1'">
                    <span>
                      <img :src="item.content" alt="" @click="big(item.content)" />
                    </span>
                  </p>
                  <span v-if="item.type == '14'">
                        <i>
                          <div class="audiobox" style="background-color: #43cfcc">
                            <div @click="Listening(index)">
                              <b>
                                <span style="border: none;background: none;">{{ JSON.parse(item.content).time }}"</span>
                                <i class="el-icon-video-play" style="font-size: 26px"></i></b>
                              <!-- @play="handlePlay()" -->
                              <audio class="talklistaudio" :src="JSON.parse(item.content).url" controls style="display: none" :ref="'audio' + index" @ended="endplay(index)"></audio>
                            </div>
                          </div>
                        </i>
                      </span>
                </div>
                <div class="rightimg" v-if="item.from != toID">
                  <img :src="headimg" alt="" />
                </div>
                <div class="talkright" v-if="item.from != toID">
                  <span>
                    <i v-if="item.type == '0'"><b>{{ item.content }}</b></i>
                    <i v-if="item.type == '1'">
                      <img :src="item.content" alt="" @click="big(item.content)" />
                    </i>
                    <i v-if="item.type == '5'">
                      <div style="width: 220px">
                        <p>寻医问诊表</p>
                        <p>
                          您好，为了便于帮您精准匹配好医生，请点击此对话框，详细填写寻医问诊表。
                        </p>
                        <p @click="
                            EditXY = true;
                            EditXYMsg.kid = JSON.parse(item.content).id;
                            EditXYMsg.noedit = false;
                          ">
                          查看详情>
                        </p>
                      </div>
                    </i>
                    <i v-if="item.type == '6'">
                      <div style="width: 220px">
                        <p>{{ JSON.parse(item.content).doc_name }}&nbsp;医生&nbsp;问诊单</p>
                        <p>
                          您好，为了让匹配的好医生详细了解您的病情信息，给出准确治疗方案，请点击此对话框，详细填写医生问诊单。
                        </p>
                        <p @click="
                            EditZD = true;
                            EditMsg.kid = JSON.parse(item.content).id;
                            EditMsg.noedit = false;
                          ">
                          查看详情>
                        </p>
                      </div>
                    </i>
                    <i v-if="item.type == '7'">
                      <div style="width: 220px">
                        <p>频道名:{{ JSON.parse(item.content).channel }}</p>
                        <p>ID:{{ JSON.parse(item.content).id }}</p>
                        <p @click="lookchanneldetail(JSON.parse(item.content))">
                          查看详情>
                        </p>
                      </div>
                    </i>
                    <i v-if="item.type == '13'">
                      <div style="width: 220px">
                        <p>反馈表</p>
                        <p>
                          您好，为了便于您以后的治疗请认真填写反馈信息表。
                        </p>
                        <p @click="
                            lookfeedback = true;
                            feedbacknoedit = true;
                            feedbackid = JSON.parse(item.content).id;
                          ">
                          查看详情>
                        </p>
                      </div>
                    </i>
                    <i v-if="item.type == '14'">
                          <div class="audiobox" @click="Listening(index)">
                            <div>
                              <b><span style="border: none;background: none;">{{ JSON.parse(item.content).time }}"</span>
                                <i class="el-icon-video-play" style="font-size: 26px"></i></b>
                              <!-- @play="handlePlay()" -->
                              <audio class="talklistaudio" :src="JSON.parse(item.content).url" controls style="display: none" :ref="'audio' + index" @ended="endplay(index)"></audio>
                            </div>
                          </div>
                        </i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="talk-main-text">
            <div style="width: 100%; overflow: hidden; position: relative">
              <div style="width: 78%; float: left; box-sizing: border-box">
                <el-input class="talk-text" type="textarea" v-model="message" resize="none" id="cOthersResponsible" :autosize="{ minRows: 1, maxRows: 8 }" @focus="focusallfalse"></el-input>
              </div>
              <div style="
                      width: 22%;
                      float: left;
                      padding-top: 14px;
                      position: absolute;
                      left: 78%;
                      bottom: 4px;
                    ">
                <div class="send" v-if="showsend">
                  <el-button size="mini" @click="send(0, message)">发送</el-button>
                </div>
                <div class="imgbtn" @click="showemoji">
                  <img :src="smileicon" alt="" class="smilebtn" />
                </div>
                <div class="imgbtn" @click="showmenu" v-if="showplus">
                  <i class="el-icon-circle-plus-outline"></i>
                </div>
                <!-- 录制音频 -->
                    <div class="imgbtn" @click="wantSoundRecording">
                      <i class="el-icon-microphone"></i>
                    </div>
              </div>
            </div>
            <div class="btnlinefather">
              <div class="btnline" v-show="plus">
                <div class="btnk">
                  <div class="graybtn">
                    <div class="imgbtn c1">
                      <img :src="talktp" alt="" />
                      <el-upload class="avatar-uploader" action="    " :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :http-request="upload">
                        <img v-if="picsrc" :src="picsrc" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </div>
                  </div>
                  <span>图片</span>
                </div>
                <div class="btnk">
                  <div class="graybtn">
                    <div class="imgbtn c2" @click="docxyshow = true">
                      <!-- medicalSeek -->
                      <img :src="talkxy" alt="" />
                    </div>
                  </div>
                  <span>寻医</span>
                </div>
                <div class="btnk">
                  <div class="graybtn">
                    <div class="imgbtn c1" @click="doczdshow = true">
                      <!-- newList -->
                      <img :src="talkzd" alt="" />
                    </div>
                  </div>
                  <span>诊单</span>
                </div>
                <div class="btnk">
                  <div class="graybtn">
                    <div class="imgbtn c1" @click="docfkshow = true">
                      <!-- feedback -->
                      <img :src="talkfk" alt="" />
                    </div>
                  </div>
                  <span>反馈</span>
                </div>
                <div class="btnk">
                  <div class="graybtn">
                    <div class="imgbtn c1" @click="docvcrshow = true">
                      <!-- launchVideo -->
                      <img :src="talklx" alt="" />
                    </div>
                  </div>
                  <span>医患<br />连线</span>
                </div>
                <div class="btnk">
                  <div class="graybtn">
                    <div class="imgbtn c2" @click="synchronization">
                      <img :src="talktb" alt="" />
                    </div>
                  </div>
                  <span>同步</span>
                </div>
              </div>
            </div>
            <div class="emotionList" v-show="emoji1">
              <a href="javascript:void(0);" @click="getEmo(index)" v-for="(item, index) in faceList" :key="index" class="emotionItem">{{ item }}</a>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 右键菜单开始 -->
    <div id="rightmenudoc" v-show="visible">
      <div @click="deltalkuser()">删除聊天</div>
    </div>
    <!-- 右键菜单结束 -->
    <!-- 查看大图开始 -->
    <el-dialog title="查看图片" :visible.sync="bigpic">
      <img :src="bigpicurl" alt="" width="90%" />
    </el-dialog>
    <!-- 查看大图结束 -->
    <doczdlist :doczdshow.sync="doczdshow" :docid="docid" @send="send"></doczdlist>
    <el-dialog title="问诊表" :modal="false" :visible.sync="EditZD" width="566px">
      <preview :kid="EditMsg.kid" v-if="EditZD" :EditZD="EditZD" :noedit="EditMsg.noedit"></preview>
    </el-dialog>
    <docxylist :docxyshow.sync="docxyshow" :docid="docid" @send="send"></docxylist>
    <el-dialog title="寻医表" :modal="false" :visible.sync="EditXY" width="566px">
      <xypreview :kid="EditXYMsg.kid" v-if="EditXY" :EditZD="EditXY" :noedit="EditXYMsg.noedit"></xypreview>
    </el-dialog>
    <docfklist :docfkshow.sync="docfkshow" :docid="docid" @send="send"></docfklist>
    <feedback :lookfeedback.sync="lookfeedback" :feedbackid="feedbackid" :feedbacknoedit="feedbacknoedit"></feedback>
    <docvcrlist :docvcrshow.sync="docvcrshow" :docid="docid" @send="send"></docvcrlist>
    <!-- 录音插件开始 -->
    <recorder :SoundRecording.sync="SoundRecording" :toID="toID" :db="db1" :audioElement="audioElement" :talkconnection="talkconnection" @sendMoneyChangeAlllist="sendMoneyChangeAlllist" @sendMoneyChangeTalklist="sendMoneyChangeTalklist"></recorder>
    <!-- 录音插件结束 -->
  </div>
</template>
<script>
var db = "",
  _this = "",
  nowtouser;
const appData = require("../assets/emoji.json");
import { insert, del, push, read, search } from "../assets/sql2.js";
import {
  addud,
  readud,
  delud,
  updateud,
  putud,
  inud,
} from "../assets/msglistdoc.js";
import doczdlist from "./doczdlist.vue";
import docxylist from "./docxylist.vue";
import docfklist from "./docfklist.vue";
import docvcrlist from "./docvcrlist.vue";
import preview from "./preview.vue";
import xypreview from "./xypreview.vue";
import feedback from "./feedback.vue";
import recorder from "../components/recorder1.vue"; //录音文件
export default {
  props: ["talkconnection", "serve", "todocdb"],
  components: {
    doczdlist,
    preview,
    docxylist,
    xypreview,
    docfklist,
    feedback,
    docvcrlist,
    recorder,
  },
  data() {
    return {
      visible: false, //右键菜单
      custall: "",
      doclist: [],
      dialogFormVisible: false,
      toID: "",
      message: "",
      sid: sessionStorage.getItem("id"),
      talklist: [],
      picsrc: "",
      faceList: [], //表情列表
      plus: false,
      emoji1: false,
      showsend: false,
      showplus: true,
      lookmoreshow: true,
      talklistloading: true,
      db2: "",
      db1: "",
      uid: sessionStorage.getItem("id"),
      smileicon: require("../assets/icon-xiaolian.png"),
      talktp: require("../assets/talktp.png"),
      talktb: require("../assets/talktb.png"),
      talkbl: require("../assets/talkbl.png"),
      talkbm: require("../assets/talkbm.png"),
      talkdx: require("../assets/talkdx.png"),
      talkgh: require("../assets/talkgh.png"),
      talkxy: require("../assets/talkxy.png"),
      talkys: require("../assets/talkys.png"),
      talkzd: require("../assets/talkzd.png"),
      talkfk: require("../assets/talkfk.png"),
      talklx: require("../assets/talklx.png"),
      female: require("../assets/female.png"),
      male: require("../assets/male.png"),
      headimg: "", //客服头像
      userheadimg: "", //医生头像
      indexedDBindex: 1,
      row: "", //存储医生列表数据
      talklistloading: false,
      bigpic: false, //查看大图弹窗
      bigpicurl: "", //查看大图路径
      doczdshow: false, //医生诊单弹窗
      docid: "", //医生id
      EditZD: false, //编辑诊单
      EditMsg: {
        kid: "",
        custid: "",
        noedit: true,
      },
      docxyshow: false, //医生寻医弹窗
      EditXY: false, //编辑诊单
      EditXYMsg: {
        kid: "",
        custid: "",
        noedit: true,
      },
      docfkshow: false,
      lookfeedback: false,
      feedbackid: "",
      feedbacknoedit: false,
      docvcrshow: false,
      SoundRecording:false,//发送语音
      audioElement:[],
    };
  },
  filters: {
    capitalize: function (value) {
      if (value == "0001-01-01 00:00:00" || value == "undefined") return "";
      if (!value) return "";
      value = value.substr(0, 10);
      return value;
    },
    capitalize2: function (value) {
      if (value == "0001-01-01 00:00:00" || value == "undefined") return "";
      if (!value) return "";
      value = value.substr(10, value.length);
      return value;
    },
    filtername: function (value, id) {
      if (value.indexOf("dxhs_") == 0) {
        return "待完善用户" + id.substr(id.length - 4);
      } else {
        return value;
      }
    },
  },
  watch: {
    todocdb(o, n) {
      db = o;
      this.message = "";
    },
    serve(o, n) {
      this.message = "";
    },
    visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    },
    message(val) {
      if (val != "") {
        this.showsend = true;
        this.showplus = false;
        sessionStorage.setItem(this.toID, val);
      } else {
        this.showsend = false;
        this.showplus = true;
        sessionStorage.removeItem(this.toID);
      }
    },
    talkconnection(o, n) {
      this.getdetail();
      console.log(o);
      //监听来消息
      this.talkconnection.on("recieve", function (message, user) {
        console.log(message);
        console.log(user);
        var audio = document.getElementById("music");
        audio.play();

        if (!("Notification" in window)) {
          console.log(
            "您的浏览器暂不支持网站接收通知，请查看浏览器版本或打开浏览器通知权限！"
          );
        } else {
          console.log("zhichi");
        }
        var Notification =
          window.Notification ||
          window.mozNotification ||
          window.webkitNotification;
        if (Notification) {
          if (
            Notification.permission === "default" ||
            Notification.permission === "denied"
          ) {
            Notification.requestPermission().then((res) => {
              // denied 或 granted
              console.log(res);
            });
          }
        }
        //设置显示文字
        var notificationdata = "",
          notificationstr = "";
        //文字
        // if (message.type == 0) {
          notificationdata = {
            // 正文内容
            body: '',
            requireInteraction: true,
          };
          notificationstr = "医生 " + user.name + " 给您发来了一条消息";
        // }
        let notification = new Notification(notificationstr, notificationdata);

        if (user.type == "10") {
          user.hasmsg = true;
          user.isOnline = true;
          user.sendTime = message.sendTime;
          putud(_this.db1, _this.sid, user.id, user, function (res) {
            if (res == "has") {
              updateud(
                _this.db1,
                _this.sid,
                user.id,
                "sendTime",
                user,
                function (res) {
                  updateud(
                    _this.db1,
                    _this.sid,
                    user.id,
                    "isOnline",
                    user,
                    function (res) {
                      updateud(
                        _this.db1,
                        _this.sid,
                        user.id,
                        "hasmsg",
                        user,
                        function (res) {
                          readud(_this.db1, _this.sid, function (res) {
                            _this.doclist = res;
                          });
                        }
                      );
                    }
                  );
                }
              );
            } else {
              readud(_this.db1, _this.sid, function (res) {
                _this.doclist = res;
                // _this.axios
                //   .post(
                //     _this.serve + "/api/MessageStore/add",
                //     _this.qs.stringify({
                //       UserId: user.id,
                //       Service: "s" + _this.sid,
                //       Name: user.name,
                //       HeadImg: user.headImg,
                //       Desc: user.desc,
                //     })
                //   )
                //   .then((res) => {
                //     console.log(res);
                //   });
              });
            }
          });
          push(db, user.id, message);
        }
        // Html encode display name and message.
        var encodedName = user.name;
        var encodedMsg = message.content;
        // Add the message to the page.
        if (message.from == nowtouser) {
          _this.talklist.push(message);
          _this.axios
            .post(
              this.serve + "/api/User/read_message",
              this.qs.stringify({ fromID: user.id, toID: "s" + _this.sid })
            )
            .then((res) => {
              if (res.data.code == 1) {
                console.log(res);
              }
            })
            .catch((error) => {
              console.error(error.message);
            });
        }
        
      });
      //监听用户上线情况
      this.talkconnection.on("online", function (user) {
        if (user.type == "10") {
          user.isOnline = true;
          updateud(
            _this.db1,
            _this.sid,
            user.id,
            "isOnline",
            user,
            function (res) {
              readud(_this.db1, _this.sid, function (res) {
                _this.doclist = res;
              });
            }
          );
        }
      });
      //监听用户下线情况
      this.talkconnection.on("offline", function (user) {
        user.isOnline = false;
        updateud(
          _this.db1,
          _this.sid,
          user.id,
          "isOnline",
          user,
          function (res) {
            readud(_this.db1, _this.sid, function (res) {
              _this.doclist = res;
            });
          }
        );
        this.axios
          .post(
            "/log/chat_login_log",
            this.qs.stringify({
              data:
                "【聊天下线】 " + user.id + "  ---   " + rs.data.result.account,
            })
          )
          .then((res) => {});
      });
    },
  },
  mounted() {
    /*表情注入*/
    for (let i in appData) {
      this.faceList.push(appData[i].char);
    }
    // db = openDatabase("mydb" + this.uid, "1.0", "Test DB", 2000 * 1024 * 1024);
    _this = this;
    /*获取客服头像*/
    if (sessionStorage.getItem("headimg")) {
      this.headimg = sessionStorage.getItem("headimg");
    } else {
      this.headimg = this.female;
    }
    this.message = "";
  },
  methods: {
    //对数组转格式
    talklistextend(obj) {
      var arr = [];
      for (var i = 0; i < obj.length; i++) {
        arr.push(obj[i]);
      }
      arr = this.MsgSort(arr);
      return arr;
    },
    //根据时间排序
    MsgSort(obj) {
      obj.sort((a, b) => {
        let t1 = new Date(Date.parse(a.sendTime.replace(/-/g, "/")));
        let t2 = new Date(Date.parse(b.sendTime.replace(/-/g, "/")));
        return t1.getTime() - t2.getTime();
      });
      return obj;
    },
    //显示右键菜单
    show(item, id) {
      // this.stopPropagation();
      this.visible = true;
      var e = window.event;
      var ele = "";
      ele = document.getElementById("rightmenudoc");
      ele.style.top = e.clientY + "px";
      ele.style.left = e.clientX + "px"; //获取当前位置显示
      this.custall = item;
      this.searchfiel = id;
    },
    //关闭右键菜单
    closeMenu() {
      this.visible = false;
    },
    //表情和菜单的显示隐藏操作
    showemoji() {
      this.emoji1 = !this.emoji1;
      this.plus = false;
    },
    showmenu() {
      this.plus = !this.plus;
      this.emoji1 = false;
    },
    focusallfalse() {
      this.plus = false;
      this.emoji1 = false;
    },
    blurallfalse(val) {
      if (val.length <= 0) {
        this.showsend = false;
        this.showplus = true;
      } else {
        this.showsend = true;
        this.showplus = false;
      }
    },
    clearsend() {
      this.showsend = false;
      this.showplus = true;
    },
    //查看大图
    big(url) {
      this.bigpic = true;
      this.bigpicurl = url;
    },
    //注入表情
    getEmo(index) {
      var textArea = document.getElementById("cOthersResponsible");
      function changeSelectedText(obj, str) {
        if (window.getSelection) {
          // 非IE浏览器
          textArea.setRangeText(str);
          // 在未选中文本的情况下，重新设置光标位置
          textArea.selectionStart += str.length;
          textArea.focus();
        } else if (document.selection) {
          // IE浏览器
          obj.focus();
          var sel = document.selection.createRange();
          sel.text = str;
        }
      }
      changeSelectedText(textArea, this.faceList[index]);
      this.message = textArea.value; // 要同步data中的数据
      return;
    },
    filterheadImg1(val) {
      var url = "";
      if (
        val == null ||
        val == "" ||
        val == undefined ||
        val == "null" ||
        val == "undefined"
      ) {
        url = this.male;
      } else {
        url = val;
      }
      return url;
    },
    //与服务器同步聊天
    synchronization() {
      var id = this.toID;
      this.talklistloading = true;
      del(db, "user" + id, function (res) {
        if (res == "ok") {
          //如果没有数据请求第一页
          db.transaction(function (tx) {
            tx.executeSql(
              "CREATE TABLE if not exists `user" +
                id +
                "` (`content` TEXT,`from` varchar(10),`sendTime` varchar(100),`to` varchar(50),`type` int,`id` varchar(200),`sid` varchar(200),`isRead` int)",
              [],
              function (tx, result) {
                console.log("创建stu表成功");
                _this.gethistory(id).then((res) => {
                  insert(db, id, res);
                  _this.talklistloading = false;
                  _this.talklist = _this.talklistextend(res);
                  _this.$nextTick(() => {
                    var div = document.getElementById("scrolldIV");
                    div.scrollTop = div.scrollHeight;
                  });
                });
              },
              function (tx, error) {
                console.log("创建stu表失败:" + error.message);
              }
            );
          });
        }
      });
    },
    deltalkuser() {
      //删除本地聊天
      var row = this.custall;
      // console.log(row);
      this.$confirm("此操作将删除该聊天, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          //获取确定操作后，在本地的聊天列表中删除改条记录
          delud(this.db1, this.sid, this.custall.id, function (res) {
            // console.log(res);
            if (res == "ok") {
              readud(_this.db1, _this.sid, function (res) {
                _this.doclist = res;
              });
            }
          });
          this.axios
            .post(
              this.serve + "/api/MessageStore/remove",
              this.qs.stringify({ service: "s" + this.sid, userId: row.id })
            )
            .then((res) => {
              // console.log(res);
              if (res.data.code == 1) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getdetail() {
      //先加载本地的医生列表
      db.transaction(function (tx) {
        tx.executeSql(
          "CREATE TABLE if not exists `msglistdoc" +
            _this.sid +
            "` (`desc` TEXT,`name` TEXT,`id` varchar(20) PRIMARY KEY,`sendTime` varchar(100),`noReadCount` int,`headImg` TEXT,`hasmsg` varchar(10),`isOnline` varchar(10))",
          [],
          function (tx, result) {
            _this.db1 = db;
            readud(_this.db1, _this.sid, function (res) {
              console.log(res);
              if (res.length > 0) {
                _this.axios
                  .get(
                    _this.serve + "/api/Doctor/get_doctor_list?id=s" + _this.sid
                  )
                  .then((res) => {
                    if (res.data.code == 1) {
                      var arr = res.data.result;
                      for (let a = 0; a < arr.length; a++) {
                        inud(_this.db1, _this.sid, arr[a].id, function (res) {
                          arr[a].sendTime = arr[a].lastSendTime;
                          updateud(
                            _this.db1,
                            _this.sid,
                            arr[a].id,
                            "noReadCount",
                            arr[a],
                            function (res) {
                              updateud(
                                _this.db1,
                                _this.sid,
                                arr[a].id,
                                "sendTime",
                                arr[a],
                                function (res) {
                                  updateud(
                                    _this.db1,
                                    _this.sid,
                                    arr[a].id,
                                    "isOnline",
                                    arr[a],
                                    function (res) {
                                      readud(
                                        _this.db1,
                                        _this.sid,
                                        function (res) {
                                          _this.doclist = res;
                                        }
                                      );
                                    }
                                  );
                                }
                              );
                            }
                          );
                        });
                      }
                    }
                  });
              } else {
                _this.axios
                  .get(
                    _this.serve + "/api/Doctor/get_doctor_list?id=s" + _this.sid
                  )
                  .then((res) => {
                    if (res.data.code == 1) {
                      addud(_this.db1, _this.sid, res.data.result);
                      _this.doclist = res.data.result;
                    }
                  });
              }
            });
          },
          function (tx, error) {
            console.log("创建消息表失败:" + error.message);
          }
        );
      });
    },
    lookup1(row, id, type, name, desc) {
      this.lookmoreshow = true;
      
      this.axios
        .post(
          "/gu/search_gu_docs",
          this.qs.stringify({ keywords: name, is_flag: "all" })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.docid = res.data.result[0].id;
          }
        });

      // if (this.isShowNewstype == true) {
      //   this.isShowNewstype = !this.isShowNewstype;
      // }
      // if (sessionStorage.getItem(id)) {
      //   this.message = sessionStorage.getItem(id);
      // } else {
      this.message = "";
      // }
      this.toID = id;
      if (id != nowtouser) {
        //判断是当前的聊天窗口还是新的聊天窗窗口，对聊天记录进行操作
        this.talklist = [];
      }
      if (row.noReadCount > 0) {
        this.axios
          .get(
            this.serve +
              "/api/Doctor/get_no_reads_message?userId=s" +
              this.sid +
              "&targetId=" +
              id
          )
          .then((res) => {
            if (res.data.code == 1) {
              if (res.data.result.length > 0) {
                var arr = res.data.result;
                db.transaction(function (tx) {
                  tx.executeSql(
                    "CREATE TABLE if not exists `user" +
                      id +
                      "` (`content` TEXT,`from` varchar(20),`sendTime` varchar(100),`to` varchar(50),`type` int,`id` varchar(200),`sid` varchar(200),`isRead` int)",
                    [],
                    function (tx, result) {
                      _this.db = db;
                      console.log("创建stu表成功");
                      insert(db, id, arr);
                      read(db, id, function (res) {
                        console.log(_this.talklistextend(res));
                        _this.talklist = _this.talklistextend(res);
                        _this.$nextTick(() => {
                          var div = document.getElementById("scrolldIV");
                          div.scrollTop = div.scrollHeight;
                        });
                      });
                    },
                    function (tx, error) {
                      console.log("创建stu表失败:" + error.message);
                    }
                  );
                });
              }
              //  , function(res) {
              //           //点击用户时先去查询数据库中是否存在数据
              //           read(db, id, function(res) {
              //             _this.talklist = _this.talklistextend(res);
              //             _this.$nextTick(() => {
              //               var div = document.getElementById("scrolldIV");
              //               div.scrollTop = div.scrollHeight;
              //             });
              //           });
              //         }
              //else {
              //   read(db, id, function(res) {
              //     console.log(_this.talklistextend(res));
              //     _this.talklist = _this.talklistextend(res);
              //     _this.$nextTick(() => {
              //       var div = document.getElementById("scrolldIV");
              //       div.scrollTop = div.scrollHeight;
              //     });
              //   });
              // }
            }
          });
        // var cc = this.gethistory(id)
        //   .then((res) => {
        //     var _aa = res;
        //     var _bb = _aa.slice(50 - Number(row.noReadCount), 50);
        //   //   return _bb;
        //   // })
        //   // .then((res) => {
        //     console.log(_bb);
        //     var arr = _bb;
        //     var j = arr.length;
        //     var show = true;
        //     for (let i = 0; i < arr.length; i++) {
        //       push(db, id, arr[i], function(res) {});
        //       if (i == j) {
        //         show = false;
        //       }
        //     }
        //     if (show) {
        //       alert(show)
        //       db.transaction(function(tx) {
        //         tx.executeSql(
        //           "CREATE TABLE if not exists `user" +
        //             id +
        //             "` (`content` TEXT,`from` varchar(20),`sendTime` varchar(100),`to` varchar(50),`type` int,`id` varchar(200),`sid` varchar(200),`isRead` int)",
        //           [],
        //           function(tx, result) {
        //             _this.db = db;
        //             console.log("创建stu表成功");
        //             //点击用户时先去查询数据库中是否存在数据
        //             read(db, id, function(res) {
        //               console.log(res);
        //               //如果有数据，直接获取最后50条
        //               if (res.length > 0) {
        //                 _this.talklist = _this.talklistextend(res);

        //                 _this.$nextTick(() => {
        //                   var div = document.getElementById("scrolldIV");
        //                   div.scrollTop = div.scrollHeight;
        //                 });
        //                 _this.indexedDBindex = 2;
        //               } else {
        //                 _this.gethistory(id).then((res) => {
        //                   insert(db, id, res);
        //                   _this.talklist = res;
        //                   _this.$nextTick(() => {
        //                     var div = document.getElementById("scrolldIV");
        //                     div.scrollTop = div.scrollHeight;
        //                   });
        //                 });
        //               }
        //             });
        //           },
        //           function(tx, error) {
        //             console.log("创建stu表失败:" + error.message);
        //           }
        //         );
        //       });
        //     }
        //   });
      } else {
        //如果没有数据请求第一页
        db.transaction(function (tx) {
          tx.executeSql(
            "CREATE TABLE if not exists `user" +
              id +
              "` (`content` TEXT,`from` varchar(10),`sendTime` varchar(100),`to` varchar(50),`type` int,`id` varchar(200),`sid` varchar(200),`isRead` int)",
            [],
            function (tx, result) {
              _this.db = db;
              console.log("创建stu表成功");
              //点击用户时先去查询数据库中是否存在数据
              read(db, id, function (res) {
                //如果有数据，直接获取最后50条
                if (res.length > 0) {
                  _this.talklist = _this.talklistextend(res);
                  _this.$nextTick(() => {
                    var div = document.getElementById("scrolldIV");
                    div.scrollTop = div.scrollHeight;
                  });
                  _this.indexedDBindex = 2;
                } else {
                  _this.gethistory(id).then((res) => {
                    insert(db, id, res);
                    _this.talklist = _this.talklistextend(res);
                    _this.$nextTick(() => {
                      var div = document.getElementById("scrolldIV");
                      div.scrollTop = div.scrollHeight;
                    });
                  });
                }
              });
            },
            function (tx, error) {
              console.log("创建stu表失败:" + error.message);
            }
          );
        });
      }

      nowtouser = id;

      this.audioElement = document.getElementsByClassName("talklistaudio");
      console.log(this.audioElement);

      // if (type != 0) {
      //   this.isRegisterUser = true;
      // } else {
      //   this.isRegisterUser = false;
      // }
      if (desc != "" && desc != null && desc != undefined) {
        this.talkname = desc;
      } else if (name != "" && name != null && name != undefined) {
        //展示聊天姓名
        if (name.indexOf("dxhs_") == 0) {
          this.talkname = "待完善用户" + id.substr(id.length - 4);
        } else {
          this.talkname = name;
        }
      } else {
        this.talkname = "资料待完善用户" + id.substr(id.length - 4);
      }
      if (sessionStorage.getItem("logout") == "logout") {
        //判断客服的登录状态
        this.$message({
          message: "账号已在另一台电脑登录，请重新登陆",
          type: "error",
        });
      } else {
        row.hasmsg = false;
        // this.searchfiel = id;
        this.dialogFormVisible = true;
        this.row = row;
        //设置头像
        if (
          row.headImg == "" ||
          row.headImg == null ||
          row.headImg == undefined ||
          row.headImg == "null" ||
          row.headImg == "undefined"
        ) {
          this.userheadimg = this.male;
        } else {
          this.userheadimg = row.headImg;
        }
        // if (row.noReadCount > 0) {
        //   this.axios
        //     .post(
        //       this.serve + "/api/MessageStore/add",
        //       this.qs.stringify({
        //         UserId: row.id,
        //         Service: "s" + this.sid,
        //         Name: row.name,
        //         HeadImg: row.headImg,
        //         Desc: row.desc,
        //       })
        //     )
        //     .then((res) => {
        //       console.log(res);
        //     });
        // }
        //把未读状态置为已读
        this.axios
          .post(
            this.serve + "/api/User/read_my_message",
            this.qs.stringify({ fromID: id, toID: "s" + this.sid })
          )
          .then((res) => {
            if (res.data.code == 1) {
              row.noReadCount = 0;
              row.hasmsg = false;
              updateud(
                this.db1,
                this.sid,
                row.id,
                "noReadCount",
                row,
                function (res) {
                  updateud(
                    _this.db1,
                    _this.sid,
                    row.id,
                    "hasmsg",
                    row,
                    function (res) {
                      readud(_this.db1, _this.sid, function (res) {
                        _this.doclist = res;
                      });
                    }
                  );
                }
              );
            }
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    },
    //获取第一篇历史记录
    gethistory(id) {
      var list = this.axios
        .get(
          this.serve +
            "/api/Doctor/get_message_history?userId=s" +
            this.sid +
            "&targetId=" +
            id +
            "&index=1"
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.indexedDBindex = 2;
            return res.data.result;
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
      return list;
    },
    //查看更多历史记录
    loadmore() {
      let newtalklist = [],
        oldtalklist = [];
      var _id = this.toID;
      var _index = this.indexedDBindex;
      search(db, _id, _index, function (res) {
        var reslist = _this.talklistextend(res);
        if (res.length > 0) {
          if (res.length == 50) {
            console.log("长度有50个");
            newtalklist = _this.talklistextend(res);
            oldtalklist = _this.talklist;
            _this.talklist = [];
            _this.talklist = newtalklist.concat(oldtalklist);
            _this.indexedDBindex = _this.indexedDBindex + 1;
          } else if (res.length < 50) {
            console.log("长度没有50个");
            _this.getmorehistory(_id, _index).then((res) => {
              var list = [];
              if (res.length > 0) {
                list = _this.MergeArray(reslist, res);
                insert(db, _id, list);
                newtalklist = list;
                oldtalklist = _this.talklist;
                _this.talklist = [];
                _this.talklist = newtalklist.concat(oldtalklist);
              }
            });
          } else {
          }
        } else {
          _this.getmorehistory(_id, _index).then((res) => {
            if (res.length > 0) {
              insert(db, _id, res);
            }
            newtalklist = res;
            oldtalklist = _this.talklist;
            _this.talklist = [];
            _this.talklist = newtalklist.concat(oldtalklist);
          });
        }
      });
    },
    getmorehistory(toid, index) {
      var list = this.axios
        .get(
          this.serve +
            "/api/Doctor/get_message_history?userId=s" +
            this.sid +
            "&targetId=" +
            toid +
            "&index=" +
            index
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
            this.indexedDBindex = 1;
            return [];
          } else if (res.data.code == 1) {
            if (res.data.result.length > 0) {
              if (this.indexedDBindex == 1) {
                this.talklist = [];
              }
              this.indexedDBindex++;
              return res.data.result;
            } else {
              this.$message({
                message: "没有更多了",
                type: "warning",
              });
              this.lookmoreshow = false;
              return [];
            }
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
      return list;
    },
    send(type, msg) {
      var toID = this.toID;
      var fromID = "s" + this.sid;
      //说明是文字
      this.talkconnection
        .invoke("send", toID, fromID, msg, type)
        .then((res) => {
          if (res.err == 1) {
            this.$message({
              message: "发送成功",
              type: "success",
            });
            sessionStorage.removeItem(this.toID);
            //发送成功
            if (toID == nowtouser) {
              this.talklist.push(res.data);
              push(db, toID, res.data);
            }
            updateud(
              this.db1,
              this.sid,
              toID,
              "sendTime",
              res.data,
              function (res) {
                readud(_this.db1, _this.sid, function (res) {
                  _this.doclist = res;
                });
              }
            );
            this.message = "";
            this.showsend = false;
            this.showplus = true;
          } else {
            this.axios
              .post(
                "/test/log",
                this.qs.stringify({
                  TtoID: toID,
                  TfromID: fromID,
                  Tmsg: msg,
                  Ttype: type,
                })
              )
              .then((res) => {});
            this.$message({
              message: "发送失败，请重新发送",
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //上传图片
    upload(content) {
      let formData = new FormData();
      formData.append("file", content.file); // 'file[]' 代表数组 其中`file`是可变的
      this.axios
        .post(
          "http://192.168.11.253:8080/qiniu/examples/upload_simple_file.php",
          formData
        )
        .then((rs) => {
          if (rs.data.code == 0) {
            this.picsrc = rs.data.file;
            this.send(1, this.picsrc);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //数组去重
    MergeArray(arr1, arr2) {
      var jsonArr = [];
      let length1 = arr1.length;
      let length2 = arr2.length;
      for (let i = 0; i < length1; i++) {
        for (let j = 0; j < length2; j++) {
          //判断添加的数组是否为空了
          if (arr1.length > 0) {
            if (arr1[i]["sendTime"] === arr2[j]["sendTime"]) {
              arr1.splice(i, 1); //利用splice函数删除元素，从第i个位置，截取长度为1的元素
              length1--;
              // console.log(arr2[j]); //重复元素
            }
          }
        }
      }
      for (let n = 0; n < arr2.length; n++) {
        jsonArr.push(arr2[n]);
      }
      return jsonArr;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isLt2M;
    },
    lookchanneldetail(row) {
      let routeData = this.$router.resolve({
        path: "/lookvideodetail",
        query: { id: row.id },
      });
      window.open(routeData.href, "_blank");
    },
    //发送语音消息
    wantSoundRecording() {
      this.SoundRecording = true;
    },
    Listening(index) {
      var middle = "audio" + index;
      middle = this.$refs[middle][0];
      var playicon = middle.parentNode.firstChild.lastChild;
      if (middle.paused) {
        this.pauseAll();
        middle.play();
        playicon.className = "el-icon-video-pause";
      } else {
        middle.pause();
        playicon.className = "el-icon-video-play";
      }
    },
    pauseAll(exclude) {
      const audios = this.audioElement;
      audios.forEach((audio, index) => {
        audio.pause();
        let playicon = audio.parentNode.firstChild.lastChild;
        playicon.className = "el-icon-video-play";
        audio.currentTime = 0;
      });
    },
    endplay(index) {
      var middle = "audio" + index;
      middle = this.$refs[middle][0];
      middle.pause();
      var playicon = middle.parentNode.firstChild.lastChild;
      playicon.className = "el-icon-video-play";
    },
    sendMoneyChangeAlllist() {
      readud(this.db1, this.sid, function(res) {
        _this.doclist = res;
      });
    },
    sendMoneyChangeTalklist() {
      read(this.db1, nowtouser, function(res) {
        //如果有数据，直接获取最后50条
        if (res.length > 0) {
          _this.talklist = _this.talklistextend(res);
          _this.$nextTick(() => {
            var div = document.getElementById("scrolldIV");
            div.scrollTop = div.scrollHeight;
          });
          _this.indexedDBindex = 2;
        } else {
          _this.gethistory(id).then((res) => {
            insert(db, id, res);
            _this.talklist = res;
            _this.$nextTick(() => {
              var div = document.getElementById("scrolldIV");
              div.scrollTop = div.scrollHeight;
            });
          });
        }
        _this.audioElement = document.getElementsByClassName("talklistaudio");
        console.log(_this.audioElement);
        _this.audioElement.forEach((audio, index) => {
          audio.pause();
          let playicon = audio.parentNode.firstChild.lastChild;
          playicon.className = "el-icon-video-play";
          audio.currentTime = 0;
        });
      });
    },
  },
};
</script>
