<template>
  <div>
    <el-dialog title="查看订单" :visible.sync="show" width="80%" @close="exit()">
      <el-table :data="list" border style="width: 100%" :default-sort="{ prop: 'state' }">
        <el-table-column prop="id" label="ID" v-if="false"></el-table-column>
        <el-table-column prop="Receiver" label="患者姓名" width="100"></el-table-column>
        <el-table-column prop="Tel" label="电话号" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.Tel.substring(0, 3) + "****" + scope.row.Tel.substring(7) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Cust_ID" label="用户编号" width="120"></el-table-column>
        <el-table-column prop="Order_ID" label="订单编号" width="160"></el-table-column>
        <el-table-column prop="Order_Date" label="创建时间" width="180"></el-table-column>
        <el-table-column prop="amount_str" label="金额" width="120"></el-table-column>
        <el-table-column prop="" label="地址" width="300">
          <template slot-scope="scope">
            <span>
              {{ scope.row.Province }}{{ scope.row.City }}{{ scope.row.Counties
              }}{{ scope.row.Adress }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="Order_state" label="订单状态" width="100">
          <template slot-scope="scope">
            <span>
              {{ dingdanzhuangtai(scope.row.Order_state) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="Shipping_State" label="物流状态" width="100">
          <template slot-scope="scope">
            <span>
              {{ wuliuzhuangtai(scope.row.Shipping_State) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center;">
        <p class="paging">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="current"
            :page-sizes="pageSizes"
            :page-size="PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
            :hide-on-single-page="value"
          >
          </el-pagination>
        </p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["lookuserdd", "friendlistcustid"],
  data() {
    return {
      show: false,
      custid: "",
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [6], // 个数选择器（可修改）
      PageSize: 6, // 默认每页显示的条数（可修改）
      value: false,
    };
  },
  watch: {
    lookuserdd(o, n) {
      this.show = o;
    },
    friendlistcustid(o, n) {
      this.custid = o;
      this.getdata(1);
    },
  },
  methods: {
    exit() {
      this.show = false;
      this.$emit("update:lookuserdd", false);
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage);
    },
    getdata(page) {
      this.axios
        .get(
          "/order/get_orders?" + "cust_id=" + this.custid+"&page=" + page + "&size=" + this.PageSize
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          }
        });
    },
    //物流状态
    wuliuzhuangtai(val) {
      var str = "";
      var dic = {
        0: "未发货",
        1: "已发货",
        2: "签收",
        3: "配货中",
        4: "已发货部分商品",
        5: "发货中",
        9: "退回",
      };
      for (var key in dic) {
        if (key == val) {
          str = dic[key];
        }
      }
      return str;
    },
    //订单状态
    dingdanzhuangtai(val) {
      var str = "";
      var dic = {
        "-1": "付款失败",
        0: "未付款",
        1: "已付款",
        // 2: "已确认",
        // 6: "已退款",
      };
      for (var key in dic) {
        if (key == val) {
          str = dic[key];
        }
      }
      return str;
    },
  },
};
</script>
