const addu = function(db, id, list) {
  db.transaction(function(tx) {
    for (let m = 0; m < list.length; m++) {
      tx.executeSql(
        "insert into msglist" +
          id +
          " (desc,name,id,sendTime,noReads,headImg,hasmsg,isOnline) values(?,?,?,?,?,?,?,?)",
        [
          list[m].desc,
          list[m].name,
          list[m].id,
          list[m].sendTime,
          list[m].noReads,
          list[m].headImg,
          "false",
          list[m].isOnline,
        ],
        function() {
          console.log("插入成功");
        },
        function(tx, error) {
          console.log(error);
        }
      );
    }
  });
};
const putu = function(db, sid, id, obj, callback) {
  db.transaction(function(tx) {
    tx.executeSql(
      "select * FROM msglist" + sid + " where id = ?",
      [id],
      function(tx, result) {
        if (result.rows.length > 0) {
          callback("has");
        } else {
          // console.log('nohas')
          db.transaction(function(tx) {
            tx.executeSql(
              "insert into msglist" +
                sid +
                " (desc,name,id,sendTime,noReads,headImg,hasmsg,isOnline) values(?,?,?,?,?,?,?,?)",
              [
                obj.desc,
                obj.name,
                obj.id,
                obj.sendTime,
                obj.noReads,
                obj.headImg,
                obj.hasmsg,
                obj.isOnline,
              ],
              function() {
                callback("");
                console.log("插入成功");
              },
              function(tx, error) {
                console.log(error);
              }
            );
          });
        }
      },
      function(tx, error) {
        console.log("error");
      }
    );
  });
};
const readu = function(db, id, callback) {
  db.transaction(function(tx) {
    tx.executeSql(
      "select * FROM msglist" + id + " order by sendTime desc, noReads desc", //查列表
      [],
      function(tx, result) {
        //执行成功的回调函数
        //在这里对result 做你想要做的事情吧.
        callback(result.rows);
      },
      function(tx, error) {
        console.log("查询失败: " + error.message);
      }
    );
  });
};
const delu = function(db, sid, id, callback) {
  db.transaction(function(tx) {
    tx.executeSql(
      "delete from msglist" + sid + " where id = ?",
      [id],
      function(tx, result) {
        callback("ok");
      },
      function(tx, error) {
        callback("error");
      }
    );
  });
};
const updateu = function(db, sid, id, zd, obj, callback) {
  db.transaction(function(tx) {
    tx.executeSql(
      "update msglist" + sid + " set " + zd + " = ? where id = ?",
      [obj[zd], id],
      function(tx, result) {
        callback("");
      },
      function(tx, error) {
        console.log("更新失败" + error.message);
      }
    );
  });
};
const inu = function(db, sid, id, callback) {
  db.transaction(function(tx) {
    tx.executeSql(
      "select * FROM msglist" + sid + " where id = ?",
      [id],
      function(tx, result) {
        //  console.log(id)
        // console.log(result);
        if (result.rows.length > 0) {
          callback("in");
        } else {
          callback("out");
        }
      },
      function(tx, error) {
        console.log("不存在" + error.message);
        callback("out");
      }
    );
  });
};
const deletemore7u = function(db, id, callback) {
  db.transaction(function(tx) {
    tx.executeSql(
      "delete from msglist" + id + " where sendTime <= datetime('now','-3 day')",
      [],
      function(tx, result) {
        //执行成功的回调函数
        //在这里对result 做你想要做的事情吧.
        callback(result.rows);
      },
      function(tx, error) {
        console.log("查询失败: " + error.message);
      }
    );
  });
};
const deletenoin7day = function(db, id, callback) {
  db.transaction(function(tx) {
    tx.executeSql(
      "select * from msglist" + id + " where sendTime <= datetime('now','-3 day')",
      [],
      function(tx, result) {
        //执行成功的回调函数
        //在这里对result 做你想要做的事情吧.
        let list = result.rows;
        for (let a = 0; a < list.length; a++) {
          tx.executeSql("drop table user" + list[a].id);
        }
        callback("ok");
      },
      function(tx, error) {
        console.log("查询失败: " + error.message);
      }
    );
  });
};
export { addu, readu, delu, updateu, putu, inu, deletemore7u, deletenoin7day };
