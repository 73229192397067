<template>
  <div>
    <el-dialog title="选择反馈视频" :visible.sync="show" width="70%" @close="exit()">
      <div style="text-align: left; margin-bottom: 20px">
        <!-- <span>科室：</span> -->
        <!-- <el-select
          filterable
          v-model="dp"
          clearable
          placeholder="请选择科室"
          style="width: 180px"
        >
          <el-option
            v-for="item in dpoptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select> -->
        <el-input placeholder="请输入疾病全名" v-model="illsearch" clearable style="width: 200px; margin-left: 10px"></el-input>
        <el-input placeholder="请输入医生全名" v-model="doc" clearable style="width: 200px; margin-left: 10px"></el-input>
        <el-input placeholder="请输入患者姓名" v-model="keywords" clearable style="width: 200px; margin-left: 10px"></el-input>
        <el-button icon="el-icon-search" type="primary" style="margin-left: 10px" @click="searchkeywors(1)">查询</el-button>
      </div>
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" :row-key="
          (row) => {
            return row.id;
          }
        " @selection-change="handleSelectionChange">
        <el-table-column type="selection" :reserve-selection="true" width="55">
        </el-table-column>
        <!-- <el-table-column label="标题" prop="title"></el-table-column> -->
        <!-- <el-table-column prop="cust_id" label="用户ID"></el-table-column> -->
        <el-table-column prop="name" label="用户姓名"></el-table-column>
        <el-table-column prop="doc_name" label="医生姓名"></el-table-column>
        <el-table-column label="科室" prop="ill_name"></el-table-column>
        <el-table-column label="简介" prop="summary">
          <template slot-scope="scope">
            <div style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              ">
              {{ scope.row.summary }}
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="opt doc">
              <a @click="look(scope.row)">查看</a>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <p class="paging">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value"></el-pagination>
      </p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="exit()">取 消</el-button>
        <el-button type="primary" @click="send">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="show1" width="30%" @close="closelook">
      <video id="look" :src="looksrc" controls style="width: 100%"></video>
    </el-dialog>
  </div>
</template>
<script>
import { insert, del, push, read, search } from "../assets/sql2.js";
import { addu, readu, delu, updateu, putu } from "../assets/msglist.js";
export default {
  props: ["sendcase", "connection1", "toID", "talklist", "db"],
  data() {
    return {
      show: false,
      keywords: "",
      dp: "",
      dpoptions: [],
      tableData: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [10], // 个数选择器（可修改）
      PageSize: 10, // 默认每页显示的条数（可修改）
      value: false,
      connection2: "",
      sid: "",
      multipleSelection: [],
      show1: false,
      looksrc: "",
      female: require("../assets/female.png"),
      male: require("../assets/male.png"),
      custid: "",
      doc: "",
      docoptions: [],
      illsearch: "",
    };
  },
  watch: {
    sendcase(o, n) {
      this.show = o;
      // this.getdp();
      this.doc = "";
      this.dp = "";
      this.illsearch = "";
      this.multipleSelection = [];
      this.tableData = [];
      this.totalCount = 0;
    },
    connection1(o, n) {
      this.connection2 = o;
    },
  },
  methods: {
    exit() {
      this.multipleSelection = [];
      this.tableData = [];
      this.totalCount = 0
      this.dp = "";
      this.toggleSelection();
      this.show = false;
      this.$emit("update:sendcase", false);
    },
    getdp() {
      this.axios.get("/gu/get_gu_department").then((res) => {
        if (res.data.code == 0) {
          this.dpoptions = res.data.result;
        }
      });
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      if (this.keywords == "") {
        this.searchkeywors(currentPage);
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    toggleSelection() {
      this.$refs.multipleTable.clearSelection();
      this.multipleSelection = [];
    },
    searchkeywors(page) {
      this.tableData = [];
      // if (this.keywords != "") {
      //   //关键字查询
      //   this.axios.get("/item/search_gu_items?keyword=" + this.keywords).then((res) => {
      //     if (res.data.code == 0) {
      //       this.tableData = res.data.result;
      //       this.totalCount = res.data.result.length;
      //     } else {
      //       this.tableData = [];
      //       console.log(res);
      //     }
      //   });
      // } else {
      // this.axios
      //   .get(
      //     "/item/get_gu_items?page=" +
      //       page +
      //       "&size=" +
      //       this.PageSize +
      //       "&type=1&content_type=2&is_visible=1&is_audit=1" +
      //       "&dp_id=" +
      //       this.dp
      //   )
      //   .then((res) => {
      //     if (res.data.code == "0") {
      //       this.tableData = res.data.result;
      //       this.totalCount = res.data.count;
      //     } else {
      //       this.tableData = [];
      //       this.totalCount = 0;
      //     }
      //   })
      //   .catch((res) => {
      //     console.log(res);
      //   });
      // }
      var str = "";
      if (this.keywords.trim() != "") {
        str += "&name=" + this.keywords.trim();
      }
      this.checkempty().then((res) => {
        if (res.length > 0) {
          res.forEach((ele1) => {
            if (ele1.data.code == 0) {
              console.log(ele1);
              // if ("Cust_ID" in ele1.data.result[0]) {
              //   str += "&cust_id=" + ele1.data.result[0].Cust_ID;
              // }
              if ("dp_id" in ele1.data.result[0]) {
                str += "&ill_lib_id=" + ele1.data.result[0].id;
              }
              if (
                "id" in ele1.data.result[0] &&
                "flag" in ele1.data.result[0]
              ) {
                str += "&doc_id=" + ele1.data.result[0].id;
              }
            }
          });
          console.log(str);
          this.sss(page, str);
        } else {
          console.log(str);
          this.sss(page, str);
        }
      });
    },
    sss(page, str) {
      this.tableData = [];
      this.axios
        .get("/gu/get_gu_showcusts?page=" + page + "&size=10" + str)
        .then((res) => {
          this.tableData = res.data.result;
          this.totalCount = res.data.count;
        });
    },
    checkempty() {
      var arr = [];
      // if (this.keywords != "") {
      //   var re = this.axios.post(
      //     "/gu/search_gu_custs",
      //     this.qs.stringify({ keyword: this.keywords })
      //   );
      //   arr.push(re);
      // }
      if (this.doc.trim() != "") {
        var a = this.axios.post(
          "/gu/search_gu_docs",
          this.qs.stringify({ keyword: this.doc.trim(), is_flag: "all" })
        );
        arr.push(a);
      }
      if (this.illsearch.trim() != "") {
        var c = this.axios.get(
          "/gu/get_gu_ill_lib_like_name?ill_lib_name=" + this.illsearch.trim()
        );
        arr.push(c);
      }
      return this.axios.all(arr).then(
        this.axios.spread(function (...resList) {
          return resList;
        })
      );
    },
    send() {
      this.sid = sessionStorage.getItem("id");
      var toID = this.toID;
      var fromID = "s" + this.sid;
      // var alllist = JSON.parse(localStorage.getItem('usertalklist'+this.sid));
      if (this.multipleSelection.length > 1) {
        this.$message({
          message: "只能选择一个反馈视频，请重新选择",
          type: "error",
        });
        return;
      }
      var headImg = "";
      if (
        this.multipleSelection[0].cust_headimg == "" ||
        this.multipleSelection[0].cust_headimg == null ||
        this.multipleSelection[0].cust_headimg == "null" ||
        this.multipleSelection[0].cust_headimg == undefined
      ) {
        headImg = this.multipleSelection[0].headimg;
      } else if (
        this.multipleSelection[0].cust_headimg != "" &&
        this.multipleSelection[0].cust_headimg != null &&
        this.multipleSelection[0].cust_headimg != "null" &&
        this.multipleSelection[0].cust_headimg != undefined
      ) {
        headImg = this.multipleSelection[0].cust_headimg;
      } else {
        headImg = this.male;
      }
      var oo = {
        id: this.multipleSelection[0].id,
        name: this.multipleSelection[0].name,
        des: this.multipleSelection[0].summary,
        ill_name: this.multipleSelection[0].ill_name,
        headimg: headImg,
        cust_name: this.multipleSelection[0].cust_name,
      };
      this.connection1
        .invoke("send", toID, fromID, JSON.stringify(oo), 10)
        .then((res) => {
          if (res.err == 1) {
            //发送成功
            let measge = {
              content: res.data.content,
              from: fromID,
              type: "10",
              sendTime: res.data.sendTime,
            };
            push(this.db, toID, res.data);
            updateu(
              this.db,
              this.sid,
              toID,
              "sendTime",
              measge,
              function (res) {}
            );
            this.$emit("sendMoneyChangeAlllist");
            this.$emit("sendMoneyChangeTalklist");
            this.exit();
          } else {
            this.$message({
              message: "发送失败，请重新发送",
              type: "error",
            });
          }
        });
    },
    //按照日期排序
    comparetime(property) {
      return function (a, b) {
        var value1 = new Date(a[property]).getTime();
        var value2 = new Date(b[property]).getTime();
        return value2 - value1;
      };
    },
    /** 获取当前时间 格式：yyyy-MM-dd HH:MM:SS*/
    getCurrentTime() {
      var date = new Date(); //当前时间
      var month = this.zeroFill(date.getMonth() + 1); //月
      var day = this.zeroFill(date.getDate()); //日
      var hour = this.zeroFill(date.getHours()); //时
      var minute = this.zeroFill(date.getMinutes()); //分
      var second = this.zeroFill(date.getSeconds()); //秒
      //当前时间
      var curTime =
        date.getFullYear() +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      return curTime;
    },
    /**补零*/
    zeroFill(i) {
      if (i >= 0 && i <= 9) {
        return "0" + i;
      } else {
        return i;
      }
    },
    look(row) {
      this.show1 = true;
      // this.looksrc = row.content;
      this.looksrc = row.video;
    },
    closelook() {
      this.show1 = false;
      var video = document.getElementById("look");
      video.pause();
    },
  },
};
</script>
