<template>
  <div>
    <div class="CSmainbox">
      <div style="width: 100%; text-align: left; margin-bottom: 20px">
        <p
          style="
            width: 90px;
            float: left;
            text-align: right;
            padding-right: 10px;
          "
        >
          选择客服
        </p>
        <el-select v-model="ChangeServeRadio" filterable placeholder="请选择" @change="change" size="small">
          <el-option
            v-for="item in servecheckList"
            :key="item.id"
            :label="item.account || item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="CSleft">
        <p
          style="
            width: 90px;
            float: left;
            text-align: right;
            padding-right: 10px;
          "
        >
          该客服下的用户
        </p>
        <div style="padding-left: 90px;">
          <div class="CSsearch">
            <el-input
              placeholder="请输入关键字(姓名或电话号或用户编号)"
              clearable
              prefix-icon="el-icon-search"
              v-model="input2"
              @clear="
                input2 = '';
                ChangeServeIndex = 1;
                usercheckList = [];
                usercheckList1 = [];
              "
              size="small"
            >
            </el-input>
            <el-button type="primary" size="small" style="float:left;" @click="ChangeServeQuery('')"
              >查询</el-button
            >
          </div>
          <!-- <div class="CSsearch">
            <el-input
              placeholder="请输入客户编号"
              clearable
              prefix-icon="el-icon-search"
              v-model="input3"
              @clear="
                input3 = '';
                ChangeServeIndex = 1;
                usercheckList = [];
                usercheckList1 = [];
              "
            >
            </el-input>
            <el-button type="primary" size="mini" @click="ChangeServeQueryB">查询</el-button>
          </div> -->
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <div
            style="width:100%"
            class="infinite-list"
            v-infinite-scroll="getusercheckList"
            infinite-scroll-immediate
          >
            <el-checkbox-group v-model="ChangeServeCheckList" @change="handleCheckedCitiesChange">
              <el-checkbox
                style="width: 100%; height: 28px; color: #003366"
                :label="item.id"
                :key="item.id"
                v-for="item in usercheckList"
              >
                <span style="color: green; margin-right: 8px">{{ item.name }}</span>
                <span style="margin-right: 8px">{{ item.desc }}</span>
                <span style="color:#E6A23C;">话务客服ID：{{ item.serviceId }}</span>
                <!-- <span>{{item.name}}</span> -->
                <!-- <span>{{
                  item.desc != "" && item.desc != null && item.desc != undefined
                    ? item.desc
                    : item.name == "" || item.name == null || item.name == undefined
                    ? item.id
                    : item.name
                }}</span> -->
                <span style="color: #99ccff; margin: 0 10px">{{ item.id }}</span>
                <span style="color: #cccccc;margin: 0 10px">{{ item.createTime }}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <el-button
        style="margin-top: 30px; margin-left: 150px"
        type="primary"
        @click="dialogVisible = true"
        >转移服务人
      </el-button>
      <el-dialog title="" :visible.sync="dialogVisible" width="30%">
        <span style="margin-right: 20px">请选择转移到的客服</span>
        <el-select v-model="ChoiceServeRadio" filterable placeholder="请选择" value-key="id">
          <el-option
            v-for="item in servecheckList"
            :key="item.id"
            :label="item.account || item.name"
            :value="item"
          >
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subChangeServer02">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  inject: ["reload"],
  data() {
    return {
      ChangeServeRadio: [],
      servecheckList: [],
      input2: "",
      ChangeServeCheckList: [],
      usercheckList: [],
      ChangeServeIndex: 1,
      pagesize: 40,
      dialogVisible: false,
      ChoiceServeRadio: [],
      loading: false,
      sid: sessionStorage.getItem("id"),
      input3: "",
      checkAll: false,
      isIndeterminate: true,
      uid: sessionStorage.getItem("servicer_id"),
      servicelist: [],
      changechoideserveradio: "",
    };
  },
  mounted() {
    this.getservecheckList();
  },
  methods: {
    handleCheckAllChange(val) {
      var userchecklist = this.usercheckList.map((obj) => {
        return obj.id;
      });
      this.ChangeServeCheckList = val ? userchecklist : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.usercheckList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.usercheckList.length;
    },
    filterid(val) {
      val = String(val);
      if (val.substr(0, 1) == "s") {
        return val;
      } else {
        return "s" + val;
      }
    },
    change() {
      this.ChangeServeIndex = 1;
      this.usercheckList = [];
      this.usercheckList1 = [];
      this.getusercheckList();
    },
    // changeserveradio(value) {
    //   this.changechoideserveradio = value;
    //   console.log(this.changechoideserveradio)
    // },
    //转移服务人用户列表
    getusercheckList() {
      if (this.loading) return;
      this.loading = true;
      var id = this.filterid(this.ChangeServeRadio);
      this.axios
        .get(
          this.serveurl.serve +
            "/api/User/get_user_with_relation?userID=" +
            id +
            "&index=" +
            this.ChangeServeIndex
        )
        .then((res) => {
          this.loading = false;
          if (res.data.code == 1) {
            if (res.data.result.length > 0) {
              this.usercheckList1 = res.data.result;
              var str = "";
              this.usercheckList1.forEach((e) => {
                str += "'" + e.id + "',";
              });
              str = str.substr(0, str.length - 1);
              this.axios
                .post("/chat/get_user_cust_servicer", this.qs.stringify({ tels: str }))
                .then((res) => {
                  if (res.data.code == 0) {
                    this.servicelist = res.data.result;
                    this.usercheckList1.forEach((item) => {
                      this.servicelist.forEach((tem) => {
                        if (item.id == tem.Tel1) {
                          item.serviceId = tem.Cust_Servicer;
                          item.custId = tem.Cust_ID;
                        }
                      });
                    });
                    this.usercheckList = this.usercheckList.concat(this.usercheckList1);
                  }
                });
              this.ChangeServeIndex += 1;
            } else {
              this.ChangeServeIndex = 1;
            }
          } else {
            this.ChangeServeIndex = 1;
          }
        });
    },
    //获取客服列表
    getservecheckList() {
      // if (sessionStorage.getItem("level") == 1) {
      //   this.axios.get("ma/get_gu_ma_cs_relationship_by_maid?ma_id=" + this.sid).then((res) => {
      //     if (res.data.code == 0) {
      //       this.servecheckList = res.data.result;
      //     }
      //   });
      // } else {
      //   this.axios.get(serve + "/api/User/get_service_user").then((res) => {
      //     if (res.data.code == 1) {
      //       this.servecheckList = res.data.result;
      //     }
      //   });
      // }
      this.axios.get(this.serveurl.serve + "/api/Employee/get_employee?Id=" + this.uid).then((res) => {
        if (res.data.code == 1) {
          this.servecheckList = res.data.result;
        }
      });
    },
    //提交修改服务人
    subChangeServer02() {
      if (this.ChangeServeCheckList.length <= 0) {
        this.$message({
          message: "请选择用户",
          type: "error",
        });
      } else if (this.ChoiceServeRadio == "") {
        this.$message({
          message: "请选择客服",
          type: "error",
        });
      } else {
        var id = this.filterid(this.ChoiceServeRadio.id);
        //聊天修改服务人员
        this.axios
          .post(
            this.serveurl.serve + "/api/User/change_users_service",
            this.qs.stringify({
              targetID: id,
              userIDList: this.ChangeServeCheckList,
              agent: sessionStorage.getItem("servicer_id"),
              fromId: this.ChangeServeRadio,
            })
          )
          .then((res) => {
            this.$message({
              message: "修改成功",
              type: "success",
            });
          });
          this.removeMsgList(this.ChangeServeRadio,this.ChangeServeCheckList);
        var oldid = this.filterid(this.ChangeServeRadio);
        var _cust_servicer = this.ChoiceServeRadio.serviceId;
        for (let i = 0; i < this.ChangeServeCheckList.length; i++) {
          this.axios
            .post(
              "/log/chat_login_log",
              this.qs.stringify({
                data:
                  "【修改服务人】 " +
                  sessionStorage.getItem("id") +
                  "  ---   " +
                  sessionStorage.getItem("name") +
                  "转移得用户姓名：" +
                  this.ChangeServeCheckList[i],
              })
            )
            .then((res) => {});
          //清除转移服务人时的未读
          this.axios
            .post(
              this.serveurl.serve + "/api/User/read_message",
              this.qs.stringify({
                fromID: this.ChangeServeCheckList[i],
                toID: oldid,
              })
            )
            .then((res) => {
              if (res.data.code == 1) {
                console.log(res);
              } else {
                console.log(res);
              }
            })
            .catch((error) => {
              console.error(error.message);
            });
          //根据电话号查询患者的cust_id
          this.axios
            .get("/gu/get_cust_info_by_tel?tel=" + this.ChangeServeCheckList[i])
            .then((res) => {
              if (res.data.code == 0) {
                var custID = res.data.Cust_ID;
                var _assigner = sessionStorage.getItem("servicer_id");

                //改变服务人同步话务系统
                this.axios
                  .post(
                    "/chat/insert_assigntable",
                    this.qs.stringify({
                      cust_id: custID,
                      assigner: _assigner,
                      cust_servicer: _cust_servicer,
                    })
                  )
                  .then((res) => {
                    if (res.data.code == 0) {
                      console.log(res);
                    } else {
                      console.log(res);
                    }
                  });
              }
            });
        }
      }
      this.dialogVisible = false;
      this.ChangeServeCheckList = [];
      this.ChoiceServeRadio = "";
      this.changechoideserveradio = "";
      this.ChangeServeRadio = "";
      this.input2 = "";
      this.usercheckList = [];
      this.isIndeterminate = true;
    },
    //转移完服务人之后删除消息
    removeMsgList(sid,arr){
      arr.forEach(item=>{
        this.axios
            .post(
              this.serveurl.serve + "/api/MessageStore/remove",
              this.qs.stringify({ service: sid, userId: item })
            )
            .then((res) => {
              if (res.data.code == 1) {
                console.log(res);
                console.log('移除成功')
              }
            });
      })
    },
    //修改服务人查询用户
    ChangeServeQuery(a) {
      if (this.ChangeServeRadio == "") {
        this.$message({
          message: "请选择客服",
          type: "error",
        });
        return;
      }
      var id = this.filterid(this.ChangeServeRadio);
      // if (this.input2.match(/^\d/)) {
      //   this.axios
      //     .get(serve + "/api/User/search_user?userID=" + id + "&name=" + this.input2)
      //     .then((res) => {
      //       if (res.data.code == 1) {
      //         if (res.data.result.length > 0) {
      //           this.usercheckList = res.data.result;
      //         } else {
      //           this.$message({
      //             message: "没有在该客服下搜索到此用户",
      //             type: "error",
      //           });
      //         }
      //       }
      //     })
      //     .catch((res) => {
      //       console.log(res);
      //     });
      // } else {
      this.axios.get("/gu/get_cust_info_by_custid?cust_id=" + this.input2).then((res) => {
        if (res.data.code == 0) {
          // this.ChangeServeQuery(res.data.result.Tel1);
          this.getuserserve(id, res.data.result.Tel1);
        } else {
          this.getuserserve(id, this.input2);
        }
      });
    },
    getuserserve(id, val) {
      this.axios.get(this.serveurl.serve + "/api/User/search_user?userID=" + id + "&name=" + val).then((res) => {
        if (res.data.code == 1) {
          if (res.data.result.length > 0) {
            this.usercheckList1 = res.data.result;
            var str = "";
            this.usercheckList1.forEach((e) => {
              str += "'" + e.id + "',";
            });
            str = str.substr(0, str.length - 1);
            this.axios
              .post("/chat/get_user_cust_servicer", this.qs.stringify({ tels: str }))
              .then((res) => {
                if (res.data.code == 0) {
                  this.servicelist = res.data.result;
                  this.usercheckList1.forEach((item) => {
                    this.servicelist.forEach((tem) => {
                      if (item.id == tem.Tel1) {
                        item.serviceId = tem.Cust_Servicer;
                        item.custId = tem.Cust_ID;
                      }
                    });
                  });
                  this.usercheckList = this.usercheckList1;
                }
              });
          } else {
            this.$message({
              message: "没有在该客服下搜索到此用户",
              type: "error",
            });
          }
        }
      });
    },
    //
    ChangeServeQueryB() {
      this.axios.get("/gu/get_cust_info_by_custid?cust_id=" + this.input3).then((res) => {
        if (res.data.code == 0) {
          this.ChangeServeQuery(res.data.result.Tel1);
        }
      });
    },
  },
};
</script>
<style>
.CSmainbox {
  width: 100%;
  height: 640px;
  text-align: left;
}

.CSleft {
  width: 100%;
  float: left;
}

.CSsearch {
  width: 100%;
  height: 40px;
  margin: 10px 0;
}

.CSsearch .el-input {
  width: 310px;
  float: left;
}

.CSsearch .el-input__inner {
  float: left;
  height: 40px;
  line-height: 40px;
}

.CSsearch .el-input__icon {
  line-height: 30px;
}

.CSsearch .el-button {
  margin-left: 25px;
  height: 100%;
}

.CSleft .infinite-list {
  width: 100%;
  height: 350px;
  text-align: left;
  border: 1px solid #dcdfe6;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

.CStips {
  width: 8%;
  float: left;
  height: 100%;
  line-height: 150px;
}

.CSright {
  width: 46%;
  float: right;
}

.CSkfList {
  width: 100%;
  height: 380px;
  text-align: left;
  border: 1px solid #000;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
