<template>
  <div>
    <el-dialog title="查看寻医" :visible.sync="show" width="80%" @close="exit()">
      <el-table :data="userxylist" border style="width: 100%; margin-top: 40px" :default-sort="{prop: 'State'}">
        <el-table-column prop="Creat_DT" label="时间" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.Creat_DT.split(".")[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="KID" label="编号" width="160"></el-table-column>
        <el-table-column prop="Dis_Name" label="寻医单号"></el-table-column>
        <el-table-column prop="Cust_ID" label="用户id" width="120"></el-table-column>
        <el-table-column prop="State" label="状态" sortable width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.State == 0" style="color:#E6A23C;">未提交</span>
            <span v-if="scope.row.State == 1" style="color:#409EFF;">待挂号</span>
            <span v-if="scope.row.State == 2" style="color:#67C23A;">已抓取</span>
            <span v-if="scope.row.State == 6" style="color:#C709F7;">审核</span>
            <span v-if="scope.row.State == 9" style="color:#F56C6C;">删除</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <div class="opt">
              <a @click="NewXYEdit(scope.row, 'look')">查看</a>
              <!-- <el-link
                    type="success"
                    :disabled="scope.row.State != 0 && scope.row.State != 1"
                    @click="NewXYEdit(scope.row, '')"
                  >
                    编辑</el-link
                  >
                  <a @click="choicexy(scope.row)">下发</a>
                  <a @click="XYrepulse(scope.row)">打回</a> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <p class="paging">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value">
        </el-pagination>
      </p>
    </el-dialog>
    <el-dialog title="编辑寻医单" :modal="false" :visible.sync="EditXY" width="566px">
      <xypreview :kid="EditXYMsg.kid" :custid="EditXYMsg.custid" v-if="EditXY" :EditZD="EditXY" :noedit="EditXYMsg.noedit"></xypreview>
    </el-dialog>
  </div>
</template>
<script>
import xypreview from "../components/xypreview.vue";
export default {
  props: ["lookuserxy", "friendlistcustid"],
  components: {
    xypreview,
  },
  data() {
    return {
      show: false,
      userxylist: [],
      EditXYMsg: {},
      EditXY: false,
      custid: "",
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [6], // 个数选择器（可修改）
      PageSize: 6, // 默认每页显示的条数（可修改）
      value: false,
    };
  },
  watch: {
    lookuserxy(o, n) {
      this.show = o;
    },
    friendlistcustid(o, n) {
      this.custid = o;
      this.getdata(1);
    },
  },
  mounted() {},
  methods: {
    getdata(page) {
      this.axios
        .get(
          "/field/get_z_newseadoc_main_by_custid?cust_id=" +
            this.custid +
            "&page=" +
            page +
            "&size=" +
            this.PageSize
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.userxylist = res.data.result;
            this.totalCount = res.data.count;
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    exit() {
      this.show = false;
      this.$emit("update:lookuserxy", false);
    },
    NewXYEdit(row, type) {
      this.EditXYMsg.kid = row.KID;
      this.EditXYMsg.custid = row.Cust_ID;
      if (type == "look") {
        this.EditXYMsg.noedit = false;
      } else {
        this.EditXYMsg.noedit = true;
      }
      this.EditXY = true;
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage);
    },
  },
};
</script>
