const insert = function(db, id, list) {
  var _id = id;
  db.transaction(function(tx) {
    for (let m = 0; m < list.length; m++) {
      tx.executeSql("select * FROM user" + id + " where id = ?", [list[m].id], function(
        tx,
        result
      ) {
        if (result.rows.length <= 0) {
          tx.executeSql(
            "insert into user" +
              id +
              " (content,`from`,sendTime,`to`,type,id,sid,isRead) values(?,?,?,?,?,?,?,?)",
            [
              list[m].content,
              list[m].from,
              list[m].sendTime,
              list[m].to,
              list[m].type,
              list[m].id,
              list[m].sid,
              list[m].isRead,
            ],
            function() {
              console.log("插入成功");
              // callback('ok')
            },
            function(tx, error) {
              console.log(error);
            }
          );
        } else {
          console.log("已在本地存在");
        }
      });
    }
  });
};
const del = function(db, id, callback) {
  console.log(id)
  db.transaction(function(tx) {
    tx.executeSql("drop table user" + id);
    callback("ok");
  });
};
const push = function(db, id, msg) {
  var _id = id;
  db.transaction(function(tx) {
    tx.executeSql(
      "select * FROM user" + id + " where id = ?",
      [id],
      function(tx, result) {
        if (result.rows.length > 0) {
          tx.executeSql(
            "update user" + id + " set " + "content" + " = ? where id = ?",
            [msg.content, msg.id],
            function(tx, result) {
              console.log("更新成功");
            },
            function(tx, error) {
              console.log("更新失败" + error.message);
            }
          );
        } else {
          tx.executeSql(
            "insert into user" +
              id +
              " (content,`from`,sendTime,`to`,type,id,sid,isRead) values(?,?,?,?,?,?,?,?)",
            [msg.content, msg.from, msg.sendTime, msg.to, msg.type, msg.id, msg.sid, msg.isRead],
            function() {
              console.log("插入成功");
            },
            function(tx, error) {
              console.log(error);
            }
          );
        }
      },
      function(tx, error) {
        console.log(tx);
        console.log(error);
      }
    );
  });
};
const read = function(db, id, callback) {
  db.transaction(function(tx) {
    //删除重复id的聊天记录
    tx.executeSql(
      "delete FROM user" +
        id +
        " where id  in (select  id  from user" +
        id +
        "  group  by  id   having  count(id) > 1) " +
        "and rowid not in (select min(rowid) from  user" +
        id +
        "  group by id  having count(id )>1)", //从几开始起查多少个
      [],
      function(tx, result) {
        //执行成功的回调函数
        //在这里对result 做你想要做的事情吧.
        tx.executeSql(
          "select * FROM user" + id + " order by sendTime desc Limit " + 0 + "," + 50, //从几开始起查多少个

          [],
          function(tx, result) {
            //执行成功的回调函数
            //在这里对result 做你想要做的事情吧.
            callback(result.rows);
          },
          function(tx, error) {
            console.log("查询失败: " + error.message);
          }
        );
      },
      function(tx, error) {
        console.log("查询失败: " + error.message);
      }
    );
  });
};
const search = function(db, id, index, callback) {
  // console.log((((index - 1) * 50)+1) + "," + (index * 50));
  //删除重复id的聊天记录
  db.transaction(function(tx) {
    tx.executeSql(
      "delete FROM user" +
        id +
        " where id  in (select  id  from user" +
        id +
        "  group  by  id   having  count(id) > 1) " +
        "and rowid not in (select min(rowid) from  user" +
        id +
        "  group by id  having count(id )>1)", //从几开始起查多少个
      [],
      function(tx, result) {
        //执行成功的回调函数
        //在这里对result 做你想要做的事情吧.
        tx.executeSql(
          "select * FROM user" +
            id +
            " order by sendTime desc Limit " +
            (index - 1) * 50 +
            "," +
            50,
          [],
          function(tx, result) {
            //执行成功的回调函数
            //在这里对result 做你想要做的事情吧.
            // console.log("查询成功");
            callback(result.rows);
          },
          function(tx, error) {
            // console.log("查询失败: " + error.message);
          }
        );
      },
      function(tx, error) {
        console.log("查询失败: " + error.message);
      }
    );
  });
};

const edit = function() {};
const deletemore7 = function(db, id, callback) {
  db.transaction(function(tx) {
    tx.executeSql(
      "delete FROM user" + id + " where sendTime <= datetime('now','-7 day')",
      [],
      function(tx, result) {
        //执行成功的回调函数
        //在这里对result 做你想要做的事情吧.
        callback(result.rows);
      },
      function(tx, error) {
        console.log("查询失败: " + error.message);
      }
    );
  });
};
export { insert, del, push, read, search, edit, deletemore7 };
