const addt = function(db, id, list) {
  db.transaction(function(tx) {
    tx.executeSql(
      "insert into replay" + id + " (content) values(?)",
      [list],
      function() {
        console.log("插入成功");
      },
      function(tx, error) {
        console.log(error);
      }
    );
  });
};
const readt = function(db, id, callback) {
  db.transaction(function(tx) {
    tx.executeSql(
      "select * FROM replay" + id, //查列表
      [],
      function(tx, result) {
        //执行成功的回调函数
        //在这里对result 做你想要做的事情吧.
        var arr = [];
        var len = result.rows.length;
        for (let i = 0; i < len; i++) {
          arr.push(result.rows[i]);
        }
        callback(arr);
      },
      function(tx, error) {
        console.log("查询失败: " + error.message);
      }
    );
  });
};
const delt = function(db, sid, content, callback) {
  db.transaction(function(tx) {
    tx.executeSql(
      "delete from replay" + sid + " where content = ?",
      [content],
      function(tx, result) {
        callback("ok");
      },
      function(tx, error) {
        callback("error");
      }
    );
  });
};
const updatet = function(db, sid, content, zd, obj) {
  db.transaction(function(tx) {
    tx.executeSql(
      "update replay" + sid + " set content = ? where content = ?",
      [content, obj],
      function(tx, result) {
        console.log("更新成功");
      },
      function(tx, error) {
        console.log("更新失败" + error.message);
      }
    );
  });
};
const deltt = function(db, sid, id, zd, obj) {
  db.transaction(function(tx) {
    tx.executeSql(
      "drop table replay" + sid,
      [],
      function(tx, result) {},
      function(tx, error) {
        console.log("更新失败" + error.message);
      }
    );
  });
};

export { addt, readt, updatet, delt, deltt };
