<!-- 客服个人信息 -->
<template>
  <div>
    <el-container style="padding:8px;">
      <el-card>
        <el-form :model="myMsg" label-width="110px" style="color:#2e76ee;width:240px;">
          <el-form-item label="昵称：">
            <span>{{ myMsg.account }}</span>
          </el-form-item>
          <el-form-item label="服务人ID：">
            <span>s{{ this.sid }}</span>
          </el-form-item>
          <el-form-item label="服务ID：">
            <span>{{ myMsg.serveid }}</span>
          </el-form-item>
        </el-form>
      </el-card>
      <el-main width="200px" style="background-color:#eee">
        <el-form :model="myMsg" label-width="120px">
          <el-form-item label="头像：" class="myMSGStyle">
            <uploadIMG :src.sync="myMsg.headimg"></uploadIMG>
          </el-form-item>
          <el-form-item label="我的密码：">
            <el-input size="small" v-model="myMsg.pwd" style="width: 17%;"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="SaveMyMsg" style="margin-left:115px">保存</el-button>
          </el-form-item>
        </el-form>
      </el-main>
      <el-card style="overflow-y:auto;height: 100vh;">
        <el-form style="width:260px;">
          <el-form-item label="请选择工作开始时间">
            <el-time-picker
              arrow-control
              v-model="starthour"
              :picker-options="{
                selectableRange: '00:00:00 - 23:59:00',
              }"
              placeholder="工作起始时间"
              format="HH:mm"
              value-format="HH:mm"
              size="small"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="请选择工作时长">
            <div style="width: 100%;height: auto;overflow: hidden;">
              <el-select
                v-model="workhour"
                placeholder="请选择"
                style="width:70px;"
                size="small"
              >
                <el-option
                  v-for="item in workhouroptions"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              小时
              <el-select v-model="workminute" placeholder="请选择" style="width:70px;" size="small">
                <el-option
                  v-for="item in workminuteoptions"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              分钟
            </div>
          </el-form-item>
          <el-form-item label="工作结束时间">
            <!-- <p>{{  }}</p> -->
            <el-input v-model="workendtime" disabled size="small"></el-input>
          </el-form-item>
          <el-form-item label="设置非工作时间消息">
            <el-input size="small" v-model="offlinemsg" type="textarea" :rows="5" maxlength="80" show-word-limit></el-input>
          </el-form-item>
          <!-- <p>是否展示离线消息：</p> -->
          <el-form-item label="设置暂时离开消息">
            <!-- <el-switch
              style="display: block"
              v-model="isopen"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="是"
              inactive-text="否"
              @change="isact"
            >
            </el-switch> -->
            <el-input size="small" v-model="afk" type="textarea" :rows="5" maxlength="80" show-word-limit></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="SaveOfflineMsg">保存</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-container>
    <!-- <el-form :model="myMsg" label-width="120px" style="width:50%;float:left;">
            <el-form-item label="我的昵称：">
                <span>{{myMsg.account}}</span>
            </el-form-item>
            <el-form-item label="我的服务人ID：">
                <span>s{{this.sid}}</span>
            </el-form-item>
            <el-form-item label="我的服务ID：">
                <span>{{myMsg.serveid}}</span>
            </el-form-item>
            <el-form-item label="我的头像：" class="myMSGStyle">
                <uploadIMG :src.sync="myMsg.headimg"></uploadIMG>
            </el-form-item>
            <el-form-item label="我的密码：">
                <el-input v-model="myMsg.pwd" style="width: 15%;"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="SaveMyMsg">保存</el-button>
                <p class="tips">如有修改请点击保存按钮</p>
            </el-form-item>
        </el-form> -->
    <!-- <div style="width: 50%;float: right;">
            <el-form label-width="120px">
                <el-form-item label="设置我的离线消息">
                    <el-input v-model="offlinemsg" style="width: 15%;"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="SaveOfflineMsg">保存</el-button>
                </el-form-item>
            </el-form>
        </div> -->
  </div>
</template>
<script>
var db = "",
  _this = "";
var moment = require("moment");
import uploadIMG from "../components/upload.vue";
export default {
  props: ["myMsg", "sid"],
  components: {
    uploadIMG,
  },
  data() {
    return {
      offlinemsg: "",
      id: "",
      isopen: true,
      workhour: "", //工作时长（小时）
      workhouroptions: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
        { value: 10 },
        { value: 11 },
        { value: 12 },
        { value: 13 },
        { value: 14 },
        { value: 15 },
        { value: 16 },
      ],
      workminute: "", //工作时长（分钟）
      workminuteoptions: [
        { value: "00" },
        { value: "05" },
        { value: 10 },
        { value: 15 },
        { value: 20 },
        { value: 25 },
        { value: 30 },
        { value: 35 },
        { value: 40 },
        { value: 45 },
        { value: 50 },
        { value: 55 },
      ],
      starthour: "", //工作开始时间
      workendtime: "", //工作结束时间
      afk: "", //暂离消息
    };
  },
//   computed:{
// workendtime:function (){
//   if (this.starthour != "") {
//         var worktime = this.starthour.split(":");
//       }
//       var start = moment({ hour: worktime[0], minute: worktime[1] });
//       var end = start.add({ hours: this.workhour, minutes: this.workminute }).format("HH:mm");
//       // this.workendtime = end;
//       return end
// }
//   },
  watch: {
    starthour: "choiceendtime",
    workhour: "choiceendtime",
    workminute: "choiceendtime",
  },
  mounted() {
    _this = this;
    this.id = sessionStorage.getItem("id");
    this.axios
      .get(this.serveurl.serve + "/api/OfflineMessage/get_offline_message?serviceId=s" + this.id)
      .then((res) => {
        if (res.data.code == 1) {
          if (res.data.result != null && res.data.result != "" && res.data.result != undefined) {
            this.offlinemsg = res.data.result.message;
            this.starthour = res.data.result.startHour + ":" + res.data.result.startMinute;
            this.workhour = res.data.result.workHour;
            this.workminute = res.data.result.workMinute;
            this.afk = res.data.result.afk;
          }
        }
      });
    db = openDatabase("mydb" + this.id, "1.0", "Test DB", 2000 * 1024 * 1024);
    db.transaction(function(tx) {
      tx.executeSql(
        "select * FROM isact" + _this.id, //查列表
        [],
        function(tx, result) {
          //执行成功的回调函数
          //在这里对result 做你想要做的事情吧.
          // console.log(result.rows[0])
          _this.isopen = result.rows[0].is_act === "false" ? false : true;
        },
        function(tx, error) {
          console.log("查询失败: " + error.message);
          tx.executeSql(
            "CREATE TABLE if not exists `isact" +
              _this.id +
              "` (`id` varchar(20) PRIMARY KEY,`is_act` TEXT)",
            [],
            function(tx, result) {
              tx.executeSql(
                "insert into isact" + _this.id + " (id,is_act) values(?,?)",
                ["1", _this.isopen],
                function() {
                  console.log("插入成功");
                },
                function(tx, error) {
                  console.log(error);
                }
              );
            },
            function(tx, error) {
              console.log("创建失败");
            }
          );
        }
      );
    });
  },
  methods: {
    //修改自己的头像并保存
    SaveMyMsg() {
      let form = {
        account: sessionStorage.getItem("name"),
        pwd: this.myMsg.pwd,
        cust_id: sessionStorage.getItem("cust_id"),
        servicer_id: sessionStorage.getItem("servicer_id"),
        headimg: this.myMsg.headimg,
        id: sessionStorage.getItem("id"),
        is_chat: sessionStorage.getItem("stype"),
        uid: sessionStorage.getItem("id"),
      };
      // console.log(form)
      this.axios
        .post("/ma/update_gu_ma_admin", this.qs.stringify(form))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            sessionStorage.setItem("headimg", this.myMsg.headimg);
          } else {
            this.$message(rs.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //选择完工作时间后，显示工作结束时间
    choiceendtime() {
      if (this.starthour != "") {
        var worktime = this.starthour.split(":");
      }
      var start = moment({ hour: worktime[0], minute: worktime[1] });
      var end = start.add({ hours: this.workhour, minutes: this.workminute }).format("HH:mm");
      this.workendtime = end;
    },
    //保存离线消息
    SaveOfflineMsg() {
      if (this.starthour == "" || this.starthour == "0:0") {
        this.$message({
          message: "请选择工作开始时间",
          type: "error",
        });
        return;
      }
      if (this.workhour === "" || this.workminute === "" ) {
        this.$message({
          message: "请设置工作时长",
          type: "error",
        });
        return;
      }
      if (this.offlinemsg == "" || this.offlinemsg == null) {
        this.$message({
          message: "请设置非工作时间消息",
          type: "error",
        });
        return;
      }
      if (this.afk === "" || this.afk == null) {
        this.$message({
          message: "请设置暂时离开消息",
          type: "error",
        });
        return;
      }
      var worktime = this.starthour.split(":");
      // console.log(worktime);
      let form = {
        message: this.offlinemsg,
        serviceId: "s" + this.id,
        startHour: worktime[0],
        startMinute: worktime[1],
        workHour: this.workhour,
        workMinute: this.workminute,
        afk: this.afk,
      };
      // console.log(form);
      this.axios
        .post(this.serveurl.serve + "/api/OfflineMessage/add_edit", this.qs.stringify(form))
        .then((rs) => {
          // console.log(rs);
          if (rs.data.code == 1) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
          } else {
            this.$message(rs.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    isact(val) {
      var is_act = val ? 1 : 0;
      console.log(val);
      this.axios
        .post(
          this.serveurl.serve + "/api/OfflineMessage/set_is_reply",
          this.qs.stringify({ serviceId: "s" + this.id, isAct: is_act })
        )
        .then((res) => {
          if (res.data.code == 1) {
            console.log(res);
          }
        });

      db.transaction(function(tx) {
        tx.executeSql(
          "update isact" + _this.id + " set " + "is_act" + " = ? where id = ?",
          [val, "1"],
          function() {
            console.log("更新成功");
          },
          function(tx, error) {
            console.log(error);
          }
        );
      });
    },
  },
};
</script>
<style>
.tips {
  margin: 10px 0;
  color: #909399;
  line-height: 12px;
}
</style>
