<template>
  <div>
    <el-dialog title="查看订单" :visible.sync="show" width="80%" @close="exit()">
      <el-table :data="list" border style="width: 100%; margin-top: 40px">
        <el-table-column prop="id" label="ID" v-if="false" width="80"></el-table-column>
        <el-table-column prop="Receiver" label="患者姓名" width="100"></el-table-column>
        <el-table-column prop="Tel" label="电话号" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.Tel.substring(0, 3) + "****" + scope.row.Tel.substring(7) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Cust_ID" label="用户编号" width="120"></el-table-column>
        <el-table-column prop="Order_ID" label="订单编号" width="180"></el-table-column>
        <el-table-column prop="Order_Date" label="创建时间" width="180"></el-table-column>
        <el-table-column prop="amount_str" label="金额" width="150"></el-table-column>
        <el-table-column prop="Solution" label="明细" width="300"></el-table-column>
        <el-table-column prop="" label="地址" width="300">
          <template slot-scope="scope">
            <span>
              {{ scope.row.Province }}{{ scope.row.City }}{{ scope.row.Counties
              }}{{ scope.row.Adress }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="Order_state" label="订单状态" width="100">
          <template slot-scope="scope">
            <span>
              {{ dingdanzhuangtai(scope.row.Order_state) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="Pay_State" label="付款状态" width="100">
          <template slot-scope="scope">
            <span>
              {{ fukuanzhuangtai(scope.row.Pay_State) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="Shipping_State" label="物流状态" width="100">
          <template slot-scope="scope">
            <span>
              {{ wuliuzhuangtai(scope.row.Shipping_State) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="100">
          <template slot-scope="scope">
            <div class="opt doc">
              <a v-if="scope.row.Order_state == 0" @click="changeorderstate(scope.row, 'tj')"
                >提交审核</a
              >
              <a v-if="scope.row.Order_state == 3" @click="changeorderstate(scope.row, 'tj')"
                >重新提交</a
              >
              <span v-if="scope.row.Order_state == 1" style="color:red;">等待审核</span>
              <!-- <a v-if="scope.row.Order_state == 2" @click="send(scope.row)">下发</a> -->
              <!-- 生成药品订单 -->
              <a v-if="scope.row.Order_state == 2" @click="createyforder(scope.row)">下发</a>
              <!-- <a v-if="scope.row.Order_state == 2" @click="shenqingtuikuan(scope.row)">申请退款</a> -->
              <!-- <a @click="lookzd(scope.row)">查看诊单</a> -->
              <!-- <a @click="lookdrug(scope.row)">查看药方</a> -->
              <!-- <a @click="sendordertouser(scope.row)">发起支付</a> -->
              <!-- <talkchangeorderstate
                v-if="scope.row.Order_state == 2"
                :ordermsg="scope.row"
                @changeorderlist="changeorderlist"
              ></talkchangeorderstate> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <p class="paging">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-sizes="pageSizes"
          :page-size="PageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
          :hide-on-single-page="value"
        ></el-pagination>
      </p>
    </el-dialog>
    <el-dialog title="查看诊单" :modal="false" :visible.sync="EditZD" width="566px">
      <preview
        :kid="EditMsg.kid"
        :custid="EditMsg.custid"
        v-if="EditZD"
        :EditZD="EditZD"
        :noedit="EditMsg.noedit"
      ></preview>
    </el-dialog>
    <lookdrug
      :drug.sync="drug"
      :drugdetail="drugdetail"
      :db="db"
      @uploadlist="uploadlist"
      :talkconnection="talkconnection"
      :toID="toID"
    ></lookdrug>
    <feedback
      :lookfeedback.sync="lookfeedback"
      :feedbackid="feedbackid"
      :feedbacknoedit="feedbacknoedit"
    ></feedback>
    <el-dialog title="补全信息" :visible.sync="userIDcard" width="30%">
      <el-form label-width="100px">
        <el-form-item label="患者身份证号">
          <el-input v-model="userIDCardNumber"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="userIDcard = false">取消</el-button>
          <el-button type="primary" @click="onSubmituserIDcard">创建订单</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import preview from "../components/preview.vue";
import lookdrug from "../components/lookdrug.vue";
import feedback from "../components/feedback.vue";
import talkchangeorderstate from "../components/talkchangeorderstate.vue";
import { insert, del, push, read, search } from "../assets/sql2.js";
import { addu, readu, delu, updateu, putu } from "../assets/msglist.js";
export default {
  props: ["showsendorder", "toID", "talkconnection", "db"],
  components: {
    preview,
    lookdrug,
    feedback,
    talkchangeorderstate,
  },
  data() {
    return {
      show: false,
      custid: "",
      list: [],
      EditMsg: {},
      EditZD: false,
      drug: false,
      drugdetail: "",
      sid: sessionStorage.getItem("id"),
      lookfeedback: false,
      feedbackid: "",
      feedbacknoedit: true,
      servicerid: sessionStorage.getItem("servicer_id"), //制单人
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [6], // 个数选择器（可修改）
      PageSize: 6, // 默认每页显示的条数（可修改）
      value: false,
      KID: "",
      orderrow: "",
      userIDcard: false, //填写患者身份证号弹窗
      userIDCardNumber: "", //患者身份证号
      drug_msg: [],
    };
  },
  watch: {
    showsendorder(o, n) {
      this.show = o;
      this.getdata(1);
    },
    toID(o, n) {
      this.axios
        .post("/gu/search_gu_custs", this.qs.stringify({ keyword: o }))
        .then((res) => {
          if (res.data.code == 0) {
            this.custid = res.data.result[0].Cust_ID;
          } else {
            console.log(res);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    db(o, n) {
      // console.log(o)
    },
  },
  mounted() {},
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage);
    },
    exit() {
      this.show = false;
      this.$emit("update:showsendorder", false);
    },
    getdata(page) {
      this.list = [];
      //   this.axios.get("/docpres/get_gu_docpres_order?cust_id=" + this.custid).then((res) => {
      //     if (res.data.code == 0) {
      //       this.list = res.data.result;
      //     }
      //   });
      //查询客服患者下面审核通过了的订单"&state=2"
      this.axios
        .get(
          "/order/get_orders?page=" +
            page +
            "&size=6" +
            "&cust_id=" +
            this.custid +
            "&input_ids=" +
            this.servicerid
        )
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
    //查看诊单
    sendorderlookzd(row) {
      var a = row.kid.indexOf("Nis");
      if (a == 0) {
        this.EditMsg.kid = row.kid;
        this.EditMsg.custid = row.cust_id;
        this.EditMsg.noedit = false;
        this.EditZD = true;
      } else {
        this.lookfeedback = true;
        this.feedbackid = row.kid;
        this.feedbacknoedit = false;
      }
    },
    //查看药方
    sendorderlookyf(row) {
      this.drug = true;
      this.drugdetail = row;
    },
    //更新上一级的聊天列表
    uploadlist() {
      this.$emit("sendMoneyChangeAlllist");
      this.$emit("sendMoneyChangeTalklist");
      this.exit();
    },
    //物流状态
    wuliuzhuangtai(val) {
      var str = "";
      var dic = {
        0: "未发货",
        1: "已发货",
        2: "签收",
        3: "配货中",
        4: "已发货部分商品",
        5: "发货中",
        9: "退回",
      };
      for (var key in dic) {
        if (key == val) {
          str = dic[key];
        }
      }
      return str;
    },
    //付款状态
    fukuanzhuangtai(val) {
      var str = "";
      var dic = {
        "-1": "付款失败",
        0: "未付款",
        1: "已付款",
        2: "已确认",
        6: "已退款",
      };
      for (var key in dic) {
        if (key == val) {
          str = dic[key];
        }
      }
      return str;
    },
    //订单状态
    dingdanzhuangtai(val) {
      var str = "";
      var dic = {
        0: "新建",
        1: "提交",
        2: "审核",
        3: "打回",
      };
      for (var key in dic) {
        if (key == val) {
          str = dic[key];
        }
      }
      return str;
    },
    changeorderlist() {
      this.getdata(1);
    },
    send(row) {
      var toID = this.toID;
      var fromID = "s" + this.sid;
      this.talkconnection
        .invoke("send", toID, fromID, JSON.stringify({ yforderno: row.Order_ID, id: row.ID }), 9)
        .then((res) => {
          console.log(res);
          if (res.err == 1) {
            //发送成功
            this.$message({
              message: "发送成功",
              type: "success",
            });
            push(this.db, toID, res.data);
            updateu(this.db, this.sid, toID, "sendTime", res.data, function(res) {});
            this.$emit("sendMoneyChangeAlllist");
            this.$emit("sendMoneyChangeTalklist");
            this.$emit("drugtcfalse");
            this.exit();
          } else {
            this.$message({
              message: "发送失败，请重新发送",
              type: "error",
            });
          }
        });
    },
    send2(row) {
      var toID = this.toID;
      var fromID = "s" + this.sid;
      console.log({ yforderno: row });
      this.talkconnection
        .invoke(
          "send",
          toID,
          fromID,
          JSON.stringify({ yforderno: row, orderid: this.orderrow.Order_ID }),
          9
        )
        .then((res) => {
          console.log(res);
          if (res.err == 1) {
            //发送成功
            this.$message({
              message: "发送成功",
              type: "success",
            });
            push(this.db, toID, res.data);
            updateu(this.db, this.sid, toID, "sendTime", res.data, function(res) {});
            this.$emit("sendMoneyChangeAlllist");
            this.$emit("sendMoneyChangeTalklist");
            this.$emit("drugtcfalse");
            this.exit();
          } else {
            this.$message({
              message: "发送失败，请重新发送",
              type: "error",
            });
          }
        });
    },
    changeorderstate(row, type) {
      var _state = "",
        str = "";
      if (type == "tj") {
        _state = "1";
        str = "已提交，请等待审核";
      }
      this.axios
        .post(
          "/order/update_order_state",
          this.qs.stringify({ state: _state, order_id: row.Order_ID })
        )
        .then((res) => {
          this.$message({
            message: str,
            type: "success",
          });
        })
        .catch((res) => {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        });
    },
    //填写身份证号
    onSubmituserIDcard() {
      if (this.userIDCardNumber == "") {
        this.$message({
          message: "请填写患者的身份证号",
          type: "error",
        });
        return;
      }
      var form = {
        // yf_id: "", //医生id
        pt_id: this.orderrow.Cust_ID, //患者编号
        // pt_no: "", //患者姓名
        id_no: this.userIDCardNumber, //患者身份证号
        pt_tel: this.orderrow.Tel, //患者手机号
        diseases_code: "疾病编码", //疾病编码
        diseases_name: "疾病名称", //疾病名称
        consignee_mobile: this.orderrow.Tel, //收货人手机号
        consignee_name: this.orderrow.Receiver, //收货人姓名
        consignee_address:
          this.orderrow.Province +
          this.orderrow.City +
          this.orderrow.Counties +
          this.orderrow.Adress, //收货人地址
        // drugs_infos:[],//药品信息
        chat_files: {
          pdffile: "",
          image_files: [],
          sound_files: [],
          video_files: [],
        }, //聊天信息
      };
      console.log(this.orderrow);
      this.axios
        .get("/docpres/get_gu_docpres_order_by_orderid?order_id=" + this.orderrow.Order_ID)
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res);
            var arr = res.data.result;
            this.KID = arr[0].kid;
            // form.yf_id = arr[0].doc_id;
            form.yf_id = "3a8a9c7624204b0b95b08a271776aee3";
            form.pt_no = arr[0].patient_name;
            form.chat_files.pdffile =
              "http://112.250.105.14:10087/public/pdf/chufang/" + this.KID + ".pdf";
            form.chat_files = JSON.stringify(form.chat_files);
            //整理药品信息
            this.handledrugs(arr).then((res) => {
              var nodrugid = false;
              this.drug_msg.forEach((ele) => {
                res.forEach((ele1) => {
                  if (ele1.data.code == 0) {
                    if (ele.drug_code == ele1.data.result.HH) {
                      ele.drug_id = ele1.data.result.drug_id;
                    }
                  } else {
                    nodrugid = true;
                  }
                });
              });
              if (nodrugid) {
                this.$message({
                  message: "请核对药品信息",
                  type: "error",
                });
                return;
              }
              form.drugs_infos = JSON.stringify(this.drug_msg);
              console.log(form);
              this.sendtoyf(form, this.KID);
            });
          }
        });
    },
    //生成药品订单
    createyforder(row) {
      this.userIDcard = true;
      this.orderrow = row;
      this.userIDCardNumber = "";
    },
    sendtoyf(form, _kid) {
      this.axios
        .post("/yf/pat_order_create", this.qs.stringify(form))
        .then((res) => {
          console.log(res);
          var aaa = {
            input_id: this.servicerid,
            kid: _kid,
          };
          if (res.data.res == "OK") {
            aaa.yf_order_no = res.data.data.yf_order_no;
            console.log(aaa);
            this.axios
              .post("/docpres/update_get_gu_docpres_order_yf_no", this.qs.stringify(aaa))
              .then((res) => {
                console.log(res);
                if (res.data.code == 0) {
                  this.send2(aaa.yf_order_no);
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
                this.userIDcard = false;
              });
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    handledrugs(arr) {
      this.drug_msg = [];
      console.log(arr);
      arr.forEach((ele) => {
        let a = JSON.parse(ele.carts);
        a.forEach((ele) => {
          var obj = {
            drug_num: ele.num,
            // drug_id: "a0f00911313746de9969896792e3d3c0",
            appr_drug_name: ele.title,
            drug_code: ele.code,
            drug_name: ele.title,
            drug_form: "药剂类型",
            dosage: "用药剂量",
            dosage_unit: ele.grams,
            drug_frequency: "用药频率",
            standard_desc: "药品规格",
            single_price: ele.price,
            comments: "用药嘱托",
            total_charge: "1",
          };
          this.drug_msg.push(obj);
        });
      });
      console.log(this.drug_msg);
      var axiosList = [];
      for (let i = 0; i < this.drug_msg.length; i++) {
        var req = this.axios.get(
          "/docpres/get_get_taxk_drugs_by_hh?hh=" + this.drug_msg[i].drug_code
        );
        axiosList.push(req);
      }
      return this.axios.all(axiosList).then(
        this.axios.spread(function(...resList) {
          return resList;
        })
      );
    },
    shenqingtuikuan(row) {
      this.$message({
        message: "退款申请已提交，请等待审核",
        type: "success",
      });
    },
  },
};
</script>
