<template>
  <div>
    <el-dialog
      title="发送语音"
      :visible.sync="show"
      width="40%"
      @close="exit()"
    >
      <div class="record" style="text-align: left; display: inline-flex">
        <el-button
          @click="onStartVoice"
          style="margin-right: 40px"
          id="start"
          v-show="isatart"
          icon="el-icon-microphone"
          type="success"
          >开始录音</el-button
        >
        <el-button
          style="margin-right: 40px"
          @click="onEndVoice"
          type="danger"
          id="stop"
          v-show="isend"
          icon="el-icon-turn-off-microphone"
          >结束录音</el-button
        >
        <!-- <el-button @click="onPlayAudio">播放音频</el-button> -->
        <el-button
          v-show="isFinished"
          @click="upload"
          type="primary"
          icon="el-icon-s-promotion"
          >发送语音</el-button
        >
      </div>
      <div
        class="record-play"
        v-show="isFinished"
        style="width: 100%; margin-top: 20px"
      >
        <div id="audio-container" style="width: 100%; margin-bottom: 40px">
          <!-- <audio id="audioVoice" controls autoplay></audio> -->
        </div>
      </div>
      <audio
        @canplay="canplaysrc"
        :src="audiosrc"
        v-show="false"
        id="uploadurl"
        ref="audio"
      ></audio>
    </el-dialog>
  </div>
</template>
<script>
const qiniu = require("qiniu-js");
var recorder = "";
var timer = "";
import { insert, del, push, read, search } from "../assets/sql2.js";
import { addu, readu, delu, updateu, putu } from "../assets/msglist.js";
import {
  addud,
  readud,
  delud,
  updateud,
  putud,
  inud,
} from "../assets/msglistdoc.js";
export default {
  props: ["SoundRecording", "toID", "db", "talkconnection", "audioElement"],
  data() {
    return {
      show: false,
      isatart: false,
      isend: false,
      recorder: "",
      audiosrc: "",
      isFinished: false,
      duration: "",
      audiomsg: "", //录音文件信息
      sid: sessionStorage.getItem("id"),
    };
  },
  watch: {
    SoundRecording(o, n) {
      this.show = o;
      this.isatart = true;
      this.isend = false;
      this.isFinished = false;
      recorder = new Recorder({
        sampleRate: 44100, //采样频率，默认为44100Hz(标准MP3采样率)
        bitRate: 128, //比特率，默认为128kbps(标准MP3质量)
        success: function () {
          //成功回调函数
          this.isatart = true;
        },
        error: function (msg) {
          //失败回调函数
          alert(msg);
        },
        fix: function (msg) {
          //不支持H5录音回调函数
          alert(msg);
        },
      });
      //暂停页面内所有音频的播放
      this.audioElement.forEach((audio, index) => {
        audio.pause();
        let playicon = audio.parentNode.firstChild.lastChild;
        playicon.className = "el-icon-video-play";
        audio.currentTime = 0;
      });
    },
  },
  methods: {
    exit() {
      this.show = false;
      this.$emit("update:SoundRecording", false);
    },
    mounted() {},
    canplaysrc() {},
    onStartVoice() {
      var _this = this;
      this.isatart = false;
      this.isend = true;
      this.isFinished = false;
      var audio = document.getElementsByClassName("talklistaudio");
      for (var i = 0; i < audio.length; i++) {
        if (!audio[i].paused) {
          audio[i].pause();
        }
      }
      recorder.start();
      let duratioin = 0;

      timer = setInterval(function () {
        duratioin++;
        if (duratioin > 30) {
          clearInterval(timer);
          _this.$message({
            message: "录制时长不能超过30秒",
            type: "error",
          });
          _this.onEndVoice();
        }
      }, 1000);
    },
    onEndVoice() {
      clearInterval(timer);
      var container = document.getElementById("audio-container");
      container.innerHTML = "";
      this.isend = false;
      this.isatart = true;
      this.isFinished = true;
      var _this = this;
      recorder.stop();
      recorder.getBlob(function (blob) {
        var audio = document.createElement("audio");
        audio.src = URL.createObjectURL(blob);
        audio.controls = true;
        container.appendChild(audio);
        _this.audiomsg = blob;
      });
    },
    upload() {
      var file = this.audiomsg;
      console.log(file);
      let _this = this;
      file.status = "uploading";
      file.message = "上传中...";
      let files = file;
      let key =
        "froms" +
        _this.sid +
        "to" +
        _this.toID +
        new Date().getTime() +
        "." +
        files.type.split("/")[1]; // 设置文件的名字
      let config = {
        useCdnDomain: true, //cdn加速
        retryCount: 3, // 上传错误重新上传次数
      };
      let putExtra = {
        fname: file,
        params: {},
        mimeType: ["audio/ogg,audio/mp3,audio/wav,audio/mpeg"], //可以上传的type
      };
      let observer = {
        next(res) {
          // res.total.percent 上传进度
          file.message = parseInt(res.total.percent) + "%";
        },
        error(code, message, isRequestError) {
          //错误信息
          console.log(code);
          console.log(message);
          _this.$message({
            message: message,
            type: "error",
          });
          console.log(isRequestError);
        },
        complete: (res) => {
          //上传成功
          // console.log(res)
          let str = "http://cdn.51xk.info/" + res.key;
          file.status = "";
          file.message = "";
          // this.vedioList.push({ url: str, name: files.name });
          console.log(str);
          this.audiosrc = str;
        },
      };
      //获取token
      this.axios
        .get("http://192.168.11.253:8080/qiniu/examples/upload_tokens.php")
        // .get("http://112.250.105.14:10087/qiniu/examples/upload_tokens.php")
        .then((res) => {
          // console.log(files);
          // this.token = res.data;
          let observable = qiniu.upload(files, key, res.data, putExtra, config); //调用七牛的上传
          let subscription = observable.subscribe(observer); //上传监听
          // console.log(qiniu)
        });
    },

    canplaysrc() {
      this.duration = this.$refs.audio.duration;
      //上传成功后发送给患者
      this.send(this.audiosrc, Math.ceil(this.duration));
    },

    send(audiosrc, duration) {
      var fromID = "s" + this.sid;
      this.talkconnection
        .invoke(
          "send",
          this.toID,
          fromID,
          JSON.stringify({ url: audiosrc, time: duration }),
          14
        )
        .then((res) => {
          if (res.err == 1) {
            //发送成功
            let measge = {
              content: res.data.content,
              from: fromID,
              type: "14",
              sendTime: res.data.sendTime,
            };
            push(this.db, this.toID, res.data);
            updateu(
              this.db,
              this.sid,
              this.toID,
              "sendTime",
              measge,
              function (res) {}
            );
            this.$emit("sendMoneyChangeAlllist");
            this.$emit("sendMoneyChangeTalklist");
            this.exit();
          } else {
            this.$message({
              message: "发送失败，请重新发送",
              type: "error",
            });
          }
        });
    },
  },
};
</script>