<template>
  <div>
    <el-dialog title="设置标签" :visible.sync="show" width="50%" @close="exit()">
      <div style="line-height: 60px;text-align:left;">
        <p style="float: left;margin: 0;">添加第一标签：</p>
        <el-button class="button-new-tag" size="small" @click="add(1)">+ 添加</el-button>
        <div>
          <el-tag
            v-for="tag in tags1"
            :key="tag.id"
            closable
            :disable-transitions="true"
            @close="handleClose(tag, '1')"
            style="margin-right: 8px;"
          >
            {{ tag.tag }}
          </el-tag>
        </div>
      </div>
      <div style="line-height: 60px;text-align:left;">
        <p style="float: left;margin: 0;">添加第二标签：</p>
        <el-button class="button-new-tag" size="small" @click="add(2)">+ 添加</el-button>
        <div>
          <el-tag
            v-for="tag in tags2"
            :key="tag.id"
            closable
            :disable-transitions="true"
            @close="handleClose(tag, '2')"
            style="margin-right: 8px;"
          >
            {{ tag.tag }}
          </el-tag>
        </div>
      </div>
      <div style="line-height: 60px;text-align:left;">
        <p style="float: left;margin: 0;">添加第三标签：</p>
        <el-button class="button-new-tag" size="small" @click="add(3)">+ 添加</el-button>
        <div>
          <el-tag
            v-for="tag in tags3"
            :key="tag.id"
            closable
            :disable-transitions="true"
            @close="handleClose(tag, '3')"
            style="margin-right: 8px;"
          >
            {{ tag.tag }}
          </el-tag>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button @click="exit()">取 消</el-button>
        <el-button type="primary" @click="sub">保 存</el-button>
        <p style="color:#F56C6C;">删除标签时不用保存，但新添标签时必需保存</p>
      </div>
    </el-dialog>
    <!-- 第一标签 -->
    <el-dialog title="添加第一标签" :visible.sync="choicetag1" width="50%">
      <el-checkbox-group v-model="checkList1" style="text-align:left;line-height:28px;">
        <el-checkbox
          style="width:15%;"
          :key="a"
          @change="choicetags1(item, $event)"
          v-for="(item, a) in taglist"
          :label="item.id"
          >{{ item.tag }}</el-checkbox
        >
      </el-checkbox-group>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            choicetag1 = false;
            checkList1 = [];
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="sub1">选 择</el-button>
      </div>
    </el-dialog>
    <!-- 第二标签 -->
    <el-dialog title="添加第二标签" :visible.sync="choicetag2" width="50%">
      <el-checkbox-group v-model="checkList2" style="text-align:left;line-height:28px;">
        <el-checkbox
          style="width:15%;"
          :key="item.id"
          @change="choicetags2(item, $event)"
          v-for="item in taglist"
          :label="item.id"
          >{{ item.tag }}</el-checkbox
        >
      </el-checkbox-group>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            choicetag2 = false;
            checkList2 = [];
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="sub2">选 择</el-button>
      </div>
    </el-dialog>
    <!-- 第3标签 -->
    <el-dialog title="添加第三标签" :visible.sync="choicetag3" width="50%">
      <el-checkbox-group v-model="checkList3" style="text-align:left;line-height:28px;">
        <el-checkbox
          style="width:15%;"
          :key="item.id"
          @change="choicetags3(item, $event)"
          v-for="item in taglist"
          :label="item.id"
          >{{ item.tag }}</el-checkbox
        >
      </el-checkbox-group>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            choicetag3 = false;
            checkList3 = [];
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="sub3">选 择</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["settag", "tagId"],
  data() {
    return {
      show: false,
      custid: "",
      taglist: [],
      tags1: [],
      tags2: [],
      tags3: [],
      //选择一级二级标签的弹窗
      choicetag1: false,
      choicetag2: false,
      choicetag3: false,
      //一级二级标签的id存放数组
      checkList1: [],
      checkList2: [],
      checkList3: [],
      fathertagid: "",
    };
  },
  watch: {
    settag(o, n) {
      this.show = o;
      this.tags1 = [];
      this.tags2 = [];
      this.tags3 = [];
      this.axios
        .get("/gu/get_gu_cust_by_tel?tel=" + this.tagId)
        .then((res) => {
          if (res.data.code == 0) {
            this.custid = res.data.result.id;
            this.getusertag();
          } else {
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
  },
  mounted() {},
  methods: {
    exit() {
      this.tags1 = [];
      this.tags2 = [];
      this.tags3 = [];
      this.custid = "";
      this.checkList1 = [];
      this.checkList2 = [];
      this.checkList3 = [];
      this.show = false;
      this.$emit("update:settag", false);
    },
    getalltag() {
      this.axios.get("/tag/get_gu_tag").then((res) => {
        if (res.data.code == 0) {
          this.taglist = res.data.result;
        }
      });
    },
    getusertag() {
      this.axios.get("/tag/get_gu_tag_custtag_by_custid?cust_id=" + this.custid).then((res) => {
        if (res.data.code == 0) {
          var arr = res.data.result;
          var _tag1 = [],
            _tag2 = [],
            _tag3 = [];
          for (let m = 0; m < arr.length; m++) {
            const element = arr[m];
            if (element.rank == 1) {
              _tag1.push(element);
            } else if (element.rank == 2) {
              _tag2.push(element);
            } else {
              _tag3.push(element);
            }
          }
          this.tags1 = _tag1;
          this.tags2 = _tag2;
          this.tags3 = _tag3;
        } else {
          this.tags1 = [];
          this.tags2 = [];
          this.tags3 = [];
        }
      });
    },
    add(type) {
      this.getalltag();
      this.checkList1 = [];
      this.checkList2 = [];
      this.checkList3 = [];
      if (type == 1) {
        this.choicetag1 = true;
        // 	for (let m = 0; m < this.tags1.length; m++) {
        // 		const element = this.tags1[m];
        // 		this.checkList1.push(element.id);
        // 	}
      } else if (type == 2) {
        this.choicetag2 = true;
        // 	for (let m = 0; m < this.tags2.length; m++) {
        // 		const element = this.tags2[m];
        // 		this.checkList2.push(element.id);
        // 	}
      } else {
        this.choicetag3 = true;
      }
    },
    removeByVal(array, val) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].id == val) {
          array.splice(i, 1);
          break;
        }
      }
    },
    choicetags1(item, val) {
      if (this.tags1.length <= 0) {
        this.tags1.push(item);
      } else {
        var isjoin = false;
        for (let m = 0; m < this.tags1.length; m++) {
          const element = this.tags1[m];
          if (item.id == element.id) {
            isjoin = true;
          }
        }
        if (!isjoin) {
          this.tags1.push(item);
        }
      }
    },
    choicetags2(item, val) {
      if (this.tags2.length <= 0) {
        this.tags2.push(item);
      } else {
        var isjoin = false;
        for (let m = 0; m < this.tags2.length; m++) {
          const element = this.tags2[m];
          if (item.id == element.id) {
            isjoin = true;
          }
        }
        if (!isjoin) {
          this.tags2.push(item);
        }
      }
    },
    choicetags3(item, val) {
      if (this.tags3.length <= 0) {
        this.tags3.push(item);
      } else {
        var isjoin = false;
        for (let m = 0; m < this.tags3.length; m++) {
          const element = this.tags3[m];
          if (item.id == element.id) {
            isjoin = true;
          }
        }
        if (!isjoin) {
          this.tags3.push(item);
        }
      }
    },
    selectlabels(value) {
      for (let m = 0; m < this.typelable.length; m++) {
        const element = this.typelable[m];
        if (element.id == value) {
          this.choicelabel = element;
          return;
        }
      }
    },
    handleInputConfirm1(val) {
      var arr = [];
      arr = this.tags1;
      arr.push(val);
      this.tags1 = [];
      this.tags1 = arr;
    },
    handleInputConfirm2(val) {
      var arr = [];
      arr = this.tags2;
      arr.push(val);
      this.tags2 = [];
      this.tags2 = arr;
    },
    handleInputConfirm3(val) {
      var arr = [];
      arr = this.tags3;
      arr.push(val);
      this.tags3 = [];
      this.tags3 = arr;
    },
    sub1() {
      this.choicetag1 = false;
    },
    sub2() {
      this.choicetag2 = false;
    },
    sub3() {
      this.choicetag3 = false;
    },
    sub() {
      if (this.tags1.length <= 0) {
        this.$message({
          message: "请选择一级标签",
          type: "error",
        });
        return;
      }
      if (this.tags1.length > 1 || this.tags2.length > 1 || this.tags3.length > 1) {
        this.$message({
          message: "每一级只能有一个标签",
          type: "error",
        });
        return;
      }
      var tagstr1 = "",
        tagstr2 = "",
        tagstr3 = "";
      // for (let a = 0; a < this.tags1.length; a++) {
      // 	const element = this.tags1[a];
      // 	tagstr1 += element.id + ",";
      // }
      // tagstr1 = tagstr1.substr(0, tagstr1.length - 1);
      // for (let b = 0; b < this.tags2.length; b++) {
      // 	const element = this.tags2[b];
      // 	tagstr2 += element.id + ",";
      // }
      // tagstr2 = tagstr2.substr(0, tagstr2.length - 1);
      // for (let c = 0; c < this.tags3.length; c++) {
      // 	const element = this.tags3[c];
      // 	tagstr3 += element.id + ",";
      // }
      // tagstr3 = tagstr3.substr(0, tagstr3.length - 1);
      tagstr1 = this.tags1[0].id;
      tagstr2 = this.tags2[0].id;
      tagstr3 = this.tags3[0].id;
      let url = "/tag/insert_gu_tag_custtag";
      let str = "添加成功";
      this.axios
        .post(
          url,
          this.qs.stringify({
            cust_id: this.custid,
            tag_ids1: tagstr1,
            tag_ids2: tagstr2,
            tag_ids3: tagstr3,
          })
        )
        .then((rs) => {
          if (rs.data.code == 0) {
            this.$message({
              message: str,
              type: "success",
            });
            this.getusertag();
            this.exit();
          } else {
            this.$message(rs.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClose(val, type) {
      console.log(val);
      var tag = "";
      if (type == "1") {
        tag = this.tags1;
      } else if (type == "2") {
        tag = this.tags2;
      } else {
        tag = this.tags3;
      }

      this.$confirm("此操作将永久删除该标签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (!("rank" in val)) {
            tag.splice(tag.indexOf(val), 1);
          } else {
            var tagid = val.tag_id;
            this.axios
              .post(
                "/tag/delete_gu_tag_custtag",
                this.qs.stringify({ cust_id: this.custid, tag_id: tagid })
              )
              .then((rs) => {
                if (rs.data.code == 0) {
                  this.$message({
                    message: "删除成功",
                    type: "success",
                  });
                  tag.splice(tag.indexOf(val), 1);
                } else {
                  this.$message(rs.data.msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
