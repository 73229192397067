<template>
  <div>
    <el-dialog title="视频卡" :visible.sync="show" width="80%" @close="exit()">
      <div class="add">
        <el-button size="small" type="warning" @click="insert">添加</el-button>
      </div>
      <el-table :data="list" border style="width: 100%; margin-bottom: 10px">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column label="创建时间" prop="create_time"></el-table-column>
        <el-table-column label="有效时间" prop="days"></el-table-column>
        <el-table-column label="当前状态" prop="state" width="200">
          <template slot-scope="scope">
            <span>{{ filterstate(scope.row.state) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="" prop="" width="200">
          <template slot-scope="scope">
            <div class="opt doc">
              <el-button size="mini" type="danger" @click="del(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      >
      </el-pagination>
    </el-dialog>
    <el-dialog title="添加视频卡" :visible.sync="addVideoCard" width="50%">
      <el-select
        v-model="day"
        placeholder="请选择有效期天数"
        style="margin-right: 10px"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option> </el-select
      >天
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            addVideoCard = false;
            day = '';
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["isshowVideoCard", "toID"],
  data() {
    return {
      show: false,
      uid: sessionStorage.getItem("servicer_id"),
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [10], // 个数选择器（可修改）
      PageSize: 10, // 默认每页显示的条数（可修改）
      value: false,
      list: [],
      custid: "",
      addVideoCard: false,
      options: [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
      ],
      day: "",
    };
  },
  watch: {
    isshowVideoCard(o, n) {
      this.show = o;
      this.list = [];
      if (o) {
        this.axios
          // .get("/gu/get_gu_cust_by_tel?tel=" + this.toID)
          .post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.toID }))
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.custid = res.data.result[0].Cust_ID;
              this.getdata(1, this.custid);
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    },
    toID(o, n) {},
  },
  mounted() {},
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage, this.custid);
    },
    exit() {
      this.show = false;
      this.$emit("update:isshowVideoCard", false);
    },
    filterstate(state) {
      var str = "";
      var statelist = [
        { id: 0, label: "新建" },
        { id: 1, label: "使用中" },
        { id: 9, label: "失效" },
      ];
      statelist.forEach((element) => {
        if (state == element.id) {
          str = element.label;
        }
      });
      return str;
    },
    getdata(page, custid) {
      this.axios
        .get(
          "/videocard/get_gu_video_card?cust_id=" +
            custid +
            "&state=all" +
            "&page=" +
            page +
            "&size=" +
            this.PageSize
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        });
    },
    insert() {
      this.addVideoCard = true;
      this.day = "";
    },
    sub() {
      let form = {
        cust_id: this.custid,
        days: this.day,
        uid: this.uid,
      };
      console.log(form);
      this.axios
        .post("/videocard/insert_gu_video_card", this.qs.stringify(form))
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "创建成功",
              type: "success",
            });
            this.addVideoCard = false;
            this.getdata(1, this.custid);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
    del(row) {
      this.$confirm("是否删除该视频卡?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              "/videocard/delete_gu_video_card",
              this.qs.stringify({ id: row.id })
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "成功删除",
                });
                this.getdata(1, this.custid);
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
  },
};
</script>
