<template>
  <div>
    <el-tabs id="needcontent" v-model="activeneed" @tab-click="handleClick">
      <el-tab-pane label="今日需联系" name="firstneed"> </el-tab-pane>
      <el-tab-pane label="超时未联系" name="secondneed"></el-tab-pane>
    </el-tabs>
    <ul class="infinite-list" style="overflow: auto">
      <div
        v-for="(item,index) in threedayfriendlist"
        class="zdyline infinite-list-item"
        :key="item.id"
        @contextmenu.prevent="showclear(item.id,index)"
      >
        <div style="cursor: pointer; height: 48px; float: left; width: 243px" @click="wanttalk1(item)">
          <div class="zdyuserimg">
            <img :src="filterheadImg(item.headImg)" alt="" />
          </div>
          <div class="zdycustname" style="width: 180px">
            <span
              style="
                    width: 94px;
                    display: inline-block;
                    line-height: 14px;
                    vertical-align: middle;
                  "
              v-if="item.desc != '' && item.desc != null && item.desc != undefined"
              >{{ item.desc }}</span
            >
            <span
              style="
                    width: 94px;
                    display: inline-block;
                    line-height: 14px;
                    vertical-align: middle;
                  "
              v-else-if="item.name != '' && item.name != null && item.name != undefined"
              >{{ item.name | filtername(item.id) }}</span
            >
            <span
              style="
                    width: 94px;
                    display: inline-block;
                    line-height: 14px;
                    vertical-align: middle;
                  "
              v-else
              >待完善用户{{ item.id.substr(item.id.length - 4) }}</span
            >
            <span
              style="
                    width: 65px;
                    float: right;
                    font-size: 12px;
                    color: #808080;
                    transform: scale(0.8);
                    line-height: 15px;
                    margin-top: 10px;
                  "
            >
              <i style="display: inline-block">联系时间</i>
              <i style="display: inline-block">{{ item.nextConcatTime | capitalize }}</i>
              <!-- <i style="display: inline-block">{{ item.createTime | capitalize2 }}</i> -->
            </span>
          </div>
        </div>
      </div>
    </ul>
    <!-- 右键菜单开始 -->
    <div id="rightmenutalk" v-show="visible">
      <div @click="cleartime()">已联系</div>
    </div>
    <!-- 右键菜单结束 -->
  </div>
</template>
<script>
export default {
  props: ["serve", "needcontact"],
  data() {
    return {
      activeneed: "firstneed",
      female: require("../assets/female.png"),
      male: require("../assets/male.png"),
      serveurl: "",
      threedayfriendlist: [],
      sid: sessionStorage.getItem("id"),
      visible: false,
      clearitem: "",
      nowin:1,
      clearindex:'',
    };
  },
  watch: {
    serve(o, n) {
      this.serveurl = o;
    },
    needcontact(o, n) {
      if (o) {
        this.getonelist();
        this.activeneed = "firstneed";
      }
      let doc = document.getElementById("needcontent");
      let activebar = doc.getElementsByClassName("el-tabs__active-bar")[0];
      activebar.style.width = "70px";
    },
    visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closetalkMenu);
      } else {
        document.body.removeEventListener("click", this.closetalkMenu);
      }
    },
  },
  filters: {
    capitalize: function(value) {
      if (value == "0001-01-01 00:00:00") return "";
      if (!value) return "";
      value = value.substr(0, 10);
      return value;
    },
    capitalize2: function(value) {
      if (value == "0001-01-01 00:00:00") return "";
      if (!value) return "";
      value = value.substr(10, value.length);
      return value;
    },
    filtername: function(value, id) {
      if (value.indexOf("dxhs_") == 0) {
        return "待完善用户" + id.substr(id.length - 4);
      } else {
        return value;
      }
    },
  },
  mounted() {},
  methods: {
    handleClick(tab, event) {
      this.threedayfriendlist = [];
      if (tab.index == 0) {
        this.getonelist();
        this.nowin =1;
      } else if (tab.index == 1) {
        this.gettwolist();
        this.nowin=2;
      }
    },
    //过滤头像
    filterheadImg(val) {
      var url = "";
      if (val == null || val == "" || val == undefined || val == "null") {
        url = this.male;
      } else {
        url = val;
      }
      return url;
    },
    wanttalk1(row) {
      this.$emit("wanttalk", row);
    },
    getonelist() {
      this.axios
        .get(this.serveurl + "/api/Concat/get_need_concat_user?serviceId=s" + this.sid)
        .then((res) => {
          if (res.data.code == 1) {
            this.threedayfriendlist = res.data.result;
          }
        });
    },
    gettwolist() {
      this.axios
        .get(this.serveurl + "/api/Concat/get_expired_no_concat_user?serviceId=s" + this.sid)
        .then((res) => {
          if (res.data.code == 1) {
            this.threedayfriendlist = res.data.result;
          }
        });
    },
    //关闭右键菜单
    closetalkMenu() {
      this.visible = false;
    },
    showclear(item,index) {
      this.visible = true;
      var e = window.event;
      var ele = document.getElementById("rightmenutalk");
      ele.style.top = e.clientY + "px";
      ele.style.left = e.clientX + "px"; //获取当前位置显示
      this.clearitem = item;
      this.clearindex = index;
    },
    cleartime() {
      this.axios
        .post(
          this.serveurl + "/api/Concat/clear_next_concat_time",
          this.qs.stringify({ userId: this.clearitem })
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.threedayfriendlist.splice(this.clearindex, 1);
            this.$message({
              type: "success",
              message: "成功",
            });
            this.closetalkMenu();
          }
        });
    },
  },
};
</script>
<style>
#needcontent {
  width: 100%;
  background-color: #fff;
}
#talktabs #needcontent .el-tabs__header {
  width: 100%;
  height: auto;
}
#needcontent .el-tabs__nav-scroll {
  background: #fff;
}
#rightmenutalk {
  position: fixed;
  width: 120px;
  height: auto;
  background: #fff;
  border: 1px solid #dcdfe6;
  z-index: 100;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#DCDFE6, direction=120, strength=4);
  -moz-box-shadow: 2px 2px 10px #dcdfe6;
  -webkit-box-shadow: 2px 2px 10px #dcdfe6;
  box-shadow: 2px 2px 10px #dcdfe6;
  text-align: center;
}

#rightmenutalk div {
  height: 40px;
  line-height: 40px;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid #dcdfe6;
}

#rightmenutalk div:last-child {
  border-bottom: 0;
}
</style>
