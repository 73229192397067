<template>
  <div>
    <el-dialog title="分配医生" :visible.sync="show" width="40%" @close="exit()">
      <p>
        <el-input
          placeholder="请输入医生名"
          clearable
          prefix-icon="el-icon-search"
          v-model="searchdoc"
          style="width: 30%"
          @clear="cleardoc"
        >
        </el-input>
        <el-button type="primary" style="margin-left: 20px" @click="querydoc">查询</el-button>
      </p>
      <el-radio-group v-model="binddoccheckbox" style="text-align:left;">
        <el-radio
          style="height: 50px; line-height: 50px;width:15%;margin-right:0;"
          v-for="(item, index) in binddoclist"
          :label="item"
          :key="index"
          >{{ item.name }}</el-radio
        >
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exit()">取 消</el-button>
        <el-button type="primary" @click="subbinddoc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["assigdoctor", "assigdoctoritem"],
  data() {
    return {
      show: false,
      searchdoc: "",
      binddoccheckbox: "",
      binddoclist: [],
      sid: sessionStorage.getItem("id"),
    };
  },
  watch: {
    assigdoctor(o, n) {
      this.show = o;
      this.binddoccheckbox = '';
      this.binddoclist = [];
      this.searchdoc = "";
    },
  },
  methods: {
    exit() {
      this.show = false;
      this.$emit("update:assigdoctor", false);
    },
    querydoc() {
      //搜索医生姓名
      this.axios
        .post("/gu/search_gu_docs", this.qs.stringify({ keyword: this.searchdoc, is_flag: "all" }))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.binddoclist = rs.data.result;
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到该医生",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    subbinddoc() {
      var form = {
        z_doc_id: this.binddoccheckbox.id,
        kid: this.assigdoctoritem.KID,
        uid: sessionStorage.getItem("servicer_id"),
      };
      // console.log(form);
      // console.log(this.assigdoctoritem)
      // console.log(this.binddoccheckbox)
      this.$confirm("确定指定给" + this.binddoccheckbox.name + "医生吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/field/update_z_newseadoc_main_zdocid", this.qs.stringify(form))
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "指定医生成功",
                });
                this.exit();
              }
            });
          this.axios
            .post(
              "/field/insert_gu_ac_notice",
              this.qs.stringify({
                input_id: "s" + this.sid,
                content: "您下发的寻医表 "+this.assigdoctoritem.KID+" 已被 " + this.binddoccheckbox.name + " 医生抓取",
                type: "disman",
                op_id: this.assigdoctoritem.KID,
              })
            )
            .then((res) => {
              if (res.data.code == 0) {
                console.log(res);
              } else {
                console.log(res);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    //清除医生
    cleardoc() {
      this.binddoclist = [];
    },
  },
};
</script>
