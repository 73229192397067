<template>
  <div class="talkpage" ref="child">
    <!-- <el-button @click="lssc">删除</el-button> -->
    <!-- <el-tooltip
      class="item aaa"
      effect="dark"
      :content="showhidetips"
      placement="top"
    >
      <div class="isshowmenubtn" @click="isshowmenu">
        <i class="el-icon-s-fold" v-show="isshowbtn"></i>
        <i class="el-icon-s-unfold" v-show="ishidebtn"></i>
      </div>
    </el-tooltip> -->

    <el-tabs id="talktabs" tab-position="left" type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="" style="height: 80vh" name="second">
        <span slot="label">
          <el-tooltip effect="dark" content="消息 " placement="right-start">
            <i class="el-icon-chat-round" style="font-size: 20px"></i>
          </el-tooltip>
        </span>
        <el-row>
          <div class="xtmsg" @click="isShowNews()">
            系统消息 >>
            <span v-if="shownewsnum" class="item">{{ xtmsgNum }}</span>
          </div>
          <el-col :span="8" v-loading="msglistloading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" style="height: 100vh; overflow-y: auto; width: 320px">
            <div class="zdymain" v-if="list.length > 0">
              <div v-for="item in list" class="zdyline" :key="item.id" :class="{ act: toID == item.id }" @contextmenu.prevent="show(item, item.id)">
                <div style="width: 25px; float: left">
                  <span v-if="item.isOnline == 'true'" class="inline"></span>
                  <span v-else class="outline"></span>
                </div>
                <div @click="
                    lookup(item, item.id, item.type, item.name, item.desc)
                  " style="
                    cursor: pointer;
                    height: 48px;
                    float: left;
                    width: 250px;
                  ">
                  <div class="zdyuserimg">
                    <img :src="filterheadImg(item.headImg)" alt="" />
                  </div>
                  <div class="zdycustname" style="width: 190px">
                    <span style="
                        width: 90px;
                        display: inline-block;
                        line-height: 14px;
                        vertical-align: middle;
                      " v-if="
                        item.desc != '' &&
                        item.desc != null &&
                        item.desc != undefined
                      ">{{ item.desc }}</span>
                    <span style="
                        width: 90px;
                        display: inline-block;
                        line-height: 14px;
                        vertical-align: middle;
                      " v-else-if="
                        item.name != '' &&
                        item.name != null &&
                        item.name != undefined
                      ">{{ item.name | filtername(item.id) }}</span>
                    <span style="
                        width: 90px;
                        display: inline-block;
                        line-height: 14px;
                        vertical-align: middle;
                      " v-else>待完善用户{{ item.id.substr(item.id.length - 4) }}</span>
                    <span class="itemmsg" v-show="item.hasmsg == 'true'"></span>
                    <span class="item" v-if="item.noReads > 0">{{
                      item.noReads
                    }}</span>
                    <span style="
                        width: 65px;
                        float: right;
                        font-size: 12px;
                        color: #808080;
                        transform: scale(0.8);
                        line-height: 15px;
                        margin-top: 10px;
                      ">
                      <i style="display: inline-block">{{
                        item.sendTime | capitalize
                      }}</i>
                      <i style="display: inline-block">{{
                        item.sendTime | capitalize2
                      }}</i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="zdymain zdynolist">暂无数据</div>
          </el-col>
          <el-col :span="16">
            <div class="talknewsmain" v-if="isShowNewstype">
              <el-button @click="setIsRead('', '')">清除全部未读</el-button>
              <ul style="margin-top: 10px">
                <li class="newsline" v-for="(item, index) in fiveList" :key="item.id">
                  <span class="newsdetail"></span>
                  {{ item.content }}
                  <!-- <span
                    v-if="!item.content.includes('医生')"
                    class="TJtalkBtn"
                    @click="
                      EditMsg.noedit = false;
                      EditMsg.kid = item.op_id;
                      EditZD = true;
                      shenhe = false;
                    "
                    >查看</span
                  > -->
                  <span v-if="!item.content.includes('医生')" class="TJtalkBtn" @click="TJshenhe(item, 'message')">审核</span>
                  <span v-if="item.content.includes('医生')" class="TJtalkBtn" @click="
                      EditXYMsg.noedit = false;
                      EditXY = true;
                      EditXYMsg.kid = item.op_id;
                    ">查看</span>
                  <span class="TJtalkBtn" @click="setIsRead(item, index)">已读</span>
                </li>
              </ul>
            </div>
            <div class="talkmain" v-if="dialogFormVisible">
              <div class="talkname">{{ talkname }}</div>
              <p class="lookhistory" v-if="lookmoreshow" @click="loadmore">
                查看历史记录
              </p>
              <div v-loading="talklistloading">
                <div class="talk-main" id="scrolldIV">
                  <div class="talkline" v-for="(item, index) in talklist" :key="index">
                    <div class="timeline">{{ item.sendTime }}</div>
                    <div class="leftimg" v-if="item.from == toID">
                      <img :src="userheadimg" alt="" />
                    </div>
                    <div class="talkleft" v-if="item.from == toID">
                      <p style="margin: 0" v-if="item.type == '0'">
                        <span>
                          {{ item.content }}
                        </span>
                        <!-- <i style="display: inherit;">
                          <em style="font-size:10px;">发送给：{{ item.to }}</em>
                          <em style="font-size:10px;margin-left:6px;" v-if="item.isRead == 0"
                            >未读</em
                          >
                        </i> -->
                      </p>
                      <p style="margin: 0" v-if="item.type == '1'">
                        <span>
                          <img :src="item.content" alt="" @click="big(item.content)" />
                        </span>
                        <!-- <i style="display: inherit;">
                          <em style="font-size:10px;">发送给：{{ item.to }}</em>
                          <em style="font-size:10px;margin-left:6px;" v-if="item.isRead == 0"
                            >未读</em
                          >
                        </i> -->
                      </p>
                      <span class="type15">
                        <i v-if="item.type == '15'">
                          <div style="width: 300px">
                            <p>APP商城订单</p>
                            <p>
                              您的患者在商城中下单并购买了产品<br />订单编号：{{
                                item.content
                              }}
                            </p>
                            <p @click="
                                lookSCorderdetail(JSON.parse(item.content))
                              ">
                              查看详情>
                            </p>
                          </div>
                        </i>
                      </span>
                      <span v-if="item.type == '14'">
                        <i>
                          <div class="audiobox" style="background-color: #43cfcc">
                            <div @click="Listening(index)">
                              <b>
                                <span style="border: none;background: none;">{{ JSON.parse(item.content).time }}"</span>
                                <i class="el-icon-video-play" style="font-size: 26px"></i></b>
                              <!-- @play="handlePlay()" -->
                              <audio class="talklistaudio" :src="JSON.parse(item.content).url" controls style="display: none" :ref="'audio' + index" @ended="endplay(index)"></audio>
                            </div>
                          </div>
                        </i>
                      </span>
                    </div>
                    <div class="rightimg" v-if="item.from != toID">
                      <img :src="headimg" alt="" />
                    </div>
                    <div class="talkright" v-if="item.from != toID">
                      <span>
                        <i v-if="item.type == '0'"><b>{{ item.content }}</b></i>
                        <i v-if="item.type == '1'">
                          <img :src="item.content" alt="" @click="big(item.content)" />
                        </i>
                        <i v-if="item.type == 3">
                          <div style="width: 220px">
                            <p>寻医报名表</p>
                            <p>
                              您好，为了便于更好的帮您寻找擅长治疗您疾病的好医生，请点击对话框，详细填写报名表。
                            </p>
                            <p style="display: none"></p>
                          </div>
                        </i>
                        <i v-if="item.type == '2'">
                          <div style="width: 100px">
                            <p>问诊单:{{ JSON.parse(item.content).name }}</p>
                            <p>诊单编号:{{ JSON.parse(item.content).id }}</p>
                            <p @click="
                                EditZD = true;
                                EditMsg.kid = JSON.parse(item.content).id;
                                shenhe = false;
                              ">
                              查看详情>
                            </p>
                          </div>
                        </i>
                        <i v-if="item.type == 4">
                          <div style="width: 300px">
                            <p>精准匹配</p>
                            <div>
                              <div class="talkdocline" v-for="(it, index) in JSON.parse(item.content)" :key="index">
                                <img :src="filterheadImg(it.headimg)" alt="" />
                                <div class="wenzi">
                                  <p class="wenzidocname">
                                    {{ it.name }}<span>{{ it.rank }}</span>
                                  </p>
                                  <p class="wenzihospatil">{{ it.hospital }}</p>
                                  <p class="wenzigoodat">
                                    擅长：
                                    <span>{{ it.goodat }}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p style="display: none"></p>
                          </div>
                        </i>
                        <i v-if="item.type == '5'">
                          <div style="width: 220px">
                            <p>寻医问诊表</p>
                            <p>
                              您好，为了便于帮您精准匹配好医生，请点击此对话框，详细填写寻医问诊表。
                            </p>
                            <p @click="
                                EditXY = true;
                                EditXYMsg.kid = JSON.parse(item.content).id;
                                EditXYMsg.noedit = true;
                              ">
                              查看详情>
                            </p>
                          </div>
                        </i>
                        <i v-if="item.type == '6'">
                          <div style="width: 220px">
                            <p>
                              {{
                                JSON.parse(item.content).doc_name
                              }}&nbsp;医生&nbsp;问诊单
                            </p>
                            <p>
                              您好，为了让匹配的好医生详细了解您的病情信息，给出准确治疗方案，请点击此对话框，详细填写医生问诊单。
                            </p>
                            <p @click="
                                EditZD = true;
                                EditMsg.kid = JSON.parse(item.content).id;
                                EditMsg.noedit = true;
                                shenhe = false;
                              ">
                              查看详情>
                            </p>
                          </div>
                        </i>

                        <i v-if="item.type == '7'">
                          <div style="width: 220px">
                            <p>频道名:{{ JSON.parse(item.content).channel }}</p>
                            <p>ID:{{ JSON.parse(item.content).id }}</p>
                            <p @click="
                                lookchanneldetail(JSON.parse(item.content))
                              ">
                              查看详情>
                            </p>
                          </div>
                        </i>
                        <i v-if="item.type == '8'">
                          <div style="width: 220px">
                            <p style="background: #ff8040">支付问诊费用</p>
                            <p>
                              您好，在线医助给您发来了支付问诊费用请求，点击开始支付。
                            </p>
                            <p @click="
                                lookzfdetail(JSON.parse(item.content).id, 'zf')
                              ">
                              查看详情>
                            </p>
                          </div>
                        </i>
                        <i v-if="item.type == '9'">
                          <div style="width: 240px">
                            <p style="background: #ff8040">支付订单费用</p>
                            <p>ID:{{ JSON.parse(item.content).yforderno }}</p>
                            <p>ID：{{ JSON.parse(item.content).orderid }}</p>
                            <p></p>
                            <p></p>
                            <!-- <p>KID:{{ JSON.parse(item.content).KID }}</p> -->
                            <!-- <p>ID:{{ JSON.parse(item.content).id }}</p> -->
                            <!-- <p @click="lookzfdetail(JSON.parse(item.content).yforderno, 'dd')">
                              查看详情>
                            </p> -->
                          </div>
                        </i>
                        <i v-if="item.type == '10'">
                          <div style="width: 300px">
                            <p>好转病例</p>
                            <div class="talkdocline" style="border-bottom: none">
                              <img :src="
                                  filterheadImg(
                                    JSON.parse(item.content).headimg
                                  )
                                " alt="" />
                              <div class="wenzi">
                                <p class="wenzidocname">
                                  {{ JSON.parse(item.content).name
                                  }}<span>{{
                                    JSON.parse(item.content).ill_name
                                  }}</span>
                                </p>
                                <p class="wenzigoodat">
                                  <span>{{
                                    JSON.parse(item.content).des
                                  }}</span>
                                </p>
                              </div>
                            </div>
                            <p style="display: none"></p>
                          </div>
                        </i>
                        <i v-if="item.type == '11'">
                          <div style="width: 220px">
                            <p></p>
                            <p>
                              {{ JSON.parse(item.content).name }}给患者:{{
                                changetel(JSON.parse(item.content).id)
                              }}发送了留言短信。
                            </p>
                            <p></p>
                          </div>
                        </i>

                        <i v-if="item.type == '13'">
                          <div style="width: 220px">
                            <p>反馈表</p>
                            <p>
                              您好，为了便于您以后的治疗请认真填写反馈信息表。
                            </p>
                            <p @click="
                                lookfeedback = true;
                                feedbacknoedit = true;
                                feedbackid = JSON.parse(item.content).id;
                                shenhe = false;
                              ">
                              查看详情>
                            </p>
                          </div>
                        </i>

                        <i v-if="item.type == '14'">
                          <div class="audiobox" @click="Listening(index)">
                            <div>
                              <b><span style="border: none;background: none;">{{ JSON.parse(item.content).time }}"</span>
                                <i class="el-icon-video-play" style="font-size: 26px"></i></b>
                              <!-- @play="handlePlay()" -->
                              <audio class="talklistaudio" :src="JSON.parse(item.content).url" controls style="display: none" :ref="'audio' + index" @ended="endplay(index)"></audio>
                            </div>
                          </div>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="talk-main-text">
                <div style="width: 100%; overflow: hidden; position: relative">
                  <div style="width: 78%; float: left; box-sizing: border-box">
                    <el-input class="talk-text" type="textarea" v-model="message" resize="none" id="cOthersResponsible" :autosize="{ minRows: 1, maxRows: 8 }" @focus="focusallfalse"></el-input>
                    <!--  @input="blurallfalse" @blur="blurallfalse" v-if="showsend"  v-if="showplus"-->
                  </div>
                  <div style="
                      width: 22%;
                      float: left;
                      padding-top: 14px;
                      position: absolute;
                      left: 78%;
                      bottom: 4px;
                    ">
                    <div class="send" v-if="showsend">
                      <el-button size="mini" @click="send()">发送</el-button>
                    </div>
                    <div class="imgbtn" @click="showemoji">
                      <img :src="smileicon" alt="" class="smilebtn" />
                    </div>
                    <div class="imgbtn" @click="showmenu" v-if="showplus">
                      <i class="el-icon-circle-plus-outline"></i>
                    </div>
                    <div class="imgbtn" @click="showReplyTemplate" v-if="showplus">
                      <i class="el-icon-edit"></i>
                    </div>
                    <!-- 录制音频 -->
                    <div class="imgbtn" @click="wantSoundRecording">
                      <i class="el-icon-microphone"></i>
                    </div>
                  </div>
                </div>
                <div class="btnlinefather">
                  <div class="btnline" v-show="plus">
                    <div class="btnk">
                      <div class="graybtn">
                        <div class="imgbtn c1">
                          <!-- <i class="el-icon-picture-outline"></i> -->
                          <img :src="talktp" alt="" />
                          <el-upload class="avatar-uploader" action="    " :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :http-request="upload">
                            <img v-if="picsrc" :src="picsrc" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                          </el-upload>
                        </div>
                      </div>
                      <span>图片</span>
                    </div>
                    <div class="btnk">
                      <div class="graybtn">
                        <div class="imgbtn c2" @click="querydoc1">
                          <!-- <i class="el-icon-s-custom"></i> -->
                          <img :src="talkys" alt="" />
                        </div>
                      </div>
                      <span>医生</span>
                    </div>
                    <div class="btnk">
                      <div class="graybtn">
                        <div class="imgbtn c2" @click="userbaoming">
                          <!-- <i class="el-icon-document-checked"></i> -->
                          <img :src="talkbm" alt="" />
                        </div>
                      </div>
                      <span>报名</span>
                    </div>
                    <div class="btnk">
                      <div class="graybtn">
                        <div class="imgbtn c2" @click="medicalSeek">
                          <!-- <i class="el-icon-question"></i> -->
                          <img :src="talkxy" alt="" />
                        </div>
                      </div>
                      <span>寻医</span>
                    </div>
                    <div class="btnk">
                      <div class="graybtn">
                        <div class="imgbtn c1" @click="newList">
                          <!-- <i class="el-icon-document"></i> -->
                          <img :src="talkzd" alt="" />
                        </div>
                      </div>
                      <span>诊单</span>
                    </div>
                    <div class="btnk">
                      <div class="graybtn">
                        <div class="imgbtn c1" @click="feedback">
                          <img :src="talkfk" alt="" />
                        </div>
                      </div>
                      <span>反馈</span>
                    </div>
                    <div class="btnk">
                      <div class="graybtn">
                        <div class="imgbtn c2" @click="money">
                          <!-- <i class="el-icon-wallet"></i> -->
                          <img :src="talkgh" alt="" />
                        </div>
                      </div>
                      <span>挂号</span>
                    </div>
                    <!-- <div class="btnk">
                      <div class="graybtn">
                        <div class="imgbtn c1" @click="launchVideo">
                          <img :src="talklx" alt="" />
                        </div>
                      </div>
                      <span>医患<br />连线</span>
                    </div> -->
                    <div class="btnk">
                      <div class="graybtn">
                        <div class="imgbtn c2" @click="placeorder">
                          <!-- <i class="talkzd"></i> -->
                          <img :src="talkdd" alt="" />
                        </div>
                      </div>
                      <span>订单</span>
                    </div>
                    <div class="btnk">
                      <div class="graybtn">
                        <div class="imgbtn c2" @click="bingli">
                          <!-- <i class="el-icon-wind-power"></i> -->
                          <img :src="talkbl" alt="" />
                        </div>
                      </div>
                      <span>病例</span>
                    </div>
                    <div class="btnk">
                      <div class="graybtn">
                        <div class="imgbtn c2" @click="sendshortmessage">
                          <!-- <i class="el-icon-message"></i> -->
                          <img :src="talkdx" alt="" />
                        </div>
                      </div>
                      <span>短信</span>
                    </div>
                    <div class="btnk">
                      <div class="graybtn">
                        <div class="imgbtn c2" @click="synchronization">
                          <!-- <i class="el-icon-refresh"></i> -->
                          <img :src="talktb" alt="" />
                        </div>
                      </div>
                      <span>同步</span>
                    </div>
                    <!-- <div class="btnk">
                      <div class="graybtn">
                        <div class="imgbtn c2" @click="sendVideoCard">
                          <i class="el-icon-s-ticket"></i>
                        </div>
                      </div>
                      <span>视频卡</span>
                    </div> -->
                  </div>
                </div>
                <div class="emotionList" v-show="emoji1">
                  <a href="javascript:void(0);" @click="getEmo(index)" v-for="(item, index) in faceList" :key="index" class="emotionItem">{{ item }}</a>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- 发起会话 -->
        <!-- <div class="imgbtn" @click="launchVideo">
					<el-tooltip effect="dark" content="发起会话" placement="top-start">
						<i class="el-icon-video-camera" style="margin-top: 5px;float: right;"></i>
					</el-tooltip>
				</div> -->
      </el-tab-pane>
      <!-- 通讯录页面开始 -->
      <el-tab-pane label="" style="height: 80vh" name="one">
        <span slot="label">
          <el-tooltip effect="dark" content="通讯录" placement="right-start">
            <i class="el-icon-notebook-1" style="font-size: 20px"></i>
          </el-tooltip>
        </span>
        <el-alert type="success" :closable="false">
          <p style="text-align: left">当前您共有{{ allfriendcount }}位患者</p>
        </el-alert>
        <p style="width: 100%; float: left">
          <el-input placeholder="请输入关键字" size="small" clearable prefix-icon="el-icon-search" v-model="searchtxl" style="width: 15%; float: left" @clear="clearsearch">
          </el-input>
          <el-button type="primary" style="margin-left: 25px; float: left" @click="querytxl" size="small">查询</el-button>
        </p>
        <van-list class="infinite-list" style="float: left; position: relative" v-model="loadingfriend" :finished="finishedfriend" finished-text="没有更多了" @load="friendload" v-loading="friendlistloading" element-loading-text="正在加载中">
          <!-- <van-index-bar :sticky="false" :highlight-color="'#67C23A'" :index-list="indexList">
            <div v-for="(item, key) in indexList" :key="key">
              <van-index-anchor :index="item" /> v-if="user.pinyinIndex == item" :key="index"-->
          <div v-for="user in friendlist" @click="lookuser(user)" :class="{ act: friendlistToId == user.id }" class="cell zdyline">
            <div class="zdyuserimg">
              <img :src="filterheadImg(user.headImg)" alt="" />
            </div>
            <div class="zdycustname">
              <span style="
                  width: 94px;
                  display: inline-block;
                  line-height: 14px;
                  vertical-align: middle;
                " v-if="
                  user.desc != '' && user.desc != null && user.desc != undefined
                ">{{ user.desc }}</span>
              <span style="
                  width: 94px;
                  display: inline-block;
                  line-height: 14px;
                  vertical-align: middle;
                " v-else-if="
                  user.name != '' && user.name != null && user.name != undefined
                ">{{ user.name }}</span>
              <span style="
                  width: 94px;
                  display: inline-block;
                  line-height: 14px;
                  vertical-align: middle;
                " v-else>待完善用户{{ user.id.substr(user.id.length - 4) }}</span>
              <span style="
                  width: 65px;
                  float: right;
                  font-size: 12px;
                  color: #808080;
                  transform: scale(0.8);
                  line-height: 15px;
                  margin-top: 10px;
                ">
                <i style="display: inline-block">{{
                  user.nextConcatTime | capitalize
                }}</i>
              </span>
            </div>
          </div>
          <!-- </div>
          </van-index-bar> -->
        </van-list>
        <!-- <ul class="infinite-list" style="overflow: auto;float:left;">
          <div
            v-for="item in friendlist"
            class="zdyline infinite-list-item"
            :key="item.id"
            @click="lookuser(item)"
            :class="{ act: friendlistToId == item.id }"
          >
            @contextmenu.prevent="show(item, item.id)"
            <div style="cursor: pointer; height: 48px; float: left;">
              <div class="zdyuserimg">
                <img :src="filterheadImg(item.headImg)" alt="" />
              </div>
              <div class="zdycustname" style="width:150px;">
                <span v-if="item.desc != '' && item.desc != null && item.desc != undefined">{{
                  item.desc
                }}</span>
                <span v-else-if="item.name != '' && item.name != null && item.name != undefined">{{
                  item.name | filtername(item.id)
                }}</span>
                <span v-else>待完善用户{{ item.id.substr(item.id.length - 4) }}</span>
              </div>
            </div>
          </div>
        </ul> -->

        <div v-if="showusermsg" class="friendlistmsgmain">
          <div class="friendlistusermsg">
            <div>用户ID：{{ LookUserMsg.Cust_ID }}</div>
            <div>
              姓名：{{ LookUserMsg.Cust_Name }}
              <span class="changeBtn" @click="
                  CUM = true;
                  CUMname = LookUserMsg.Cust_Name;
                  CUMsex = LookUserMsg.Cust_Sex + '';
                  CUMage = LookUserMsg.Cust_Age;
                ">修改</span>
            </div>
            <div>性别：{{ LookUserMsg.Cust_Sex == 0 ? "男" : "女" }}</div>
            <div>年龄：{{ LookUserMsg.Cust_Age }}</div>
            <div>身高：{{ LookUserMsg.Cust_Height }}cm</div>
            <div>体重：{{ LookUserMsg.Cust_Weight }}kg</div>
            <div>
              状态：{{ changeStatus }}
              <span class="changeBtn" @click="changeStatustc = true">修改</span>
            </div>
            <div>
              疾病：{{ Ill_Lib }}
              <span class="changeBtn" @click="bindIll">修改</span>
              <span class="changeBtn" @click="delIll">置空</span>
            </div>
            <div>
              医生：{{ Doc_Name }}
              <!-- <span class="changeBtn" @click="bindDoc">修改</span>
              <span class="changeBtn" @click="delDoc">置空</span> -->
            </div>
          </div>
          <el-divider></el-divider>
          <!-- 设置备注 -->
          <div class="friendlistusermsg">
            <div class="friendlistbeizhu">
              备注：{{ LookUserMsgtalk.desc }}
              <span class="changeBtn" @click="setNotes('usercard', LookUserMsgtalk.desc)">修改</span>
            </div>
            <div class="friendlistbeizhu">
              等级：{{ LookUserlivinglevel }}
              <span class="changeBtn" @click="setLevelNote()">修改</span>
            </div>
          </div>
          <el-divider></el-divider>
          <!-- 下次联系时间 -->
          <!-- <div class="friendlistusermsg">
            <div class="friendlistbeizhu">
              下次联系时间：{{ LookUserMsgtalk.nextConcatTime | capitalize }}
              <span class="changeBtn" @click="talkSetNextContactTime(talkitem)"
                >设置</span
              >
            </div>
            <div class="friendlistbeizhu">
              联系内容：{{ LookUserMsgtalk.nextConcatContent }}
            </div>
            <div class="friendlistbeizhu">
              其他备注：{{ LookUserMsgtalk.notes }}
            </div>
          </div>
          <el-divider></el-divider> -->
          <!-- 标签 -->
          <div class="friendlistbeizhu">
            标签：
            <span v-for="(item, index) in friendlistusertag" :key="index">{{ item.tag }}、</span>
            <span class="changeBtn" @click="talksettag(talkitem)">设置标签</span>
          </div>
          <el-divider></el-divider>
          <div class="friendlistwanttalkbtn">
            <el-button @click="wanttalk()" type="success" size="small">发消息</el-button>
            <el-button @click="userlookxy()" type="primary" size="small" plain>查看寻医</el-button>
            <el-button @click="userlookzd()" type="primary" size="small" plain>查看诊单</el-button>
            <el-button @click="userlookfk()" type="primary" size="small" plain>查看反馈</el-button>
            <el-button @click="userlookzf()" type="primary" size="small" plain>查看诊费</el-button>
            <el-button @click="userlookdd()" type="primary" size="small" plain>查看订单</el-button>
            <el-button @click="userlookfoot()" type="primary" size="small" plain>查看浏览足迹</el-button>
          </div>
        </div>
      </el-tab-pane>
      <!-- 通讯录页面结束 -->
      <!-- 最近新增页面开始 -->
      <el-tab-pane label="" style="height: 100%" name="three">
        <span slot="label">
          <el-tooltip effect="dark" content="最近新增" placement="right-start">
            <i class="el-icon-notebook-2" style="font-size: 20px"></i>
          </el-tooltip>
        </span>
        <ul class="infinite-list" v-infinite-scroll="friendload2" style="overflow: auto">
          <div v-for="item in threedayfriendlist" class="zdyline infinite-list-item" :key="item.id" @click="wanttalk(item)">
            <div style="cursor: pointer; height: 48px; float: left; width: 243px">
              <div class="zdyuserimg">
                <img :src="filterheadImg(item.headImg)" alt="" />
              </div>
              <div class="zdycustname" style="width: 180px">
                <span style="
                    width: 94px;
                    display: inline-block;
                    line-height: 14px;
                    vertical-align: middle;
                  " v-if="
                    item.desc != '' &&
                    item.desc != null &&
                    item.desc != undefined
                  ">{{ item.desc }}</span>
                <span style="
                    width: 94px;
                    display: inline-block;
                    line-height: 14px;
                    vertical-align: middle;
                  " v-else-if="
                    item.name != '' &&
                    item.name != null &&
                    item.name != undefined
                  ">{{ item.name | filtername(item.id) }}</span>
                <span style="
                    width: 94px;
                    display: inline-block;
                    line-height: 14px;
                    vertical-align: middle;
                  " v-else>待完善用户{{ item.id.substr(item.id.length - 4) }}</span>
                <span style="
                    width: 65px;
                    float: right;
                    font-size: 12px;
                    color: #808080;
                    transform: scale(0.8);
                    line-height: 15px;
                    margin-top: 10px;
                  ">
                  <i style="display: inline-block">{{
                    item.createTime | capitalize
                  }}</i>
                  <i style="display: inline-block">{{
                    item.createTime | capitalize2
                  }}</i>
                </span>
              </div>
            </div>
          </div>
        </ul>
      </el-tab-pane>
      <!-- 最近新增页面结束 -->
      <!-- 我的信息页面开始 -->
      <el-tab-pane label="" style="text-align: left" name="four">
        <span slot="label">
          <el-tooltip effect="dark" content="我的信息" placement="right-start">
            <i class="el-icon-user" style="font-size: 20px"></i>
          </el-tooltip>
        </span>
        <talkMyMsg :myMsg="myMsg" :sid="sid"></talkMyMsg>
      </el-tab-pane>
      <!-- 我的信息页面结束 -->
      <!-- 我的统计页面开始 -->
      <el-tab-pane label="" style="text-align: left; padding: 10px" name="five">
        <span slot="label">
          <el-tooltip effect="dark" content="我的统计" placement="right-start">
            <i class="el-icon-pie-chart" style="font-size: 20px"></i>
          </el-tooltip>
        </span>
        <div style="overflow: auto; height: 85vh">
          <div style="height: 40px; line-height: 40px; font-size: 14px">
            <span style="
                font-weight: bold;
                color: #409eff;
                margin-right: 20px;
                font-size: 16px;
              ">今日寻医表统计</span>
            新增寻医表：{{ tjnum.xybzs }}；已抓取寻医表：{{
              tjnum.xyyzq
            }}；已审核寻医表：{{ tjnum.xyysh }}；
          </div>
          <div style="margin-bottom: 20px">
            <!-- <div style="width:280px;" v-if="get_own_roles != 0"> -->
            <!-- <el-select
              v-model="tjkefuchoice"
              filterable
              placeholder="请选择客服"
              @change="tjchange"
            >
              <el-option
                v-for="(item, index) in tjkefulist"
                :key="index"
                :label="item.name"
                :value="item.serviceId"
              >
              </el-option>
            </el-select> -->
            <el-select size="small" v-if="get_own_roles != 0" v-model="tjkefuchoice" multiple collapse-tags value-key="serviceId" placeholder="请选择客服" style="margin-right: 10px; width: 200px">
              <el-option label="全选" value="all"></el-option>
              <el-option v-for="item in tjkefulist" :key="item.name" :label="item.name" :value="item">
              </el-option>
            </el-select>
            <!-- </div> -->
            <el-select size="small" v-model="MyZDType" placeholder="请选择类型" style="margin-right: 10px; width: 100px">
              <el-option v-for="item in MyZDTypeoptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select size="small" v-if="MyZDType == '6' || MyZDType == '7'" v-model="MyZDState" placeholder="请选择状态" style="margin-right: 10px; width: 120px">
              <el-option v-for="(item, index) in MyZDStateoptions" :key="index" :label="item.label" :value="item.value">
              </el-option>
              <!-- <el-option v-if="MyZDType == '5'" v-for="item in MyXYStateoptions" :key="item" :label="item.label" :value="item.value"> </el-option> -->
            </el-select>
            <el-select size="small" v-if="MyZDType == '5'" v-model="MyZDState" placeholder="请选择状态" style="margin-right: 10px; width: 120px">
              <!-- <el-option v-if="MyZDType == '6'" v-for="item in MyZDStateoptions" :key="item" :label="item.label" :value="item.value"> </el-option> -->
              <el-option v-for="(item, index) in MyXYStateoptions" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-date-picker size="small" v-model="MyZDDate" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="margin-right: 10px; width: 260px">
            </el-date-picker>
            <el-input size="small" placeholder="请输入电话号或用户编号" style="width: 190px; margin-right: 10px" v-model="tjcustid" clearable></el-input>
            <el-button size="small" type="primary" @click="SearchMyDayZD">查询</el-button>
          </div>
          <el-table :data="MyDayList" border style="width: 100%">
            <el-table-column label="时间" prop="Creat_DT"></el-table-column>
            <el-table-column label="编号" prop="KID"></el-table-column>
            <el-table-column label="名称" prop="Dis_Name"></el-table-column>
            <el-table-column label="用户ID" prop="Cust_ID"></el-table-column>
            <el-table-column label="所属医生" v-if="MyZDType == '6'" prop="doc_name"></el-table-column>
            <el-table-column label="抓取医生" v-if="MyZDType == '5'" prop="Doc_Name"></el-table-column>
            <el-table-column label="指定医生" v-if="MyZDType == '5'" prop="Z_Doc_Name"></el-table-column>
            <el-table-column prop="State" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.State == 0">新建</span>
                <span v-if="scope.row.State == 1">提交</span>
                <span v-if="
                    scope.row.State == 2 && (MyZDType == '6' || MyZDType == '7')
                  ">审核</span>
                <span v-if="scope.row.State == 2 && MyZDType == '5'">已抓取</span>
                <span v-if="scope.row.State == 3">打回</span>
                <span v-if="
                    scope.row.State == 6 && (MyZDType == '6' || MyZDType == '7')
                  ">已下发</span>
                <span v-if="scope.row.State == 6 && MyZDType == '5'">审核</span>
                <span v-if="scope.row.State == 9">已删除</span>
                <span class="TJtalkBtn" v-if="MyZDType == '6'" @click="
                    EditMsg.noedit = false;
                    EditMsg.cust_id = scope.row.Cust_ID;
                    EditMsg.kid = scope.row.KID;
                    EditZD = true;
                    shenhe = false;
                  ">查看</span>
                <span class="TJtalkBtn" v-if="MyZDType == '5'" @click="
                    EditXYMsg.noedit = false;
                    EditXYMsg.cust_id = scope.row.Cust_ID;
                    EditXYMsg.kid = scope.row.KID;
                    EditXY = true;
                  ">查看</span>
                <span class="TJtalkBtn" v-if="MyZDType == '7'" @click="
                    lookfeedback = true;
                    feedbackid = scope.row.KID;
                    feedbacknoedit = false;
                    shenhe = false;
                  ">查看</span>
                <span v-if="scope.row.State == 0" class="TJtalkBtn" @click="TemporaryDialog(scope.row)">对话</span>
                <span v-if="scope.row.State == 1 && MyZDType == '6'" class="TJtalkBtn" @click="TJshenhe(scope.row)">审核</span>
                <span v-if="scope.row.State == 1 && MyZDType == '7'" class="TJtalkBtn" @click="fkshenhe(scope.row)">审核</span>
                <span v-if="
                    (scope.row.State == 1 || scope.row.State == 0) &&
                    MyZDType == '5'
                  " class="TJtalkBtn" @click="NewXYEdit(scope.row, '')">编辑</span>
                <span v-if="scope.row.State == 1 && MyZDType == '5'" class="TJtalkBtn" @click="XYshenhe(scope.row)">审核</span>
                <!-- <span
                  v-if="scope.row.State == 1 && MyZDType == '5'"
                  class="TJtalkBtn"
                  @click="assigDoc(scope.row)"
                  >审核并指定医生</span
                > -->
              </template>
            </el-table-column>
          </el-table>
          <p class="paging" style="text-align: center">
            <el-pagination @size-change="MyZDhandleSizeChange" @current-change="MyZDhandleCurrentChange" :current-page="MyZDcurrent" :page-sizes="MyZDpageSizes" :page-size="MyZDPageSize" layout="total, sizes, prev, pager, next, jumper" :total="MyZDtotalCount" :hide-on-single-page="MyZDvalue"></el-pagination>
          </p>
        </div>
      </el-tab-pane>
      <!-- 我的统计页面结束 -->
      <!-- 支付列表开始 -->
      <el-tab-pane label="" style="text-align: left; padding: 10px" name="seven">
        <span slot="label">
          <el-tooltip effect="dark" content="支付列表" placement="right-start">
            <i class="el-icon-wallet" style="font-size: 20px"></i>
          </el-tooltip>
        </span>
        <payMoneyList :get_own_roles="get_own_roles" :serve="serve" :showMoneyList.sync="showMoneyList"></payMoneyList>
      </el-tab-pane>
      <!-- 支付列表结束 -->
      <!-- 订单列表开始 -->
      <el-tab-pane label="" style="text-align: left" name="nine">
        <span slot="label">
          <el-tooltip effect="dark" content="订单列表" placement="right-start">
            <i class="el-icon-s-order" style="font-size: 20px"></i>
          </el-tooltip>
        </span>
        <talkorder :showOrder.sync="showOrder" :get_own_roles="get_own_roles" :serve="serve"></talkorder>
      </el-tab-pane>
      <!-- 订单列表结束 -->
      <!-- 今日分配开始 -->
      <el-tab-pane label="" style="text-align: left; padding: 10px" name="twelve">
        <span slot="label">
          <el-tooltip effect="dark" content="今日分配" placement="right-start">
            <i class="el-icon-thumb" style="font-size: 20px; transform: rotate(90deg)"></i>
          </el-tooltip>
        </span>
        <talkdistributetoday :serve="serve" @wanttalk="wanttalk"></talkdistributetoday>
      </el-tab-pane>
      <!-- 今日分配结束 -->
      <!-- 需联系开始 -->
      <el-tab-pane label="" style="text-align: left; padding: 10px" name="thirteen">
        <span slot="label">
          <el-tooltip effect="dark" content="需联系" placement="right-start">
            <i class="el-icon-phone-outline" style="font-size: 20px"></i>
          </el-tooltip>
        </span>
        <talkneedcontact :serve="serve" @wanttalk="wanttalk" :needcontact.sync="needcontact"></talkneedcontact>
      </el-tab-pane>
      <!-- 需联系结束 -->
      <!-- 医生列表 -->
      <!-- <el-tab-pane label="" style="height: 100%" name="fiveteen">
        <span slot="label">
          <el-tooltip effect="dark" content="医生列表" placement="right-start">
            <i class="el-icon-postcard" style="font-size:20px;"></i>
          </el-tooltip>
        </span>
        <talktodoc
          :talkconnection="talkconnection"
          :serve="serve"
          @lookup="lookup"
          :todocdb="todocdb"
        ></talktodoc>
      </el-tab-pane> -->
      <!-- 医生列表 -->
      <!-- 视频列表开始 -->
      <!-- <el-tab-pane label="" style="text-align: left;" name="eight">
        <span slot="label">
          <el-tooltip effect="dark" content="视频列表" placement="right-start">
            <i class="el-icon-video-camera" style="font-size:20px;"></i>
          </el-tooltip>
        </span>
        <videoList :showvideolist.sync="showvideolist"></videoList>
      </el-tab-pane> -->
      <!-- 视频列表结束 -->
      <el-tab-pane style="height: 100%" name="1">
        <span slot="label">
          <el-tooltip effect="dark" content="退出" placement="right">
            <i class="el-icon-switch-button" style="font-size: 20px"></i>
          </el-tooltip>
        </span>
      </el-tab-pane>
      <!-- 我的下级开始 -->
      <el-tab-pane label="" v-if="get_own_roles != 0" style="text-align: left; padding: 10px" name="ten">
        <span slot="label">
          <el-tooltip effect="dark" content="我的组员" placement="right-start">
            <i class="el-icon-s-custom" style="font-size: 20px"></i>
          </el-tooltip>
        </span>
        <talkLowerLevel :gettalkLowerLevel.sync="gettalkLowerLevel" :get_own_roles="get_own_roles" :serve="serve" @lookuser="lookuser" style="height: 100vh"></talkLowerLevel>
      </el-tab-pane>
      <!-- 我的下级结束 -->
      <!-- 班长转移服务人开始 -->
      <el-tab-pane label="" v-if="get_own_roles != 0" style="text-align: left; padding: 10px" name="eleven">
        <span slot="label">
          <el-tooltip effect="dark" content="转移服务人" placement="right-start">
            <i class="el-icon-sort" style="font-size: 20px; transform: rotate(90deg)"></i>
          </el-tooltip>
        </span>
        <talkchangeservice></talkchangeservice>
      </el-tab-pane>
      <!-- 班长转移服务人结束 -->
      <!-- 标记开始 -->
      <el-tab-pane label="" style="text-align: left; padding: 10px" name="fourteen">
        <span slot="label">
          <el-tooltip effect="dark" content="标记诊单" placement="right-start">
            <i class="el-icon-s-flag" style="font-size: 20px"></i>
          </el-tooltip>
        </span>
        <talkflagsheetmark></talkflagsheetmark>
      </el-tab-pane>
      <!-- 标记结束 -->
    </el-tabs>
    <!-- 查看大图开始 -->
    <el-dialog title="查看图片" :visible.sync="bigpic">
      <img :src="bigpicurl" alt="" width="90%" />
    </el-dialog>
    <!-- 查看大图结束 -->
    <!-- 用户建档弹窗开始 -->
    <el-dialog title="用户档案" width="40%" :visible.sync="searchUserID" class="zhendan" :close-on-click-modal="false">
      <!-- 用户在没有建档时候的操作 -->
      <div v-if="havedangan">
        <p>该用户在系统内没有档案，请先创建档案！</p>
        <el-button type="primary" style="margin-left: 20px" @click="newcust">新建</el-button>
      </div>
      <div v-else>
        <p>用户在系统内已经建立档案！</p>
      </div>
    </el-dialog>
    <!-- 用户建档弹窗结束 -->
    <!-- 用户信息弹窗开始 -->
    <el-dialog title="用户信息" class="usermsgtc" :visible.sync="lookusermsg" width="60%">
      <!-- <div>用户ID：{{ LookUserMsg.Cust_ID }}</div>
      <div>
        姓名：{{ LookUserMsg.Cust_Name }}
        <span
          class="changeBtn"
          @click="
            CUM = true;
            CUMname = LookUserMsg.Cust_Name;
            CUMsex = LookUserMsg.Cust_Sex + '';
            CUMage = LookUserMsg.Cust_Age;
          "
          >修改</span
        >
      </div>
      <div>性别：{{ LookUserMsg.Cust_Sex == 0 ? "男" : "女" }}</div>
      <div>年龄：{{ LookUserMsg.Cust_Age }}</div>
      <div>
        状态：{{ changeStatus }}
        <span class="changeBtn" @click="changeStatustc = true">修改</span>
      </div>
      <div>
        疾病：{{ Ill_Lib }}
        <span class="changeBtn" @click="bindIll">修改</span>
        <span class="changeBtn" @click="delIll">置空</span>
      </div>
      <div>
        医生：{{ Doc_Name }}
        <span class="changeBtn" @click="bindDoc">修改</span>
        <span class="changeBtn" @click="delDoc">置空</span>
      </div>
      <div>
        备注：{{ LookUserMsgtalk.desc }}
        <span class="changeBtn" @click="setNotes('msglist')">修改</span>
      </div>
      <div>
        下次联系时间：{{ LookUserMsgtalk.nextConcatTime | capitalize }}
        <span class="changeBtn" @click="talkSetNextContactTime()">设置</span>
      </div>
      <div>联系内容：{{ LookUserMsgtalk.nextConcatContent }}</div>
      <div>其他备注：{{ LookUserMsgtalk.notes }}</div> -->
      <div class="friendlistusermsg">
        <div>用户ID：{{ LookUserMsg.Cust_ID }}</div>
        <div>
          姓名：{{ LookUserMsg.Cust_Name }}
          <span v-if="lowerright" class="changeBtn" @click="
              CUM = true;
              CUMname = LookUserMsg.Cust_Name;
              CUMsex = LookUserMsg.Cust_Sex + '';
              CUMage = LookUserMsg.Cust_Age;
              CUMheight = LookUserMsg.Cust_Height;
              CUMweight = LookUserMsg.Cust_Weight;
            ">修改</span>
        </div>
        <div>性别：{{ LookUserMsg.Cust_Sex == 0 ? "男" : "女" }}</div>
        <div>年龄：{{ LookUserMsg.Cust_Age }}</div>
        <div>身高：{{ LookUserMsg.Cust_Height }}cm</div>
        <div>体重：{{ LookUserMsg.Cust_Weight }}kg</div>
        <div>
          状态：{{ changeStatus }}
          <span class="changeBtn" v-if="lowerright" @click="changeStatustc = true">修改</span>
        </div>
        <div>
          疾病：{{ Ill_Lib }}
          <span class="changeBtn" v-if="lowerright" @click="bindIll">修改</span>
          <span class="changeBtn" v-if="lowerright" @click="delIll">置空</span>
        </div>
        <div>
          医生：{{ Doc_Name }}
          <!-- <span class="changeBtn" v-if="lowerright" @click="bindDoc">修改</span>
          <span class="changeBtn" v-if="lowerright" @click="delDoc">置空</span> -->
        </div>
      </div>
      <el-divider></el-divider>
      <!-- 设置备注 -->
      <div class="friendlistusermsg">
        <div class="friendlistbeizhu">
          备注：{{ LookUserMsgtalk.desc }}
          <span class="changeBtn" v-if="lowerright" @click="setNotes('usercard', LookUserMsgtalk.desc)">修改</span>
        </div>
        <div class="friendlistbeizhu">
          等级：{{ LookUserlivinglevel }}
          <span class="changeBtn" @click="setLevelNote()">修改</span>
        </div>
      </div>
      <el-divider></el-divider>
      <!-- 下次联系时间 -->
      <!-- <div class="friendlistusermsg">
        <div class="friendlistbeizhu">
          下次联系时间：{{ LookUserMsgtalk.nextConcatTime | capitalize }}
          <span
            class="changeBtn"
            v-if="lowerright"
            @click="talkSetNextContactTime(talkitem)"
            >设置</span
          >
        </div>
        <div class="friendlistbeizhu">
          联系内容：{{ LookUserMsgtalk.nextConcatContent }}
        </div>
        <div class="friendlistbeizhu">
          其他备注：{{ LookUserMsgtalk.notes }}
        </div>
      </div> -->
      <!-- <el-divider></el-divider> -->
      <!-- 标签 -->
      <div class="friendlistbeizhu">
        标签：
        <span v-for="(item, index) in friendlistusertag" :key="index">{{ item.tag }}、</span>
        <span class="changeBtn" v-if="lowerright" @click="talksettag(talkitem)">设置标签</span>
      </div>
      <el-divider></el-divider>
      <div class="friendlistwanttalkbtn">
        <!-- <el-button @click="wanttalk()" type="success">发消息</el-button> -->
        <el-button @click="userlookxy()" type="primary" plain>查看寻医</el-button>
        <el-button @click="userlookzd()" type="primary" plain>查看诊单</el-button>
        <el-button @click="userlookfk()" type="primary" plain>查看反馈</el-button>
        <el-button @click="userlookzf()" type="primary" plain>查看诊费</el-button>
        <el-button @click="userlookdd()" type="primary" plain>查看订单</el-button>
        <el-button @click="userlookfoot()" type="primary" plain>查看浏览足迹</el-button>
      </div>
    </el-dialog>
    <!-- 用户信息弹窗结束 -->
    <!-- 推送医生弹窗开始 -->
    <el-dialog title="推送医生" :visible.sync="pushDoc" width="60%">
      <div style="width: 100%; height: auto; float: left">
        <el-input placeholder="请输入精确的姓名或电话号" clearable prefix-icon="el-icon-search" v-model="pusgDocSearch" style="width: 26%; float: left" @clear="cleardoc">
        </el-input>
        <el-button type="primary" style="float: left; margin-left: 20px" @click="sendDocQuery">查询</el-button>
      </div>
      <div style="
          width: 100%;
          height: 40px;
          line-height: 40px;
          float: left;
          text-align: left;
        ">
        您已选择的医生：{{ selectDocList }}
      </div>
      <el-table :data="doclist" border style="width: 100%; margin-top: 40px" tooltip-effect="dark" @selection-change="handleSelectionChange" :row-key="
          (row) => {
            return row.id;
          }
        " ref="multipleTable">
        <el-table-column type="selection" :reserve-selection="true" width="55"></el-table-column>
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="age" label="年龄"></el-table-column>
        <el-table-column prop="sex" label="性别"></el-table-column>
        <el-table-column prop="hospital" label="所在医院"></el-table-column>
        <el-table-column prop="rank" label="职称"></el-table-column>
        <el-table-column prop="goodat" label="擅长治疗"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toggleSelection()">取 消</el-button>
        <el-button type="primary" @click="subDoc">发 送</el-button>
      </span>
    </el-dialog>
    <!-- 推送医生弹窗结束 -->
    <!-- 设置备注弹窗开始 -->
    <el-dialog title="设置备注" :visible.sync="setDesc" width="30%">
      <el-form :model="help" label-width="100px">
        <el-form-item label="请输入备注">
          <el-input type="text" v-model="setdesc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setDesc = false">取 消</el-button>
        <el-button type="primary" @click="subsetDesc">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设置备注弹窗结束 -->
    <!-- 设置等级弹窗开始 -->
    <el-dialog title="设置等级" :visible.sync="setLevel" width="30%">
      <el-form :model="help" label-width="100px">
        <el-select v-model="setlevelval" clearable placeholder="请选择">
          <el-option v-for="item in leveloptions" :key="item.id" :label="item.label" :value="item.id">
          </el-option>
        </el-select>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setLevel = false">取 消</el-button>
        <el-button type="primary" @click="subsetLevel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设置等级弹窗结束 -->
    <!-- 修改状态弹窗开始 -->
    <el-dialog title="修改状态" :visible.sync="changeStatustc" width="30%">
      <el-select v-model="changeStatusVal" clearable placeholder="请选择">
        <el-option v-for="item in statusoptions" :key="item.id" :label="item.label" :value="item.id">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(changeStatustc = false), (changeStatusVal = '')">取 消</el-button>
        <el-button type="primary" @click="subchangeStatus">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改状态弹窗结束 -->
    <!-- 绑定疾病弹窗开始 -->
    <el-dialog title="绑定疾病" :visible.sync="Bind_Ill" width="30%">
      <p>
        <el-input placeholder="请输入疾病名" clearable prefix-icon="el-icon-search" v-model="searchill" style="width: 30%" @clear="clearill">
        </el-input>
        <el-button type="primary" style="margin-left: 20px" @click="queryill">查询</el-button>
      </p>
      <el-radio-group v-model="bindillcheckbox" @change="getillval">
        <el-radio style="width: 100%; height: 50px; line-height: 50px" v-for="(item, index) in bindilllist" :label="item" :key="index">{{ item.name }}</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Bind_Ill = false">取 消</el-button>
        <el-button type="primary" @click="subbindill">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 绑定疾病弹窗结束 -->
    <!-- 绑定医生弹窗开始 -->
    <el-dialog title="绑定医生" :visible.sync="Bind_Doc" width="50%">
      <p>
        <el-input placeholder="请输入医生姓名" clearable prefix-icon="el-icon-search" v-model="searchdoc" style="width: 40%" @clear="cleardoc">
        </el-input>
        <el-button type="primary" style="margin-left: 20px" @click="querydoc">查询</el-button>
      </p>
      <el-radio-group v-model="binddoccheckbox" @change="getdocval">
        <el-radio style="width: 100%; height: 50px; line-height: 50px" v-for="(item, index) in binddoclist" :label="item" :key="index">{{ item.name }}</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Bind_Doc = false">取 消</el-button>
        <el-button type="primary" @click="subbinddoc">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 绑定医生弹窗结束 -->
    <!-- 寻医管理弹窗开始 -->
    <el-dialog title="寻医表" width="80%" :visible.sync="choicexunyi" class="zhendan" :close-on-click-modal="false">
      <el-tabs v-model="xyactive" class="xytab" @tab-click="selectxunyitab">
        <el-tab-pane label="新建寻医表" name="xyfirst">
          <!-- 用户有档案但是没有问寻医  @tab-click="handleClick"-->
          <div class="newzd" style="
              margin: 0 auto;
              margin-top: 20px;
              overflow: hidden;
              text-align: left;
            ">
            <el-select size="small" v-model="selectxydoc" clearable filterable placeholder="请选择寻医表" @change="selectxunyi" class="changewidth" style="margin-right: 20px; width: 200px">
              <el-option v-for="item in optionsxy" :key="item.S_ID" :label="item.S_Name" :value="item.S_ID">
              </el-option>
            </el-select>
            <el-button type="primary" size="small" @click="newxy">新建</el-button>
            <!-- <span style="width: 240px">
              <el-select
                size="small"
                v-model="selectxydoc"
                clearable
                @clear="clearselectxydoc"
                filterable
                placeholder="请选择医生"
                @change="selectxydocchange"
                class="changewidth"
                style="margin-right: 20px; width: 200px"
              >
                <el-option
                  v-for="item in selectxydocmsg"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </span> -->
            <!-- 或 -->
            <!-- <span style="width: 240px">
              <el-input
                size="small"
                clearable
                @clear="clearselectxydoc"
                v-model="searchxymuban"
                placeholder="请输入模板关键字"
                class="changewidth"
                style="margin-right: 20px; margin-left: 20px; width: 200px"
              >
              </el-input>
            </span> -->
            <!-- <el-button
              type="primary"
              style="margin-right: 20px"
              size="small"
              @click="searchxymb"
              >查询</el-button
            > -->
            <!-- <span style="width: 240px;" v-if="isshowselectxy">
              <el-select size="small" v-model="selectxy" filterable placeholder="请选择寻医表模板" @change="selectxunyi" class="changewidth" style="margin-right: 20px; width: 200px;">
                <el-option v-for="item in optionsxy" :key="item.S_ID" :label="item.S_Name" :value="item.S_ID">
                </el-option>
              </el-select>
            </span> -->
            <!-- <p v-if="selectxydocmsg.length>0">
              <el-radio-group v-model="selectitemxy" @change="selectxunyi" class="xunyichoicedoc">
                <el-radio v-for="item in selectxydocmsg" :label="item.id">{{item.name}}</el-radio>
              </el-radio-group>
            </p> -->
            <!-- <el-table
              :data="xymubanlist"
              v-if="xymubanlist.length > 0"
              border
              style="width: 100%; margin-top: 10px"
            >
              <el-table-column prop="S_ID" label="模板编号"></el-table-column>
              <el-table-column
                prop="S_Name"
                label="寻医表名称"
              ></el-table-column>
              <el-table-column
                prop="Doc_Name"
                label="关联医生"
              ></el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <div class="opt">
                    <a @click="newxy(scope.row)">下发</a>
                  </div>
                </template>
              </el-table-column>
            </el-table> -->
            <!-- <el-button type="primary" size="small" @click="newxy">新建</el-button> -->
          </div>
        </el-tab-pane>
        <el-tab-pane label="查看历史寻医表" name="xysecond">
          <!-- 用户有档案并且有诊单 -->
          <el-table :data="xylist" v-if="xylist.length > 0" border style="width: 100%; margin-top: 10px">
            <el-table-column prop="Creat_DT" label="时间" width="160">
              <template slot-scope="scope">
                <span>{{ scope.row.Creat_DT.split(".")[0] }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="KID" label="寻医表编号" width="160"></el-table-column>
            <el-table-column prop="Dis_Name" label="寻医表名称" width="260"></el-table-column>
            <el-table-column prop="Cust_ID" label="用户id" width="120"></el-table-column>
            <el-table-column prop="State" label="状态" sortable width="80">
              <template slot-scope="scope">
                <span v-if="scope.row.State == 0" style="color: #e6a23c">未提交</span>
                <span v-if="scope.row.State == 1" style="color: #409eff">待挂号</span>
                <span v-if="scope.row.State == 2" style="color: #67c23a">已抓取</span>
                <span v-if="scope.row.State == 6" style="color: #c709f7">审核</span>
                <span v-if="scope.row.State == 9" style="color: #f56c6c">删除</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="220" fixed="right">
              <template slot-scope="scope">
                <div class="opt">
                  <a @click="NewXYEdit(scope.row, 'look')">查看</a>
                  <el-link type="success" :disabled="scope.row.State != 0 && scope.row.State != 1" @click="NewXYEdit(scope.row, '')">
                    编辑</el-link>
                  <a @click="choicexy(scope.row)">下发</a>
                  <el-link type="success" :disabled="
                      scope.row.State == 6 ||
                      scope.row.State == 9 ||
                      scope.row.State == 2
                    " @click="XYrepulse(scope.row)">打回</el-link>
                  <a v-if="scope.row.State == 1" @click="XYshenhe(scope.row)">审核</a>
                  <!-- <a v-if="scope.row.State == 1" @click="assigDoc(scope.row)">审核并分配</a> -->
                </div>
              </template>
            </el-table-column>
          </el-table>
          <p class="paging">
            <el-pagination @size-change="handleSizeChangexy" @current-change="handleCurrentChangexy" :current-page="currentxy" :page-sizes="pageSizesxy" :page-size="PageSizexy" layout="total, sizes, prev, pager, next, jumper" :total="totalCountxy" :hide-on-single-page="valuexy">
            </el-pagination>
          </p>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!-- 寻医管理弹窗结束 -->
    <!-- 新的选择诊单弹窗 -->
    <el-dialog title="问诊表" width="80%" :visible.sync="Newchoicezd" class="zhendan" :close-on-click-modal="false">
      <el-tabs v-model="zdactive" class="zdtab" @tab-click="selectzhendantab">
        <el-tab-pane label="新建问诊表" name="zdfirst">
          <!-- 用户有档案但是没有问诊单 -->
          <div class="newzd" style="margin-top: 20px; text-align: left">
            请选择问诊单模板：
            <el-select v-model="Newselectzd" filterable placeholder="请选择问诊单模板" @change="Newselect" class="changewidth" style="margin-right: 20px; width: 200px">
              <el-option v-for="item in Newoptions" :key="item.KDis_ID" :label="item.KDis_Name + '  ' + item.doc_name" :value="item.KDis_ID">
              </el-option>
            </el-select>
            <el-button type="primary" @click="NewZhenDan">新建</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="查看历史问诊表" name="zdsecond">
          <!-- 用户有档案并且有诊单 -->
          <el-table :data="Newzdlist" v-if="Newzdlist.length > 0" border style="width: 100%; margin-top: 10px" v-loading="pdfloading" element-loading-text="正在生成PDF，请稍后...">
            <el-table-column prop="Creat_DT" label="时间" width="160">
              <template slot-scope="scope">
                <span>{{ scope.row.Creat_DT.split(".")[0] }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="KID" label="问诊单编号" width="160"></el-table-column>
            <el-table-column prop="Dis_Name" label="问诊单名称" width="260"></el-table-column>
            <el-table-column prop="Cust_ID" label="用户id" width="120"></el-table-column>
            <el-table-column prop="State" label="状态" sortable width="80">
              <template slot-scope="scope">
                <span v-if="scope.row.State == 0" style="color: #e6a23c">新建</span>
                <span v-if="scope.row.State == 1" style="color: #409eff">提交</span>
                <span v-if="scope.row.State == 2" style="color: #c709f7">审核</span>
                <span v-if="scope.row.State == 3" style="color: #ff0033">打回</span>
                <span v-if="scope.row.State == 6" style="color: #67c23a">已下发</span>
                <span v-if="scope.row.State == 9" style="color: #f56c6c">已删除</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="180">
              <template slot-scope="scope">
                <div class="opt">
                  <a @click="NewchoiceEdit(scope.row, 'look')">查看</a>
                  <el-link type="success" :disabled="scope.row.State != 0 && scope.row.State != 1" @click="NewchoiceEdit(scope.row)">
                    编辑</el-link>
                  <a @click="Newchoice(scope.row)">下发</a>
                  <el-link type="success" :disabled="scope.row.State == 6 || scope.row.State == 9" @click="Newrepulse(scope.row)">打回</el-link>
                  <a v-if="scope.row.State == 1" @click="TJshenhe(scope.row, 'user')">审核</a>
                  <el-link type="success" v-if="scope.row.State == 6" @click="shengchengpdf(scope.row)">生成pdf</el-link>
                  <a v-if="scope.row.State == 6" @click="
                      lookdrugtc = true;
                      drugkid = scope.row.KID;
                      drugcustid = scope.row.Cust_ID;
                      drugcusttel = toID;
                    ">订单</a>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <p class="paging">
            <el-pagination @size-change="handleSizeChangezd" @current-change="handleCurrentChangezd" :current-page="currentzd" :page-sizes="pageSizeszd" :page-size="PageSizezd" layout="total, sizes, prev, pager, next, jumper" :total="totalCountzd" :hide-on-single-page="valuezd">
            </el-pagination>
          </p>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!-- 新的选择诊单弹窗结束 -->
    <!-- 编辑寻医弹窗开始 -->
    <el-dialog title="编辑寻医表" :modal="false" :closeOnClickModal="false" :visible.sync="EditXY" width="566px">
      <xypreview :kid="EditXYMsg.kid" :custid="EditXYMsg.custid" v-if="EditXY" :EditZD="EditXY" :noedit="EditXYMsg.noedit" :isshenhe="isshenhe" :ishelp="ishelp" @choicexy="choicexy"></xypreview>
    </el-dialog>
    <!-- 编辑寻医弹窗结束 -->
    <!-- 编辑诊单开始:shenhe="shenhe" -->
    <el-dialog title="编辑问诊表" :modal="false" :visible.sync="EditZD" width="566px">
      <preview :kid="EditMsg.kid" :custid="EditMsg.custid" v-if="EditZD" :EditZD="EditZD" :noedit="EditMsg.noedit" @subTJshenhe="subTJshenhe" :shenhe.sync="shenhe" ref="uppdf"></preview>
    </el-dialog>
    <!-- 编辑诊单结束 -->
    <!-- 右键菜单开始 -->
    <div id="rightmenu" v-show="visible">
      <div @click="setuserfilescustidopen()">客户编号建档</div>
      <div @click="setuserfiles()">手机号建档</div>
      <div @click="searchkehu()">查询档案</div>
      <!-- <div @click="lookusermag()">用户信息</div> -->
      <div @click="lookuser('', 'right')">用户信息</div>
      <div @click="setNotes('msglist', '')">设置备注</div>
      <div @click="talksettag()">设置标签</div>
      <!-- <div @click="talkSetNextContactTime()">下次联系时间</div> -->
      <div @click="deltalkuser()">删除聊天</div>
      <div @click="clearnoread()">清除未读</div>
      <div @click="rightmenusetnextcontenttime()">下次联系时间</div>
    </div>
    <!-- 右键菜单结束 -->
    <!-- 来消息提示音 -->
    <audio :src="msg" id="music" muted="true"></audio>
    <!-- 统计页面的临时对话 -->
    <el-dialog title="临时对话" :visible.sync="LSHH" width="50%" class="LSTIPS">
      <div class="talkmain">
        <div class="talkname">{{ LSHHROW.name }}</div>
        <p class="lookhistory" v-if="lookmoreshow" @click="LSloadmore">
          查看历史记录
        </p>
        <div class="talk-main" id="scrolldIV1" style="height: 400px">
          <div class="talkline" v-for="(item, index) in talklist" :key="index">
            <div class="timeline">{{ item.sendTime }}</div>
            <div class="leftimg" v-if="item.from == toID">
              <img :src="userheadimg" alt="" />
            </div>
            <div class="talkleft" v-if="item.from == toID">
              <span>
                <i v-if="item.type == '0'">{{ item.content }}</i>
                <i v-if="item.type == '1'" @click="big(item.content)">
                  <img :src="item.content" alt="" />
                </i>
                <i v-if="item.type == '2'">诊单名：{{ JSON.parse(item.content).name }}；诊单id：{{
                    JSON.parse(item.content).id
                  }}</i>
              </span>
            </div>
            <div class="rightimg" v-if="item.from != toID">
              <img :src="headimg" alt="" />
            </div>
            <div class="talkright" v-if="item.from != toID">
              <span>
                <i v-if="item.type == '0'"><b>{{ item.content }}</b></i>
                <i v-if="item.type == '1'" @click="big(item.content)">
                  <img :src="item.content" alt="" />
                </i>
                <i v-if="item.type == 3">
                  <div style="width: 220px">
                    <p>寻医报名表</p>
                    <p>
                      您好，为了便于更好的帮您寻找擅长治疗您疾病的好医生，请点击对话框，详细填写报名表。
                    </p>
                    <p style="display: none"></p>
                  </div>
                </i>
                <i v-if="item.type == '2'">
                  <div style="width: 100px">
                    <p>问诊单:{{ JSON.parse(item.content).name }}</p>
                    <p>诊单编号:{{ JSON.parse(item.content).id }}</p>
                    <p @click="
                        EditZD = true;
                        EditMsg.kid = JSON.parse(item.content).id;
                      ">
                      查看详情>
                    </p>
                  </div>
                </i>
                <i v-if="item.type == 4">
                  <div style="width: 300px">
                    <p>精准匹配</p>
                    <div>
                      <div class="talkdocline" v-for="(it, index) in JSON.parse(item.content)" :key="index">
                        <img :src="it.headimg" alt="" />
                        <div class="wenzi">
                          <p class="wenzidocname">
                            {{ it.name }}<span>{{ it.rank }}</span>
                          </p>
                          <p class="wenzihospatil">{{ it.hospital }}</p>
                          <p class="wenzigoodat">
                            擅长：
                            <span>{{ it.goodat }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <p style="display: none"></p>
                  </div>
                </i>
                <i v-if="item.type == '5'">
                  <div style="width: 220px">
                    <p>寻医问诊表</p>
                    <p>
                      您好，为了便于帮您精准匹配好医生，请点击此对话框，详细填写寻医问诊表。
                    </p>
                    <p @click="
                        EditXY = true;
                        EditXYMsg.kid = JSON.parse(item.content).id;
                      ">
                      查看详情>
                    </p>
                  </div>
                </i>
                <i v-if="item.type == '6'">
                  <div style="width: 220px">
                    <p>
                      {{
                        JSON.parse(item.content).doc_name
                      }}&nbsp;医生&nbsp;问诊单
                    </p>
                    <p>
                      您好，为了让匹配的好医生详细了解您的病情信息，给出准确治疗方案，请点击此对话框，详细填写医生问诊单。
                    </p>
                    <p @click="
                        EditZD = true;
                        EditMsg.kid = JSON.parse(item.content).id;
                      ">
                      查看详情>
                    </p>
                  </div>
                </i>
                <i v-if="item.type == '7'">
                  <div style="width: 220px">
                    <p>频道名:{{ JSON.parse(item.content).channel }}</p>
                    <p>ID:{{ JSON.parse(item.content).id }}</p>
                    <p @click="lookchanneldetail(JSON.parse(item.content))">
                      查看详情>
                    </p>
                  </div>
                </i>
                <i v-if="item.type == '8'">
                  <div style="width: 220px">
                    <p style="background: #ff8040">支付问诊费用</p>
                    <p>
                      您好，在线医助给您发来了支付问诊费用请求，点击开始支付。
                    </p>
                    <p @click="lookzfdetail(JSON.parse(item.content).id, 'zf')">
                      查看详情>
                    </p>
                  </div>
                </i>
                <i v-if="item.type == '9'">
                  <div style="width: 220px">
                    <p style="background: #ff8040">支付订单费用</p>
                    <p>订单ID:{{ JSON.parse(item.content).yforderno }}</p>
                    <p>ID:{{ JSON.parse(item.content).id }}</p>
                    <p @click="lookzfdetail(JSON.parse(item.content).id, 'dd')">
                      查看详情>
                    </p>
                  </div>
                </i>
                <i v-if="item.type == '10'">
                  <div style="width: 300px">
                    <p>好转病例</p>
                    <div class="talkdocline" style="border-bottom: none">
                      <img :src="JSON.parse(item.content).headimg" alt="" />
                      <div class="wenzi">
                        <p class="wenzidocname">
                          {{ JSON.parse(item.content).name
                          }}<span>{{ JSON.parse(item.content).ill_name }}</span>
                        </p>
                        <p class="wenzigoodat">
                          <span>{{ JSON.parse(item.content).des }}</span>
                        </p>
                      </div>
                    </div>
                    <p style="display: none"></p>
                  </div>
                </i>
                <i v-if="item.type == '11'">
                  <div style="width: 220px">
                    <p></p>
                    <p>
                      {{ JSON.parse(item.content).name }}给患者:{{
                        changetel(JSON.parse(item.content).id)
                      }}发送了留言短信。
                    </p>
                    <p></p>
                  </div>
                </i>
                <i v-if="item.type == '13'">
                  <div style="width: 220px">
                    <p>反馈表</p>
                    <p>您好，为了便于您以后的治疗请认真填写反馈信息表。</p>
                    <p @click="
                        lookfeedback = true;
                        feedbackid = JSON.parse(item.content).id;
                      ">
                      查看详情>
                    </p>
                  </div>
                </i>
              </span>
            </div>
          </div>
        </div>
        <div class="talk-main-text">
          <div style="width: 100%; overflow: hidden; position: relative">
            <div style="width: 78%; float: left; box-sizing: border-box">
              <el-input class="talk-text" type="textarea" v-model="message" resize="none" id="cOthersResponsible" @focus="focusallfalse" :autosize="{ minRows: 1, maxRows: 8 }"></el-input>
            </div>
            <div style="
                width: 22%;
                float: left;
                padding-top: 14px;
                position: absolute;
                left: 78%;
                bottom: 4px;
              ">
              <div class="send" v-if="showsend">
                <el-button size="mini" @click="send()">发送</el-button>
              </div>
              <div class="imgbtn" @click="showemoji">
                <img :src="smileicon" alt="" class="smilebtn" />
              </div>
              <div class="imgbtn" @click="showmenu" v-if="showplus">
                <i class="el-icon-circle-plus-outline"></i>
              </div>
            </div>
          </div>
          <div class="btnlinefather">
            <div class="btnline" v-show="plus">
              <div class="btnk">
                <div class="graybtn">
                  <div class="imgbtn c1">
                    <!-- <i class="el-icon-picture-outline"></i> -->
                    <img :src="talktp" alt="" />
                    <el-upload class="avatar-uploader" action="    " :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :http-request="upload">
                      <img v-if="picsrc" :src="picsrc" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </div>
                </div>
                <span>图片</span>
              </div>
              <!-- <div class="btnk">
        <div class="graybtn">
         <div class="imgbtn c2" @click="querydoc1">
          <i class="el-icon-s-custom"></i>
         </div>
        </div>
        <span>医生</span>
       </div> -->
              <!-- <div class="btnk">
        <div class="graybtn">
         <div class="imgbtn c2" @click="userbaoming">
          <i class="el-icon-document-checked"></i>
         </div>
        </div>
        <span>报名</span>
       </div> -->
              <div class="btnk">
                <div class="graybtn">
                  <div class="imgbtn c2" @click="medicalSeek">
                    <img :src="talkxy" alt="" />
                  </div>
                </div>
                <span>寻医</span>
              </div>
              <div class="btnk">
                <div class="graybtn">
                  <div class="imgbtn c1" @click="newList">
                    <!-- <i class="el-icon-document"></i> -->
                    <img :src="talkzd" alt="" />
                  </div>
                </div>
                <span>诊单</span>
              </div>
              <!-- <div class="btnk">
        <div class="graybtn">
         <div class="imgbtn c2" @click="money">
          <i class="el-icon-wallet"></i>
         </div>
        </div>
        <span>挂号</span>
       </div> -->
              <!-- <div class="btnk">
        <div class="graybtn">
         <div class="imgbtn c2" @click="launchVideo">
          <i class="el-icon-video-camera"></i>
         </div>
        </div>
        <span>直播</span>
       </div>
       <div class="btnk">
        <div class="graybtn">
         <div class="imgbtn c2" @click="placeorder">
          <i class="el-icon-s-order"></i>
         </div>
        </div>
        <span>订单</span>
       </div> -->
            </div>
          </div>
          <div class="emotionList" v-show="emoji1">
            <a href="javascript:void(0);" @click="getEmo(index)" v-for="(item, index) in faceList" :key="index" class="emotionItem">{{ item }}</a>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 修改用户MSG开始 -->
    <el-dialog title="修改用户信息" :visible.sync="CUM" width="30%">
      <el-form label-width="80px">
        <el-form-item label="姓名">
          <el-input v-model="CUMname"></el-input>
        </el-form-item>
        <el-form-item label="性别" style="text-align: left">
          <el-radio v-model="CUMsex" label="0">男</el-radio>
          <el-radio v-model="CUMsex" label="1">女</el-radio>
        </el-form-item>
        <el-form-item label="年龄">
          <el-input v-model="CUMage" type="number" placeholder="请输入数字"></el-input>
        </el-form-item>
        <el-form-item label="身高">
          <el-input v-model="CUMheight" type="number" placeholder="请输入数字">
            <template slot="append">cm</template>
          </el-input>
        </el-form-item>
        <el-form-item label="体重">
          <el-input v-model="CUMweight" type="number" placeholder="请输入数字">
            <template slot="append">kg</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="ChangeUserMsg">确定</el-button>
          <el-button @click="
              CUM = false;
              CUMname = '';
              CUMsex = '0';
              CUMage = '';
            ">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 修改用户MSG结束 -->
    <!-- 电话号新建用户档案开始 -->
    <el-dialog title="新建用户档案" :visible.sync="setuserfilesbtn" width="30%">
      <el-form label-width="80px">
        <el-form-item label="电话号码">
          <el-input v-model="setuserfilestel"></el-input>
          <p style="
              font-size: 12px;
              color: #ccc;
              float: left;
              width: 100%;
              margin: 0;
              text-align: left;
            ">
            登录密码为电话号后6位。
          </p>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="subsetuserfiles">确定</el-button>
          <el-button @click="setuserfilesbtn = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 电话号新建用户档案结束 -->
    <!-- 用户编号新建用户档案开始 -->
    <el-dialog title="新建用户档案" :visible.sync="setuserfilesbtncustid" width="30%">
      <el-form label-width="80px">
        <el-form-item label="用户编号">
          <el-input v-model="setuserfilescustid"></el-input>
          <!-- <p style="font-size: 12px;color: #ccc;float: left;width: 100%;margin: 0;text-align:left;">
            登录密码为电话号后6位。
          </p> -->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="subsetuserfilescustid">确定</el-button>
          <el-button @click="setuserfilesbtncustid = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 用户编号新建用户档案结束 -->
    <!-- 发起会话选择医生开始 -->
    <talkvideo :VideoChoiceDoc.sync="VideoChoiceDoc" :toID1="toID1" :connection1="connection1" :nowtouser1="nowtouser1" :talklist="talklist" :list="list" :db="db" @changetalklist="changetalklist" @changelist="changelist"></talkvideo>
    <!-- 发起会话选择医生结束 -->
    <!-- 发送诊费开始 -->
    <sendmoney :sendM.sync="sendM" :toID="toID" :talkconnection="talkconnection" :talklist="talklist" :db="db" @sendMoneyChangeAlllist="sendMoneyChangeAlllist" @sendMoneyChangeTalklist="sendMoneyChangeTalklist">
    </sendmoney>
    <!-- 发送诊费结束 -->
    <!-- 发送订单开始 -->
    <sendorder :showsendorder.sync="showsendorder" :toID="toID" :talkconnection="talkconnection" :talklist="talklist" :db="db" @sendMoneyChangeAlllist="sendMoneyChangeAlllist" @sendMoneyChangeTalklist="sendMoneyChangeTalklist"></sendorder>
    <!-- 发送订单结束 -->
    <!-- 设置标签开始 -->
    <talksettag :settag.sync="settag" :tagId="tagId"></talksettag>
    <!-- 设置标签结束 -->
    <!-- 查看订单详情 -->
    <talklookorder :lookorder.sync="lookorder" :talkorderid="talkorderid" :talkordertype="talkordertype"></talklookorder>
    <!-- 查看订单详情 -->
    <!-- 查看用户寻医 -->
    <userxylist :lookuserxy.sync="lookuserxy" :friendlistcustid="friendlistcustid"></userxylist>
    <!-- 查看用户寻医 -->
    <!-- 查看用户诊单 -->
    <userzdlist :lookuserzd.sync="lookuserzd" :talkitem="talkitem"></userzdlist>
    <!-- 查看用户诊单 -->
    <!-- 查看用户反馈 -->
    <userfklist :lookuserfk.sync="lookuserfk" :talkitem="talkitem"></userfklist>
    <!-- 查看用户反馈 -->
    <!-- 查看用户诊费 -->
    <usermoneylist :lookuserzf.sync="lookuserzf" :friendlistcustid="friendlistcustid"></usermoneylist>
    <!-- 查看用户诊费 -->
    <!-- 查看用户订单 -->
    <userorderlist :lookuserdd.sync="lookuserdd" :friendlistcustid="friendlistcustid"></userorderlist>
    <!-- 查看用户订单 -->
    <!-- 寻医表客服给分配医生 -->
    <talkassigDoc :assigdoctor.sync="assigdoctor" :assigdoctoritem="assigdoctoritem"></talkassigDoc>
    <!-- 寻医表客服给分配医生 -->
    <!-- 给患者发送反馈视频 -->
    <talksendcase :sendcase.sync="sendcase" :connection1="connection1" :toID="toID" :db="db" @sendMoneyChangeAlllist="sendMoneyChangeAlllist" @sendMoneyChangeTalklist="sendMoneyChangeTalklist"></talksendcase>
    <!-- 给患者发送反馈视频 -->
    <!-- 选择反馈表弹窗开始 -->
    <el-dialog title="反馈表" width="80%" :visible.sync="feedbacktc" class="feedback">
      <el-tabs v-model="fkactive" class="fktab" @tab-click="selectfankuitab">
        <el-tab-pane label="新建反馈表" name="fkfirst">
          <div style="margin-top: 20px; text-align: left; min-height: 200px">
            选择反馈表模板：
            <el-select filterable style="width: 200px; margin-right: 20px" @change="feedbackchange" v-model="feedbackvalue" placeholder="请选择反馈表模板">
              <el-option v-for="item in feedbackoptions" :key="item.Back_ID" :label="item.Back_Name" :value="item.Back_ID">
              </el-option>
            </el-select>
            <el-button type="primary" @click="sendfeedback">新建</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="查看历史反馈表" name="fksecond">
          <!-- 用户已有反馈表 -->
          <el-table :data="feedbacklist" border style="width: 100%; margin-top: 10px" v-loading="pdfloading" element-loading-text="正在生成PDF，请稍后...">
            <el-table-column prop="Creat_DT" label="时间" width="160">
              <template slot-scope="scope">
                <span>{{ scope.row.Creat_DT.split(".")[0] }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="KID" label="反馈表编号" width="150"></el-table-column>
            <el-table-column prop="Dis_Name" label="反馈表名称" width="260"></el-table-column>
            <el-table-column prop="Cust_ID" label="用户id" width="120"></el-table-column>
            <el-table-column prop="State" label="状态" sortable width="80">
              <template slot-scope="scope">
                <span v-if="scope.row.State == 0" style="color: #e6a23c">新建</span>
                <span v-if="scope.row.State == 1" style="color: #409eff">提交</span>
                <span v-if="scope.row.State == 2" style="color: #c709f7">审核</span>
                <span v-if="scope.row.State == 3" style="color: #ff0033">打回</span>
                <span v-if="scope.row.State == 6" style="color: #67c23a">已下发</span>
                <span v-if="scope.row.State == 9" style="color: #f56c6c">已删除</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180" fixed="right">
              <template slot-scope="scope">
                <div class="opt">
                  <a @click="
                      lookfeedback = true;
                      feedbackid = scope.row.KID;
                      feedbacknoedit = false;
                      shenhe = false;
                    ">查看</a>
                  <el-link type="success" :disabled="scope.row.State != 0 && scope.row.State != 1" @click="
                      lookfeedback = true;
                      feedbackid = scope.row.KID;
                      feedbacknoedit = true;
                      shenhe = false;
                    ">
                    编辑</el-link>
                  <a @click="feedbackxf(scope.row.KID)">下发</a>
                  <el-link type="success" :disabled="scope.row.State == 6" @click="changefeedbackstate('0', scope.row)">打回</el-link>
                  <a v-if="scope.row.State == 1" @click="fkshenhe(scope.row, 'user')">审核</a>
                  <el-link type="success" v-if="scope.row.State == 6" @click="shengchengpdf(scope.row)">生成pdf</el-link>
                  <a v-if="scope.row.State == 6" @click="
                      lookdrugtc = true;
                      drugkid = scope.row.KID;
                      drugcustid = scope.row.Cust_ID;
                      drugcusttel = toID;
                    ">订单</a>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <p class="paging">
            <el-pagination @size-change="handleSizeChangefk" @current-change="handleCurrentChangefk" :current-page="currentfk" :page-sizes="pageSizesfk" :page-size="PageSizefk" layout="total, sizes, prev, pager, next, jumper" :total="totalCountfk" :hide-on-single-page="valuefk">
            </el-pagination>
          </p>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!-- 选择反馈表弹窗结束 -->
    <!-- 查看反馈表详情开始 -->
    <feedback :lookfeedback.sync="lookfeedback" :feedbackid="feedbackid" :feedbacknoedit="feedbacknoedit" :shenhe.sync="shenhe" @fkshenhetcout="fkshenhetcout" ref="uploadfkpdf"></feedback>
    <!-- 查看反馈表详情结束 -->
    <!-- 设置下次联系时间开始 -->
    <setNextContactTime :TalkSetNextContactTime.sync="TalkSetNextContactTime" :ContactId="ContactId" :serve="serve" @changenextcontactTime="changenextcontactTime"></setNextContactTime>
    <!-- 设置下次联系时间结束 -->
    <!-- 查看订单 -->
    <lookdrug2 :lookdrugtc.sync="lookdrugtc" :drugkid="drugkid" :drugcustid="drugcustid" :drugcusttel="drugcusttel" :talkconnection="talkconnection" :toID="toID" :db="db" @sendMoneyChangeAlllist="sendMoneyChangeAlllist" @sendMoneyChangeTalklist="sendMoneyChangeTalklist" @drugtcfalse="drugtcfalse"></lookdrug2>
    <!-- 查看订单 -->
    <!-- 设置回复模板开始 -->
    <replytemplate :setreply.sync="setreply" v-bind:message="message" @suu="suu" :toID="toID" :db="db" :talkconnection="talkconnection" @sendMoneyChangeAlllist="sendMoneyChangeAlllist" @sendMoneyChangeTalklist="sendMoneyChangeTalklist"></replytemplate>
    <!-- 设置回复模板结束 -->
    <!-- 商城订单接收推送开始 -->
    <talkSCorderdetail :isshowscorder.sync="isshowscorder" :scorderid="scorderid"></talkSCorderdetail>
    <!-- 商城订单接收推送结束 -->
    <!-- 录音插件开始 -->
    <recorder :SoundRecording.sync="SoundRecording" :toID="toID" :db="db" :audioElement="audioElement" :talkconnection="talkconnection" @sendMoneyChangeAlllist="sendMoneyChangeAlllist" @sendMoneyChangeTalklist="sendMoneyChangeTalklist"></recorder>
    <!-- 录音插件结束 -->
    <!-- 给患者发送视频卡开始 -->
    <talkVideoCard :isshowVideoCard.sync="isshowVideoCard" :toID="toID"></talkVideoCard>
    <!-- 给患者发送视频卡结束 -->
    <!-- 查看患者足迹开始 -->
    <foot :lookfoot.sync="lookfoot" :footuserid="footuserid"></foot>
    <!-- 查看患者足迹结束 -->
    <!-- 下次联系时间 -->
    <el-dialog title="下次联系时间" width="50%" :visible.sync="rightmenusetnextcontenttimetc" class="feedback">
      <div class="friendlistusermsg">
        <div class="friendlistbeizhu">
          下次联系时间：{{ nextTCMsg.nextConcatTime | capitalize }}
          <span class="changeBtn" @click="talkSetNextContactTime('')">设置</span>
        </div>
        <div class="friendlistbeizhu">
          联系内容：{{ nextTCMsg.nextConcatContent }}
        </div>
        <div class="friendlistbeizhu">
          其他备注：{{ nextTCMsg.notes }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import "../assets/talkStyle.css";
import talkjs from "../assets/talk.js";
export default talkjs;
</script>

<style>
.main-broad {
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.el-main {
  padding: 0;
}
.el-tabs--left .el-tabs__header.is-left {
  font-size: 14px;
  height: 85vh;
  width: 180px;
  border-radius: 10px;
  margin-right: 0;
}

.el-tabs__item {
  padding: 0 30px;
  height: 35px;
}

.el-alert .el-alert__description {
  font-size: 14px;
}
* {
  box-sizing: border-box;
}
.desktop {
  height: 100vh;
  overflow: hidden;
}
.el-container {
  min-height: 0%;
}
body,
html {
  min-height: 0;
}
#talktabs .el-tabs__header {
  width: 60px;
  height: 100vh;
}
#talktabs .el-tabs__item {
  padding: 0 20px;
  margin-top: 5px;
}
</style>
