<template>
  <div style="padding:0;">
    <ul class="infinite-list" style="overflow: auto">
      <div
        v-for="item in fourdayfriendlist"
        class="zdyline infinite-list-item"
        @click="wanttalk1(item)"
      >
        <div style="cursor: pointer; height: 48px; float: left; width: 243px">
          <div class="zdyuserimg">
            <img :src="filterheadImg(item.headImg)" alt="" />
          </div>
          <div class="zdycustname" style="width: 180px">
            <span
              style="
                    width: 94px;
                    display: inline-block;
                    line-height: 14px;
                    vertical-align: middle;
                  "
              v-if="item.desc != '' && item.desc != null && item.desc != undefined"
              >{{ item.desc }}</span
            >
            <span
              style="
                    width: 94px;
                    display: inline-block;
                    line-height: 14px;
                    vertical-align: middle;
                  "
              v-else-if="item.name != '' && item.name != null && item.name != undefined"
              >{{ item.name | filtername(item.id) }}</span
            >
            <span
              style="
                    width: 94px;
                    display: inline-block;
                    line-height: 14px;
                    vertical-align: middle;
                  "
              v-else
              >待完善用户{{ item.id.substr(item.id.length - 4) }}</span
            >
            <span
              style="
                    width: 65px;
                    float: right;
                    font-size: 12px;
                    color: #808080;
                    transform: scale(0.8);
                    line-height: 15px;
                    margin-top: 10px;
                  "
            >
              <i style="display: inline-block">{{ item.createTime | capitalize }}</i>
              <i style="display: inline-block">{{ item.createTime | capitalize2 }}</i>
            </span>
          </div>
        </div>
      </div>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["serve"],
  watch: {
    serve(o, n) {
      if (o) {
        this.serveurl = o;
        this.fourdayfriendlist = [];
        this.axios
          .get(this.serveurl + "/api/User/today_allot?targetId=s" + this.sid)
          .then((res) => {
            // console.log(res);
            if (res.data.code == 1) {
              if (res.data.result.length > 0) {
                this.fourdayfriendlist = res.data.result;
              } else {
              }
            } else {
            }
          })
          .catch((res) => {
            console.log(res);
          });
      }
    },
  },
  data() {
    return {
      female: require("../assets/female.png"),
      male: require("../assets/male.png"),
      serveurl: "",
      fourdayfriendlist: [],
      sid: sessionStorage.getItem("id"),
    };
  },
  filters: {
    capitalize: function(value) {
      if (value == "0001-01-01 00:00:00") return "";
      if (!value) return "";
      value = value.substr(0, 10);
      return value;
    },
    capitalize2: function(value) {
      if (value == "0001-01-01 00:00:00") return "";
      if (!value) return "";
      value = value.substr(10, value.length);
      return value;
    },
    filtername: function(value, id) {
      if (value.indexOf("dxhs_") == 0) {
        return "待完善用户" + id.substr(id.length - 4);
      } else {
        return value;
      }
    },
  },
  mounted() {},
  methods: {
    //过滤头像
    filterheadImg(val) {
      var url = "";
      if (val == null || val == "" || val == undefined || val == "null") {
        url = this.male;
      } else {
        url = val;
      }
      return url;
    },
    wanttalk1(row) {
      this.$emit("wanttalk", row);
    },
  },
};
</script>
<style></style>
