<template>
  <div>
    <el-dialog title="查看反馈" :visible.sync="show" width="80%" @close="exit()">
      <el-table :data="list" border style="width: 100%; margin-top: 40px" :default-sort="{ prop: 'State' }">
        <el-table-column prop="Creat_DT" label="时间" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.Creat_DT.split(".")[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="KID" label="编号" width="160"></el-table-column>
        <el-table-column prop="Dis_Name" label="反馈单号"></el-table-column>
        <el-table-column prop="Cust_ID" label="用户id" width="120"></el-table-column>
        <el-table-column prop="State" label="状态" sortable width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.State == 0" style="color:#E6A23C;">新建</span>
            <span v-if="scope.row.State == 1" style="color:#409EFF;">提交</span>
            <span v-if="scope.row.State == 2" style="color:#C709F7;">审核</span>
            <span v-if="scope.row.State == 3" style="color:#FF0033">打回</span>
            <span v-if="scope.row.State == 6" style="color:#67C23A">已下发</span>
            <span v-if="scope.row.State == 9" style="color:#F56C6C;">已删除</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <div class="opt">
              <a @click="lookfk(scope.row)">查看</a>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <p class="paging">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value">
        </el-pagination>
      </p>
    </el-dialog>
    <feedback :lookfeedback.sync="lookfeedback" :feedbackid="feedbackid" :feedbacknoedit="feedbacknoedit"></feedback>
  </div>
</template>
<script>
import feedback from "../components/feedback.vue";
export default {
  props: ["lookuserfk", "talkitem"],
  components: {
    feedback,
  },
  data() {
    return {
      show: false,
      cust_id: "",
      list: [],
      lookfeedback: false,
      feedbackid: "",
      feedbacknoedit: true,
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [6], // 个数选择器（可修改）
      PageSize: 6, // 默认每页显示的条数（可修改）
      value: false,
    };
  },
  watch: {
    lookuserfk(o, n) {
      this.show = o;
    },
    talkitem(o, n) {
      this.getdata(1);
    },
  },
  mounted() {},
  methods: {
    getdata(page) {
      this.list = [];
      this.axios
        .post(
          "/gu/search_gu_custs",
          this.qs.stringify({ keyword: this.talkitem.id, doc_id: "" })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.cust_id = res.data.result[0].Cust_ID;
            this.axios
              .get(
                "/field/get_z_newback_main_by_custid?cust_id=" +
                  this.cust_id +
                  "&state=0126&page=" +
                  page +
                  "&size=" +
                  this.PageSize
              )
              .then((res) => {
                if (res.data.code == 0) {
                  this.list = res.data.result;
                  this.totalCount = res.data.count;
                }
              });
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    exit() {
      this.show = false;
      this.$emit("update:lookuserfk", false);
    },
    //编辑新诊单
    lookfk(row) {
      this.lookfeedback = true;
      this.feedbackid = row.KID;
      this.feedbacknoedit = false;
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage);
    },
  },
};
</script>
