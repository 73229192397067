<template>
  <div>
    <el-dialog title="创建预约" width="50%" :visible.sync="show" @close="hide()">
      <el-form label-width="100px" style="text-align: left;">
        <el-form-item label="请选择医生" style="margin-bottom: 8px;">
          <div>
            <el-input
              placeholder="请输入医生名"
              clearable
              prefix-icon="el-icon-search"
              v-model="searchdoc"
              style="width: 30%;"
              @clear="cleardoc"
            >
            </el-input>
            <el-button type="primary" style="margin-left:20px" @click="querydoc">查询</el-button>
          </div>
          <div>
            <el-radio-group v-model="binddoccheckbox" @change="selectdoc">
              <el-radio
                style="width:70px;height: 50px;line-height: 50px;"
                v-for="(item, index) in binddoclist"
                :label="item"
                :key="index"
                >{{ item.name }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="选择开始时间">
          <el-date-picker
            v-model="date"
            type="datetime"
            placeholder="选择日期时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="请添加描述">
          <el-input type="textarea" :rows="3" v-model="des"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hide()">取 消</el-button>
        <el-button type="primary" @click="VideoUpdate">确 定</el-button>
        <el-button
          type="primary"
          @click="
            roomlist = true;
            getdata(1);
          "
          >查询已有房间</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="房间列表" width="70%" :visible.sync="roomlist">
      <el-table :data="videolist" border style="width: 100%">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="channel_name" label="频道名"></el-table-column>
        <el-table-column label="用户ID" prop="cust_id"></el-table-column>
        <!-- <el-table-column label="医生" prop="channel_token"></el-table-column> -->
        <el-table-column label="医生" prop="doc_name"></el-table-column>
        <el-table-column label="医生ID" prop="doc_id"></el-table-column>
        <el-table-column label="创建时间" prop="create_time"></el-table-column>
        <el-table-column label="状态" prop="state">
          <template slot-scope="scope">
            <div class="opt doc">
              <span v-if="scope.row.state == 0">创建</span>
              <span v-if="scope.row.state == 5">结束</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="opt doc">
              <a @click="look(scope.row)">推送</a>
              <a @click="lookdetail(scope.row)">详情</a>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <p class="paging">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-sizes="pageSizes"
          :page-size="PageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
          :hide-on-single-page="value"
        >
        </el-pagination>
      </p>
    </el-dialog>
  </div>
</template>
<script>
import { insert, del, push, read, search } from "../assets/sql2.js";
import { addu, readu, delu, updateu, putu } from "../assets/msglist.js";
import { addud, readud, delud, updateud, putud, inud } from "../assets/msglistdoc.js";
export default {
  props: ["VideoChoiceDoc", "toID1", "connection1", "nowtouser1", "talklist", "list", "db"],
  data() {
    return {
      sid: "",
      binddoccheckbox: "",
      searchdoc: "",
      binddoclist: "",
      show: false,
      cust_id: "",
      form: {},
      videolist: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [10], // 个数选择器（可修改）
      PageSize: 10, // 默认每页显示的条数（可修改）
      value: false,
      roomlist: false,
      servicer_id: "",
      cust: "",
      date: "",
      des: "",
      doc_tel: "",
    };
  },
  watch: {
    VideoChoiceDoc(o, n) {
      this.show = o;
    },
    toID1(o, n) {
      this.cust_id = o;
      this.axios
        .post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.cust_id, doc_id: "" }))
        .then((res) => {
          if (res.data.code == 0) {
            this.cust = res.data.result[0].Cust_ID;
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
  mounted() {
    this.servicer_id = sessionStorage.getItem("servicer_id");
    this.sid = sessionStorage.getItem("id");
    this.getdata(1);
  },
  methods: {
    getdata(page) {
      this.totalCount = 0;
      this.videolist = [];

      this.axios
        .get(
          "/channel/get_gu_channel?page=" +
            page +
            "&input_id=" +
            this.servicer_id +
            "&size=" +
            this.PageSize +
            "&state=0" +
            "&cust_id=" +
            this.cust
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.totalCount = res.data.count;
            this.videolist = res.data.result;
          } else {
            this.totalCount = 0;
            this.videolist = [];
          }
        });
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage);
    },
    hide() {
      this.show = false;
      this.searchdoc = "";
      this.binddoclist = "";
      this.date = "";
      this.des = "";
      this.$emit("update:VideoChoiceDoc", false);
    },
    cleardoc() {
      this.binddoclist = [];
     this.binddoccheckbox=''
    },
    querydoc() {
      //搜索医生姓名
      if (this.searchdoc == "") {
        this.$message({
          type: "error",
          message: "请填写医生",
        });
        return;
      }
      this.axios
        .post("/gu/search_gu_docs", this.qs.stringify({ keyword: this.searchdoc, is_flag: "all" }))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.binddoclist = rs.data.result;
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到该医生",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectdoc() {
      this.doc_tel = this.binddoccheckbox.tel;
    },
    VideoUpdate() {
      this.insertvideo();
    },
    //创建视频
    async insertvideo() {
      const time = new Date().getTime();
      //获取视频token
      let token = await this.axios
        .get(
          "http://192.168.11.253:8080/agora/sample/RtcTokenBuilderSample.php?channel=" +
          // "http:///112.250.105.14:10087/agora/sample/RtcTokenBuilderSample.php?channel=" +
            time +
            "&uid=" +
            this.sid
        )
        .then((res) => {
          return res.data.token;
        })
        .catch((res) => {
          console.log(res);
        });

      this.form = {
        channel_name: time,
        channel_token: token,
        input_id: this.servicer_id,
        cust_id: this.cust,
        doc_id: this.binddoccheckbox.id,
        date: this.date,
        des: this.des,
      };
      if (this.binddoccheckbox == "") {
        this.$message({
          type: "error",
          message: "请选择医生",
        });
        return;
      }
      console.log(this.form);
      this.axios
        .post("/channel/insert_gu_channel", this.qs.stringify(this.form))
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "创建成功",
            });
            this.form.id = res.data.result;
            this.form.doc_tel = this.doc_tel;
            this.binddoccheckbox = "";
            this.binddoclist = [];
            this.searchdoc = "";
            this.hide();
            this.look(this.form);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    look(row) {
      console.log(row);
      //给患者发消息
      this.connection1
        .invoke(
          "send",
          this.toID1,
          "s" + this.sid,
          JSON.stringify({ channel: row.channel_name, id: row.id }),
          7
        )
        .then((res) => {
          if (res.err == 1) {
            // var fromID = "s" + this.sid;
            this.$message({
              message: "发送成功",
              type: "success",
            });
            this.roomlist = false;
            this.hide();
            //发送成功
            if (this.toID1 == this.nowtouser1) {
              this.talklist.push(res.data);
              push(this.db, this.toID1, res.data);
              this.$emit("changetalklist");
            }
            updateu(this.db, this.sid, this.toID1, "sendTime", res.data, function(res) {});
            this.$emit("changelist");
            this.form = {};
          } else {
            this.axios
              .post(
                "/test/log",
                this.qs.stringify({
                  TtoID: this.toID1,
                  TfromID: "s" + this.sid,
                  Tmsg: JSON.stringify({ channel: row.channel_name, id: row.id }),
                  Ttype: 7,
                })
              )
              .then((res) => {});
            this.$message({
              message: "发送失败，请重新发送",
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      //给医生发消息row.doc_id
      this.connection1
        .invoke(
          "send",
          row.doc_tel,
          "s" + this.sid,
          JSON.stringify({ channel: row.channel_name, id: row.id }),
          7
        )
        .then((res) => {
          console.log(res);
          if (res.err == 1) {
            console.log("send to doc success");
            push(this.db, row.doc_tel, res.data);
            updateud(this.db, this.sid, row.doc_tel, "sendTime", res.data, function(res) {});
          } else {
            this.axios
              .post(
                "/test/log",
                this.qs.stringify({
                  TtoID: row.doc_tel,
                  TfromID: "s" + this.sid,
                  Tmsg: JSON.stringify({ channel: row.channel_name, id: row.id }),
                  Ttype: 7,
                })
              )
              .then((res) => {});
            console.log("send to doc failed");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //按照日期排序
    comparetime(property) {
      return function(a, b) {
        var value1 = new Date(a[property]).getTime();
        var value2 = new Date(b[property]).getTime();
        return value2 - value1;
      };
    },
    /** 获取当前时间 格式：yyyy-MM-dd HH:MM:SS*/
    getCurrentTime() {
      var date = new Date(); //当前时间
      var month = this.zeroFill(date.getMonth() + 1); //月
      var day = this.zeroFill(date.getDate()); //日
      var hour = this.zeroFill(date.getHours()); //时
      var minute = this.zeroFill(date.getMinutes()); //分
      var second = this.zeroFill(date.getSeconds()); //秒
      //当前时间
      var curTime =
        date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
      return curTime;
    },
    /**补零*/
    zeroFill(i) {
      if (i >= 0 && i <= 9) {
        return "0" + i;
      } else {
        return i;
      }
    },
    lookdetail(row) {
      this.roomlist = false;
      this.hide();
      this.row = row;
      let routeData = this.$router.resolve({ path: "/talk/lookvideodetail", query: { id: row.id } });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
