<template>
  <div style="text-align: center;padding:10px;">
    <div class="add">
      <p style="float: left;margin: 0;">医生ID：</p>
      <el-input
        v-model="doc_id"
        placeholder="请输入医生ID"
        style="float: left;width: 15%;"
        clearable
      ></el-input>
      <p style="float: left;margin: 0;margin-left: 20px;">选择日期：</p>
      <el-date-picker
        v-model="MyZDDate"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        clearable
        @clear="cleardate()"
      >
      </el-date-picker>
      <el-button type="primary" style="margin-left:20px" @click="query(1)">查询</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"></el-table-column>
      <el-table-column prop="channel_name" label="频道名" width="130"></el-table-column>
      <el-table-column label="用户ID" prop="cust_id" width="130"></el-table-column>
      <el-table-column label="医生姓名" prop="doc_name" width="80"></el-table-column>
      <el-table-column label="医生ID" prop="doc_id" width="70"></el-table-column>
      <el-table-column label="医生电话" prop="doc_tel" width="130"></el-table-column>
      <el-table-column label="预约时间" prop="date" width="160"></el-table-column>
      <el-table-column label="创建时间" prop="create_time" width="160"></el-table-column>
      <el-table-column label="描述" prop="des"></el-table-column>
      <el-table-column label="状态" prop="state" width="80">
        <template slot-scope="scope">
          <div class="opt doc">
            <span v-if="scope.row.state == 0">创建</span>
            <span v-if="scope.row.state == 5">结束</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div class="opt doc">
            <a v-if="scope.row.state == 0" @click="look(scope.row)">查看</a>
            <a v-if="scope.row.state == 0" @click="edit(scope.row)">编辑</a>
            <a v-if="scope.row.state == 0" @click="changeState(scope.row)">修改状态</a>
            <a
              v-if="
                !(
                  scope.row.fileList == '' ||
                  scope.row.fileList == null ||
                  scope.row.fileList == undefined
                )
              "
              @click="lookreplay(scope.row)"
              >查看回放</a
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      ></el-pagination>
    </p>
    <!-- 修改状态 -->
    <el-dialog
      title="修改状态"
      :visible.sync="ChangeState"
      width="30%"
      @close="
        ChangeState = false;
        state = '';
      "
    >
      <el-form>
        <el-form-item label="">
          <el-select v-model="state" placeholder="请选择状态">
            <el-option
              v-for="item in stateoptions"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updateState">确定</el-button>
          <el-button
            @click="
              ChangeState = false;
              state = '';
            "
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 修改医生 -->
    <el-dialog title="修改医生" :visible.sync="ChangeDoc" width="30%">
      <el-input
        placeholder="请输入医生名"
        clearable
        prefix-icon="el-icon-search"
        v-model="searchdoc"
        style="width: 30%;"
      >
      </el-input>
      <el-button type="primary" style="margin-left:20px" @click="querydoc">查询</el-button>
      <el-radio-group style="width:100%;" v-model="binddoccheckbox">
        <el-radio
          style="width:100%;height: 50px;line-height: 50px;"
          v-for="(item, index) in binddoclist"
          :label="item"
          :key="index"
          >{{ item.name }}</el-radio
        >
      </el-radio-group>
      <div style="margin-top: 20px;">
        <el-button type="primary" @click="updateDoc">确定</el-button>
        <el-button>取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["showvideolist"],
  inject: ["reload"],
  data() {
    return {
      sid: "",
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [10], // 个数选择器（可修改）
      PageSize: 10, // 默认每页显示的条数（可修改）
      value: false,
      stateoptions: [
        { id: 0, label: "创建" },
        { id: 5, label: "结束" },
      ],
      ChangeState: false,
      state: "",
      row: "",
      lookDetail: false,
      client: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      MyZDDate: [],
      doc_id: "",
      ChangeDoc: false,
      binddoccheckbox: "",
      binddoclist: [],
      searchdoc: "",
      docrow: "",
      videourl: "",
    };
  },
  watch: {
    showvideolist(o, n) {
      if (o) {
        this.query(1);
      }
    },
    MyZDDate(newVal, oldVal) {
      if (!newVal) {
        this.MyZDDate = [];
      }
    },
  },
  mounted() {
    this.sid = sessionStorage.getItem("servicer_id");
  },
  methods: {
    query(page) {
      let date1, date2;
      if (this.MyZDDate.length > 0) {
        date1 = this.MyZDDate[0];
        date2 = this.MyZDDate[1];
      } else {
        date1 = "";
        date2 = "";
      }
      this.axios
        .get(
          "/channel/get_gu_channel?page=" +
            page +
            "&input_id=" +
            this.sid +
            "&size=" +
            this.PageSize +
            "&datetime1=" +
            date1 +
            "&datetime2=" +
            date2 +
            "&doc_id=" +
            this.doc_id
        )
        .then((res) => {
          this.list = [];
          this.totalCount = res.data.count;
          this.list = res.data.result;
        });
    },
    // getdata(page) {
    //     this.axios.get('/channel/get_gu_channel?page=' + page + '&input_id=' + this.sid + '&size=' + this.PageSize)
    //         .then(res => {
    //             this.totalCount = res.data.count;
    //             this.list = res.data.result;
    //         })
    // },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.query(currentPage);
    },
    //查看
    look(row) {
      this.lookDetail = true;
      this.row = row;
      let routeData = this.$router.resolve({ path: "/lookvideodetail", query: { id: row.id } });
      window.open(routeData.href, "_blank");
    },
    //修改状态
    changeState(row) {
      this.ChangeState = true;
      this.row = row;
    },
    updateState() {
      console.log(this.state);
      this.axios
        .post(
          "/channel/update_gu_channel_state",
          this.qs.stringify({ id: this.row.id, state: this.state })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.ChangeState = false;
            // this.reload();
            this.query(1);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    edit(row) {
      this.ChangeDoc = true;
      this.binddoclist = [];
      this.docrow = row;
    },
    querydoc() {
      //搜索医生姓名
      if (this.searchdoc == "") {
        this.$message({
          type: "error",
          message: "请填写医生",
        });
        return;
      }
      this.axios
        .post("/gu/search_gu_docs", this.qs.stringify({ keyword: this.searchdoc, is_flag: "all" }))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.binddoclist = rs.data.result;
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到该医生",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateDoc() {
      let form = {
        channel_name: this.docrow.channel_name,
        channel_token: this.docrow.channel_token,
        input_id: this.docrow.input_id,
        cust_id: this.docrow.cust_id,
        doc_id: this.binddoccheckbox.id,
        id: this.docrow.id,
      };
      console.log(form);
      this.axios.post("/channel/update_gu_channel", this.qs.stringify(form)).then((res) => {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.ChangeDoc = false;
        this.query(1);
      });
    },
    cleardate() {
      this.MyZDDate = [];
    },
    //查看回放
    lookreplay(row) {
      this.videourl = "http://cdn.51xk.info/" + row.fileList;
      let routeData = this.$router.resolve({
        path: "/videoLookReplay",
        query: { des: row.des, url: this.videourl },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  height: 50px;
  text-align: left;
  float: left;
}
</style>
