<template>
  <div>
    <el-dialog :title="title" :visible.sync="show" width="60%" @close="exit()">
      <el-table :data="orderdetail" border style="width: 100%" show-summary :summary-method="getSummaries">
        <el-table-column label="产品图" prop="thumb">
          <template slot-scope="scope">
            <img slot="reference" :src="scope.row.thumb" style="width: 80px;margin-left:20px;" />
          </template>
        </el-table-column>
        <!-- <el-table-column label="订单号" prop="orderId"></el-table-column> -->
        <el-table-column label="产品名" prop="goodsName"></el-table-column>
        <el-table-column label="规格" prop="standardName"></el-table-column>
        <el-table-column label="数量（件）" prop="num"></el-table-column>
        <el-table-column label="价格（元）" prop="price"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exit()">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["isshowscorder", "scorderid"],
  watch: {
    isshowscorder(o, n) {
      this.show = o;
      this.getDetail();
      this.title = "订单编号：" + this.scorderid;
    },
  },
  data() {
    return {
      show: false,
      orderdetail: [],
      title: "",
    };
  },
  mounted() {},
  methods: {
    exit() {
      this.show = false;
      this.$emit("update:isshowscorder", false);
    },
    getDetail() {
      //传id获取商城订单详情
      this.axios
        .get(
          this.serveurl.mallserve1 +
            "/api/admin/Order/lookup_all?index=1&pagesize=20&shopId=4&orderid=" +
            this.scorderid
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.orderdetail = this.filterdetail(res.data.data.orderList);
          } else {
            this.orderdetail = [];
          }
        });
    },
    filterdetail(ary) {
      var newarr = [];
      for (let i = 0; i < ary.length; i++) {
        if (ary[i].orderDetails.length > 0) {
          for (let n = 0; n < ary[i].orderDetails.length; n++)
            newarr.push(ary[i].orderDetails[n]);
        }
      }
      return newarr;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总价";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (index == 3) {
            sums[index] += " 件";
          } else if (index == 4) {
            sums[index] += " 元";
          }
        } else {
          // sums[index] = "N/A";
        }
      });

      return sums;
    },
  },
};
</script>
