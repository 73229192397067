<template>
  <div>
    <a @click="changestate()">修改付款状态</a>
    <el-dialog title="修改状态" :modal="false" :visible.sync="orderstate" width="30%">
      <el-select v-model="orderchangestate" placeholder="请选择要更改的状态" style="width: 300px;">
        <el-option
          v-for="item in orderstateoptions"
          :key="item.id"
          :label="item.value"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            orderstate = false;
            orderchangestate = '';
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="suborderstate">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["ordermsg"],
  watch: {
    ordermsg(o, n) {
      console.log(o);
      this.orderrow = o;
    },
  },
  data() {
    return {
      orderstateoptions: [
        { id: 0, value: "未付款" },
        { id: 1, value: "已付款" },
        { id: -1, value: "付款失败" },
      ],
      orderchangestate: "",
      orderstate: false,
      orderrow: "",
    };
  },
  methods: {
    //修改状态
    changestate() {
      this.orderstate = true;
    },
    //提交要修改的订单状态
    suborderstate() {
      if (this.orderchangestate === "") {
        this.$message({
          message: "请选择要修改的状态",
          type: "error",
        });
        return;
      }
      var form = {
        state: this.orderchangestate,
        order_id: this.ordermsg.Order_ID,
      };
      // console.log(form);
      this.axios.post("/order/update_order_pay_state", this.qs.stringify(form)).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.orderstate = false;
          this.orderchangestate = "";
          this.$emit("changeorderlist");
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>
