<template>
  <div>
    <el-dialog
      title="设置诊费"
      :visible.sync="show"
      width="50%"
      @close="exit()"
    >
      <!-- <div style="margin-bottom: 20px;">
                <el-button @click="setmoney">设置金额</el-button>
                <el-input style="width:50%" v-model="setprice" placeholder="输入价格"></el-input>
            </div> -->
      <el-form label-width="100px">
        <el-form-item label="患者姓名">
          <el-input
            style="float: left; width: 100%"
            v-model="custName"
            placeholder="请输入患者姓名"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="患者身份证号">
                    <el-input v-model="custIDCard" placeholder="请输入患者的身份证号"></el-input>
                </el-form-item> -->
        <!-- <el-form-item label="患者手机号">
                    <el-input v-model="custTel" placeholder="请输入患者的手机号"></el-input>
                </el-form-item> -->
        <el-form-item label="诊费金额" style="text-align: left">
          <!-- 旧版选择金额 -->
          <!-- <el-select v-model="price" placeholder="请选择金额" style="float: left;width: 300px;">
            <el-option
              v-for="item in options"
              :key="item.price"
              :label="item.price"
              :value="item.price"
            >
            </el-option>
          </el-select> -->
          <!-- 新版客服自己输入金额 -->
          <el-input
            style="width: 200px; float: left; margin-right: 8px"
            placeholder="请输入10-15000之间的整数"
            v-model="price"
            type="number"
            @mousewheel.native.prevent
            @keyup.native="prevent($event)"
          ></el-input
          >元
        </el-form-item>
        <el-form-item>
          <!-- <el-button type="primary" @click="Sub">确定</el-button> -->
          <el-button type="primary" @click="Subnew">确定</el-button>
          <el-button @click="exit()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { insert, del, push, read, search } from "../assets/sql2.js";
import { addu, readu, delu, updateu, putu } from "../assets/msglist.js";
export default {
  props: ["sendM", "toID", "talkconnection", "talklist", "db"],
  data() {
    return {
      show: false,
      custName: "",
      custIDCard: "220204199305176459",
      custTel: "15843285858",
      price: "",
      custid: "",
      yforderno: "",
      sid: "",
      options: [],
      id: "",
      setprice: "",
      yfid: "",
      docid: "",
      uid: sessionStorage.getItem("servicer_id"),
    };
  },
  watch: {
    sendM(o, n) {
      this.show = o;
    },
    toID(o, n) {
      this.axios
        .post("/gu/search_gu_custs", this.qs.stringify({ keyword: o }))
        .then((res) => {
          if (res.data.code == 0) {
            this.custid = res.data.result[0].Cust_ID;
          } else {
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
      this.options = [];
      this.axios
        .get("/yf/pat_doctor_servlst?yf_id=" + this.yfid)
        .then((res) => {
          let arr = res.data.data;
          arr.forEach((element) => {
            if (element.type == 2) {
              this.options.push(element);
            }
          });
        });
    },
  },
  mounted() {
    this.sid = sessionStorage.getItem("id");
    this.axios
      .get("/gu/get_gu_keyval_by_group?group=pat_servorder")
      .then((res) => {
        if (res.data.code == 0) {
          this.yfid = res.data.result[1].val;
          this.docid = res.data.result[0].val;
        }
      });
  },
  methods: {
    //按照日期排序
    comparetime(property) {
      return function (a, b) {
        var value1 = new Date(a[property]).getTime();
        var value2 = new Date(b[property]).getTime();
        return value2 - value1;
      };
    },
    /** 获取当前时间 格式：yyyy-MM-dd HH:MM:SS*/
    getCurrentTime() {
      var date = new Date(); //当前时间
      var month = this.zeroFill(date.getMonth() + 1); //月
      var day = this.zeroFill(date.getDate()); //日
      var hour = this.zeroFill(date.getHours()); //时
      var minute = this.zeroFill(date.getMinutes()); //分
      var second = this.zeroFill(date.getSeconds()); //秒
      //当前时间
      var curTime =
        date.getFullYear() +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      return curTime;
    },
    /**补零*/
    zeroFill(i) {
      if (i >= 0 && i <= 9) {
        return "0" + i;
      } else {
        return i;
      }
    },
    keyupEvent(e, input) {
      e.target.value = e.target.value.replace(/[^\d.]/g, "");
      e.target.value = e.target.value.replace(/\.{2,}/g, ".");
      e.target.value = e.target.value.replace(/^\./g, "0.");
      e.target.value = e.target.value.replace(
        /^\d*\.\d*\./g,
        e.target.value.substring(0, e.target.value.length - 1)
      );
      e.target.value = e.target.value.replace(/^0[^\.]+/g, "0");
      e.target.value = e.target.value.replace(/^(\d+)\.(\d\d).*$/, "$1.$2");
      this.price = e.target.value;
    },
    checkPhone(phone) {
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        return false;
      } else {
        return true;
      }
    },
    isIdCard(idCard) {
      idCard = "220204199305176459";
      // 15位和18位身份证号码的正则表达式
      var regIdCard =
        /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
      // 如果通过该验证，说明身份证格式正确，但准确性还需计算
      if (regIdCard.test(idCard)) {
        if (idCard.length == 18) {
          var idCardWi = new Array(
            7,
            9,
            10,
            5,
            8,
            4,
            2,
            1,
            6,
            3,
            7,
            9,
            10,
            5,
            8,
            4,
            2
          ); // 将前17位加权因子保存在数组里
          var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); // 这是除以11后，可能产生的11位余数、验证码，也保存成数组
          var idCardWiSum = 0; // 用来保存前17位各自乖以加权因子后的总和
          for (var i = 0; i < 17; i++) {
            idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i];
          }
          var idCardMod = idCardWiSum % 11; // 计算出校验码所在数组的位置
          var idCardLast = idCard.substring(17); // 得到最后一位身份证号码
          // 如果等于2，则说明校验码是10，身份证号码最后一位应该是X
          if (idCardMod == 2) {
            if (idCardLast == "X" || idCardLast == "x") {
              return true;
            } else {
              return false;
            }
          } else {
            // 用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
            if (idCardLast == idCardY[idCardMod]) {
              return true;
            } else {
              return false;
            }
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    exit() {
      this.show = false;
      this.$emit("update:sendM", false);
      this.custTel = "";
      this.custName = "";
      this.custIDCard = "";
      this.price = "";
    },
    setmoney() {
      //设置金额
      this.axios
        .post(
          "/yf/pat_doctor_setserv",
          this.qs.stringify({ yf_id: this.yfid, price: this.setprice })
        )
        .then((res) => {
          console.log(res);
        });
    },
    prevent(e) {
      var keynum = window.event ? e.keyCode : e.which; //获取键盘码
      if (keynum == 189 || keynum == 190 || keynum == 109 || keynum == 110) {
        this.$message.warning("禁止输入小数以及负数");
        e.target.value = "";
        this.price = "";
      }
    },
    //切换到自己的支付20220324新
    Subnew() {
      if (this.custName == "") {
        this.$message({
          message: "请输入患者姓名",
          type: "error",
        });
        return;
      }
      if (this.price === "") {
        this.$message({
          message: "请输入金额",
          type: "error",
        });
        return;
      }
      if (this.price < 10 || this.price > 15000) {
        this.$message({
          message: "请输入10-15000之间的整数",
          type: "error",
        });
        this.price = "";
        return;
      }
      this.custTel = "15812341234";
      let form = {
        yf_id: this.yfid,
        pt_id: this.custTel,
        pt_no: this.custName,
        id_no: "220204199305176459", //this.custIDCard,//220204199305176459
        pt_tel: this.custTel,
        trans_amount: this.price,
        cust_id: this.custid,
        doc_id: this.docid,
        input_id: this.uid,
      };
      console.log(form);
      this.axios
        .post("/yf/pat_servorder_create", this.qs.stringify(form))
        .then((res) => {
          if (res.data.res == "ERROR") {
            this.$message({
              message: res.data.res,
              type: "error",
            });
          } else {
            this.yforderno = res.data.data.yf_order_no;
            this.id = res.data.data.id;
            // var alllist = JSON.parse(localStorage.getItem("usertalklist"+this.sid));
            var toID = this.toID;
            var fromID = "s" + this.sid;
            this.talkconnection
              .invoke(
                "send",
                toID,
                fromID,
                JSON.stringify({ yforderno: this.yforderno, id: this.id }),
                8
              )
              .then((res) => {
                if (res.err == 1) {
                  //发送成功
                  let measge = {
                    content: res.data.content,
                    from: fromID,
                    type: "8",
                    sendTime: res.data.sendTime,
                  };
                  push(this.db, toID, res.data);
                  updateu(
                    this.db,
                    this.sid,
                    toID,
                    "sendTime",
                    measge,
                    function (res) {}
                  );
                  this.custTel = "";
                  this.custName = "";
                  this.custIDCard = "";
                  this.price = "";
                  this.$emit("sendMoneyChangeAlllist");
                  this.$emit("sendMoneyChangeTalklist");
                  this.exit();
                } else {
                  this.$message({
                    message: "发送失败，请重新发送",
                    type: "error",
                  });
                }
              });
          }
        });
    },
    //逸飞旧的诊费提交
    Sub() {
      if (this.custName == "") {
        this.$message({
          message: "请输入患者姓名",
          type: "error",
        });
        return;
      }
      // if (!(this.isIdCard(this.custIDCard))) {
      //     this.$message({
      //         message: '请输入正确的身份证号码',
      //         type: 'error'
      //     });
      //     return;
      // }
      // if (!(this.checkPhone(this.custTel))) {
      //     this.$message({
      //         message: '请输入正确的电话号码',
      //         type: 'error'
      //     });
      //     return;
      // }
      if (this.price === "") {
        this.$message({
          message: "请输入金额",
          type: "error",
        });
        return;
      }

      this.custTel = "15812341234";
      let form = {
        yf_id: this.yfid,
        pt_id: this.custTel,
        pt_no: this.custName,
        id_no: "220204199305176459", //this.custIDCard,//220204199305176459
        pt_tel: this.custTel,
        trans_amount: this.price,
        cust_id: this.custid,
        doc_id: this.docid,
        input_id: this.uid,
      };
      // console.log(form);
      this.axios
        .post("/yf/pat_servorder_create", this.qs.stringify(form))
        .then((res) => {
          if (res.data.res == "ERROR") {
            this.$message({
              message: res.data.res,
              type: "error",
            });
          } else {
            this.yforderno = res.data.data.yf_order_no;
            this.id = res.data.data.id;
            // var alllist = JSON.parse(localStorage.getItem("usertalklist"+this.sid));
            var toID = this.toID;
            var fromID = "s" + this.sid;
            this.talkconnection
              .invoke(
                "send",
                toID,
                fromID,
                JSON.stringify({ yforderno: this.yforderno, id: this.id }),
                8
              )
              .then((res) => {
                if (res.err == 1) {
                  //发送成功
                  let measge = {
                    content: res.data.content,
                    from: fromID,
                    type: "8",
                    sendTime: res.data.sendTime,
                  };
                  push(this.db, toID, res.data);
                  updateu(
                    this.db,
                    this.sid,
                    toID,
                    "sendTime",
                    measge,
                    function (res) {}
                  );
                  this.custTel = "";
                  this.custName = "";
                  this.custIDCard = "";
                  this.price = "";
                  this.$emit("sendMoneyChangeAlllist");
                  this.$emit("sendMoneyChangeTalklist");
                  this.exit();
                } else {
                  this.$message({
                    message: "发送失败，请重新发送",
                    type: "error",
                  });
                }
              });
          }
        });
    },
  },
};
</script>
<style>
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
