<template>
  <div>
    <el-dialog title="查看诊费订单" :visible.sync="show" width="80%" @close="exit()">
      <el-table :data="list" border style="width: 100%" :default-sort = "{prop: 'state'}">
        <el-table-column prop="id" label="编号" width="80"></el-table-column>
        <el-table-column prop="pt_no" label="患者姓名" width="100"></el-table-column>
        <!-- <el-table-column label="电话" prop="pt_tel"></el-table-column> -->
        <el-table-column label="订单编号" prop="yf_order_no" width="200"></el-table-column>
        <el-table-column label="金额" prop="trans_amount" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.trans_amount | number_format(scope.row.trans_amount) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="create_time" width="190"></el-table-column>
        <el-table-column label="支付时间" prop="pay_time" width="190"></el-table-column>
        <el-table-column label="支付状态" prop="state" width="120" sortable>
          <template slot-scope="scope">
            <div class="opt doc">
              <span v-if="scope.row.state == -1">取消付款</span>
              <span v-if="scope.row.state == 0">新订单</span>
              <span v-if="scope.row.state == 1">已付款</span>
              <span v-if="scope.row.state == 2">已确认</span>
              <span v-if="scope.row.state == 6">已退款</span>
              <!-- <a @click="refresh(scope.row, scope.$index)" style="margin-left:20px;">刷新状态</a> -->
              <!-- <a v-if="scope.row.state == 2&&(scope.row.refund_state==0||scope.row.refund_state==3)" @click="applyRefund(scope.row, scope.$index)" style="color:red;">申请退款</a>
                        <span v-if="scope.row.refund_state == 1">处理中</span> -->
              <!-- <span v-if="scope.row.refund_state == 2">已退款</span> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center;">
        <p class="paging">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="current"
            :page-sizes="pageSizes"
            :page-size="PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
            :hide-on-single-page="value"
          >
          </el-pagination>
        </p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["lookuserzf", "friendlistcustid"],
  data() {
    return {
      list: [],
      show: false,
      custid: "",
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [6], // 个数选择器（可修改）
      PageSize: 6, // 默认每页显示的条数（可修改）
      value: false,
    };
  },
  watch: {
    lookuserzf(o, n) {
      this.show = o;
    },
    friendlistcustid(o, n) {
      this.custid = o;
      this.getdata(1);
    },
  },
  filters: {
    number_format: function(number) {
      var decimals = 2;
      var dec_point = ".";
      var thousands_sep = ",";
      number = (number + "").replace(/[^0-9+-Ee.]/g, "");
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
        sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
        dec = typeof dec_point === "undefined" ? "." : dec_point,
        s = "",
        toFixedFix = function(n, prec) {
          var k = Math.pow(10, prec);
          return "" + Math.ceil(n * k) / k;
        };

      s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
      var re = /(-?\d+)(\d{3})/;
      while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
      }

      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
  },
  mounted() {},
  methods: {
      exit() {
      this.show = false;
      this.$emit("update:lookuserzf", false);
    },
    getdata(page) {
      this.axios
        .get(
          "/yf/get_gu_pat_servorder?page=" +
            page +
            "&size=" +
            this.PageSize +
            "&cust_id=" +
            this.custid
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        });
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage);
    },
  },
};
</script>
