<template>
  <div>
    <el-dialog title="查看诊单" :visible.sync="show" width="80%" @close="exit()">
      <el-table :data="Newzdlist" border style="width: 100%; margin-top: 40px" :default-sort="{prop: 'State'}">
        <el-table-column prop="Creat_DT" label="时间" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.Creat_DT.split(".")[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="KID" label="编号" width="160"></el-table-column>
        <el-table-column prop="Dis_Name" label="诊单号"></el-table-column>
        <el-table-column prop="Cust_ID" label="用户id" width="120"></el-table-column>
        <el-table-column prop="State" label="状态" sortable width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.State == 0" style="color:#E6A23C;">新建</span>
            <span v-if="scope.row.State == 1" style="color:#409EFF;">提交</span>
            <span v-if="scope.row.State == 2" style="color:#C709F7;">审核</span>
            <span v-if="scope.row.State == 3" style="color:#FF0033">打回</span>
            <span v-if="scope.row.State == 6" style="color:#67C23A">已下发</span>
            <span v-if="scope.row.State == 9" style="color:#F56C6C;">已删除</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <div class="opt">
              <a @click="NewchoiceEdit(scope.row, 'look')">查看</a>
              <!-- <el-link
                type="success"
                :disabled="scope.row.State != 0 && scope.row.State != 1"
                @click="NewchoiceEdit(scope.row)"
              >
                编辑</el-link
              >
              <a @click="Newchoice(scope.row)">下发</a>
              <a @click="Newrepulse(scope.row)">打回</a>
              <a v-if="scope.row.State == 1" @click="TJshenhe(scope.row, 'user')">审核</a> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <p class="paging">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value">
        </el-pagination>
      </p>
    </el-dialog>
    <el-dialog title="编辑诊单" :modal="false" :visible.sync="EditZD" width="566px">
      <preview :kid="EditMsg.kid" :custid="EditMsg.custid" v-if="EditZD" :EditZD="EditZD" :noedit="EditMsg.noedit"></preview>
    </el-dialog>
  </div>
</template>
<script>
import preview from "../components/preview.vue";
export default {
  props: ["lookuserzd", "talkitem"],
  components: {
    preview,
  },
  data() {
    return {
      show: false,
      Newzdlist: [],
      EditMsg: {},
      EditZD: false,
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [6], // 个数选择器（可修改）
      PageSize: 6, // 默认每页显示的条数（可修改）
      value: false,
    };
  },
  watch: {
    lookuserzd(o, n) {
      this.show = o;
    },
    talkitem(o, n) {
      this.getdata(1);
    },
  },
  mounted() {},
  methods: {
    getdata(page) {
      this.axios
        .get(
          "/field/get_z_field_dismains?tel=" +
            this.talkitem.id +
            "&state=0126&page=" +
            page +
            "&size=" +
            this.PageSize
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.Newzdlist = res.data.result;
            this.totalCount = res.data.count;
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    exit() {
      this.show = false;
      this.$emit("update:lookuserzd", false);
    },
    //编辑新诊单
    NewchoiceEdit(row, type) {
      this.EditMsg.kid = row.KID;
      this.EditMsg.custid = row.Cust_ID;
      if (type == "look") {
        this.EditMsg.noedit = false;
      } else {
        this.EditMsg.noedit = true;
      }
      this.EditZD = true;
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage);
    },
  },
};
</script>
