<template>
  <div>
    <el-dialog title="寻医表列表" :visible.sync="show" width="50%" class="drugmain" @close="exit()">
      <el-date-picker
        v-model="date"
        type="daterange"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        clearable
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="margin-right:10px;width:300px;"
      >
      </el-date-picker>
      <el-button type="primary" @click="getdetail(1)">查询</el-button>
      <el-table :data="list" v-if="list.length > 0" border style="width: 100%; margin-top: 40px">
        <el-table-column prop="Creat_DT" label="时间">
          <template slot-scope="scope">
            <span>{{ scope.row.Creat_DT.split(".")[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="KID" label="寻医表编号"></el-table-column>
        <el-table-column prop="Dis_Name" label="寻医表名称"></el-table-column>
        <el-table-column prop="Cust_ID" label="用户id"></el-table-column>
        <el-table-column prop="State" label="状态" sortable>
          <template slot-scope="scope">
            <span v-if="scope.row.State == 0">新建</span>
            <span v-if="scope.row.State == 1">提交</span>
            <span v-if="scope.row.State == 2">审核</span>
            <span v-if="scope.row.State == 3">打回</span>
            <span v-if="scope.row.State == 6">已下发</span>
            <span v-if="scope.row.State == 9">已删除</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="opt">
              <a @click="look(scope.row)">查看</a>
              <!-- <el-link
                type="success"
                :disabled="scope.row.State != 0 && scope.row.State != 1"
                @click="NewchoiceEdit(scope.row)"
              >
                编辑</el-link
              > -->
              <a @click="send1(scope.row)">发送</a>
              <!-- <el-link type="success" :disabled="(scope.row.State==6||scope.row.State==9)" @click="Newrepulse(scope.row)">打回</el-link>
              <a v-if="scope.row.State == 1" @click="TJshenhe(scope.row, 'user')">审核</a> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <p class="paging" style="text-align: center">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-sizes="pageSizes"
          :page-size="PageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
          :hide-on-single-page="value"
        ></el-pagination>
      </p>
    </el-dialog>
    <el-dialog title="编辑寻医表" :modal="false" :visible.sync="EditXY" width="566px">
      <xypreview
        :kid="EditXYMsg.kid"
        :custid="EditXYMsg.custid"
        v-if="EditXY"
        :EditZD="EditXY"
        :noedit="EditXYMsg.noedit"
      ></xypreview>
    </el-dialog>
  </div>
</template>
<script>
import xypreview from "./xypreview.vue";
export default {
  props: ["docxyshow", "docid"],
  watch: {
    docxyshow(o, n) {
      this.show = o;
      this.date = [];
      this.list = [];
    },
  },
  data() {
    return {
      show: false,
      list: [],
      date: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [10], // 个数选择器（可修改）
      PageSize: 10, // 默认每页显示的条数（可修改）
      value: false,
      EditXY: false, //编辑诊单
      EditXYMsg: {
        kid: "",
        custid: "",
        noedit: true,
      },
    };
  },
  components: {
    xypreview,
  },
  mounted() {},
  methods: {
    exit() {
      this.show = false;
      this.$emit("update:docxyshow", false);
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdetail(currentPage);
    },
    look(row) {
      this.EditXY = true;
      this.EditXYMsg.kid = row.KID;
      this.EditXYMsg.custid = row.Cust_ID;
      this.EditXYMsg.noedit = false;
    },
    getdetail(page) {
      if (this.date.length <= 0 || this.date == null) {
        this.$message({
          message: "请选择日期",
          type: "error",
        });
        return;
      }
      var date1 = this.date[0];
      var date2 = this.date[1];
      this.docid = "201";
      this.axios
        .get(
          "/field/get_z_newseadoc_mains?doc_id=" +
            this.docid +
            "&datetime1=" +
            date1 +
            "&datetime2=" +
            date2 +
            "&state=6" +
            "&page=" +
            page +
            "&size=10"
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
          }
        });
    },
    send1(row){
        this.$emit('send',5,JSON.stringify({ name: row.Dis_Name, id: row.KID }));
        this.exit();
    }
  },
};
</script>
