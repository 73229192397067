<template>
  <div style="overflow: auto;height: 100vh;">
    <div class="add" style="height:auto;margin-bottom:10px;">
      <el-select v-if="getownroles != 0" v-model="kefuchoice" multiple collapse-tags value-key="serviceId" placeholder="请选择客服" style="margin-right:10px;width:250px;" size="small">
        <el-option label="全选" value="all"></el-option>
        <el-option v-for="item in kefulist" :key="item.id" :label="item.name" :value="item">
        </el-option>
      </el-select>
      <el-select size="small" v-model="select2" placeholder="请选择状态" style="margin-right:10px;width:120px;">
        <el-option v-for="item in options2" :key="item.id" :label="item.value" :value="item.id">
        </el-option>
      </el-select>
      <el-date-picker v-model="MyZDDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-right:10px;width:260px;" size="small">
      </el-date-picker>
      <el-button type="primary" @click="query(1)" size="small">查询</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" label="编号"></el-table-column>
      <el-table-column prop="pt_no" label="患者姓名"></el-table-column>
      <!-- <el-table-column label="电话" prop="pt_tel"></el-table-column> -->
      <el-table-column label="订单编号" prop="yf_order_no"></el-table-column>
      <el-table-column label="金额" prop="trans_amount">
        <template slot-scope="scope">
          <span>{{ scope.row.trans_amount | number_format(scope.row.trans_amount) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="服务ID" prop="input_id"></el-table-column>
      <el-table-column label="创建时间" prop="create_time"></el-table-column>
      <el-table-column label="支付时间" prop="pay_time"></el-table-column>
      <el-table-column label="支付状态" prop="state" width="280">
        <template slot-scope="scope">
          <div class="opt doc">
            <span v-if="scope.row.state == -1">取消付款</span>
            <span v-if="scope.row.state == 0">新订单</span>
            <span v-if="scope.row.state == 1">已付款</span>
            <span v-if="scope.row.state == 2">已确认</span>
            <span v-if="scope.row.state == 6">已退款</span>
            <a @click="refresh(scope.row, scope.$index)" style="margin-left:20px;">刷新状态</a>
            <a @click="orderdetail(scope.row, scope.$index)" style="color:blue;">详情</a>
            <a v-if="
                scope.row.state == 2 && (scope.row.refund_state == 0 || scope.row.refund_state == 3)
              " @click="applyRefund(scope.row, scope.$index)" style="color:red;">申请退款</a>
            <span v-if="scope.row.refund_state == 1">处理中</span>
            <!-- <span v-if="scope.row.refund_state == 2">已退款</span> -->

          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center;">
      <p class="paging">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value">
        </el-pagination>
      </p>
    </div>
    <el-dialog title="查看详情" :visible.sync="looktkdetail">
      <table class="tkdetail">
        <tr>
          <td class="title">订单号</td>
          <td>{{tkdetaildes.out_trade_no}}</td>
        </tr>
        <!-- <tr>
          <td>退款次数</td>
          <td>{{tkdetaildes.refund_count}}</td>
        </tr> -->
        <tr>
          <td class="title">金额</td>
          <td>{{filtermoney(tkdetaildes.cash_fee)}}</td>
        </tr>
        <tr>
          <td class="title">付款方式</td>
          <td>{{tkdetaildes.bank_type}}</td>
        </tr>
        <tr>
          <td class="title">time_end</td>
          <td>{{tkdetaildes.time_end}}</td>
        </tr>
        <!-- <tr>
          <td class="title">结果</td>
          <td>{{filtercode(tkdetaildes.result_code,tkdetaildes.return_code)}}</td>
        </tr> -->
        <!-- <tr>
          <td class="title">return_code</td>
          <td>{{tkdetaildes.return_code}}</td>
        </tr> -->
        <tr>
          <td class="title">总金额</td>
          <td>{{filtermoney(tkdetaildes.total_fee)}}</td>
        </tr>
        <tr>
          <td class="title">订单状态</td>
          <td>{{tkdetaildes.trade_state}}</td>
        </tr>
        <tr>
          <td class="title">订单状态描述</td>
          <td>{{tkdetaildes.trade_state_desc}}</td>
        </tr>
        <tr>
          <td class="title">支付类型</td>
          <td>{{tkdetaildes.trade_type}}</td>
        </tr>
        <tr>
          <td class="title">交易编号</td>
          <td>{{tkdetaildes.transaction_id}}</td>
        </tr>
      </table>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["showMoneyList", "serve", "get_own_roles"],
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [10], // 个数选择器（可修改）
      PageSize: 10, // 默认每页显示的条数（可修改）
      value: false,
      options2: [
        // { id: -1, value: '已关闭' },
        { id: 0, value: "新订单" },
        { id: 1, value: "已付款" },
        { id: 2, value: "已确认" },
        // { id: 6, value: '已退款' },
      ],
      MyZDDate: [],
      select2: "",
      sid: "",
      kefuchoice: "", //选择的客服
      kefulist: [], //客服列表
      getownroles: "", //客服的角色权限
      uid: sessionStorage.getItem("servicer_id"),
      TJcheckAll: false, //我的统计选择客服
      TJcheckedKF: [], //
      TJisIndeterminate: true,
      looktkdetail: false,
      tkdetaildes: {},
    };
  },
  watch: {
    showMoneyList(o, n) {
      if (o) {
        this.select2 = "";
        this.MyZDDate = "";
        this.getdddata(1);
      }
    },
    get_own_roles(o, n) {
      this.getownroles = o;
      if (o != 0) {
        this.getkefulist();
      }
    },
    kefuchoice(val, oldval) {
      let newindex = val.indexOf("all"),
        oldindex = oldval.indexOf("all"); //获取val和oldval里all的索引,如果没有则返回-1
      if (newindex != -1 && oldindex == -1 && val.length > 1)
        //如果新的选择里有勾选了选择所有选择所有 则 只直线勾选所有整个选项
        this.kefuchoice = ["all"];
      else if (newindex != -1 && oldindex != -1 && val.length > 1)
        //如果操作前有勾选了选择所有且当前也选中了勾选所有且勾选数量大于1  则移除掉勾选所有
        this.kefuchoice.splice(val.indexOf("all"), 1);
    },
    MyZDDate(newVal, oldVal) {
      if (!newVal) {
        this.MyZDDate = [];
      }
    },
  },
  mounted() {
    this.sid = sessionStorage.getItem("id");
  },
  filters: {
    number_format: function (number) {
      /*
       * 参数说明：
       * number：要格式化的数字
       * decimals：保留几位小数
       * dec_point：小数点符号
       * thousands_sep：千分位符号
       * */
      var decimals = 2;
      var dec_point = ".";
      var thousands_sep = ",";
      number = (number + "").replace(/[^0-9+-Ee.]/g, "");
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
        sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
        dec = typeof dec_point === "undefined" ? "." : dec_point,
        s = "",
        toFixedFix = function (n, prec) {
          var k = Math.pow(10, prec);
          return "" + Math.ceil(n * k) / k;
        };

      s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
      var re = /(-?\d+)(\d{3})/;
      while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
      }

      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
  },
  methods: {
    change() {
      this.sid = this.kefuchoice.substring(this.kefuchoice.length - 2);
    },
    getkefulist() {
      console.log(this.serve);
      this.axios
        .get(this.serve + "/api/Employee/get_employee?Id=" + this.uid)
        .then((res) => {
          if (res.data.code == 1) {
            this.kefulist = res.data.result;
          }
        });
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.query(currentPage);
    },
    getNewDay(dateTemp, days) {
      var dateTemp = dateTemp.split("-");
      var nDate = new Date(dateTemp[1] + "-" + dateTemp[2] + "-" + dateTemp[0]); //转换为MM-DD-YYYY格式
      var millSeconds = Math.abs(nDate) + days * 24 * 60 * 60 * 1000;
      var rDate = new Date(millSeconds);
      var year = rDate.getFullYear();
      var month = rDate.getMonth() + 1;
      if (month < 10) month = "0" + month;
      var date = rDate.getDate();
      if (date < 10) date = "0" + date;
      return year + "-" + month + "-" + date;
    },
    getdddata(page) {
      var str = "";
      if (this.TJcheckedKF.length > 0) {
        this.TJcheckedKF.forEach((item) => {
          str += item.serviceId + ",";
        });
        str = str.substr(0, str.length - 1);
      } else {
        str = this.uid;
      }
      this.axios
        .get(
          "/yf/get_gu_pat_servorder?page=" +
            page +
            "&size=" +
            this.PageSize +
            "&input_ids=" +
            str
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        });
    },
    cleardate() {
      this.MyZDDate = [];
      this.getdddata(1);
    },
    query(page) {
      var kflist = [];
      if (this.kefuchoice == "all") {
        kflist = this.kefulist;
      } else {
        kflist = this.kefuchoice;
      }
      var _state,
        datetimestr = "";
      _state = this.select2;
      if (this.MyZDDate.length <= 0 || this.MyZDDate == null) {
        this.$message({
          message: "请选择日期",
          type: "error",
        });
        return;
      }
      if (
        (_state == "" || _state == null || _state == undefined) &&
        _state != 0
      ) {
        this.$message({
          message: "请选择状态",
          type: "error",
        });
        return;
      }
      var date1 = this.MyZDDate[0];
      var date2 = this.MyZDDate[1];
      if (this.select2 == 0) {
        datetimestr =
          "&create_datetime1=" + date1 + "&create_datetime2=" + date2;
      } else {
        datetimestr = "&datetime1=" + date1 + "&datetime2=" + date2;
      }

      var str = "";
      if (kflist.length > 0) {
        kflist.forEach((item) => {
          str += item.serviceId + ",";
        });
        str = str.substr(0, str.length - 1);
      } else {
        str = this.uid;
      }
      this.axios
        .get(
          "/yf/get_gu_pat_servorder?page=" +
            page +
            "&size=" +
            this.PageSize +
            datetimestr +
            "&input_ids=" +
            str +
            "&state=" +
            _state
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        });
    },
    //刷新状态
    refresh(row, index) {
      this.axios
        .get("/yf/pat_order_infos?yf_order_no=" + row.yf_order_no)
        .then((res) => {
          if (res.data.res == "OK") {
            this.$message({
              message: "刷新成功",
              type: "success",
            });
            row.state = res.data.data.order_status;
            this.$set(this.list, index, row);
            this.axios
              .post(
                "/yf/update_gu_pat_servorder_state",
                this.qs.stringify({
                  state: res.data.data.order_status,
                  yf_order_no: row.yf_order_no,
                })
              )
              .then((res) => {
                console.log(res);
              });
          }
        });
    },
    //申请退款
    applyRefund(item, index) {
      var form = {
        refund_state: 1,
        yf_order_no: item.yf_order_no,
        uid: this.uid,
      };
      this.axios
        .post(
          "/yf/update_gu_pat_servorder_pay_refund_state",
          this.qs.stringify(form)
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "申请发送成功",
              type: "success",
            });
          }
        });
    },
    //我的统计
    TJhandleCheckAllChange(val) {
      this.TJcheckedKF = val ? this.kefulist : [];
      this.TJisIndeterminate = false;
      console.log(this.TJcheckedKF);
    },
    TJhandleCheckedKFChange(value) {
      let checkedCount = value.length;
      this.TJcheckAll = checkedCount === this.kefulist.length;
      this.TJisIndeterminate =
        checkedCount > 0 && checkedCount < this.kefulist.length;
      console.log(this.TJcheckedKF);
    },
    //查看订单详情
    orderdetail(row, index) {
      this.getorderdetail(row);
    },
    filtermoney(val) {
      if(val){
        return '￥'+val / 100;
      }else{
        return '';
      }
    },
    filtercode(val1, val2) {
      if (val1 == "SUCCESS" && val2 == "SUCCESS") {
        return "成功";
      } else {
        return "失败";
      }
    },
    getorderdetail(row) {
      this.axios
        .post(
          "http://192.168.11.253:8080/wpay/example/orderquery.php?",
          this.qs.stringify({ out_trade_no: row.yf_order_no })
        )
        .then((res) => {
          if (
            res.data.result_code == "SUCCESS" &&
            res.data.return_code == "SUCCESS"
          ) {
            this.looktkdetail = true;
            console.log(res.data);
            this.tkdetaildes = res.data;
          } else {
            this.$message({
              message: res.data.err_code_des,
              type: "error",
            });
          }
        });
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  height: 50px;
  text-align: left;
  float: left;
}
.el-date-editor .el-range-separator {
  width: 8%;
}
.tkdetail tr {
  height: 30px;
  text-align: left;
}
.tkdetail .title {
  width: 130px;
}
</style>
