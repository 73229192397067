import preview from "../components/preview.vue";
import xypreview from "../components/xypreview.vue";
import uploadIMG from "../components/upload.vue";
import talkvideo from "../components/talkvideo.vue";
import videoList from "../components/videoList.vue";
import sendmoney from "../components/sendmoney.vue";
import payMoneyList from "../components/payMoneyList.vue";
import talkMyMsg from "../components/talkMyMsg.vue";
import sendorder from "../components/sendorder.vue";
import talksettag from "../components/talksettag.vue";
import talkorder from "../components/talkorder.vue";
import talklookorder from "../components/talklookorder.vue";
import userxylist from "../components/userxylist.vue";
import userzdlist from "../components/userzdlist.vue";
import usermoneylist from "../components/usermoneylist.vue";
import talkassigDoc from "../components/talkassigDoc.vue";
import talksendcase from "../components/talksendcase.vue";
import feedback from "../components/feedback.vue";
import userfklist from "../components/userfklist.vue";
import talkLowerLevel from "../components/talkLowerLevel.vue";
import talkchangeservice from "../components/talkchangeservice.vue";
import talkdistributetoday from "../components/talkdistributetoday.vue";
import setNextContactTime from "../components/talkSetNextContactTime.vue";
import talkneedcontact from "../components/talkneedcontact.vue";
import talktodoc from "../components/talktodoc.vue";
import lookdrug2 from "../components/lookdrug2.vue";
import userorderlist from "../components/userorderlist.vue"; //查看用户订单
import talkflagsheetmark from "../components/talkflagsheetmark.vue"; //标记诊单
import replytemplate from "../components/replytemplate.vue"; //回复模板
import talkSCorderdetail from "../components/talkSCorderdetail.vue"; //聊天接收商城订单信息
import recorder from "../components/recorder1.vue"; //录音文件
import talkVideoCard from "../components/talkVideoCard.vue"; //发送视频卡
import foot from "../components/talkfootprint.vue"; //查看足迹

import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

import { insert, del, push, read, search, deletemore7 } from "../assets/sql2.js";
import {
  addu,
  readu,
  delu,
  updateu,
  putu,
  inu,
  deletemore7u,
  deletenoin7day,
} from "../assets/msglist.js";
var connection,
  nowtouser,
  _this,
  alllist = [],
  ele,
  db = null;

const appData = require("../assets/emoji.json");
var serve = "";
export default {
  name: "child",
  components: {
    preview,
    xypreview,
    uploadIMG,
    talkvideo,
    videoList,
    sendmoney,
    payMoneyList,
    talkMyMsg,
    sendorder,
    talksettag,
    talkorder,
    talklookorder,
    userxylist,
    userzdlist,
    usermoneylist,
    talkassigDoc,
    talksendcase,
    feedback,
    userfklist,
    talkLowerLevel,
    talkchangeservice,
    talkdistributetoday,
    setNextContactTime,
    talkneedcontact,
    talktodoc,
    lookdrug2,
    userorderlist,
    talkflagsheetmark,
    replytemplate,
    talkSCorderdetail,
    recorder,
    talkVideoCard,
    foot,
  },
  data() {
    return {
      serve: "",
      talktp: require("../assets/talktp.png"),
      talktb: require("../assets/talktb.png"),
      talkbl: require("../assets/talkbl.png"),
      talkbm: require("../assets/talkbm.png"),
      talkdx: require("../assets/talkdx.png"),
      talkgh: require("../assets/talkgh.png"),
      talkxy: require("../assets/talkxy.png"),
      talkys: require("../assets/talkys.png"),
      talkzd: require("../assets/talkzd.png"),
      talkfk: require("../assets/talkfk.png"),
      talklx: require("../assets/talklx.png"),
      talkdd: require("../assets/talkdd.png"),
      talkconnection: "",
      servicer_id: sessionStorage.getItem("servicer_id"),
      myMsg: {
        account: sessionStorage.getItem("name"),
        serveid: sessionStorage.getItem("servicer_id"),
        headimg: sessionStorage.getItem("headimg"),
        pwd: sessionStorage.getItem("pwd"),
      },
      msg: require("../assets/msg.mp3"),
      sid: 0,
      stype: "", //客服类型
      message: "",
      list: [], //用户列表
      talklist: [], //聊天内容列表
      dialogFormVisible: false,
      female: require("../assets/female.png"),
      male: require("../assets/male.png"),
      userheadimg: "",
      toID: "",
      picsrc: "",
      activeName: "second",
      row: "",
      index: 1, //开始页数
      lookmoreshow: true, //查看历史记录按钮
      bigpic: false, //查看大图弹窗
      bigpicurl: "", //查看大图路径
      choicezd: false, //选择诊单弹窗
      searchfiel: "", //用户的电话号
      zdlist: [], //用户已有的诊单列表
      newzd: false, //是否需要新建诊单
      selectzd: "", //用户选择的诊单模板id
      selectitem: {}, //用户选择的诊单模板内容
      options: [], //诊单模板的选项
      havedangan: "", //用户在系统内是否有档案
      kefuname: "", //客服姓名
      talkname: "", //用户聊天姓名
      searchcust: "", //查询用户的id，即电话号
      cust_id: "", //查询的用户cust_id
      custall: "", //右键所在的item
      visible: false, //右键菜单是否显示
      ispic: false,
      isword: true,
      piclist: [], //发送的图片列表
      serve_id: "", //服务id
      iconConfig: {
        placement: "top",
        size: "22px",
        name: "fa-smile-o", // font awesome icon name
        color: "#606266", // icon color: hex、rgb or rgba value
      },
      faceList: [], //表情的列表
      smileicon: require("../assets/icon-xiaolian.png"),
      emoji: false,
      headimg: "",
      lookusermsg: false, //用户信息弹窗
      LookUserMsg: {}, //用户信息存放变量
      LookUserMsgtalk: {
        desc: "",
        nextConcatContent: "",
        nextConcatTime: "",
        notes: "",
      }, //用户备注信息等存放变量
      help: {
        tel: "",
        pwd: "",
      },
      searchUserID: false,
      isRegisterUser: false,
      pushDoc: false, //推送医生
      dockeywords: "",
      doclist: [],
      multipleSelection: [],
      friendlist: [], //好友列表
      friendpage: 1, //好友列表oage
      setDesc: false, //设置备注
      setdesc: "", //备注名
      setdescitem: "", //暂存设置备注
      uid: "",
      searchtxl: "", //搜索通讯录好友
      threedayfriendlist: [], //最近三天好友
      noMore: false,
      noMore1: true,
      changeStatus: "",
      changeStatusVal: "",
      statusoptions: [
        { id: 1, label: "待完善寻医信息" },
        { id: 2, label: "等待精准匹配好医生" },
        { id: 3, label: "已被医生选取" },
        { id: 4, label: "等待完善疾病信息" },
        { id: 5, label: "执行医生方案" },
        { id: 6, label: "治疗无效等待匹配其他医生治疗" },
        { id: 8, label: "确认有效继续治疗" },
        { id: 7, label: "已治愈" },
      ],
      Ill_Lib: "", //用户信息疾病名
      Doc_Name: "", //用户信息医生名
      bindillcheckbox: false,
      bindillval: "",
      Bind_Ill: false,
      searchill: "",
      bindilllist: [],
      Bind_Doc: false,
      searchdoc: "",
      binddoclist: [],
      changeStatustc: false,
      binddoccheckbox: "",
      illLibList: "",
      beforeToUser: "",
      choicexunyi: false, //寻医管理
      selectxy: "",
      optionsxy: "",
      xylist: [],
      selectitemxy: {}, //用户选择的寻医模板内容
      Newchoicezd: false, //新的诊单
      Newselectzd: "",
      Newoptions: [],
      Newzdlist: [],
      Newselectitem: {},
      EditZD: false, //编辑诊单
      EditMsg: {
        kid: "",
        custid: "",
        noedit: true,
      },
      EditXY: false, //编辑寻医
      EditXYMsg: {
        kid: "",
        custid: "",
        noedit: true,
      },
      pusgDocSearch: "", //推送医生搜索关键字
      selectDocList: "", //所选医生列表
      MyZDType: "5", //我的诊单类型
      MyZDTypeoptions: [
        //我的诊单选项
        { value: "5", label: "寻医表" },
        { value: "6", label: "问诊单" },
        { value: "7", label: "反馈表" },
      ],
      MyZDState: "", //我的诊单状态
      MyXYStateoptions: [
        //寻医表状态选项
        { value: "0", label: "未提交" },
        { value: "1", label: "待挂号" },
        { value: "6", label: "审核" },
        { value: "2", label: "已抓取" },
        { value: "9", label: "删除" },
      ],
      MyZDStateoptions: [
        //诊单表状态选项
        { value: "0", label: "未填写" },
        { value: "1", label: "提交" },
        { value: "2", label: "审核" },
        { value: "3", label: "打回" },
        { value: "6", label: "已下发" },
        { value: "9", label: "删除" },
      ],
      MyZDDate: "", //我的诊单日期
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      MyDayList: [], //我的诊单列表
      MyZDcurrent: 1, //默认显示第几页
      MyZDtotalCount: 0, // 总条数，根据接口获取数据长度
      MyZDpageSizes: [10], // 个数选择器（可修改）
      MyZDPageSize: 10, // 默认每页显示的条数（可修改）
      MyZDvalue: false,
      // MyZDIndex: 1,
      LSHH: false, //临时会话
      LSHHROW: {
        name: "",
        headImg: "",
      },
      LStalklist: [],
      LSindex: 1,
      LStoID: "",
      CUM: false, //修改用户信息弹窗
      CUMname: "", //用户姓名
      CUMsex: "0", //用户性别
      CUMage: "", //用户年龄
      CUMheight: "", //用户身高
      CUMweight: "", //用户体重
      sendvoice: false,
      pageindex: 1,
      pageYN: true,
      allfriendcount: "", //通讯录好友总数
      // isNotificationShow: false,
      fiveList: [], //待处理列表
      fivecurrent: 1, //默认显示第几页
      fivetotalCount: 0, // 总条数，根据接口获取数据长度
      fivepageSizes: [10], // 个数选择器（可修改）
      fivePageSize: 10, // 默认每页显示的条数（可修改）
      fivevalue: false,
      VideoChoiceDoc: false, //发起会话选择医生
      toID1: "",
      connection1: "",
      nowtouser1: "",
      showvideolist: false,
      xtmsgNum: "", //未读消息数
      isShowNewstype: false, //是否显示未读消息弹窗
      shownewsnum: false, //是否显示未读消息数
      sendM: false, //发送诊费弹窗
      showMoneyList: false,
      plus: false,
      emoji1: false,
      showsend: false,
      showplus: true,
      settag: false,
      tagId: "",
      showOrder: false,
      showsendorder: false,
      indexedDBindex: 1,
      talklistloading: false, //同步聊天记录
      lookorder: false, //查看收款详情弹窗
      talkorderid: "", //40元诊费id
      talkordertype: "", //订单类型，诊费还是订单
      showusermsg: false, //是否显示用户信息
      talkitem: "",
      friendlistToId: "", //wanttalk需要用到得id以及信息
      friendlistusertag: "", //通讯录页面患者得标签
      userxylist: [],
      lookuserxy: false, //查看用户寻医
      lookuserzd: false, //查看用户诊单
      lookuserfk: false, //查看用户反馈
      lookuserzf: false, //查看用户诊费
      lookuserdd: false, //查看用户订单
      friendlistcustid: "",
      indexList: [
        "#",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      loadingfriend: false,
      finishedfriend: false,
      db: "",
      db1: "",
      assigdoctor: false, //寻医表客服分配医生
      assigdoctoritem: "", //寻医表的信息
      sendcase: false, //给患者发送反馈视频
      clickshowhidebtn: false,
      isshowbtn: true,
      ishidebtn: false,
      showhidetips: "折叠菜单",
      usertell: "",
      tjcustid: "",
      tjnum: {
        xybzs: "0",
        xyysh: "0",
        xyyzq: "0",
      },
      feedbacktc: false, //发送反馈表弹窗显示
      feedbackoptions: [], //模板list
      feedbackvalue: "", //反馈表模板ID
      feedbacklist: [], //用户已有反馈表列表
      lookfeedback: false, //显示反馈表弹窗
      feedbackmsg: {}, //选择的反馈模板信息
      feedbackid: "", //反馈信息id
      feedbacknoedit: true, //反馈表是否可以编辑
      setuserfilesbtn: false, //新建档案按钮
      setuserfilestel: "", //建档用户电话号
      setuserfilesbtncustid: false, //新建档案按钮用户编号
      setuserfilescustid: "", //建档用户电话号用户编号
      gettalkLowerLevel: false, //获取我的组员信息
      get_own_roles: "", //获取客服自己的角色权限
      TalkSetNextContactTime: false, //设置下次联系时间弹窗
      ContactId: "", //下次联系人ID
      needcontact: false, //今日需联系人请求接口
      msglistloading: true, //第一次加载消息列表的loading
      isshowtel: false, //是否显示电话号
      tjkefuchoice: "", //统计页面选择客服
      tjkefulist: [], //统计页面客服列表
      lowerright: true, //是否是下级的查看用户详情
      todocdb: "",
      shenhe: false, //审核是否生成图片
      lookdrugtc: false, //查看订单弹窗
      drugkid: "", //订单KID
      drugcustid: "", //订单用户id
      drugcusttel: "", //订单用户电话
      pdfloading: false, //生成pdf
      setLevel: false, //设置用户等级弹窗
      setlevelval: "",
      leveloptions: [
        { id: 1, label: 1 },
        { id: 2, label: 2 },
        { id: 3, label: 3 },
        { id: 4, label: 4 },
        { id: 5, label: 5 },
      ],
      setlevelcustid: "",
      LookUserlivinglevel: "", //查看用户等级
      isshenhe: false,
      friendlistloading: false, //通讯录加载loading
      setreply: false, //设置回复信息弹窗
      isshowscorder: false, //商城订单接收
      scorderid: "", //商城订单id
      selectxydoc: "", //寻医表先选择医生
      selectxydocmsg: "", //寻医表查询医生的信息
      isshowselectxy: false, //是否先选择了医生
      xyactive: "xyfirst",
      searchxymuban: "", //根据关键字搜索寻医模板
      xymubanlist: [], //寻医模板查询结果
      currentxy: 1, //默认显示第几页
      totalCountxy: 0, // 总条数，根据接口获取数据长度
      pageSizesxy: [6], // 个数选择器（可修改）
      PageSizexy: 6, // 默认每页显示的条数（可修改）
      valuexy: false,
      zdactive: "zdfirst", //诊单tab
      currentzd: 1, //默认显示第几页
      totalCountzd: 0, // 总条数，根据接口获取数据长度
      pageSizeszd: [6], // 个数选择器（可修改）
      PageSizezd: 6, // 默认每页显示的条数（可修改）
      valuezd: false,
      fkactive: "fkfirst", //反馈tab
      currentfk: 1, //默认显示第几页
      totalCountfk: 0, // 总条数，根据接口获取数据长度
      pageSizesfk: [6], // 个数选择器（可修改）
      PageSizefk: 6, // 默认每页显示的条数（可修改）
      valuefk: false,
      SoundRecording: false, //录音
      audioElement: [], //存放audio标签的数组
      ishelp: false, //是否是客服帮助填写
      isshowVideoCard: false,
      rightmenusetnextcontenttimetc: false, //设置下此联系时间弹窗
      nextTCMsg: {
        nextConcatContent: "",
        nextConcatTime: "",
        notes: "",
      },
      lookfoot: false, //查看足迹
      footuserid: "", //查看患者足迹id
    };
  },
  watch: {
    havedangan(o, u) {
      this.havedangan = o;
    },
    visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    },
    message(val) {
      if (val != "") {
        this.showsend = true;
        this.showplus = false;
        sessionStorage.setItem(this.toID, val);
      } else {
        this.showsend = false;
        this.showplus = true;
        sessionStorage.removeItem(this.toID);
      }
    },
    tjkefuchoice(val, oldval) {
      let newindex = val.indexOf("all"),
        oldindex = oldval.indexOf("all"); //获取val和oldval里all的索引,如果没有则返回-1
      if (newindex != -1 && oldindex == -1 && val.length > 1)
        //如果新的选择里有勾选了选择所有选择所有 则 只直线勾选所有整个选项
        this.tjkefuchoice = ["all"];
      else if (newindex != -1 && oldindex != -1 && val.length > 1)
        //如果操作前有勾选了选择所有且当前也选中了勾选所有且勾选数量大于1  则移除掉勾选所有
        this.tjkefuchoice.splice(val.indexOf("all"), 1);
    },
    MyZDDate(newVal, oldVal) {
      if (!newVal) {
        this.MyZDDate = [];
      }
    },
    searchxymuban(n, o) {
      if (n != "") {
        this.selectxydoc = "";
        this.xymubanlist = [];
        // this.selectxydocmsg = [];
      }
    },
    selectxydoc(n, o) {
      if (n != "") {
        this.searchxymuban = "";
        this.xymubanlist = [];
        // this.selectxydocmsg = [];
      }
    },
  },
  filters: {
    capitalize: function(value) {
      if (value == "0001-01-01 00:00:00") return "";
      if (!value) return "";
      value = value.substr(0, 10);
      return value;
    },
    capitalize2: function(value) {
      if (value == "0001-01-01 00:00:00") return "";
      if (!value) return "";
      value = value.substr(10, value.length);
      return value;
    },
    filtername: function(value, id) {
      if (value.indexOf("dxhs_") == 0) {
        return "待完善用户" + id.substr(id.length - 4);
      } else {
        return value;
      }
    },
  },
  mounted() {
    // window.addEventListener(
    //   "contextmenu",
    //   function(e) {
    //     e.preventDefault();
    //   },
    //   false
    // );
    serve = this.serveurl.serve;
    this.serve = serve;
    this.stype = sessionStorage.getItem("stype");
    if (serve == "http://192.168.110.220:20202") {
      setTimeout(function() {
        alert("测试地址");
      }, 10);
    }
    this.uid = sessionStorage.getItem("id"); //获取客服操作ID
    //获取当前客服的权限类型，3：全平台；2：部长；1：班长；0：组员
    this.axios.get(serve + "/api/Employee/get_roles?Id=" + this.uid).then((res) => {
      if (res.data.code == 1) {
        console.log(res);
        this.get_own_roles = res.data.result;
      }
    });
    //创建客服专属的数据库
    db = openDatabase("mydb" + this.uid, "1.0", "Test DB", 2000 * 1024 * 1024);
    this.todocdb = db;
    _this = this;
    /*获取键盘事件，发送消息*/
    document.onkeydown = function(event) {
      if (event.ctrlKey && event.keyCode == 13) {
        console.log("你按下了CTRL+Enter");
        _this.send();
      }
    };
    /*获取客服头像*/
    if (sessionStorage.getItem("headimg")) {
      this.headimg = sessionStorage.getItem("headimg");
    } else {
      this.headimg = this.female;
    }
    /*表情注入*/
    for (let i in appData) {
      this.faceList.push(appData[i].char);
    }
    this.serve_id = sessionStorage.getItem("servicer_id"); //获取服务ID
    this.kefuname = sessionStorage.getItem("name"); //获取客服昵称
    // Get the user name and store it to prepend to messages.
    this.sid = sessionStorage.getItem("id"); //prompt('Enter your id:', '');
    //先加载本地的聊天列表
    db.transaction(function(tx) {
      tx.executeSql(
        "CREATE TABLE if not exists `msglist" +
          _this.sid +
          "` (`desc` TEXT,`name` TEXT,`id` varchar(20) PRIMARY KEY,`sendTime` varchar(100),`noReads` int,`headImg` TEXT,`hasmsg` varchar(10),`isOnline` varchar(10))",
        [],
        function(tx, result) {
          _this.db1 = db;

          readu(_this.db1, _this.sid, function(res) {
            if (res.length > 0) {
              _this.axios
                .get(
                  serve +
                    "/api/MessageStore/get_message_store?service=s" +
                    _this.sid +
                    "&index=1&pagesize=1"
                )
                .then((res) => {
                  if (res.data.result.length > 0) {
                    var arr = res.data.result;
                    var show = true;
                    var j = arr.length;
                    for (let a = 0; a < arr.length; a++) {
                      inu(_this.db1, _this.sid, arr[a].id, function(res) {
                        // console.log(res);
                        if (arr[a].noReads > 0 && res == "in") {
                          updateu(_this.db1, _this.sid, arr[a].id, "noReads", arr[a], function(
                            res
                          ) {
                            updateu(_this.db1, _this.sid, arr[a].id, "sendTime", arr[a], function(
                              res
                            ) {
                              updateu(_this.db1, _this.sid, arr[a].id, "isOnline", arr[a], function(
                                res
                              ) {});
                            });
                          });
                        } else if (arr[a].noReads > 0 && res == "out") {
                          putu(_this.db1, _this.sid, arr[a].id, arr[a], function(res) {});
                          updateu(_this.db1, _this.sid, arr[a].id, "noReads", arr[a], function(
                            res
                          ) {
                            updateu(_this.db1, _this.sid, arr[a].id, "sendTime", arr[a], function(
                              res
                            ) {
                              updateu(_this.db1, _this.sid, arr[a].id, "isOnline", arr[a], function(
                                res
                              ) {});
                            });
                          });
                        } else if (res == "out") {
                          delu(_this.db1, _this.sid, arr[a].id, function(res) {
                            // console.log(res);
                          });
                        }
                      });
                      if (j == a) {
                        show = false;
                        break;
                      }
                    }
                    if (show) {
                      deletenoin7day(_this.db1, _this.sid, function(res) {
                        if (res == "ok") {
                          //超过7天的先把聊天记录的表删除，再从列表里删除
                          deletemore7u(_this.db1, _this.sid, function(res) {
                            readu(_this.db1, _this.sid, function(res) {
                              _this.list = res;
                              _this.msglistloading = false;
                            });
                          });
                        }
                      });
                    }
                  } else {
                  }
                });
            } else {
              _this.axios
                .get(
                  serve +
                    "/api/MessageStore/get_message_store?service=s" +
                    _this.sid +
                    "&index=1&pagesize=1"
                )
                .then((res) => {
                  if (res.data.result.length > 0) {
                    addu(_this.db1, _this.sid, res.data.result);
                    deletenoin7day(_this.db1, _this.sid, function(res) {
                      if (res == "ok") {
                        deletemore7u(_this.db1, _this.sid, function(res) {
                          _this.list = res;
                          _this.msglistloading = false;
                        });
                      }
                    });
                  } else {
                  }
                });
            }
          });
          //查询最近3天被转移走的人的名单，如果有被转移走的，从本地库里删除
          _this.axios
            .get(serve + "/api/user/get_allot_by_fromid?fromid=s" + _this.sid)
            .then((res) => {
              console.log(_this.db1);
              if (res.data.code == 1) {
                var arr = Array.from(new Set(res.data.result));
                for (var i = 0; i < arr.length; i++) {
                  if (nowtouser == arr[i]) {
                    _this.dialogFormVisible = false;
                  }
                  delu(_this.db1, _this.sid, arr[i], function(res) {
                    readu(_this.db1, _this.sid, function(res) {
                      _this.list = res;
                    });
                  });
                }
              }
            });
        },
        function(tx, error) {
          console.log("创建消息表失败:" + error.message);
          _this.msglistloading = false;
        }
      );
    });

    //创建本地回复模板
    db.transaction(function(tx) {
      tx.executeSql(
        "CREATE TABLE if not exists `replay" +
          _this.sid +
          "` (id integer primary key autoincrement,`content` TEXT)",
        [],
        function(tx, result) {
          console.log("创建stu表成功");
        },
        function(tx, error) {
          console.log("创建stu表失败:" + error.message);
        }
      );
    });
    // Start the connection..withAutomaticReconnect()
    connection = new signalR.HubConnectionBuilder()
      .withUrl(serve + "/chathub")

      .build();
    this.talkconnection = connection;
    connection.onclose((error) => {
      // _this.$alert("您已断开连接，请重新登录", "", {
      //   confirmButtonText: "确定",
      //   closable: false,
      //   callback: (action) => {
      //     localStorage.clear();
      //     sessionStorage.clear();
      //     _this.$router.push({ path: "/" });
      //   },
      // });
    });
    connection.on("remove_user", (res) => {
      // console.log(res);
      if (nowtouser == res) {
        this.dialogFormVisible = false;
      }
      delu(this.db1, this.sid, res, function(res) {
        readu(_this.db1, _this.sid, function(res) {
          _this.list = res;
        });
      });
      //用户被转移刷新列表
      this.getthreedayfrienddata(); //最近新增
      this.friendload(); //通讯录
    });
    //Create a function that the hub can call to broadcast messages.
    connection.on("recieve", function(message, user) {
      console.log(message);
      console.log(user);
      //浏览器显示通知开始
      // 先检查浏览器是否支持
      if (!("Notification" in window)) {
        console.log("您的浏览器暂不支持网站接收通知，请查看浏览器版本或打开浏览器通知权限！");
      } else {
        console.log("zhichi");
      }
      var Notification = window.Notification || window.mozNotification || window.webkitNotification;
      if (Notification) {
        if (Notification.permission === "default" || Notification.permission === "denied") {
          Notification.requestPermission().then((res) => {
            // denied 或 granted
            console.log(res);
          });
        }
      }
      //设置显示文字
      var notificationdata = "",
        notificationstr = "";
      //文字
      if (message.type == 0) {
        notificationdata = {
          // 正文内容
          body: message.content,
          // 图标
          // icon: 'https://common.cnblogs.com/images/wechat.png',
          // 预览大图
          // image: notificationdata,
          // 通知id
          // tag: 1,
          // 是否一直保持有效
          requireInteraction: true,
        };
        notificationstr = "患者 " + user.desc + " 给您发来了一条消息";
      } else if (message.type == 1) {
        //1图片
        notificationdata = {
          image: message.content,
          requireInteraction: true,
        };
        notificationstr = "患者 " + user.desc + " 给您发来了一张图片";
      } else if (message.type == 14) {
        notificationdata = {
          image: message.content,
          requireInteraction: true,
        };
        notificationstr = "患者 " + user.desc + " 给您发来了一段语音";
      }
      let notification = new Notification(notificationstr, notificationdata);
      //浏览器显示通知结束
      var audio = document.getElementById("music");
      audio.play();
      if (user.type == "1" || user.type == "0") {
        user.hasmsg = true;
        user.isOnline = true;
        user.sendTime = message.sendTime;
        putu(_this.db1, _this.sid, user.id, user, function(res) {
          if (res == "has") {
            updateu(_this.db1, _this.sid, user.id, "sendTime", user, function(res) {
              updateu(_this.db1, _this.sid, user.id, "isOnline", user, function(res) {
                updateu(_this.db1, _this.sid, user.id, "hasmsg", user, function(res) {
                  readu(_this.db1, _this.sid, function(res) {
                    _this.list = res;
                  });
                });
              });
            });
          } else {
            readu(_this.db1, _this.sid, function(res) {
              _this.list = res;
              _this.axios
                .post(
                  serve + "/api/MessageStore/add",
                  _this.qs.stringify({
                    UserId: user.id,
                    Service: "s" + _this.sid,
                    Name: user.name,
                    HeadImg: user.headImg,
                    Desc: user.desc,
                  })
                )
                .then((res) => {
                  console.log(res);
                });
            });
          }
        });
        push(db, user.id, message);
      } else if (user.type == 10) {
        console.log(this.doclist);
      }
      // Html encode display name and message.
      var encodedName = user.name;
      var encodedMsg = message.content;
      // Add the message to the page.
      if (message.from == nowtouser) {
        _this.talklist.push(message);
        _this.axios
          .post(
            serve + "/api/User/read_message",
            this.qs.stringify({ fromID: user.id, toID: "s" + _this.sid })
          )
          .then((res) => {
            if (res.data.code == 1) {
              console.log(res);
            }
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    });
    // Transport fallback functionality is now built into start.
    connection
      .start()
      .then(function() {
        sessionStorage.setItem("logout", "");
        //链接成功时将用户名和连结ID发送至后台。
        var user = {
          id: "s" + _this.sid,
          headimg: sessionStorage.getItem("headimg"),
          name: _this.kefuname,
          type: Number(sessionStorage.getItem("stype")), //2
          noreadmessagecount: 0,
          connectionId: connection.connectionId,
        };
        connection.invoke("login", user);
      })
      .catch((error) => {
        // alert("jjj");
        console.error(error.message);
      });
    //监听用户上线情况
    connection.on("online", function(user) {
      if (user.type == "1" || user.type == "0") {
        user.isOnline = true;
        updateu(_this.db1, _this.sid, user.id, "isOnline", user, function(res) {
          readu(_this.db1, _this.sid, function(res) {
            _this.list = res;
          });
        });
      }
    });

    //20220506新增，监听用户上线后通过浏览器弹窗提示某某已经上线
    connection.on("user_login", function(user) {
      console.log(user);
      // 先检查浏览器是否支持
      if (!("Notification" in window)) {
        console.log("您的浏览器暂不支持网站接收通知，请查看浏览器版本或打开浏览器通知权限！");
      } else {
        console.log("zhichi");
      }
      var Notification = window.Notification || window.mozNotification || window.webkitNotification;
      if (Notification) {
        console.log(Notification);
        if (Notification.permission === "default" || Notification.permission === "denied") {
          Notification.requestPermission().then((res) => {
            // denied 或 granted
            console.log(res);
          });
        } else {
          console.log(Notification);
        }
      } else {
        console.log(Notification);
      }
      //设置显示文字
      var notificationdata = {
        // 正文内容
        body: "",
        // 是否一直保持有效
        requireInteraction: true,
      };
      var notificationstr = "患者 " + user.name + " 登陆了";
      console.log(notificationstr);
      console.log(notificationdata);
      let notification = new Notification(notificationstr, notificationdata);
      notification.onclick = function() {
        _this.wanttalk(user);
      };
    });
    //监听用户退出登录情况
    connection.on("logout", function(user) {
      connection.stop();
      sessionStorage.setItem("logout", "logout");
      // _this.$alert("您的账号已在另一台电脑登录，请重新登录", "", {
      //   confirmButtonText: "确定",
      //   closable: false,
      //   callback: (action) => {
      //     localStorage.clear();
      //     sessionStorage.clear();
      //     _this.$router.push({ path: "/" });
      //   },
      // });
    });
    //监听用户下线情况
    connection.on("offline", function(user) {
      user.isOnline = false;
      updateu(_this.db1, _this.sid, user.id, "isOnline", user, function(res) {
        readu(_this.db1, _this.sid, function(res) {
          _this.list = res;
        });
      });
      this.axios
        .post(
          "/log/chat_login_log",
          this.qs.stringify({
            data: "【聊天下线】 " + user.id + "  ---   " + rs.data.result.account,
          })
        )
        .then((res) => {});
    });
    //监听是否更改进线状态
    connection.on("kick", function() {
      connection.stop();
      sessionStorage.setItem("logout", "logout");
      _this.$alert("您的状态被更改，请重新登录", "", {
        confirmButtonText: "确定",
        closable: false,
        callback: (action) => {
          localStorage.clear();
          sessionStorage.clear();
          _this.$router.push({ path: "/" });
        },
      });
    });
    //查询待审核得诊单this.loadZD();
    this.loadZD();
  },
  methods: {
    stopPropagation(e) {
      e = e || window.event;
      if (e.stopPropagation) {
        //W3C阻止冒泡方法
        e.stopPropagation();
      } else {
        e.cancelBubble = true; //IE阻止冒泡方法
      }
    },
    friendload() {
      this.loadingfriend = true;
      this.friendlistloading = true;
      this.axios
        .get(
          serve +
            "/api/User/get_user_with_relation?userID=s" +
            this.sid +
            "&index=" +
            this.friendpage
        )
        .then((res) => {
          // console.log(res);
          if (res.data.code == 1) {
            this.allfriendcount = res.data.count;
            if (res.data.result.length > 0) {
              this.friendlist = this.friendlist.concat(res.data.result);
              // for (let a = 0; a < this.friendlist.length; a++) {
              //   let element = this.friendlist[a];
              //   if (
              //     element.pinyinIndex == null ||
              //     element.pinyinIndex == "" ||
              //     element.pinyinIndex == undefined
              //   ) {
              //     element.pinyinIndex = "#";
              //   }
              // }
              this.friendpage = this.friendpage + 1;
            } else {
              this.finishedfriend = true;
            }
          } else {
            this.finishedfriend = true;
            this.loadingfriend = false;
          }
          this.loadingfriend = false;
          this.friendlistloading = false;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getfrienddata(page) {
      //获取通讯录第一页
      this.axios
        .get(serve + "/api/User/get_user_with_relation?userID=s" + this.sid + "&index=" + page)
        .then((res) => {
          // console.log(res);
          if (res.data.code == 1) {
            this.allfriendcount = res.data.count;
            if (res.data.result.length > 0) {
              this.friendlist = this.friendlist.concat(res.data.result);
            } else {
              this.noMore = true;
            }
          } else {
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    friendload2() {},
    getthreedayfrienddata() {
      //获取最近添加
      this.axios
        .get(serve + "/api/User/get_recent_register_users?userID=s" + this.sid)
        .then((res) => {
          // console.log(res);
          if (res.data.code == 1) {
            if (res.data.result.length > 0) {
              this.threedayfriendlist = res.data.result;
            } else {
              this.threedayfriendlist = [];
            }
          } else {
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    handleClick(tab, event) {
      //判断当用户退出登录后，不可再操作
      if (tab.index == 0) {
        //消息
        this.friendpage = 1;
        this.showvideolist = false;
        this.showMoneyList = false;
        this.showOrder = false;
        this.showusermsg = false;
        this.gettalkLowerLevel = false;
        this.needcontact = false;
      } else if (tab.index == 1) {
        //通讯录
        this.friendlist = [];
        this.friendpage = 1;
        this.searchtxl = "";
        this.showvideolist = false;
        this.showMoneyList = false;
        this.showOrder = false;
        this.gettalkLowerLevel = false;
        this.needcontact = false;
        this.loadingfriend = true;
        this.friendlistloading = true;
        this.finishedfriend = false;
        this.friendload();
      } else if (tab.index == 2) {
        //获取最近新增列表
        this.getthreedayfrienddata();
        this.showvideolist = false;
        this.showMoneyList = false;
        this.showOrder = false;
        this.showusermsg = false;
        this.gettalkLowerLevel = false;
        this.needcontact = false;
      } else if (tab.index == 3) {
        //个人信息
        this.showvideolist = false;
        this.showMoneyList = false;
        this.showOrder = false;
        this.showusermsg = false;
        this.gettalkLowerLevel = false;
        this.needcontact = false;
      } else if (tab.index == 4) {
        //统计
        // this.getMyDayData();
        this.showvideolist = false;
        this.showMoneyList = false;
        this.showOrder = false;
        this.showusermsg = false;
        this.gettalkLowerLevel = false;
        let date1 = this.GetDateStr(0, "-");
        let date2 = this.GetDateStr(1, "-");
        this.needcontact = false;
        this.tjgetkefulist();
        this.getkftj(date1, date2);
      } else if (tab.index == 5) {
        //支付列表
        this.showMoneyList = true;
        this.showvideolist = false;
        this.showOrder = false;
        this.showusermsg = false;
        this.gettalkLowerLevel = false;
      }
      //  else if (tab.index == 6) {
      //   //视频列表
      //   this.showvideolist = true;
      //   this.showMoneyList = false;
      //   this.showOrder = false;
      //   this.showusermsg = false;
      //   this.gettalkLowerLevel = false;
      // }
      else if (tab.index == 6) {
        //订单
        this.showvideolist = false;
        this.showMoneyList = false;
        this.showOrder = true;
        this.showusermsg = false;
        this.gettalkLowerLevel = false;
      } else if (tab.index == 7) {
        //今日分配
        this.showvideolist = false;
        this.showMoneyList = false;
        this.showOrder = false;
        this.showusermsg = false;
        this.gettalkLowerLevel = false;
        this.needcontact = false;
      } else if (tab.index == 8) {
        //今日需联系
        this.showvideolist = false;
        this.showMoneyList = false;
        this.showOrder = false;
        this.showusermsg = false;
        this.gettalkLowerLevel = false;
        this.needcontact = true;
      }
      //  else if (tab.index == 9) {
      //    console.log(tab.index)
      //   //医生列表
      //   this.showvideolist = false;
      //   this.showMoneyList = false;
      //   this.showOrder = false;
      //   this.showusermsg = false;
      //   this.gettalkLowerLevel = false;
      //   this.needcontact = false;
      // }
      else if (tab.index == 9) {
        //退出按钮
        this.talkout();
        console.log("退出");
      } else if (tab.index == 10) {
        //我的下级
        this.showvideolist = false;
        this.showMoneyList = false;
        this.showOrder = true;
        this.showusermsg = false;
        this.gettalkLowerLevel = true;
        this.needcontact = false;
      } else if (tab.index == 11) {
        //给下级转移服务人
      }
      if (sessionStorage.getItem("logout") == "logout") {
        this.$message({
          message: "账号已在另一台电脑登录，请重新登陆",
          type: "error",
        });
      } else {
        // this.getzaixian();
        this.dialogFormVisible = false;
      }
    },
    //注入表情
    getEmo(index) {
      var textArea = document.getElementById("cOthersResponsible");
      function changeSelectedText(obj, str) {
        if (window.getSelection) {
          // 非IE浏览器
          textArea.setRangeText(str);
          // 在未选中文本的情况下，重新设置光标位置
          textArea.selectionStart += str.length;
          textArea.focus();
        } else if (document.selection) {
          // IE浏览器
          obj.focus();
          var sel = document.selection.createRange();
          sel.text = str;
        }
      }
      changeSelectedText(textArea, this.faceList[index]);
      this.message = textArea.value; // 要同步data中的数据
      return;
    },
    //按照某一字段进行排序
    compare(property) {
      return function(a, b) {
        var value1 = a[property] == false ? 0 : 1;
        var value2 = b[property] == false ? 0 : 1;
        return value2 - value1;
      };
    },
    //按照日期排序
    comparetime(property) {
      return function(a, b) {
        var value1 = new Date(a[property]).getTime();
        var value2 = new Date(b[property]).getTime();
        return value2 - value1;
      };
    },
    /** 获取当前时间 格式：yyyy-MM-dd HH:MM:SS*/
    getCurrentTime() {
      var date = new Date(); //当前时间
      var month = this.zeroFill(date.getMonth() + 1); //月
      var day = this.zeroFill(date.getDate()); //日
      var hour = this.zeroFill(date.getHours()); //时
      var minute = this.zeroFill(date.getMinutes()); //分
      var second = this.zeroFill(date.getSeconds()); //秒
      //当前时间
      var curTime =
        date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
      return curTime;
    },
    /**补零*/
    zeroFill(i) {
      if (i >= 0 && i <= 9) {
        return "0" + i;
      } else {
        return i;
      }
    },
    //关闭右键菜单
    closeMenu() {
      this.visible = false;
    },
    //显示右键菜单
    show(item, id) {
      this.stopPropagation();
      this.visible = true;
      var e = window.event;
      ele = document.getElementById("rightmenu");
      ele.style.top = null;
      ele.style.left = null;
      ele.style.bottom = null;
      var mx = e.clientX;
      var my = e.clientY;
      var rmHeight = 242; //ele.offsetHeight
      var pageHeight = document.documentElement.clientHeight;
      if (my + rmHeight < pageHeight) {
        ele.style.top = my + "px";
        ele.style.left = mx + "px";
      } else {
        ele.style.bottom = 0 + "px";
        ele.style.left = mx + "px";
      }
      this.custall = item;
      this.searchfiel = id;
    },
    talkout() {
      console.log("tuichu ");
      connection.stop();
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push({ path: "/" });
    },
    userlogout() {
      //用户退出
      connection.stop();
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push({ path: "/" });
    },
    deltalkuser() {
      //删除本地聊天
      var row = this.custall;
      // console.log(row);
      this.$confirm("此操作将删除该聊天, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          //获取确定操作后，在本地的聊天列表中删除改条记录
          delu(this.db1, this.sid, this.custall.id, function(res) {
            // console.log(res);
            if (res == "ok") {
              readu(_this.db1, _this.sid, function(res) {
                _this.list = res;
              });
            }
          });
          del(this.db, this.custall.id, function(res) {
            console.log("shanchu");
            console.log(res);
          });
          this.axios
            .post(
              serve + "/api/MessageStore/remove",
              this.qs.stringify({ service: "s" + this.sid, userId: row.id })
            )
            .then((res) => {
              // console.log(res);
              if (res.data.code == 1) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                if (nowtouser == row.id) {
                  this.dialogFormVisible = false;
                }
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //点开聊天窗口
    lookup(row, id, type, name, desc) {
      this.audioElement = document.getElementsByClassName("talklistaudio");
      // this.stopallaudio();//切换窗口停止页面内所有audio播放
      // this.message = '';
      this.lookmoreshow = true;
      if (this.isShowNewstype == true) {
        this.isShowNewstype = !this.isShowNewstype;
      }
      if (sessionStorage.getItem(id)) {
        // console.log(sessionStorage.getItem(id))
        this.message = sessionStorage.getItem(id);
      } else {
        this.message = "";
      }
      this.toID = id;
      if (id != nowtouser) {
        //判断是当前的聊天窗口还是新的聊天窗窗口，对聊天记录进行操作
        this.talklist = [];
        console.log("换人了");
        //20220506主要增加，客服点击谁了告知系统
        var fromID = "s" + this.sid;
        connection.invoke("TalkToUser", id, fromID);
      }
      if (row.noReads > 0) {
        var noReads = row.noReads;
        var cc = this.gethistory(id)
          .then((res) => {
            var _aa = res;
            var _bb = _aa.slice(50 - (Number(noReads)*2+1), 50);
            console.log(_bb)
            return _bb;
          })
          .then((res) => {
            var arr = res;
            var j = arr.length;
            var show = true;
            for (let i = 0; i < arr.length; i++) {
              push(db, id, arr[i], function(res) {});
              if (i == j) {
                show = false;
              }
            }
            // console.log(show);
            if (show) {
              db.transaction(function(tx) {
                tx.executeSql(
                  "CREATE TABLE if not exists `user" +
                    id +
                    "` (`content` TEXT,`from` varchar(20),`sendTime` varchar(100),`to` varchar(50),`type` int,`id` varchar(200),`sid` varchar(200),`isRead` int)",
                  [],
                  function(tx, result) {
                    _this.db = db;
                    console.log("创建stu表成功");
                    //点击用户时先去查询数据库中是否存在数据

                    read(db, id, function(res) {
                      // console.log(res)
                      //如果有数据，直接获取最后50条
                      if (res.length > 0) {
                        // deletemore7(db, id, function(res) {
                        _this.talklist = _this.talklistextend(res);
                        _this.$nextTick(() => {
                          var div = document.getElementById("scrolldIV");
                          div.scrollTop = div.scrollHeight;
                        });
                        _this.indexedDBindex = 2;
                        // });
                      } else {
                        _this.gethistory(id).then((res) => {
                          insert(db, id, res);
                          // deletemore7(db, id, function(res) {
                          _this.talklist = res;
                          // });
                          _this.$nextTick(() => {
                            var div = document.getElementById("scrolldIV");
                            div.scrollTop = div.scrollHeight;
                          });
                        });
                      }
                    });
                  },
                  function(tx, error) {
                    console.log("创建stu表失败:" + error.message);
                  }
                );
              });
            }
          });
      } else {
        //如果没有数据请求第一页
        db.transaction(function(tx) {
          tx.executeSql(
            "CREATE TABLE if not exists `user" +
              id +
              "` (`content` TEXT,`from` varchar(10),`sendTime` varchar(100),`to` varchar(50),`type` int,`id` varchar(200),`sid` varchar(200),`isRead` int)",
            [],
            function(tx, result) {
              _this.db = db;
              console.log("创建stu表成功");
              //点击用户时先去查询数据库中是否存在数据
              read(db, id, function(res) {
                //如果有数据，直接获取最后50条
                if (res.length > 0) {
                  // deletemore7(db, id, function(res) {
                  _this.talklist = _this.talklistextend(res);
                  _this.$nextTick(() => {
                    var div = document.getElementById("scrolldIV");
                    div.scrollTop = div.scrollHeight;
                  });
                  _this.indexedDBindex = 2;
                  // });
                } else {
                  _this.gethistory(id).then((res) => {
                    insert(db, id, res);
                    // deletemore7(db, id, function(res) {
                    _this.talklist = res;
                    // });
                    _this.$nextTick(() => {
                      var div = document.getElementById("scrolldIV");
                      div.scrollTop = div.scrollHeight;
                    });
                  });
                }
              });
            },
            function(tx, error) {
              console.log("创建stu表失败:" + error.message);
            }
          );
        });
      }

      nowtouser = id;
      if (type != 0) {
        this.isRegisterUser = true;
      } else {
        this.isRegisterUser = false;
      }
      if (desc != "" && desc != null && desc != undefined) {
        this.talkname = desc;
      } else if (name != "" && name != null && name != undefined) {
        //展示聊天姓名
        if (name.indexOf("dxhs_") == 0) {
          this.talkname = "待完善用户" + id.substr(id.length - 4);
        } else {
          this.talkname = name;
        }
      } else {
        this.talkname = "资料待完善用户" + id.substr(id.length - 4);
      }
      if (sessionStorage.getItem("logout") == "logout") {
        //判断客服的登录状态
        this.$message({
          message: "账号已在另一台电脑登录，请重新登陆",
          type: "error",
        });
      } else {
        row.hasmsg = false;
        this.searchfiel = id;
        this.dialogFormVisible = true;
        this.row = row;
        //设置头像
        if (
          row.headImg == "" ||
          row.headImg == null ||
          row.headImg == undefined ||
          row.headImg == "null" ||
          row.headImg == "undefined"
        ) {
          this.userheadimg = this.male;
        } else {
          this.userheadimg = row.headImg;
        }
        if (row.noReads > 0) {
          this.axios
            .post(
              serve + "/api/MessageStore/add",
              this.qs.stringify({
                UserId: row.id,
                Service: "s" + this.sid,
                Name: row.name,
                HeadImg: row.headImg,
                Desc: row.desc,
              })
            )
            .then((res) => {
              console.log(res);
            });
        }
        //把未读状态置为已读
        this.axios
          .post(
            serve + "/api/User/read_my_message",
            this.qs.stringify({ fromID: id, toID: "s" + this.sid })
          )
          .then((res) => {
            if (res.data.code == 1) {
              row.noReads = 0;
              row.hasmsg = false;
              updateu(_this.db1, _this.sid, row.id, "noReads", row, function(res) {
                updateu(_this.db1, _this.sid, row.id, "hasmsg", row, function(res) {
                  readu(_this.db1, _this.sid, function(res) {
                    _this.list = res;
                  });
                });
              });
            }
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    },
    //获取第一篇历史记录
    gethistory(id) {
      var list = this.axios
        .get(serve + "/api/Message/get_user_history_message?id=" + id + "&index=1")
        .then((res) => {
          if (res.data.code == 1) {
            this.indexedDBindex = 2;
            return res.data.result;
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
      return list;
    },
    //发送文字和图片
    send() {
      //type:0文本1图片2问诊单
      var toID = this.toID;
      var fromID = "s" + this.sid;
      if (this.message != "") {
        sessionStorage.setItem(this.toID, this.message);
        //说明是文字
        connection
          .invoke("send", toID, fromID, this.message, 0)
          .then((res) => {
            if (res.err == 1) {
              // console.log("1057", res);
              this.$message({
                message: "发送成功",
                type: "success",
              });
              sessionStorage.removeItem(this.toID);
              //发送成功
              if (toID == nowtouser) {
                var ms = {
                  content: res.data.content,
                  from: fromID,
                  type: "0",
                  sendTime: res.data.sendTime,
                };
                this.talklist.push(ms);
                push(db, toID, res.data);
              }
              updateu(this.db1, this.sid, toID, "sendTime", ms, function(res) {
                readu(_this.db1, _this.sid, function(res) {
                  _this.list = res;
                });
              });
              this.message = "";
              this.showsend = false;
              this.showplus = true;
            } else {
              this.axios
                .post(
                  "/test/log",
                  this.qs.stringify({ TtoID: toID, TfromID: fromID, Tmsg: this.message, Ttype: 0 })
                )
                .then((res) => {});
              this.$message({
                message: "发送失败，请重新发送",
                type: "error",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$message({
          message: "请填写内容",
          type: "warning",
        });
      }
    },
    //发送报名
    userbaoming() {
      this.$confirm("此操作将给用户发送报名表, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var toID = this.toID;
          var fromID = "s" + this.sid;
          connection.invoke("send", toID, fromID, "报名", 3).then((res) => {
            //type:0文本1图片2问诊单3报名4医生
            if (res.err == 1) {
              //发送成功
              this.$message({
                message: "发送成功",
                type: "success",
              });
              if (toID == nowtouser) {
                var ms = {
                  content: res.data.content,
                  from: fromID,
                  type: "3",
                  to: toID,
                  sendTime: res.data.sendTime,
                };
                this.talklist.push(ms);
                //个人消息记录变动
                push(db, toID, res.data);
              }
              //聊天列表变动
              updateu(this.db1, this.sid, toID, "sendTime", ms, function(res) {
                readu(_this.db1, _this.sid, function(res) {
                  _this.list = res;
                });
              });
            } else {
              this.axios
                .post(
                  "/test/log",
                  this.qs.stringify({ TtoID: toID, TfromID: fromID, Tmsg: "报名", Ttype: 3 })
                )
                .then((res) => {});
              this.$message({
                message: "发送失败，请重新发送",
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //上传图片
    upload(content) {
      let formData = new FormData();
      formData.append("file", content.file); // 'file[]' 代表数组 其中`file`是可变的
      this.axios
        .post("http://192.168.11.253:8080/qiniu/examples/upload_simple_file.php", formData)
        .then((rs) => {
          if (rs.data.code == 0) {
            this.picsrc = rs.data.file;
            this.ispic = true;
            this.isword = false;
            this.piclist.push(rs.data.file);
            var toID = this.toID;
            var fromID = "s" + this.sid;
            connection.invoke("send", toID, fromID, this.picsrc, 1).then((res) => {
              //type:0文本1图片2问诊单
              if (res.err == 1) {
                //发送成功
                this.$message({
                  message: "发送成功",
                  type: "success",
                });
                if (toID == nowtouser) {
                  var ms = {
                    content: res.data.content,
                    from: fromID,
                    type: "1",
                    to: toID,
                    sendTime: res.data.sendTime,
                  };
                  this.talklist.push(ms);
                  push(db, toID, res.data);
                }
                updateu(this.db1, this.sid, toID, "sendTime", ms, function(res) {
                  readu(_this.db1, _this.sid, function(res) {
                    _this.list = res;
                  });
                });
              } else {
                this.axios
                  .post(
                    "/test/log",
                    this.qs.stringify({ TtoID: toID, TfromID: fromID, Tmsg: arr[m], Ttype: 1 })
                  )
                  .then((res) => {});
                this.$message({
                  message: "发送失败，请重新发送",
                  type: "error",
                });
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleAvatarSuccess(res, file) {
      this.ispic = true;
      this.isword = false;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      console.log(file);
      this.ispic = true;
      this.isword = false;
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isJPG = types.includes(file.type); // === "image/jpeg/png/jpg";
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isJPG && isLt2M;
    },
    //对上传的图片，按DEL删除的操作（只能挨着删除，不能隔开删除）
    enter(id, type) {
      var rawData = document.getElementById(id);
      if (type == "up") {
        if (this.piclist.length <= 0) {
          // console.log("0");
          this.ispic = false;
          this.isword = true;
        }
      } else {
        this.piclist.pop();
        // console.log(this.piclist);
        rawData.innerHTML = "";
        for (let a = 0; a < this.piclist.length; a++) {
          rawData.innerHTML += "<img src=" + this.piclist[a] + ">";
        }
      }
    },
    //查看更多历史记录
    loadmore() {
      console.log(this.indexedDBindex);
      let newtalklist = [],
        oldtalklist = [];
      var _id = this.toID;
      var _index = this.indexedDBindex;
      search(db, _id, _index, function(res) {
        // console.log(res);
        var reslist = _this.talklistextend(res);
        if (res.length > 0) {
          if (res.length == 50) {
            console.log("长度有50个");
            newtalklist = _this.talklistextend(res);
            oldtalklist = _this.talklist;
            _this.talklist = [];
            _this.talklist = newtalklist.concat(oldtalklist);
            _this.indexedDBindex = _this.indexedDBindex + 1;
          } else if (res.length < 50) {
            console.log("长度没有50个");
            _this.getmorehistory(_id, _index).then((res) => {
              var list = [];
              if (res.length > 0) {
                list = _this.MergeArray(reslist, res);
                insert(db, _id, list);
                newtalklist = list;
                oldtalklist = _this.talklist;
                _this.talklist = [];
                _this.talklist = newtalklist.concat(oldtalklist);
              }
            });
          } else {
          }
        } else {
          _this.getmorehistory(_id, _index).then((res) => {
            if (res.length > 0) {
              insert(db, _id, res);
            }
            newtalklist = res;
            oldtalklist = _this.talklist;
            _this.talklist = [];
            _this.talklist = newtalklist.concat(oldtalklist);
          });
        }
      });
    },
    getmorehistory(toid, index) {
      // console.log(index);
      var list = this.axios
        .get(serve + "/api/Message/get_user_history_message?id=" + toid + "&index=" + index)
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
            this.indexedDBindex = 1;
            return [];
          } else if (res.data.code == 1) {
            if (res.data.result.length > 0) {
              if (this.indexedDBindex == 1) {
                this.talklist = [];
              }
              this.indexedDBindex++;
              return res.data.result;
            } else {
              this.$message({
                message: "没有更多了",
                type: "warning",
              });
              this.lookmoreshow = false;
              return [];
            }
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
      return list;
    },
    //查看大图
    big(url) {
      this.bigpic = true;
      this.bigpicurl = url;
    },
    //查询客户在系统内是否有建档，如果没有去建档，如果有就去查询是否有诊单存在
    searchkehu() {
      let row = this.custall;
      this.visible = false;
      this.searchUserID = true;
      this.searchcust = row.id;
      this.havedangan = false;
      this.axios
        .post("/gu/search_gu_custs", this.qs.stringify({ keyword: row.id }))
        .then((res) => {
          if (res.data.code == 0) {
            this.cust_id = res.data.result[0].Cust_ID;
            this.havedangan = false;
          } else {
            this.havedangan = true;
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    //用户新建完档案之后，拉取诊单模板列表
    newcust() {
      if (this.serve_id == "" || this.serve_id == null || this.serve_id == undefined) {
        this.$message({
          message: "请先联系管理员设置服务ID",
          type: "error",
        });
        return;
      }
      this.axios
        .post(
          "/chat/insert_cust_info",
          this.qs.stringify({ tel: this.searchcust, servicer_id: this.serve_id })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.havedangan = false;
          } else {
            console.log(res);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    //查询用户信息
    lookusermag(type, obj) {
      this.Ill_Lib = "";
      this.Doc_Name = "";
      this.changeStatus = "";
      let row = "";
      if (!type) {
        this.lookusermsg = true;
        row = this.custall;
      } else {
        row = obj;
      }
      this.LookUserMsg = {};
      this.axios
        .post("/gu/search_gu_custs", this.qs.stringify({ keyword: row.id, doc_id: "" }))
        .then((res) => {
          if (res.data.code == 0) {
            this.LookUserMsg = res.data.result[0];
            this.axios
              .get("/gu/get_cust_info_by_custid?cust_id=" + this.LookUserMsg.Cust_ID)
              .then((res) => {
                if (res.data.code == 0) {
                  // this.changeStatus = res.data.Cust_Info.Cure_Status;
                  this.illLibList = res.data.Ill_Lib;
                  for (let i = 0; i < res.data.Ill_Lib.length; i++) {
                    const element = res.data.Ill_Lib[i];
                    this.Ill_Lib += element.name + ",";
                  }
                  this.Doc_Name = res.data.Cust_Info.Doc_Name;
                }
              });
          }
        })
        .catch((res) => {
          console.log(res);
        });
      this.axios.get("/gu/get_gu_cust_all_by_tel?tel=" + row.id).then((res) => {
        this.LookUserlivinglevel = res.data.cust.living_level;
        for (let a = 0; a < this.statusoptions.length; a++) {
          if (this.statusoptions[a].id == res.data.cust.user_status) {
            this.changeStatus = this.statusoptions[a].label;
          }
        }
      });
      this.axios.get(serve + "/api/UserExtend/get_user_extend?userId=" + row.id).then((res) => {
        if (res.data.code == 1) {
          if (res.data.result == null || res.data.result == undefined || res.data.result == "") {
            this.LookUserMsgtalk.desc = "";
            // this.LookUserMsgtalk.nextConcatContent = "";
            // this.LookUserMsgtalk.nextConcatTime = "";
            // this.LookUserMsgtalk.notes = "";
          } else {
            this.LookUserMsgtalk.desc = res.data.result.desc;
            // this.LookUserMsgtalk.nextConcatContent = res.data.result.nextConcatContent;
            // this.LookUserMsgtalk.nextConcatTime = res.data.result.nextConcatTime;
            // this.LookUserMsgtalk.notes = res.data.result.notes;
          }
        }
      });
      console.log(this.LookUserMsg);
    },

    //推送医生
    //推送医生的搜索
    sendDocQuery() {
      this.axios
        .post(
          "/gu/search_gu_docs",
          this.qs.stringify({ keyword: this.pusgDocSearch, is_flag: "all" })
        )
        .then((rs) => {
          if (rs.data.code == 0) {
            this.doclist = rs.data.result;
            this.totalCount = rs.data.result.length;
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到该医生",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cleardoc() {
      //清除医生
      this.doclist = [];
    },
    toggleSelection() {
      //取消选择医生
      this.pushDoc = false;
      this.multipleSelection = [];
      this.doclist = [];
    },
    getdoc(page, size, type) {
      this.axios
        .get(
          "/gu/get_gu_docs?page=" +
            page +
            "&size=" +
            size +
            "&dp_id=" +
            "" +
            "&flag=" +
            type +
            "&search="
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.totalCount = res.data.count;
            this.doclist = res.data.result;
          } else {
            this.totalCount = 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    subDoc(row) {
      //选择医生推送
      let _subdoclist = this.multipleSelection.filter((item) => delete item.desc);
      if (_subdoclist.length <= 0) {
        this.$message({
          message: "请选择至少一个医生",
          type: "error",
        });
      } else {
        this.$confirm("此操作将推送医生信息给用户, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            var toID = this.toID;
            var fromID = "s" + this.sid;
            connection.invoke("send", toID, fromID, JSON.stringify(_subdoclist), 4).then((res) => {
              if (res.err == 1) {
                this.$message({
                  message: "发送成功",
                  type: "success",
                });
                //发送成功
                let measge = {
                  content: res.data.content,
                  from: fromID,
                  type: "4",
                  to: toID,
                  sendTime: res.data.sendTime,
                };
                if (toID == nowtouser) {
                  this.talklist.push(measge);
                  push(db, toID, res.data);
                }
                updateu(this.db1, this.sid, toID, "sendTime", measge, function(res) {
                  readu(_this.db1, _this.sid, function(res) {
                    _this.list = res;
                  });
                });
                // this.$refs['multipleTable'].clearSelection();
                this.$nextTick(() => {
                  this.$refs.multipleTable.clearSelection();
                });
                this.multipleSelection = [];
                this.pushDoc = false;
              } else {
                this.axios
                  .post(
                    "/test/log",
                    this.qs.stringify({
                      TtoID: toID,
                      TfromID: fromID,
                      Tmsg: JSON.stringify(_subdoclist),
                      Ttype: 4,
                    })
                  )
                  .then((res) => {});
                this.$message({
                  message: "发送失败，请重新发送",
                  type: "error",
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    handleSelectionChange(val) {
      //多选中数组操作
      this.multipleSelection = val;
      let str = "";
      if (val.length > 0) {
        for (let a = 0; a < val.length; a++) {
          str += val[a].name + "、";
        }
        this.selectDocList = str;
      } else {
        this.selectDocList = "";
      }
    },

    //点击好友列表进入到聊天界面
    lookuser(item, type) {
      if (type == "right") {
        item = this.custall;
        this.lookusermsg = true;
        this.lowerright = true;
      } else if (type == "lowerright") {
        this.lookusermsg = true;
        this.lowerright = false;
      } else {
        this.showusermsg = true;
        this.custall = item;
      }
      this.talkitem = item;
      // this.custall = item;
      this.lookusermag("friendlist", item);
      this.friendlistToId = item.id;
      this.getfriendlistusertag();
      this.axios
        .post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.talkitem.id }))
        .then((res) => {
          if (res.data.code == 0) {
            this.friendlistcustid = res.data.result[0].Cust_ID;
            console.log(this.friendlistcustid);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    wanttalk(row) {
      if (row) {
        this.talkitem = row;
      }
      this.talkitem.isOnline = false;
      this.talkitem.hasmsg = false;
      this.talkitem.sendTime = this.getCurrentTime();
      putu(this.db1, this.sid, this.talkitem.id, this.talkitem, function(res) {
        if (res == "has") {
          updateu(_this.db1, _this.sid, _this.talkitem.id, "sendTime", _this.talkitem, function(
            res
          ) {
            readu(_this.db1, _this.sid, function(res) {
              _this.list = res;
            });
          });
        } else {
          readu(_this.db1, _this.sid, function(res) {
            _this.list = res;
          });
        }
      });
      this.activeName = "second";
      this.lookup(
        this.talkitem,
        this.talkitem.id,
        this.talkitem.type,
        this.talkitem.name,
        this.talkitem.desc
      );
      this.axios
        .post(
          serve + "/api/MessageStore/add",
          this.qs.stringify({
            UserId: this.talkitem.id,
            Service: "s" + this.sid,
            Name: this.talkitem.name,
            HeadImg: this.talkitem.headImg,
            Desc: this.talkitem.desc,
          })
        )
        .then((res) => {
          console.log(res);
        });
      this.loadingfriend = true;
      this.friendlistloading = true;
      this.finishedfriend = false;
    },
    //设置备注名
    setNotes(from, obj) {
      this.setDesc = true;
      this.setdesc = obj;
      if (from == "msglist") {
        let row = this.custall;
        this.setdescitem = row;
      } else if (from == "usercard") {
        this.setdescitem = this.talkitem;
      }
    },
    //提交备注名
    subsetDesc() {
      this.axios
        .post(
          serve + "/api/User/set_user_desc",
          this.qs.stringify({ id: this.setdescitem.id, desc: this.setdesc })
        )
        .then((res) => {
          if (res.data.code == 1) {
            updateu(
              this.db1,
              this.sid,
              this.setdescitem.id,
              "desc",
              { desc: this.setdesc },
              function(res) {
                readu(_this.db1, _this.sid, function(res) {
                  _this.list = res;
                });
              }
            );
            this.LookUserMsgtalk.desc = this.setdesc;
            this.setDesc = false;
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },

    //清除搜索通讯录
    clearsearch() {
      this.showusermsg = false;
      this.friendlist = [];
      this.friendpage = 1;
      this.friendload();
      this.finishedfriend = false;
      this.loadingfriend = true;
      this.friendlistloading = true;
    },
    //查询通讯录
    querytxl() {
      this.friendlist = [];
      //先查询接口换手机号
      this.axios.get("/gu/get_cust_info_by_custid?cust_id=" + this.searchtxl).then((res) => {
        if (res.data.code == 0) {
          // console.log(res);
          this.usertell = res.data.result.Tel1;
          this.querytxl1(this.usertell);
        } else {
          this.querytxl1(this.searchtxl);
        }
      });
      this.finishedfriend = true;
      this.loadingfriend = false;
    },
    querytxl1(tel) {
      //通过手机号搜索患者
      this.axios
        .get(serve + "/api/User/search_user?userID=s" + this.sid + "&name=" + tel)
        .then((res) => {
          if (res.data.code == 1) {
            var arr = res.data.result;
            for (let a = 0; a < arr.length; a++) {
              let element = arr[a];
              if (
                element.pinyinIndex == null ||
                element.pinyinIndex == "" ||
                element.pinyinIndex == undefined
              ) {
                element.pinyinIndex = "#";
              }
            }
            this.friendlist = arr;
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //修改状态
    subchangeStatus(custid) {
      // this.axios
      //   .post(
      //     "/gu/update_gu_cust_info_cure_status",
      //     this.qs.stringify({
      //       x_cust_id: this.LookUserMsg.Cust_ID,
      //       cure_status: this.changeStatusVal,
      //       uid: this.uid,
      //     })
      //   )
      //   .then((res) => {
      //     if (res.data.code == 0) {
      //       this.changeStatustc = false;
      //       this.$message({
      //         message: "修改成功",
      //         type: "success",
      //       });
      //       this.changeStatus = this.changeStatusVal;
      //       this.changeStatusVal = "";
      //     }
      //   })
      //   .catch((res) => {
      //     console.log(res);
      //   });
      // console.log({ tel: this.custall.id });
      this.axios
        .post(
          "/gu/update_gu_cust_user_status_by_tel",
          this.qs.stringify({ user_status: this.changeStatusVal, tel: this.custall.id })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.changeStatustc = false;
            this.$message({
              message: "修改成功",
              type: "success",
            });
            // this.changeStatus = this.changeStatusVal;
            for (let a = 0; a < this.statusoptions.length; a++) {
              if (this.statusoptions[a].id == this.changeStatusVal) {
                this.changeStatus = this.statusoptions[a].label;
              }
            }
            this.changeStatusVal = "";
          }
        });
    },
    bindIll() {
      //绑定疾病
      this.Bind_Ill = true;
      this.bindillcheckbox = "";
      (this.searchill = ""), (this.bindilllist = []);
    },
    queryill() {
      //搜索疾病
      this.axios
        .get("/gu/get_gu_ill_lib_like_name?ill_lib_name=" + this.searchill)
        .then((res) => {
          if (res.data.code == 0) {
            this.bindilllist = res.data.result;
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到该疾病",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    subbindill() {
      //提交绑定疾病
      if (this.bindillcheckbox == "") {
        this.$message({
          message: "请选择疾病",
          type: "error",
        });
      } else {
        this.axios
          .post(
            "/gu/insert_gu_ill_lib_cust",
            this.qs.stringify({
              cust_id: this.LookUserMsg.Cust_ID,
              ill_lib_id: this.bindillcheckbox.id,
            })
          )
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "绑定成功",
                type: "success",
              });
              this.Ill_Lib += this.bindillcheckbox.name + ",";
            } else {
              this.$message(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.Bind_Ill = false;
      }
    },
    getillval() {},
    clearill() {
      this.bindilllist = [];
    },
    //绑定医生
    bindDoc() {
      this.Bind_Doc = true;
      this.binddoccheckbox = "";
      (this.searchdoc = ""), (this.binddoclist = []);
    },
    querydoc1() {
      this.pushDoc = true;
      this.pusgDocSearch = "";
      this.selectDocList = "";
      // this.toggleSelection();
      this.$nextTick(() => {
        this.$refs.multipleTable.clearSelection();
      });
      this.multipleSelection = [];
      this.doclist = [];
    },
    querydoc() {
      //搜索医生姓名
      this.axios
        .post("/gu/search_gu_docs", this.qs.stringify({ keyword: this.searchdoc, is_flag: "all" }))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.binddoclist = rs.data.result;
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到该医生",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    subbinddoc() {
      //提交绑定医生
      if (this.binddoccheckbox == "") {
        this.$message({
          message: "请选择医生",
          type: "error",
        });
      } else {
        this.axios
          .post(
            "/gu/update_gu_cust_info_docid",
            this.qs.stringify({
              x_cust_id: this.LookUserMsg.Cust_ID,
              doc_id: this.binddoccheckbox.id,
              uid: sessionStorage.getItem("servicer_id"),
            })
          )
          .then((res) => {
            if (res.data.code == 0) {
              //20220325在聊天中修改患者信息，寻医表不转移到新的医生下
              // this.axios
              //   .post(
              //     "/field/update_z_newseadoc_main_docid_by_custid",
              //     this.qs.stringify({
              //       doc_id: this.binddoccheckbox.id,
              //       cust_id: this.LookUserMsg.Cust_ID,
              //       // uid: sessionStorage.getItem("servicer_id"),
              //     })
              //   )
              //   .then((res) => {
              //     if (res.data.code == 0) {
              //       console.log(res);
              //     }
              //   });
              this.$message({
                type: "success",
                message: "绑定成功",
              });
            } else {
              this.$message(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.Bind_Doc = false;
        this.Doc_Name = this.binddoccheckbox.name;
      }
    },
    getdocval() {},
    cleardoc() {
      this.binddoclist = [];
    },
    delIll() {
      //解绑疾病
      this.$confirm("此操作是解绑所有疾病, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          for (let i = 0; i < this.illLibList.length; i++) {
            const element = this.illLibList[i];
            this.axios
              .post(
                "/gu/delete_gu_ill_lib_cust",
                this.qs.stringify({ cust_id: this.LookUserMsg.Cust_ID, ill_lib_id: element.id })
              )
              .then((res) => {
                if (res.data.code == 0) {
                } else {
                  console.log(res);
                }
                if (i == this.illLibList.length - 1) {
                  this.$message({
                    type: "success",
                    message: "解绑成功",
                  });
                  this.Ill_Lib = "";
                }
              })
              .catch((res) => {
                console.log(res);
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消解绑",
          });
        });
    },
    //解绑医生
    delDoc() {
      this.$confirm("此操作是解绑医生, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              "/gu/update_gu_cust_info_docid",
              this.qs.stringify({
                x_cust_id: this.LookUserMsg.Cust_ID,
                doc_id: "",
                uid: sessionStorage.getItem("servicer_id"),
              })
            )
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "解绑成功",
                  type: "success",
                });
                this.Doc_Name = "";
              } else {
                this.$message(rs.data.result);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消解绑",
          });
        });
    },
    handleSizeChangexy() {},
    handleCurrentChangexy(currentPage) {
      this.getxylist(currentPage);
    },
    selectxunyitab(tab, event) {
      console.log(tab);
      if (tab.name == "xysecond") {
        this.getxylist(1);
      }
    },
    medicalSeek() {
      //寻医管理
      this.choicexunyi = true;
      this.selectxy = "";
      this.xylist = [];
      this.getxyoptions();
      // this.getxylist(1);
      // this.selectxydoc = "";
      // this.selectxydocmsg = "";
      // this.searchxymuban = "";
      // this.xymubanlist = [];
      // this.selectxydocmsg = [];
      // this.getxyalldoc(); //新版获取医生
      this.selectxydoc = "";
      this.xyactive = "xyfirst";
    },
    //查询寻医表模板，模板如果没有绑定医生不能被选择
    searchxymb() {
      if (this.searchxymuban != "") {
        this.axios.get("/field/get_z_newseadoc_m?keyword=" + this.searchxymuban).then((res) => {
          if (res.data.code == 0) {
            this.xymubanlist = res.data.result;
          } else {
            this.xymubanlist = [];
            this.$message({
              type: "error",
              message: "没有数据，请联系管理员确认",
            });
          }
        });
      } else {
        this.getxyalldoc();
      }
    },
    getxyalldoc() {
      this.axios
        .get("/gu/get_gu_docs?page=" + 1 + "&size=" + 1000 + "&dp_id=&flag=&search=")
        .then((res) => {
          if (res.data.code == 0) {
            this.selectxydocmsg = res.data.result;
          } else {
            this.totalCount = 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // this.axios
      //   .post(
      //     "/gu/search_gu_docs",
      //     this.qs.stringify({ keyword: this.selectxydoc, is_flag: "all" })
      //   )
      //   .then((rs) => {
      //     if (rs.data.code == 0) {
      //       this.selectxydocmsg = rs.data.result;
      //       // this.getxyoptions(this.selectxydocmsg[0].id);
      //     } else {
      //       this.$message({
      //         type: "error",
      //         message: "没有搜索到该医生",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    // queryxydoc() {
    //   if (this.selectxydoc == "") {
    //     this.$message({
    //       type: "error",
    //       message: "请选择医生",
    //     });
    //     return;
    //   }
    //   this.axios
    //     .post(
    //       "/gu/search_gu_docs",
    //       this.qs.stringify({ keyword: this.selectxydoc, is_flag: "all" })
    //     )
    //     .then((rs) => {
    //       if (rs.data.code == 0) {

    //         this.selectxydocmsg = rs.data.result;
    //         this.getxyoptions();
    //       } else {
    //         this.$message({
    //           type: "error",
    //           message: "没有搜索到该医生",
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    getxyoptions(docid) {
      //获取寻医模板列表
      //旧版本直接获取寻医疾病模板
      this.axios
        .get("/field/get_z_newseadoc_m")
        .then((res) => {
          if (res.data.code == 0) {
            this.optionsxy = res.data.result;
          }
        })
        .catch((res) => {
          console.log(res);
        });
      //新版本根据医生ID获取寻医疾病模板
      // console.log(this.selectxydoc)186
      // this.optionsxy = [];
      // this.xymubanlist = [];
      // this.axios.get("/field/get_z_newseadoc_m_by_docid?doc_id=" + this.selectxydoc).then((res) => {
      //   if (res.data.code == 0) {
      //     // this.optionsxy = res.data.result;
      //     this.xymubanlist = res.data.result;
      //   } else {
      //     // this.optionsxy = [];
      //     this.xymubanlist = [];
      //   }
      // });
    },
    getxylist(page) {
      //获取用户已有的寻医列表
      this.axios
        .post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.toID }))
        .then((res) => {
          if (res.data.code == 0) {
            this.axios
              .get(
                "/field/get_z_newseadoc_main_by_custid?page=" +
                  page +
                  "&size=6&cust_id=" +
                  res.data.result[0].Cust_ID
              )
              .then((res) => {
                if (res.data.code == 0) {
                  this.xylist = res.data.result;
                  this.totalCountxy = res.data.count;
                }
              })
              .catch((res) => {
                console.log(res);
              });
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    selectxunyi(value) {
      console.log(value);
      this.selectitemxy = this.optionsxy.find((item) => {
        return item.S_ID === value;
      });
      // this.getxyoptions(value);
    },
    selectxydocchange(value) {
      //寻医表选择医生下拉框改变的时候
      if (value) {
        this.isshowselectxy = true;
        this.getxyoptions();
      } else {
        this.xymubanlist = [];
      }
    },
    clearselectxydoc() {
      // this.xymubanlist = [];
    },
    newxy(row) {
      //新建寻医发送模板id和cust_id返回诊单号
      this.axios
        .post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.toID }))
        .then((res) => {
          if (res.data.code == 0) {
            var form = new Object();
            this.cust_id = res.data.result[0].Cust_ID;
            if (this.serve_id == "" || this.serve_id == null || this.serve_id == undefined) {
              this.$message({
                message: "请先联系管理员设置服务ID",
                type: "error",
              });
              return;
            } else {
              form = {
                cust_id: this.cust_id,
                servicer_id: this.serve_id,
                dis_id: this.selectitemxy.S_ID,
                dis_name: this.selectitemxy.S_Name,
              };
              // form = {
              //   cust_id: this.cust_id,
              //   servicer_id: this.serve_id,
              //   dis_id: row.S_ID,
              //   dis_name: row.S_Name,
              // };
            }
            console.log(form);
            this.axios
              .post("/field/insert_z_newseadoc_main", this.qs.stringify(form))
              .then((res) => {
                if (res.data.code == 0) {
                  this.choicexynew(res.data.result);
                  console.log(res.data.result);
                } else {
                  this.$message({
                    message: "创建失败，请重新创建",
                    type: "error",
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.$message({
              message: "请先为用户创建档案",
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    //新建寻医表客服帮忙新建
    choicexynew(row) {
      this.$confirm("寻医表创建完成，请选择你的下一步操作?", "提示", {
        confirmButtonText: "直接发送",
        cancelButtonText: "帮他填写",
        type: "warning",
      })
        .then(() => {
          var toID = this.toID;
          var fromID = "s" + this.sid;
          connection
            .invoke("send", toID, fromID, JSON.stringify({ name: row.Dis_Name, id: row.KID }), 5)
            .then((res) => {
              if (res.err == 1) {
                this.$message({
                  message: "发送成功",
                  type: "success",
                });
                //发送成功res.data.result
                let measge = {
                  content: res.data.content,
                  from: fromID,
                  type: "5",
                  to: toID,
                  sendTime: res.data.sendTime,
                };
                if (toID == nowtouser) {
                  this.talklist.push(measge);
                  push(db, toID, res.data);
                }
                updateu(this.db1, this.sid, toID, "sendTime", measge, function(res) {
                  readu(_this.db1, _this.sid, function(res) {
                    _this.list = res;
                  });
                });
                this.selectxy = "";
                this.choicexunyi = false;
              } else {
                this.axios
                  .post(
                    "/test/log",
                    this.qs.stringify({
                      TtoID: toID,
                      TfromID: fromID,
                      Tmsg: JSON.stringify({ name: row.Dis_Name, id: row.KID }),
                      Ttype: 5,
                    })
                  )
                  .then((res) => {});
                this.$message({
                  message: "发送失败，请重新发送",
                  type: "error",
                });
              }
            });
        })
        .catch(() => {
          // var testrow = {
          //   Check_DT: null,
          //   Commit_DT: null,
          //   Creat_DT: "2022-03-01 08:30:44.660",
          //   Cust_ID: "B20121500010",
          //   Delete_DT: null,
          //   Dis_ID: "100000",
          //   Dis_Name: "高尿酸（痛风）",
          //   Dis_Note: null,
          //   DocProc_DT: null,
          //   Doc_ID: null,
          //   Input_ID: "8787",
          //   KID: "XY22030187870001",
          //   Num_Doc: 1000,
          //   RowNo: "2",
          //   State: 0,
          //   Z_Doc_ID: "0",
          // }
          this.NewXYEdit(row, "help");
        });
    },
    choicexy(row) {
      //选择已有寻医
      this.$confirm("此操作会将发送寻医表给用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var toID = this.toID;
          var fromID = "s" + this.sid;
          connection
            .invoke("send", toID, fromID, JSON.stringify({ name: row.Dis_Name, id: row.KID }), 5)
            .then((res) => {
              if (res.err == 1) {
                this.$message({
                  message: "发送成功",
                  type: "success",
                });
                //发送成功res.data.result
                let measge = {
                  content: res.data.content,
                  from: fromID,
                  type: "5",
                  to: toID,
                  sendTime: res.data.sendTime,
                };
                if (toID == nowtouser) {
                  this.talklist.push(measge);
                  push(db, toID, res.data);
                }
                updateu(this.db1, this.sid, toID, "sendTime", measge, function(res) {
                  readu(_this.db1, _this.sid, function(res) {
                    _this.list = res;
                  });
                });
                this.selectxy = "";
                this.choicexunyi = false;
                this.ishelp = false;
                this.EditXY = false;
              } else {
                this.axios
                  .post(
                    "/test/log",
                    this.qs.stringify({
                      TtoID: toID,
                      TfromID: fromID,
                      Tmsg: JSON.stringify({ name: row.Dis_Name, id: row.KID }),
                      Ttype: 5,
                    })
                  )
                  .then((res) => {});
                this.$message({
                  message: "发送失败，请重新发送",
                  type: "error",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.getxylist();
        });
    },
    handleSizeChangezd() {},
    handleCurrentChangezd(currentPage) {
      this.getNewZDHistory(currentPage);
    },
    selectzhendantab(tab, event) {
      console.log(tab);
      if (tab.name == "zdsecond") {
        this.getNewZDHistory(1);
      }
    },
    //新诊单管理
    newList() {
      this.Newselectzd = "";
      this.Newzdlist = [];
      this.Newoptions = [];
      this.zdactive = "zdfirst";
      var docname;
      this.axios
        .post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.toID, doc_id: "" }))
        .then((res) => {
          if (res.data.code == 0) {
            this.axios
              .get("/gu/get_cust_info_by_custid?cust_id=" + res.data.result[0].Cust_ID)
              .then((res) => {
                // console.log(res);
                if (res.data.code == 0) {
                  docname = res.data.Cust_Info.Doc_ID;
                  if (docname == "" || docname == null || docname == undefined) {
                    this.$message({
                      message: "请先绑定医患关系",
                      type: "error",
                    });
                  } else {
                    this.getNewZDList(docname);
                    // this.getNewZDList('201');
                    // this.getNewZDHistory(1);
                  }
                }
              });
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getNewZDHistory(page) {
      //获取用户已有诊单+ "&state=012"
      this.axios
        .get("/field/get_z_field_dismains?tel=" + this.toID + "&page=" + page + "&size=6")
        .then((res) => {
          if (res.data.code == 0) {
            this.Newzdlist = res.data.result;
            this.totalCountzd = res.data.count;
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNewZDList(docname) {
      //获取新诊单模板
      this.axios
        .get("/field/get_z_field_docdis_by_docid?doc_id=" + docname, {
          headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" },
        }) // /field/get_z_field_docdis
        .then((res) => {
          if (res.data.code == 0) {
            this.Newchoicezd = true;
            this.Newoptions = res.data.result;
          } else {
            this.$message({
              message: "当前该医生下没有诊单，请联系管理员！",
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Newselect(value) {
      this.Newselectitem = this.Newoptions.find((item) => {
        return item.KDis_ID === value;
      });
    },
    NewZhenDan() {
      //新建新的诊单
      //发送模板id和cust_id返回诊单号
      this.axios
        .post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.toID }))
        .then((res) => {
          if (res.data.code == 0) {
            var form = new Object();
            this.cust_id = res.data.result[0].Cust_ID;
            if (this.serve_id == "" || this.serve_id == null || this.serve_id == undefined) {
              this.$message({
                message: "请先联系管理员设置服务ID",
                type: "error",
              });
              return;
            } else {
              form = {
                cust_id: this.cust_id,
                servicer_id: this.serve_id,
                dis_id: this.Newselectitem.KDis_ID,
                dis_name: this.Newselectitem.KDis_Name + " " + this.Newselectitem.doc_name,
                doc_id: this.Newselectitem.KDoc_ID,
              };
            }
            this.axios
              .post("/field/insert_z_field_dismain", this.qs.stringify(form))
              .then((res) => {
                if (res.data.code == 0) {
                  // console.log(res);
                  this.Newchoice(res.data.result);
                  // this.$message({
                  //   message: "诊单创建成功，请在下面列表中选择诊单下发给用户",
                  //   type: "warning",
                  // });
                  // this.getNewZDHistory();
                } else {
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.$message({
              message: "请先为用户创建档案",
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    Newchoice(row) {
      //新的诊单下发按钮
      this.$confirm("此操作会将发送诊单给用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var toID = this.toID;
          var fromID = "s" + this.sid;
          connection
            .invoke(
              "send",
              toID,
              fromID,
              JSON.stringify({ name: row.Dis_Name, id: row.KID, doc_name: row.doc_name }),
              6
            )
            .then((res) => {
              if (res.err == 1) {
                this.$message({
                  message: "发送成功",
                  type: "success",
                });
                let measge = {
                  content: res.data.content,
                  from: fromID,
                  type: "6",
                  to: toID,
                  sendTime: res.data.sendTime,
                };
                if (toID == nowtouser) {
                  this.talklist.push(measge);
                  push(db, toID, res.data);
                }
                updateu(this.db1, this.sid, toID, "sendTime", measge, function(res) {
                  readu(_this.db1, _this.sid, function(res) {
                    _this.list = res;
                  });
                });
                this.Newselectzd = "";
                this.Newchoicezd = false;
              } else {
                this.axios
                  .post(
                    "/test/log",
                    this.qs.stringify({
                      TtoID: toID,
                      TfromID: fromID,
                      Tmsg: JSON.stringify({
                        name: row.Dis_Name,
                        id: row.KID,
                        doc_name: row.doc_name,
                      }),
                      Ttype: 6,
                    })
                  )
                  .then((res) => {});
                this.$message({
                  message: "发送失败，请重新发送",
                  type: "error",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.getNewZDHistory();
        });
    },
    //编辑新诊单
    NewchoiceEdit(row, type) {
      this.EditMsg.kid = row.KID;
      this.EditMsg.custid = row.Cust_ID;
      if (type == "look") {
        this.EditMsg.noedit = false;
      } else {
        this.EditMsg.noedit = true;
      }
      this.EditZD = true;
      this.shenhe = false;
    },
    //编辑寻医
    NewXYEdit(row, type) {
      this.EditXYMsg.kid = row.KID;
      this.EditXYMsg.custid = row.Cust_ID;
      if (type == "look") {
        this.EditXYMsg.noedit = false;
      }
      if (type == "help") {
        this.EditXYMsg.noedit = true;
        this.ishelp = true;
      } else {
        this.EditXYMsg.noedit = true;
      }
      this.EditXY = true;
      this.isshenhe = false;
    },
    //打回新的问诊单
    Newrepulse(row) {
      this.$confirm("此操作将打回诊单并重新发送给用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              "/gu/update_z_field_dismain_state",
              this.qs.stringify({ state: "0", kid: row.KID, uid: this.servicer_id })
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: "打回成功",
                  type: "success",
                });
                this.Newchoicezd = false;
                var toID = this.toID;
                var fromID = "s" + this.sid;
                connection
                  .invoke(
                    "send",
                    toID,
                    fromID,
                    JSON.stringify({ name: row.Dis_Name, id: row.KID, doc_name: row.doc_name }),
                    6
                  )
                  .then((res) => {
                    if (res.err == 1) {
                      //发送成功
                      let measge = {
                        content: res.data.content,
                        from: fromID,
                        type: "6",
                        to: toID,
                        sendTime: res.data.sendTime,
                      };
                      if (toID == nowtouser) {
                        this.talklist.push(measge);
                        push(db, toID, res.data);
                      }
                      updateu(this.db1, this.sid, toID, "sendTime", measge, function(res) {
                        readu(_this.db1, _this.sid, function(res) {
                          _this.list = res;
                        });
                      });
                      this.Newselectzd = "";
                      this.Newchoicezd = false;
                    } else {
                      this.$message({
                        message: "发送失败，请重新发送",
                        type: "error",
                      });
                    }
                  });
              } else {
                console.log(res);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //寻医打回
    XYrepulse(row) {
      this.$confirm("此操作将打回寻医表并重新发送给用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              "/field/update_z_newseadoc_main_state",
              this.qs.stringify({ state: "0", kid: row.KID, uid: this.servicer_id })
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: "打回成功",
                  type: "success",
                });
                this.Newchoicezd = false;
                var toID = this.toID;
                var fromID = "s" + this.sid;
                connection
                  .invoke(
                    "send",
                    toID,
                    fromID,
                    JSON.stringify({ name: row.Dis_Name, id: row.KID }),
                    5
                  )
                  .then((res) => {
                    if (res.err == 1) {
                      //发送成功
                      let measge = {
                        content: res.data.content,
                        from: fromID,
                        type: "5",
                        to: toID,
                        sendTime: res.data.sendTime,
                      };
                      if (toID == nowtouser) {
                        this.talklist.push(measge);
                        push(db, toID, res.data);
                      }
                      updateu(this.db1, this.sid, toID, "sendTime", measge, function(res) {
                        readu(_this.db1, _this.sid, function(res) {
                          _this.list = res;
                        });
                      });
                      this.selectxy = "";
                      this.choicexunyi = false;
                    } else {
                      this.$message({
                        message: "发送失败，请重新发送",
                        type: "error",
                      });
                    }
                  });
              } else {
                console.log(res);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    MyZDhandleSizeChange() {},
    MyZDhandleCurrentChange(currentPage) {
      let date1 = this.MyZDDate[0];
      let date2 = this.MyZDDate[1];
      if (this.MyZDType == 5) {
        //寻医
        this.getMyDayDataXY(date1, date2, this.MyZDState, currentPage);
      } else if (this.MyZDType == 6) {
        this.getMyDayDataZD(date1, date2, this.MyZDState, currentPage);
      } else {
        this.getMyDayDataFK(date1, date2, this.MyZDState, currentPage);
      }
    },
    //查询我的当天诊单发送情况
    SearchMyDayZD() {
      if (this.MyZDType == "" || this.MyZDState == "") {
        this.$message({
          message: "请选择查询条件",
          type: "error",
        });
        return;
      }
      // console.log(this.MyZDDate);
      let date1, date2;
      if (this.MyZDDate != "" || this.MyZDDate.length > 0) {
        date1 = this.MyZDDate[0];
        date2 = this.MyZDDate[1];
      } else {
        date1 = "";
        date2 = "";
        this.$message({
          message: "请选择时间区间",
          type: "error",
        });
        return;
      }
      this.MyZDtotalCount = 0;
      this.MyDayList = [];
      if (this.MyZDType == 5) {
        //寻医
        this.getMyDayDataXY(date1, date2, this.MyZDState, 1);
      } else if (this.MyZDType == 6) {
        this.getMyDayDataZD(date1, date2, this.MyZDState, 1);
      } else {
        this.getMyDayDataFK(date1, date2, this.MyZDState, 1);
      }
    },
    zhengliservicerid() {
      var servicerid = "";
      if (
        !(this.tjkefuchoice == "" || this.tjkefuchoice == null || this.tjkefuchoice == undefined)
      ) {
        if (this.tjkefuchoice[0] == "all") {
          servicerid = "";
        } else {
          let a = this.tjkefuchoice;
          a.forEach((element) => {
            servicerid += "'" + element.serviceId + "',";
          });
          servicerid = servicerid.substr(0, servicerid.length - 1);
        }
      } else {
        servicerid = "'" + this.myMsg.serveid + "'";
      }
      return servicerid;
    },
    //获取我的当天发送的反馈数据
    getMyDayDataFK(date1, date2, state, page) {
      var servicerid = this.zhengliservicerid();
      //先判断数据格式，是手机号还是custid
      if (this.tjcustid.match(/^\d/)) {
        this.axios
          .post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.tjcustid, doc_id: "" }))
          .then((res) => {
            if (res.data.code == 0) {
              var id = res.data.result[0].Cust_ID;
              this.getMyDayDataFK2(servicerid, date1, date2, state, page, this.MyZDPageSize, id);
            }
          })
          .catch((res) => {
            console.log(res);
          });
      } else {
        this.getMyDayDataFK2(
          servicerid,
          date1,
          date2,
          state,
          page,
          this.MyZDPageSize,
          this.tjcustid
        );
      }
    },
    getMyDayDataFK2(servicerid, date1, date2, state, page, size, id) {
      this.axios
        .get(
          "/field/get_z_newback_mains?servicer_ids=" +
            servicerid +
            "&datetime1=" +
            date1 +
            "&datetime2=" +
            date2 +
            "&state=" +
            state +
            "&page=" +
            page +
            "&size=" +
            size +
            "&cust_id=" +
            id
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.MyZDtotalCount = res.data.count;
            this.MyDayList = res.data.result;
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((res) => {});
    },
    //获取我的当天发送的寻医数据
    getMyDayDataXY(date1, date2, state, page) {
      var servicerid = this.zhengliservicerid();
      //先判断数据格式，是手机号还是custid
      if (this.tjcustid.match(/^\d/)) {
        this.axios
          .post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.tjcustid, doc_id: "" }))
          .then((res) => {
            if (res.data.code == 0) {
              var id = res.data.result[0].Cust_ID;
              this.getMyDayDataXY2(servicerid, date1, date2, state, page, this.MyZDPageSize, id);
            }
          })
          .catch((res) => {
            console.log(res);
          });
      } else {
        this.getMyDayDataXY2(
          servicerid,
          date1,
          date2,
          state,
          page,
          this.MyZDPageSize,
          this.tjcustid
        );
      }
    },
    getMyDayDataXY2(servicerid, date1, date2, state, page, size, id) {
      this.axios
        .get(
          "/field/get_z_newseadoc_mains?servicer_ids=" +
            servicerid +
            "&datetime1=" +
            date1 +
            "&datetime2=" +
            date2 +
            "&state=" +
            state +
            "&page=" +
            page +
            "&size=" +
            size +
            "&cust_id=" +
            id
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.MyZDtotalCount = res.data.count;
            this.MyDayList = res.data.result;
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((res) => {});
    },
    //获取我的当天发送的诊单数据
    getMyDayDataZD(date1, date2, state, page) {
      var servicerid = this.zhengliservicerid();
      if (this.tjcustid.match(/^\d/)) {
        this.axios
          .post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.tjcustid, doc_id: "" }))
          .then((res) => {
            if (res.data.code == 0) {
              var id = res.data.result[0].Cust_ID;
              this.getMyDayDataZD2(servicerid, date1, date2, state, page, this.MyZDPageSize, id);
            }
          })
          .catch((res) => {
            console.log(res);
          });
      } else {
        this.getMyDayDataZD2(
          servicerid,
          date1,
          date2,
          state,
          page,
          this.MyZDPageSize,
          this.tjcustid
        );
      }
    },
    getMyDayDataZD2(servicerid, date1, date2, state, page, size, id) {
      this.axios
        .get(
          "/field/get_z_field_dismains?servicer_ids=" +
            servicerid +
            "&datetime1=" +
            date1 +
            "&datetime2=" +
            date2 +
            "&state=" +
            state +
            "&page=" +
            page +
            "&size=" +
            size +
            "&cust_id=" +
            id
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.MyZDtotalCount = res.data.count;
            this.MyDayList = res.data.result;
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((res) => {});
    },
    //统计临时对话
    TemporaryDialog(row) {
      this.LSHH = true;
      this.talklist = [];
      // this.LSHHROW = row;
      this.toID = row.Tel1;
      nowtouser = row.Tel1;
      this.getLSJL(row.Tel1);
      this.getLSuSERmSG(row.Tel1);
    },
    //获取用户得昵称和头像
    getLSuSERmSG(tel) {
      this.axios
        .get(serve + "/api/User/search_user?userID=s" + this.sid + "&name=" + tel)
        .then((res) => {
          // console.log(res);
          if (res.data.code == 1) {
            if (res.data.result.length > 0) {
              this.LSHHROW.name = res.data.result[0].name;
              this.LSHHROW.headImg = res.data.result[0].headImg;
            } else {
              this.LSHHROW.name = tel;
              this.LSHHROW.headImg = this.male;
            }
          } else {
            this.LSHHROW.name = tel;
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //获取临时历史记录
    getLSJL(id) {
      this.axios
        .get(serve + "/api/Message/get_user_history_message?id=" + id + "&index=1")
        .then((res) => {
          // console.log(res);
          if (res.data.code == 1) {
            this.talklist = res.data.result;
            this.LSindex = 2;
            this.$nextTick(() => {
              var div = document.getElementById("scrolldIV1");
              div.scrollTop = div.scrollHeight;
            });
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    //临时对话查看对话加载更多
    LSloadmore() {
      let newtalklist = [],
        oldtalklist = [];
      var _id = this.toID;
      var _index = this.indexedDBindex;
      search(db, _id, _index, function(res) {
        // console.log(res);
        var reslist = _this.talklistextend(res);
        if (res.length > 0) {
          if (res.length == 50) {
            console.log("长度有50个");
            newtalklist = _this.talklistextend(res);
            oldtalklist = _this.talklist;
            _this.talklist = [];
            _this.talklist = newtalklist.concat(oldtalklist);
            _this.indexedDBindex = _this.indexedDBindex + 1;
          } else if (res.length < 50) {
            console.log("长度没有50个");
            _this.getmorehistory(_id, _index).then((res) => {
              var list = [];
              if (res.length > 0) {
                list = _this.MergeArray(reslist, res);
                insert(db, _id, list);
                newtalklist = list;
                oldtalklist = _this.talklist;
                _this.talklist = [];
                _this.talklist = newtalklist.concat(oldtalklist);
              }
            });
          } else {
          }
        } else {
          _this.getmorehistory(_id, _index).then((res) => {
            if (res.length > 0) {
              insert(db, _id, res);
            }
            newtalklist = res;
            oldtalklist = _this.talklist;
            _this.talklist = [];
            _this.talklist = newtalklist.concat(oldtalklist);
          });
        }
      });
    },

    //修改用户的信息
    ChangeUserMsg() {
      if (this.CUMage == "" || this.CUMname == "" || this.CUMsex == "") {
        this.$message({
          message: "请填写详细的信息",
          type: "error",
        });
      } else {
        this.axios
          .post(
            "/gu/update_cust_info_userinfo",
            this.qs.stringify({
              cust_height: this.CUMheight,
              cust_weight: this.CUMweight,
              cust_name: this.CUMname,
              cust_sex: this.CUMsex,
              cust_age: this.CUMage,
              cust_id: this.LookUserMsg.Cust_ID,
            })
          )
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.LookUserMsg.Cust_Name = this.CUMname;
              this.LookUserMsg.Cust_Sex = this.CUMsex;
              this.LookUserMsg.Cust_Age = this.CUMage;
              this.LookUserMsg.Cust_Height = this.CUMheight;
              this.LookUserMsg.Cust_Weight = this.CUMweight;
              this.CUM = false;
              this.CUMage = "";
              this.CUMname = "";
              this.CUMsex = "0";
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
      }
    },
    //统计+消息的审核
    subTJshenhe(str, zdID, custid, docid) {
      this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (zdID == "" || zdID == null || zdID == undefined) {
            return;
          }
          if (this.uid == "" || this.uid == null || this.uid == undefined) {
            return;
          }
          this.axios
            .post(
              "/gu/update_z_field_dismain_state",
              this.qs.stringify({ state: "2", kid: zdID, uid: this.servicer_id })
            )
            .then((res) => {
              // console.log(res);
              if (res.data.code == 0) {
                if (custid == null || custid == "" || custid == undefined) {
                  return;
                }
                if (docid == null || docid == "" || docid == undefined) {
                  return;
                }
                if (zdID == null || zdID == "" || zdID == undefined) {
                  return;
                }
                this.axios
                  .post(
                    "/gu/insert_t_hytl_ta",
                    this.qs.stringify({ x_cust_id: custid, doc_id: docid, kid: zdID })
                  )
                  .then((res) => {
                    if (res.data.code == 0) {
                      this.$message({
                        message: "审核成功",
                        type: "success",
                      });
                      // this.SearchMyDayZD();
                      this.$refs.uppdf.saveImage("html2canvas", zdID, "");
                    } else {
                      this.$message({
                        message: res.data.msg,
                        type: "error",
                      });
                    }
                  });
              } else {
                console.log(res);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    //统计页面诊单的提交状态改变为审核
    TJshenhe(row, type) {
      console.log(row);
      if (type == "message") {
        this.EditMsg.kid = row.op_id;
      } else {
        this.EditMsg.kid = row.KID;
        this.EditMsg.custid = row.Cust_ID;
      }
      this.EditZD = true;
      this.shenhe = true;
      this.EditMsg.noedit = false;
      // this.tcTJshenhe(row, type);
    },
    tcTJshenhe(row, type) {
      var zdID,
        custid = "",
        docid = "";
      var kid, str;
      if (type == "message") {
        kid = row.op_id;
        str = "确认审核" + row.op_id + "诊单吗?";
        zdID = row.op_id;
        this.TJresult = this.axios
          .get(
            "/field/get_z_field_detail?kid=" +
              zdID +
              "&input_id=" +
              sessionStorage.getItem("servicer_id")
          )
          .then((res) => {
            return res.data.dismain;
          });
        this.TJresult.then((res) => {
          this.subTJshenhe(str, zdID, res.Cust_ID, res.Doc_ID);
        });
      } else {
        kid = row.KID;
        str = "确认审核用户:" + row.Cust_ID + "的" + row.Dis_Name + ":" + row.KID + "诊单吗?";
        zdID = row.KID;
        custid = row.Cust_ID;
        docid = row.Doc_ID;
        this.subTJshenhe(str, zdID, custid, docid);
      }
    },
    //待处理
    wait() {
      this.axios
        .get("/field/get_gu_ac_notice_by_inputid?input_id=s" + this.sid + "&is_read=0&type=")
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.result.length > 0) {
              this.fiveList = res.data.result;
            } else {
              this.fiveList = [];
            }
          } else {
            this.fiveList = [];
          }
        });
    },
    setIsRead(row, index) {
      if (this.fiveList.length > 0) {
        if (row != "") {
          this.axios
            .post("/field/update_gu_ac_notice", this.qs.stringify({ is_read: 1, id: row.id }))
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "已读",
                });
                this.fiveList.splice(index, 1);
                this.xtmsgNum = this.xtmsgNum - 1;
                if (this.xtmsgNum == 0) {
                  this.shownewsnum = false;
                }
              }
            });
        } else {
          this.axios
            .post(
              "/field/update_gu_ac_notice",
              this.qs.stringify({ input_id: "s" + this.sid, is_read: 1 })
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "全部已读",
                });
                this.shownewsnum = false;
                this.xtmsgNum = 0;
                this.fiveList = [];
              }
            });
        }
      } else {
        this.$message({
          type: "info",
          message: "当前没有未读消息",
        });
      }
    },
    //提示有多少诊单提交上来了
    loadZD() {
      //获取待处理消息
      setInterval((res) => {
        this.axios
          .get("/field/get_gu_ac_notice_by_inputid?input_id=s" + this.sid + "&is_read=0&type=")
          .then((res) => {
            if (res.data.code == 0) {
              if (res.data.result.length > 0) {
                this.shownewsnum = true;
                this.xtmsgNum = res.data.result.length;
              } else {
                this.shownewsnum = false;
                this.xtmsgNum = 0;
              }
            }
          });
        // }
      }, 60000);
    },
    //发起视频
    launchVideo() {
      this.VideoChoiceDoc = true;
      this.toID1 = this.toID;
      this.connection1 = connection;
      this.nowtouser1 = nowtouser;
    },
    changelist() {
      readu(this.db1, this.sid, function(res) {
        _this.list = res;
      });
    },
    changetalklist() {
      read(db, nowtouser, function(res) {
        //如果有数据，直接获取最后50条
        if (res.length > 0) {
          _this.talklist = _this.talklistextend(res);
          _this.$nextTick(() => {
            var div = document.getElementById("scrolldIV");
            div.scrollTop = div.scrollHeight;
          });
          _this.indexedDBindex = 2;
        } else {
          _this.gethistory(id).then((res) => {
            insert(db, id, res);
            _this.talklist = res;
            _this.$nextTick(() => {
              var div = document.getElementById("scrolldIV");
              div.scrollTop = div.scrollHeight;
            });
          });
        }
      });
    },
    //查看直播详情
    lookchanneldetail(row) {
      let routeData = this.$router.resolve({
        path: "/talk/lookvideodetail",
        query: { id: row.id },
      });
      window.open(routeData.href, "_blank");
    },
    //是否显示系统消息弹框
    isShowNews() {
      this.isShowNewstype = !this.isShowNewstype;
      if (this.isShowNewstype == true) {
        this.wait();
        this.dialogFormVisible = false;
      }
    },
    //收取诊费
    money() {
      this.sendM = true;
    },
    sendMoneyChangeAlllist() {
      readu(this.db1, this.sid, function(res) {
        _this.list = res;
      });
    },
    sendMoneyChangeTalklist() {
      read(db, nowtouser, function(res) {
        //如果有数据，直接获取最后50条
        if (res.length > 0) {
          _this.talklist = _this.talklistextend(res);
          _this.$nextTick(() => {
            var div = document.getElementById("scrolldIV");
            div.scrollTop = div.scrollHeight;
          });
          _this.indexedDBindex = 2;
        } else {
          _this.gethistory(id).then((res) => {
            insert(db, id, res);
            _this.talklist = res;
            _this.$nextTick(() => {
              var div = document.getElementById("scrolldIV");
              div.scrollTop = div.scrollHeight;
            });
          });
        }
        _this.audioElement = document.getElementsByClassName("talklistaudio");
        console.log(_this.audioElement);
        _this.audioElement.forEach((audio, index) => {
          audio.pause();
          let playicon = audio.parentNode.firstChild.lastChild;
          playicon.className = "el-icon-video-play";
          audio.currentTime = 0;
        });
      });
    },
    //表情和菜单的显示隐藏操作
    showemoji() {
      this.emoji1 = !this.emoji1;
      this.plus = false;
    },
    showmenu() {
      this.plus = !this.plus;
      this.emoji1 = false;
    },
    focusallfalse() {
      this.plus = false;
      this.emoji1 = false;
    },
    blurallfalse(val) {
      if (val.length <= 0) {
        this.showsend = false;
        this.showplus = true;
      } else {
        this.showsend = true;
        this.showplus = false;
      }
    },
    clearsend() {
      this.showsend = false;
      this.showplus = true;
    },
    //收取诊费
    placeorder() {
      this.showsendorder = true;
    },
    //设置标签
    talksettag(obj) {
      this.settag = true;
      if (!obj) {
        this.tagId = this.custall.id;
      } else {
        this.tagId = obj.id;
      }
    },
    //手动清除未读
    clearnoread() {
      var row = this.custall;
      //把未读状态置为已读
      this.axios
        .post(serve + "/api/User/read_message", this.qs.stringify({ fromID: row.id, toID: "s48" }))
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              type: "success",
              message: "清除成功",
            });
            updateu(_this.db1, this.sid, row.id, "noReads", { noReads: 0 }, function(res) {
              readu(_this.db1, _this.sid, function(res) {
                _this.list = res;
              });
            });
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    //对数组转格式
    talklistextend(obj) {
      var arr = [];
      for (var i = 0; i < obj.length; i++) {
        arr.push(obj[i]);
      }
      arr = this.MsgSort(arr);
      return arr;
    },
    //根据时间排序
    MsgSort(obj) {
      obj.sort((a, b) => {
        let t1 = new Date(Date.parse(a.sendTime.replace(/-/g, "/")));
        let t2 = new Date(Date.parse(b.sendTime.replace(/-/g, "/")));
        return t1.getTime() - t2.getTime();
      });
      return obj;
    },
    //与服务器同步聊天
    synchronization() {
      var id = this.toID;
      this.talklistloading = true;
      del(db, "user" + id, function(res) {
        if (res == "ok") {
          //如果没有数据请求第一页
          db.transaction(function(tx) {
            tx.executeSql(
              "CREATE TABLE if not exists `user" +
                id +
                "` (`content` TEXT,`from` varchar(10),`sendTime` varchar(100),`to` varchar(50),`type` int,`id` varchar(200),`sid` varchar(200),`isRead` int)",
              [],
              function(tx, result) {
                console.log("创建stu表成功");
                _this.gethistory(id).then((res) => {
                  insert(db, id, res);
                  _this.talklistloading = false;
                  _this.talklist = res;
                  _this.$nextTick(() => {
                    var div = document.getElementById("scrolldIV");
                    div.scrollTop = div.scrollHeight;
                  });
                });
              },
              function(tx, error) {
                console.log("创建stu表失败:" + error.message);
              }
            );
          });
        }
      });
    },
    //数组去重
    MergeArray(arr1, arr2) {
      var jsonArr = [];
      let length1 = arr1.length;
      let length2 = arr2.length;
      for (let i = 0; i < length1; i++) {
        for (let j = 0; j < length2; j++) {
          //判断添加的数组是否为空了
          if (arr1.length > 0) {
            if (arr1[i]["sendTime"] === arr2[j]["sendTime"]) {
              arr1.splice(i, 1); //利用splice函数删除元素，从第i个位置，截取长度为1的元素
              length1--;
              // console.log(arr2[j]); //重复元素
            }
          }
        }
      }
      for (let n = 0; n < arr2.length; n++) {
        jsonArr.push(arr2[n]);
      }
      return jsonArr;
    },
    //过滤头像
    filterheadImg(val) {
      var url = "";
      if (val == null || val == "" || val == undefined || val == "null" || val == "undefined") {
        url = this.male;
      } else {
        url = val;
      }
      return url;
    },
    //查看诊费详情
    lookzfdetail(id, type) {
      this.lookorder = true;
      this.talkorderid = id;
      this.talkordertype = type;
    },
    //获取标签
    getfriendlistusertag() {
      this.axios
        .get("/gu/get_gu_cust_by_tel?tel=" + this.talkitem.id)
        .then((res) => {
          if (res.data.code == 0) {
            var custid = res.data.result.id;
            this.setlevelcustid = res.data.result.id;
            this.axios.get("/tag/get_gu_tag_custtag_by_custid?cust_id=" + custid).then((res) => {
              if (res.data.code == 0) {
                this.friendlistusertag = res.data.result;
              }
            });
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    //点击查看寻医表
    userlookxy() {
      this.lookuserxy = true;
    },
    //点击查看诊单
    userlookzd() {
      this.lookuserzd = true;
    },
    //点击查看反馈
    userlookfk() {
      this.lookuserfk = true;
    },
    //点击查看支付列表
    userlookzf() {
      this.lookuserzf = true;
    },
    //点击查看订单
    userlookdd() {
      this.lookuserdd = true;
    },
    //判断后缀名
    pdhzm(url) {
      var filePath = url;
      //获取最后一个.的位置
      var index = filePath.lastIndexOf(".");
      //获取后缀
      var ext = filePath.substr(index + 1);
      return ext;
    },
    //客服给寻医表审核并分配医生
    assigDoc(row) {
      // this.assigdoctor = true;
      // this.assigdoctoritem = row;
      this.axios.get("/gu/get_cust_info_by_custid?cust_id=" + row.Cust_ID).then((res) => {
        if (res.data.code == 0) {
          var docid = res.data.Cust_Info.Doc_ID;
          if (docid == "" || docid == null || docid == undefined || docid == "null") {
            this.assigdoctor = true;
            this.assigdoctoritem = row;
          } else {
            // this.$confirm(
            //   "此患者已有绑定医生。如需更换医生请先与患者沟通，与原医生解绑后才可绑定新的医生。",
            //   "提示",
            //   {
            //     confirmButtonText: "确定重新指定医生",
            //     cancelButtonText: "取消",
            //     type: "warning",
            //   }
            // )
            //   .then(() => {
            //     this.assigdoctor = true;
            //     this.assigdoctoritem = row;
            //   })
            //   .catch(() => {
            //     this.$message({
            //       type: "info",
            //       message: "已取消",
            //     });
            //   });
            this.$alert(
              "此患者已有绑定医生。如需更换医生请先与患者沟通，与原医生解绑后才可绑定新的医生。",
              "提示",
              { confirmButtonText: "确定" }
            );
          }
        }
      });
    },
    //客服给寻医表审核
    XYshenhe(row) {
      this.isshenhe = true;
      this.EditXYMsg.kid = row.KID;
      this.EditXYMsg.custid = row.Cust_ID;
      this.EditXYMsg.noedit = false;
      this.EditXY = true;

      // this.$confirm("确定审核寻医表" + row.KID, "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     // console.log({ state: 6, kid: row.KID, uid: sessionStorage.getItem("servicer_id") });
      //     this.axios
      //       .post(
      //         "/field/update_z_newseadoc_main_state",
      //         this.qs.stringify({
      //           state: 6,
      //           kid: row.KID,
      //           uid: sessionStorage.getItem("servicer_id"),
      //         })
      //       )
      //       .then((res) => {
      //         if (res.data.code == 0) {
      //           this.$message({
      //             type: "success",
      //             message: "审核成功",
      //           });
      //         }
      //       });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消审核",
      //     });
      //   });
    },
    //给患者发送好转病例
    bingli() {
      this.sendcase = true;
      this.connection1 = connection;
    },
    lssc() {
      db.transaction(function(tx) {
        tx.executeSql("drop table msglist45");
      });
    },
    isshowmenu() {
      this.clickshowhidebtn = !this.clickshowhidebtn;
      var ele = document.getElementsByClassName("el-tabs__header");
      var btn = document.getElementsByClassName("isshowmenubtn");
      if (this.clickshowhidebtn) {
        this.isshowbtn = false;
        this.ishidebtn = true;
        ele[0].style.display = "none";
        btn[0].style.left = "35px";
        this.showhidetips = "展开菜单";
      } else {
        this.isshowbtn = true;
        this.ishidebtn = false;
        ele[0].style.display = "block";
        btn[0].style.left = "135px";
        this.showhidetips = "折叠菜单";
      }
    },
    //给患者发送短信
    sendshortmessage() {
      var name = sessionStorage.getItem("name");
      var tel = this.toID;
      var uid = sessionStorage.getItem("servicer_id");
      this.axios
        .get("/gu/send_sms_str?name=" + name + "&tel=" + tel + "&uid=" + uid)
        .then((res) => {
          var obj = JSON.parse(res.data);
          if (obj.Code == "OK") {
            this.$message({
              type: "success",
              message: obj.Message,
            });
            var toID = this.toID;
            var fromID = "s" + this.sid;
            connection
              .invoke("send", toID, fromID, JSON.stringify({ name: name, id: tel }), 11)
              .then((res) => {
                if (res.err == 1) {
                  //发送成功
                  let measge = {
                    content: res.data.content,
                    from: fromID,
                    type: "11",
                    to: toID,
                    sendTime: res.data.sendTime,
                  };
                  if (toID == nowtouser) {
                    this.talklist.push(measge);
                    push(db, toID, res.data);
                  }
                  updateu(this.db1, this.sid, toID, "sendTime", measge, function(res) {
                    readu(_this.db1, _this.sid, function(res) {
                      _this.list = res;
                    });
                  });
                } else {
                  this.$message({
                    message: "发送失败，请重新发送",
                    type: "error",
                  });
                }
              });
          } else {
            this.$message({
              type: "error",
              message: obj.Message,
            });
          }
        });
    },
    changetel(val) {
      var reg = /^(\d{3})\d*(\d{4})$/;
      var aaa = val.replace(reg, "$1****$2");
      return aaa;
    },
    //获取今天明天昨天
    GetDateStr(num, str) {
      var today = new Date();
      var nowTime = today.getTime();
      var ms = 24 * 3600 * 1000 * num;
      today.setTime(parseInt(nowTime + ms));
      var oYear = today.getFullYear();
      var oMoth = (today.getMonth() + 1).toString();
      if (oMoth.length <= 1) oMoth = "0" + oMoth;
      var oDay = today.getDate().toString();
      if (oDay.length <= 1) oDay = "0" + oDay;
      return oYear + str + oMoth + str + oDay;
    },
    //反馈
    feedback() {
      this.axios
        .post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.toID, doc_id: "" }))
        .then((res) => {
          if (res.data.code == 0) {
            this.cust_id = res.data.result[0].Cust_ID;
            //如果用户没有绑定医生，不允许发反馈表
            this.axios.get("/gu/get_cust_info_by_custid?cust_id=" + this.cust_id).then((res) => {
              if (res.data.code == 0) {
                var docname = res.data.Cust_Info.Doc_ID;
                if (docname == "" || docname == null || docname == undefined) {
                  this.$message({
                    message: "请先绑定医患关系",
                    type: "error",
                  });
                } else {
                  //如果用户没有已下发状态的诊单，不允许发反馈表
                  this.axios
                    .get("/field/get_z_field_dismains?" + "state=6" + "&cust_id=" + this.cust_id)
                    .then((res) => {
                      if (res.data.code == -1) {
                        this.$message({
                          message: "当前用户没有已下发状态的诊单，不能填写反馈表",
                          type: "error",
                        });
                      } else {
                        //如果用户没有绑定疾病，不能填写反馈表
                        this.axios
                          .get("/gu/get_cust_info_by_custid?cust_id=" + this.cust_id)
                          .then((res) => {
                            if (res.data.Ill_Lib.length <= 0) {
                              this.$message({
                                message: "当前用户没有没有绑定疾病，不能填写反馈表",
                                type: "error",
                              });
                            } else {
                              //用户绑定医生、绑定疾病、有已下发状态的诊单，可以发送反馈表
                              this.feedbacktc = true;
                              this.feedbackvalue = "";
                              this.feedbacklist = [];
                              this.getfeedbackmodel(docname);
                              // this.getuserhasfeedback();
                              this.fkactive = "fkfirst";
                            }
                          });
                      }
                    });
                }
              }
            });
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //获取反馈模板
    getfeedbackmodel(docname) {
      //所有模板
      // this.axios.get("/field/get_z_newback_m").then((res) => {
      //   this.feedbackoptions = res.data.result;
      // });
      this.feedbackoptions = [];
      //根据用户的疾病查询反馈表模板
      this.axios.get("/field/get_z_newbackmain_by_custid?cust_id=" + this.cust_id).then((res) => {
        if (res.data.code == 0) {
          this.feedbackoptions = res.data.result;
        } else {
          this.$message({
            message: "该疾病没有反馈表模板",
            type: "error",
          });
        }
      });
    },
    selectfankuitab(tab, event) {
      console.log(tab);
      if (tab.name == "fksecond") {
        this.getuserhasfeedback(1);
      }
    },
    handleSizeChangefk() {},
    handleCurrentChangefk(currentPage) {
      this.getuserhasfeedback(currentPage);
    },
    //获取用户已有反馈表
    getuserhasfeedback(page) {
      this.axios
        .get(
          "/field/get_z_newback_main_by_custid?cust_id=" +
            this.cust_id +
            "&state=0126&page=" +
            page +
            "&size=6"
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.feedbacklist = res.data.result;
            this.totalCountfk = res.data.count;
          }
        });
    },
    feedbackchange(value) {
      this.feedbackmsg = this.feedbackoptions.find((item) => {
        return item.Back_ID === value;
      });
    },
    //发送反馈表
    sendfeedback() {
      //先把模板id等信息发送给后台，生成反馈表id
      this.axios
        .get("/gu/get_cust_info_by_custid?cust_id=" + this.cust_id)
        .then((res) => {
          if (res.data.code == 0) {
            this.axios
              .post(
                "/field/insert_z_newback_main",
                this.qs.stringify({
                  cust_id: this.cust_id,
                  servicer_id: this.serve_id,
                  dis_id: this.feedbackmsg.Back_ID,
                  dis_name: this.feedbackmsg.Back_Name,
                  doc_id: res.data.Cust_Info.Doc_ID,
                })
              )
              .then((res) => {
                //doc_id: res.data.Cust_Info.Doc_ID,
                if (res.data.code == 0) {
                  console.log(res);
                  var KID = res.data.result.KID;
                  this.feedbackxf(KID);
                }
              });
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //发送反馈表给患者
    feedbackxf(kid) {
      this.$confirm("此操作会将发送反馈表给用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var toID = this.toID;
          var fromID = "s" + this.sid;
          connection.invoke("send", toID, fromID, JSON.stringify({ id: kid }), 13).then((res) => {
            console.log(res);
            this.feedbacktc = false;
            this.feedbackvalue = "";
            this.$message({
              message: "发送成功",
              type: "success",
            });
            let measge = {
              content: res.data.content,
              from: fromID,
              type: "13",
              to: toID,
              sendTime: res.data.sendTime,
            };
            if (toID == nowtouser) {
              this.talklist.push(measge);
              push(db, toID, res.data);
            }
            updateu(this.db1, this.sid, toID, "sendTime", measge, function(res) {
              readu(_this.db1, _this.sid, function(res) {
                _this.list = res;
              });
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.getuserhasfeedback();
        });
    },
    //更改反馈表状态
    // changefeedbackstate(_state, _kid) {
    //   this.axios
    //     .post(
    //       "/field/update_z_newback_main_state",
    //       this.qs.stringify({ state: _state, kid: _kid, uid: this.serve_id })
    //     )
    //     .then((res) => {
    //       if (res.data.code == 0) {
    //         this.$message({
    //           type: "success",
    //           message: "修改成功",
    //         });
    //         //修改状态成功后再给用户发送一条消息
    //         this.feedbackxf(_kid);
    //       }
    //     });
    // },
    //给H5进来的患者手动建档
    setuserfiles() {
      this.setuserfilesbtn = true;
    },
    subsetuserfiles() {
      var _tel = "";
      var myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.setuserfilestel)) {
        this.$message({
          type: "error",
          message: "手机号格式不正确",
        });
        return;
      } else {
        _tel = this.setuserfilestel;
      }
      var _pwd = _tel.substring(_tel.length - 6);
      var _targetid = "s" + sessionStorage.getItem("id");
      this.axios
        .post(
          "/gu/gu_cust_reg",
          this.qs.stringify({
            tel: _tel,
            pwd: _pwd,
            rf: "客服" + sessionStorage.getItem("servicer_id") + "建档",
          })
        )
        .then((res) => {
          this.axios
            .post(
              serve + "/api/User/add_user",
              this.qs.stringify({ tel: _tel, targetId: _targetid })
            )
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({
                  type: "success",
                  message: "创建成功",
                });
                putu(
                  this.db1,
                  this.sid,
                  _tel,
                  {
                    desc: "",
                    name: "",
                    id: _tel,
                    sendTime: _this.getCurrentTime(),
                    noReads: 0,
                    headImg: "",
                    hasmsg: false,
                    isOnline: false,
                  },
                  function(res) {
                    readu(_this.db1, _this.sid, function(res) {
                      _this.list = res;
                    });
                  }
                );
              } else if (res.data.code == -2) {
                this.$alert("此用户已存在，属于客服：" + res.data.msg, "提醒", {
                  confirmButtonText: "确定",
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
            });
        });
      this.setuserfilesbtn = false;
      this.setuserfilestel = "";
      this.setuserfilesbtncustid = false;
    },
    setuserfilescustidopen() {
      this.setuserfilesbtncustid = true;
      this.setuserfilescustid = "";
    },
    //客户编号建档
    subsetuserfilescustid() {
      this.axios
        .post("/gu/get_cust_info_by_custid?cust_id=" + this.setuserfilescustid)
        .then((res) => {
          if (res.data.code == 0) {
            this.setuserfilestel = res.data.result.Tel1;
            this.subsetuserfiles();
          } else {
            this.$message({
              type: "error",
              message: "系统内查无此用户",
            });
          }
        });
    },
    //设置下次联系时间
    talkSetNextContactTime(item, from) {
      this.TalkSetNextContactTime = true;
      if (item == "" || item == null || item == undefined) {
        this.ContactId = this.custall.id;
      } else {
        this.ContactId = item.id;
      }
    },
    //修改完成后改变ID
    changenextcontactTime(time, content, notes) {
      // this.LookUserMsgtalk.nextConcatTime = time;
      // this.LookUserMsgtalk.nextConcatContent = content;
      // this.LookUserMsgtalk.notes = notes;
      this.nextTCMsg.nextConcatTime = time;
      this.nextTCMsg.nextConcatContent = content;
      this.nextTCMsg.notes = notes;
    },
    //统计改变客服
    tjchange() {
      // this.this.myMsg.serveid = this.tjkefuchoice;
      let date1 = this.GetDateStr(0, "-");
      let date2 = this.GetDateStr(1, "-");
      this.getkftj(date1, date2);
    },
    tjgetkefulist() {
      this.axios.get(serve + "/api/Employee/get_employee?Id=" + this.servicer_id).then((res) => {
        if (res.data.code == 1) {
          this.tjkefulist = res.data.result;
        }
      });
    },
    getkftj(date1, date2) {
      var servicerid = "";
      if (
        !(this.tjkefuchoice == "" || this.tjkefuchoice == null || this.tjkefuchoice == undefined)
      ) {
        servicerid = this.tjkefuchoice;
      } else {
        servicerid = sessionStorage.getItem("servicer_id");
      }
      this.axios
        .get(
          "/field/get_z_newseadoc_mains?servicer_id=" +
            servicerid +
            "&datetime1=" +
            date1 +
            "&datetime2=" +
            date2 +
            "&state=1"
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.tjnum.xybzs = res.data.count;
          } else {
          }
        })
        .catch((res) => {});
      this.axios
        .get(
          "/field/get_z_newseadoc_mains?servicer_id=" +
            servicerid +
            "&commit_datetime1=" +
            date1 +
            "&commit_datetime2=" +
            date2 +
            "&state=2"
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.tjnum.xyyzq = res.data.count;
          } else {
          }
        })
        .catch((res) => {});
      this.axios
        .get(
          "/field/get_z_newseadoc_mains?servicer_id=" +
            servicerid +
            "&commit_datetime1=" +
            date1 +
            "&commit_datetime2=" +
            date2 +
            "&state=6"
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.tjnum.xyysh = res.data.count;
          } else {
          }
        })
        .catch((res) => {});
    },
    //反馈打回
    changefeedbackstate(state, row) {
      this.$confirm("此操作将打回反馈表并重新发送给用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              "/field/update_z_newback_main_state",
              this.qs.stringify({ state: "0", kid: row.KID, uid: this.servicer_id })
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: "打回成功",
                  type: "success",
                });
                this.feedbacktc = false;
                var toID = this.toID;
                var fromID = "s" + this.sid;
                connection
                  .invoke("send", toID, fromID, JSON.stringify({ id: row.KID }), 13)
                  .then((res) => {
                    if (res.err == 1) {
                      //发送成功
                      let measge = {
                        content: res.data.content,
                        from: fromID,
                        type: "13",
                        to: toID,
                        sendTime: res.data.sendTime,
                      };
                      if (toID == nowtouser) {
                        this.talklist.push(measge);
                        push(db, toID, res.data);
                      }
                      updateu(this.db1, this.sid, toID, "sendTime", measge, function(res) {
                        readu(_this.db1, _this.sid, function(res) {
                          _this.list = res;
                        });
                      });
                      // this.selectxy = "";
                      // this.choicexunyi = false;
                    } else {
                      this.$message({
                        message: "发送失败，请重新发送",
                        type: "error",
                      });
                    }
                  });
              } else {
                console.log(res);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //反馈审核
    fkshenhe(row, from) {
      console.log(row);
      this.lookfeedback = true;
      this.feedbackid = row.KID;
      this.feedbacknoedit = false;
      this.shenhe = true;
    },
    fkshenhetcout(row) {
      this.$confirm("此操作将审核反馈表, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (row.KID == "" || row.KID == null || row.KID == undefined) {
            return;
          }
          if (this.uid == "" || this.uid == null || this.uid == undefined) {
            return;
          }
          this.axios
            .post(
              "/field/update_z_newback_main_state",
              this.qs.stringify({ state: "2", kid: row.KID, uid: this.servicer_id })
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.axios
                  .post(
                    "/gu/insert_t_hytl_ta",
                    this.qs.stringify({ x_cust_id: row.Cust_ID, doc_id: row.Doc_ID, kid: row.KID })
                  )
                  .then((res) => {
                    if (res.data.code == 0) {
                      this.$message({
                        message: "审核成功",
                        type: "success",
                      });
                      this.$refs.uploadfkpdf.saveImage("html2canvas", row.KID, "");
                    } else {
                      this.$message({
                        message: res.data.msg,
                        type: "error",
                      });
                    }
                  });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    drugtcfalse() {
      this.Newchoicezd = false;
      this.feedbacktc = false;
    },
    //生成pdf
    shengchengpdf(row) {
      this.pdfloading = true;
      this.axios.get("/pdf/create_pdf_chufang?kid=" + row.KID).then((res) => {
        if (res.data.code == 0) {
          this.pdfloading = false;
          var url = "http://192.168.11.253:8080/public/" + res.data.file;
          window.open(url, "_blank");
        } else {
          this.pdfloading = false;
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
    },
    //设置等级弹窗
    setLevelNote() {
      this.setLevel = true;
      this.setlevelval = "";
    },
    //提交用户等级
    subsetLevel() {
      var form = {
        living_level: this.setlevelval,
        id: this.setlevelcustid,
      };
      console.log(form);
      this.axios
        .post("/gu/update_gu_cust_livinglevel", this.qs.stringify(form))
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "设置成功",
            });
            this.setLevel = false;
            this.LookUserlivinglevel = this.setlevelval;
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //回复模板
    showReplyTemplate() {
      this.setreply = true;
      this.plus = false;
    },
    suu(res) {
      this.message = res;
    },
    //商城订单购买商品提示
    lookSCorderdetail(id) {
      this.isshowscorder = true;
      this.scorderid = id;
    },
    //发送语音消息
    wantSoundRecording() {
      this.SoundRecording = true;
    },
    Listening(index) {
      var middle = "audio" + index;
      middle = this.$refs[middle][0];
      var playicon = middle.parentNode.firstChild.lastChild;
      if (middle.paused) {
        this.pauseAll();
        middle.play();
        playicon.className = "el-icon-video-pause";
      } else {
        middle.pause();
        playicon.className = "el-icon-video-play";
      }
    },
    endplay(index) {
      var middle = "audio" + index;
      middle = this.$refs[middle][0];
      middle.pause();
      var playicon = middle.parentNode.firstChild.lastChild;
      playicon.className = "el-icon-video-play";
    },
    //控制页面内同时只有一个audio再播放
    handlePlay() {
      const audios = this.audioElement;
      const pauseAll = (exclude) => {
        audios.forEach((audio, index) => {
          if (exclude !== index) {
            audio.pause();
            let playicon = audio.parentNode.firstChild.lastChild;
            playicon.className = "el-icon-video-play";
            audio.currentTime = 0;
          }
        });
      };
      audios.forEach((audio, index) => {
        audio.addEventListener("play", () => {
          let playicon = audio.parentNode.firstChild.lastChild;
          playicon.className = "el-icon-video-pause";
          pauseAll(index);
        });
      });
    },
    pauseAll(exclude) {
      const audios = this.audioElement;
      audios.forEach((audio, index) => {
        // if (exclude !== index) {
        audio.pause();
        let playicon = audio.parentNode.firstChild.lastChild;
        playicon.className = "el-icon-video-play";
        audio.currentTime = 0;
        // }
      });
    },
    //发送视频卡
    sendVideoCard() {
      this.isshowVideoCard = true;
    },
    //下次联系时间s
    rightmenusetnextcontenttime() {
      this.rightmenusetnextcontenttimetc = true;
      let row = this.custall;
      // this.ContactId = row.id;
      console.log(this.nextTCMsg);
      this.axios.get(serve + "/api/UserExtend/get_user_extend?userId=" + row.id).then((res) => {
        if (res.data.code == 1) {
          if (res.data.result == null || res.data.result == undefined || res.data.result == "") {
            // this.nextTCMsg.desc = "";
            this.nextTCMsg.nextConcatContent = "";
            this.nextTCMsg.nextConcatTime = "";
            this.nextTCMsg.notes = "";
          } else {
            // this.nextTCMsg.desc = res.data.result.desc;
            this.nextTCMsg.nextConcatContent = res.data.result.nextConcatContent;
            this.nextTCMsg.nextConcatTime = res.data.result.nextConcatTime;
            this.nextTCMsg.notes = res.data.result.notes;
          }
        }
      });
    },
    //查看用户浏览足迹
    userlookfoot() {
      this.lookfoot = true;
      this.footuserid = this.talkitem.id;
    },
  },
};
