const addud = function(db, id, list) {
    db.transaction(function(tx) {
      for (let m = 0; m < list.length; m++) {
        tx.executeSql(
          "insert into msglistdoc" +
            id +
            " (desc,name,id,sendTime,noReadCount,headImg,hasmsg,isOnline) values(?,?,?,?,?,?,?,?)",
          [
            list[m].desc,
            list[m].name,
            list[m].id,
            list[m].sendTime,
            list[m].noReadCount,
            list[m].headImg,
            'false',
            list[m].isOnline,
          ],
          function() {
            console.log("插入成功");
          },
          function(tx, error) {
            console.log(error);
          }
        );
      }
    });
  };
  const putud = function(db, sid, id, obj, callback) {
    db.transaction(function(tx) {
      tx.executeSql(
        "select * FROM msglistdoc" + sid + " where id = ?",
        [id],
        function(tx, result) {
          if (result.rows.length > 0) {
            callback("has");
          } else {
              // console.log('nohas')
            db.transaction(function(tx) {
              tx.executeSql(
                "insert into msglistdoc" +
                  sid +
                  " (desc,name,id,sendTime,noReadCount,headImg,hasmsg,isOnline) values(?,?,?,?,?,?,?,?)",
                [
                  obj.desc,
                  obj.name,
                  obj.id,
                  obj.sendTime,
                  obj.noReadCount,
                  obj.headImg,
                  obj.hasmsg,
                  obj.isOnline,
                ],
                function() {
                  callback("");
                  console.log("插入成功");
                },
                function(tx, error) {
                  console.log(error);
                }
              );
            });
          }
        },
        function(tx, error) {
          console.log("error");
        }
      );
    });
  };
  const readud = function(db, id, callback) {
    db.transaction(function(tx) {
      tx.executeSql(
        "select * FROM msglistdoc" + id + " order by sendTime desc, noReadCount desc", //查列表
        [],
        function(tx, result) {
          //执行成功的回调函数
          //在这里对result 做你想要做的事情吧.
          callback(result.rows);
        },
        function(tx, error) {
          console.log("查询失败: " + error.message);
        }
      );
    });
  };
  const delud = function(db, sid, id, callback) {
    db.transaction(function(tx) {
      tx.executeSql(
        "delete from msglistdoc" + sid + " where id = ?",
        [id],
        function(tx, result) {
          callback("ok");
        },
        function(tx, error) {
          callback("error");
        }
      );
    });
  };
  const updateud = function(db, sid, id, zd, obj, callback) {
    db.transaction(function(tx) {
      tx.executeSql(
        "update msglistdoc" + sid + " set " + zd + " = ? where id = ?",
        [obj[zd], id],
        function(tx, result) {
          console.log("更新成功");
          callback("");
        },
        function(tx, error) {
          console.log("更新失败" + error.message);
        }
      );
    });
  };
  const inud = function(db,sid,id,callback){
    db.transaction(function(tx) {
      tx.executeSql(
        "select * FROM msglistdoc" + sid + " where id = ?",
        [id],
        function(tx, result) {
          //  console.log(id)
          // console.log(result);
          if(result.rows.length>0){
            callback("in");
          }else{
            callback("out");
          }
        },
        function(tx, error) {
          console.log("不存在" + error.message);
          callback("out");
        }
      );
    });
  }
  export { addud, readud, delud, updateud, putud ,inud};
  